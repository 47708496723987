<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="price.id === null">{{ 'NEWPRICE' | translate }}</span>
            <span *ngIf="price.id !== null">{{ 'EDITPRICE' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="priceForm" id="priceForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="priceForm" >
            <input type="hidden" name="id" id="priceId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-tag"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="priceName" formControlName="name" type="text" pInputText [required]="true"> 
                      <label for="priceName" class="req">{{ 'NAME' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="priceForm.controls['name'].invalid && (priceForm.controls['name'].dirty || priceForm.controls['name'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="priceForm.controls['name'].errors !== null">
                  <div *ngIf="priceForm.controls['name'].errors.required">
                   {{ 'NAMEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="priceNameAr" formControlName="nameAr" type="text" pInputText [required]="false"> 
                      <label for="priceNameAr" class="">{{ 'NAMEAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="priceNameFr" formControlName="nameFr" type="text" pInputText [required]="false"> 
                      <label for="priceNameFr" class="">{{ 'NAMEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="priceAmount" formControlName="amount" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [step]="0.1" [showButtons]="true" [required]="true"></p-inputNumber>
                      <label for="priceAmount" class="req">{{ 'AMOUNT' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="priceForm.controls['amount'].invalid && (priceForm.controls['amount'].dirty || priceForm.controls['amount'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="priceForm.controls['amount'].errors !== null">
                  <div *ngIf="priceForm.controls['amount'].errors.required">
                   {{ 'AMOUNTREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="priceCurrency" formControlName="currency" type="text" pInputText [required]="true"> 
                      <label for="priceCurrency" class="req">{{ 'CURRENCY' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="priceForm.controls['currency'].invalid && (priceForm.controls['currency'].dirty || priceForm.controls['currency'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="priceForm.controls['currency'].errors !== null">
                  <div *ngIf="priceForm.controls['currency'].errors.required">
                   {{ 'CURRENCYREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKPRICES' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="price.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!priceForm.valid || disableSubmit"> <!--  [disabled]="priceForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!priceForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

