<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="company.id === null">{{ 'NEWCOMPANY' | translate }}</span>
            <span *ngIf="company.id !== null">{{ 'EDITCOMPANY' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="companyForm" id="companyForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="companyForm" >
            <input type="hidden" name="id" id="companyId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-tag"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="companyName" formControlName="name" type="text" pInputText [required]="true"> 
                      <label for="companyName" class="req">{{ 'NAME' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="companyForm.controls['name'].invalid && (companyForm.controls['name'].dirty || companyForm.controls['name'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="companyForm.controls['name'].errors !== null">
                  <div *ngIf="companyForm.controls['name'].errors.required">
                   {{ 'NAMEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-image"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="companyPicture" formControlName="picture" type="text" pInputText [required]="false"> 
                      <label for="companyPicture" class="">{{ 'PICTURE' | translate }}</label>
                    </span>
                    <button type="button" pButton pRipple icon="pi pi-folder" styleClass="p-button-info" (click)="openFilerDialog('picture')"></button> 
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-world"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="companyWebsite" formControlName="website" type="text" pInputText [required]="false"> 
                      <label for="companyWebsite" class="">{{ 'WEBSITE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-email"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="companyEmail" formControlName="email" type="text" pInputText [required]="false"> 
                      <label for="companyEmail" class="">{{ 'EMAIL' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-phone"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="companyPhone" formControlName="phone" type="text" pInputText [required]="false"> 
                      <label for="companyPhone" class="">{{ 'PHONE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-printer"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="companyFax" formControlName="fax" type="text" pInputText [required]="false"> 
                      <label for="companyFax" class="">{{ 'FAX' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="companyIsActive" formControlName="isActive" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="companyIsActive" class="p-d-inline-block p-ml-2 req">{{ 'ISACTIVE' | translate }}</label>
                </div>
                <div *ngIf="companyForm.controls['isActive'].invalid && (companyForm.controls['isActive'].dirty || companyForm.controls['isActive'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="companyForm.controls['isActive'].errors !== null">
                  <div *ngIf="companyForm.controls['isActive'].errors.required">
                   {{ 'ISACTIVEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKCOMPANIES' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="company.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!companyForm.valid || disableSubmit"> <!--  [disabled]="companyForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!companyForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

