import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public title = 'MumEat Manager';

  constructor(
    private config: PrimeNGConfig,
    private router: Router,
    private translate: TranslateService
  ) {
    let locale = localStorage.getItem('locale');
    console.log('locale', locale);
    if (locale === null) {
      locale = 'fr';
    }

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(locale);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(locale);

    localStorage.setItem('locale', locale);
    this.config.setTranslation({});

  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

}
