import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Video, VideosResult } from '../interfaces';
import { VideoSegment } from '../interfaces/video-segment.interface';

@Injectable({
  providedIn: 'root'
})
export class VideoConverterService {

  private uri = `${environment.apiUrl}video-converter/`;

  constructor(private http: HttpClient) {

  }

  generateThumbnail(data: {id?: number|null, time: number}): Observable<any> {
    return this.http.put(this.uri + `generate-thumbnail`, data);
  }

  setDuration(data: {id?: number|null, duration: number}): Observable<any> {
    return this.http.put(this.uri + `set-duration`, data);
  }

  encode(data: {id?: number|null, size: string, format: string}): Observable<any> {
    return this.http.put(this.uri + `encode`, data);
  }

  removeSegments(data: {id?: number|null, segments: VideoSegment[], size: string, format: string}): Observable<any> {
    return this.http.put(this.uri + `remove-segments`, data);
  }

  getLogFile(url: string): Observable<any> {
    return this.http.get(environment.filesUrl + url);
  }

}
