import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { CommonService } from '../../../services/common/common.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { SharingsDataService, SharingsColumnsService } from '../../services';
import { DialogSharingFormComponent } from './sharing-form/sharing-form.component';
import { DialogSharingDetailsComponent } from './sharing-details/sharing-details.component';
import { Sharing, SharingsResult } from '../../interfaces';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-sharings',
  templateUrl: './sharings.component.html',
  styleUrls: ['./sharings.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class SharingsComponent implements OnInit, OnDestroy {

  private sharingAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private sharingEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private sharingDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  public sharingToDelete: Sharing = {};
  public sharings: Sharing[] = [];
  public sharing: Sharing = {};
  public selectedSharings: Sharing[] = [];
  public submitted: boolean = false;
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '100'};
  public chunkSize: number = 200;
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  private _selectedColumns: Column[] = [];
  @ViewChild('importer', { static: false })
  public importer?: FileUpload;
  public importChooseLabel: string = 'Import';
  public importLabel: string = 'Import CSV';

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private common: CommonService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private sharingsDataService: SharingsDataService,
    private sharingsColumnsService: SharingsColumnsService,
  ) {
    this.importChooseLabel = this.translate.instant('IMPORT');
    this.importLabel = this.translate.instant('IMPORTCSV');
  }

  ngOnInit(): void {
    console.log('SharingsComponent', 'ngOnInit');
    this.isInitialized = true;
    this.titleService.setTitle('Sharings - Management');
    this.primengConfig.ripple = true;
    this.getSharings();
  }

  ngOnDestroy(): void {
    console.log('SharingsComponent', 'ngOnDestroy');
    this.isInitialized = false;
  }

  getSharings(): void {
    if (!this.isInitialized) {
      return;
    }
    if (parseInt(this.parameters.offset + '', 10) === 0) {
      this.isLoading = true;
    }
    this.sharingsDataService.getSharings(this.parameters).subscribe((response: SharingsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        if (parseInt(this.parameters.offset + '', 10) === 0) {
          this.sharings = response.results;
        } else {
          this.sharings = this.sharings.concat(response.results);
        }
        if (response.inlineCount > this.sharings.length) {
          this.parameters.offset = (parseInt(this.parameters.offset + '', 10) + parseInt(this.parameters.limit + '', 10)) + '';
          this.getSharings();
        } else {
          this.parameters.offset = '0';
        }
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SHARINGSNOTLOADED')});
    });
  }

  importSharings(event: any): void {
    if (typeof event.currentFiles === 'undefined') {
      return;
    }
    if (event.currentFiles.length === 0) {
      return;
    }
    const inputFile = event.currentFiles[0];
    const reader = new FileReader();
    let rows: any[] = [];
    reader.onload = (e: any) => {
      if (typeof this.importer !== 'undefined') {
        this.importer.clear();
      }
      const text = <string>e.target.result;
      rows = ToolsService.csvToArray(this.columns, text, ',');
      if (rows.length > 0) {
        let proceed = 0;
        const j = rows.length;
        for (let i = 0; i < j; i += this.chunkSize) {
          this.isLoading = true;
          const chunk = rows.slice(i, i + this.chunkSize);
          if (typeof chunk[0].id === 'undefined') {
            this.sharingsDataService.addSharings(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('SHARINGSCREATED')});
                if (proceed === j) {
                  this.getSharings();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SHARINGSNOTCREATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SHARINGSNOTCREATED') + ' ' + msg});
            });
          } else {
            this.sharingsDataService.updateSharings(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('SHARINGSUPDATED')});
                if (proceed === j) {
                  this.getSharings();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SHARINGSNOTUPDATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SHARINGSNOTCREATED') + ' ' + msg});
            });
          }
        }
      }
    };
    reader.readAsText(inputFile);
  }

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.sharingsColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('sharings.' + this.columns[i].field + '.showFiled', show);
    }
  }

  deleteSelectedSharings() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Sharings?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.sharingsDataService.deleteSharings(this.selectedSharings).subscribe((response) => {
          if (response === null) {
            this.messageService.clear();
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Sharings Deleted', life: 3000});
            this.getSharings();
          } else {
            this.messageService.clear();
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Sharings not deleted'});
          }
        }, (error: any) => {
          this.messageService.clear();
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Sharings not deleted'});
        });
        this.selectedSharings = [];
      }
    });
  }

  exportPdf() {
    ToolsService.exportPdf(this.sharings, this.columns, 'Sharings');
  }

  exportExcel() {
    ToolsService.exportExcel(this.sharings, this.columns, 'Sharings');
  }

  exportCsv() {
    ToolsService.exportCsv(this.sharings, this.columns, 'Sharings');
  }

  refresh() {
    this.sharingsDataService.clearCache();
    this.getSharings();
  }

  sharingAdd(): void {
    if (!this.navigation.getParamValue('sharings.addInDialog', true)) {
      this.router.navigate(['/sharings/add']);
      return;
    }
    this.sharingAddDialog = this.dialogService.open(DialogSharingFormComponent, {
      header: '', // this.translate.instant('NEWSHARING'),
      width: '80%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'add',
        sharing: {id: null},
        users: this.sharingsColumnsService.users,
      },
    });

    this.sharingAddDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'info', summary: 'Success', detail: 'Sharing added'});
        this.getSharings();
      }
    });
  }

  sharingEdit(sharing: Sharing): void {
    if (!this.navigation.getParamValue('sharings.editInDialog', true)) {
      this.router.navigate(['/sharings/edit/' + sharing.id]);
      return;
    }
    this.sharingEditDialog = this.dialogService.open(DialogSharingFormComponent, {
      header: '', // this.translate.instant('EDITSHARING') + ' ' + sharing.name,
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'edit',
        sharing: sharing,
        users: this.sharingsColumnsService.users,
      },
    });

    this.sharingEditDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Sharing updated'});
        this.getSharings();
      }
    });
  }

  sharingDetails(sharing: Sharing): void {
    if (!this.navigation.getParamValue('sharings.detailsInDialog', true)) {
      this.router.navigate(['/sharings/details/' + sharing.id]);
      return;
    }
    this.sharingDetailsDialog = this.dialogService.open(DialogSharingDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        sharing: sharing,
      }
    });
  }

  sharingDelete(sharing: Sharing): void {
    this.sharingToDelete = sharing;
    this.messageService.clear();
    this.messageService.add({
      key: 'sharingDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want delete ' + sharing.name + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.sharingsDataService.deleteSharing(this.sharingToDelete).subscribe((res: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Sharing deleted'});
      this.getSharings();
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Sharing not deleted'});
    });
  }

  onDeleteReject(): void {
    this.sharingToDelete = {};
    this.messageService.clear('sharingDelete');
  }

}
