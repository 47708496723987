import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { Ingredient, IngredientsResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class IngredientsDataService {

  private uri: string = `${environment.apiUrl}ingredients`;
  private cacheKey: string = 'ingredients.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getIngredient(id: number): Observable<Ingredient|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getIngredients(parameters: {[param: string]: string | string[]}): Observable<IngredientsResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addIngredient(data: Ingredient): Observable<Ingredient|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addIngredients(data: Ingredient[]): Observable<Ingredient[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updateIngredient(data: Ingredient): Observable<Ingredient|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updateIngredients(data: Ingredient[]): Observable<Ingredient[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchIngredient(data: Ingredient): Observable<Ingredient|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchIngredients(data: Ingredient[]): Observable<Ingredient[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deleteIngredient(data: Ingredient): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deleteIngredients(data: Ingredient[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((ingredient) => {
      ids.push(ingredient.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
