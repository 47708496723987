import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { LogsDataService, LogsColumnsService } from '../../../services';
import { Log, LogsResult } from '../../../interfaces';

@Component({
  selector: 'app-log-form',
  templateUrl: './log-form.component.html',
  styleUrls: ['./log-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class LogFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public log: Log = {};
  public logForm: FormGroup = new FormGroup({});
  public logs: Log[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;


  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private logsDataService: LogsDataService,
    public logsColumnsService: LogsColumnsService,
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'LogFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Log - Edit');
        this.getLog(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.log !== 'undefined') {
      this.log = this.dynamicDialogConfig.data.log;
    }
    if (typeof this.log.id === 'undefined' || this.log.id === null) {
      this.log.id = null;
    }
    this.getLogs();
    this.prepareFrom();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'LogFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'LogFormComponent');
  }

  getLog(id: number): void {
    this.isLoading = true;
    this.logsDataService.getLog(id).subscribe((response: Log|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.log = response;
        this.prepareFrom();
      }
    });
  }

  getLogs(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.logs !== 'undefined' && this.dynamicDialogConfig.data.logs.length > 0) {
      this.logs = this.dynamicDialogConfig.data.logs;
      return;
    }
    this.logsDataService.getLogs(this.parameters).subscribe((response: LogsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.logs = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.logForm = new FormGroup({
      id: new FormControl(this.log.id, []),
      entity: new FormControl(this.log.entity, [Validators.required, ]),
      foreignKey: new FormControl(this.log.foreignKey, [Validators.required, ]),
      action: new FormControl(this.log.action, [Validators.required, ]),
      ipAddress: new FormControl(this.log.ipAddress, []),
      userAgent: new FormControl(this.log.userAgent, []),
    });
  }

  list() {
    this.router.navigate(['/logs']);
  }

  showErrors() {
      this.showErrors();
      const invalidFields: string[] = [];
      Object.keys(this.logForm.controls).forEach((field: any) => {
        const control = this.logForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.logForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.log = this.logForm.value;
    this.disableSubmit = true;
    console.log('log', this.log);
    if (typeof this.log.id !== 'undefined' && this.log.id !== null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.logsDataService.updateLog(this.log).subscribe((data: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('LOGUPDATED')});
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('LOGNOTUPDATED') + ' ' + error.message});
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.logsDataService.addLog(this.log).subscribe((data: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('LOGCREATED')});
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('LOGNOTCREATED') + ' ' + error.message});
        console.warn(error);
    });
  }

}

@Component({
  selector: 'app-log-form',
  templateUrl: './log-form.component.html',
  styleUrls: ['./log-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogLogFormComponent extends LogFormComponent {
}

