<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="pack.id === null">{{ 'NEWPACK' | translate }}</span>
            <span *ngIf="pack.id !== null">{{ 'EDITPACK' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="packForm" id="packForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="packForm" >
            <input type="hidden" name="id" id="packId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-cog"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="packPackageTypeId" [autoDisplayFirst]="false" formControlName="packageType" [options]="packsColumnsService.packageTypes" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="packPackageTypeId" class="req">{{ 'PACKAGETYPE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="packForm.controls['packageType'].invalid && (packForm.controls['packageType'].dirty || packForm.controls['packageType'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="packForm.controls['packageType'].errors !== null">
                  <div *ngIf="packForm.controls['packageType'].errors.required">
                   {{ 'PACKAGETYPEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-tag"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="packName" formControlName="name" type="text" pInputText [required]="true"> 
                      <label for="packName" class="req">{{ 'NAME' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="packForm.controls['name'].invalid && (packForm.controls['name'].dirty || packForm.controls['name'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="packForm.controls['name'].errors !== null">
                  <div *ngIf="packForm.controls['name'].errors.required">
                   {{ 'NAMEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="packNameAr" formControlName="nameAr" type="text" pInputText [required]="false"> 
                      <label for="packNameAr" class="">{{ 'NAMEAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="packNameFr" formControlName="nameFr" type="text" pInputText [required]="false"> 
                      <label for="packNameFr" class="">{{ 'NAMEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-image"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="packPicture" formControlName="picture" type="text" pInputText [required]="false"> 
                      <label for="packPicture" class="">{{ 'PICTURE' | translate }}</label>
                    </span>
                    <button type="button" pButton pRipple icon="pi pi-folder" styleClass="p-button-info" (click)="openFilerDialog('picture')"></button> 
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-info-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="packDescription" formControlName="description" type="text" pInputText [required]="false"> 
                      <label for="packDescription" class="">{{ 'DESCRIPTION' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-align-justify"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="packDescriptionAr" formControlName="descriptionAr" type="text" pInputText [required]="false"> 
                      <label for="packDescriptionAr" class="">{{ 'DESCRIPTIONAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-align-justify"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="packDescriptionFr" formControlName="descriptionFr" type="text" pInputText [required]="false"> 
                      <label for="packDescriptionFr" class="">{{ 'DESCRIPTIONFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-money"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="packPriceId" [autoDisplayFirst]="false" formControlName="price" [options]="packsColumnsService.prices" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="packPriceId" class="req">{{ 'PRICE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="packForm.controls['price'].invalid && (packForm.controls['price'].dirty || packForm.controls['price'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="packForm.controls['price'].errors !== null">
                  <div *ngIf="packForm.controls['price'].errors.required">
                   {{ 'PRICEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="packOperatorKey" formControlName="operatorKey" type="text" pInputText [required]="true"> 
                      <label for="packOperatorKey" class="req">{{ 'OPERATORKEY' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="packForm.controls['operatorKey'].invalid && (packForm.controls['operatorKey'].dirty || packForm.controls['operatorKey'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="packForm.controls['operatorKey'].errors !== null">
                  <div *ngIf="packForm.controls['operatorKey'].errors.required">
                   {{ 'OPERATORKEYREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <label for="packMode" class="req">{{ 'MODE' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of packsColumnsService.modesOptions; let i = index">
                      <p-radioButton inputId="packMode_{{ i }}" formControlName="mode" name="mode" [value]="option.value"></p-radioButton>
                      <label for="packMode_{{ i }}">{{ option.label | translate }}</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="packForm.controls['mode'].invalid && (packForm.controls['mode'].dirty || packForm.controls['mode'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="packForm.controls['mode'].errors !== null">
                  <div *ngIf="packForm.controls['mode'].errors.required">
                   {{ 'MODEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="packIsPublished" formControlName="isPublished" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="packIsPublished" class="p-d-inline-block p-ml-2 req">{{ 'ISPUBLISHED' | translate }}</label>
                </div>
                <div *ngIf="packForm.controls['isPublished'].invalid && (packForm.controls['isPublished'].dirty || packForm.controls['isPublished'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="packForm.controls['isPublished'].errors !== null">
                  <div *ngIf="packForm.controls['isPublished'].errors.required">
                   {{ 'ISPUBLISHEDREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-timer"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="packDuration" formControlName="duration" [step]="1" [showButtons]="true" [required]="true"></p-inputNumber>
                      <label for="packDuration" class="req">{{ 'DURATION' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="packForm.controls['duration'].invalid && (packForm.controls['duration'].dirty || packForm.controls['duration'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="packForm.controls['duration'].errors !== null">
                  <div *ngIf="packForm.controls['duration'].errors.required">
                   {{ 'DURATIONREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKPACKS' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="pack.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!packForm.valid || disableSubmit"> <!--  [disabled]="packForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!packForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

