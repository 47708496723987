<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="ingredient.id === null">{{ 'NEWINGREDIENT' | translate }}</span>
            <span *ngIf="ingredient.id !== null">{{ 'EDITINGREDIENT' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="ingredientForm" id="ingredientForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="ingredientForm" >
            <input type="hidden" name="id" id="ingredientId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-tag"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="ingredientName" formControlName="name" type="text" pInputText [required]="true"> 
                      <label for="ingredientName" class="req">{{ 'NAME' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="ingredientForm.controls['name'].invalid && (ingredientForm.controls['name'].dirty || ingredientForm.controls['name'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="ingredientForm.controls['name'].errors !== null">
                  <div *ngIf="ingredientForm.controls['name'].errors.required">
                   {{ 'NAMEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="ingredientNameAr" formControlName="nameAr" type="text" pInputText [required]="false"> 
                      <label for="ingredientNameAr" class="">{{ 'NAMEAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="ingredientNameFr" formControlName="nameFr" type="text" pInputText [required]="false"> 
                      <label for="ingredientNameFr" class="">{{ 'NAMEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <!-- <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="ingredientSlug" formControlName="slug" type="text" pInputText [required]="true"> 
                      <label for="ingredientSlug" class="req">{{ 'SLUG' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="ingredientForm.controls['slug'].invalid && (ingredientForm.controls['slug'].dirty || ingredientForm.controls['slug'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="ingredientForm.controls['slug'].errors !== null">
                  <div *ngIf="ingredientForm.controls['slug'].errors.required">
                   {{ 'SLUGREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div> -->
              <!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <!-- <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="ingredientSlugAr" formControlName="slugAr" type="text" pInputText [required]="false"> 
                      <label for="ingredientSlugAr" class="">{{ 'SLUGAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div>
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="ingredientSlugFr" formControlName="slugFr" type="text" pInputText [required]="false"> 
                      <label for="ingredientSlugFr" class="">{{ 'SLUGFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div>
            </div> -->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="ingredientLetter" formControlName="letter" type="text" pInputText [required]="true"> 
                      <label for="ingredientLetter" class="req">{{ 'LETTER' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="ingredientForm.controls['letter'].invalid && (ingredientForm.controls['letter'].dirty || ingredientForm.controls['letter'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="ingredientForm.controls['letter'].errors !== null">
                  <div *ngIf="ingredientForm.controls['letter'].errors.required">
                   {{ 'LETTERREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="ingredientLetterAr" formControlName="letterAr" type="text" pInputText [required]="false"> 
                      <label for="ingredientLetterAr" class="">{{ 'LETTERAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="ingredientLetterFr" formControlName="letterFr" type="text" pInputText [required]="false"> 
                      <label for="ingredientLetterFr" class="">{{ 'LETTERFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-image"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="ingredientPicture" formControlName="picture" type="text" pInputText [required]="false"> 
                      <label for="ingredientPicture" class="">{{ 'PICTURE' | translate }}</label>
                    </span>
                    <button type="button" pButton pRipple icon="pi pi-folder" styleClass="p-button-info" (click)="openFilerDialog('picture')"></button> 
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-info-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="ingredientDescription" formControlName="description" type="text" pInputText [required]="true"> 
                      <label for="ingredientDescription" class="req">{{ 'DESCRIPTION' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="ingredientForm.controls['description'].invalid && (ingredientForm.controls['description'].dirty || ingredientForm.controls['description'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="ingredientForm.controls['description'].errors !== null">
                  <div *ngIf="ingredientForm.controls['description'].errors.required">
                   {{ 'DESCRIPTIONREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-align-justify"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="ingredientDescriptionAr" formControlName="descriptionAr" type="text" pInputText [required]="false"> 
                      <label for="ingredientDescriptionAr" class="">{{ 'DESCRIPTIONAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-align-justify"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="ingredientDescriptionFr" formControlName="descriptionFr" type="text" pInputText [required]="false"> 
                      <label for="ingredientDescriptionFr" class="">{{ 'DESCRIPTIONFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="ingredientDefaultUnit" formControlName="defaultUnit" type="text" pInputText [required]="true"> 
                      <label for="ingredientDefaultUnit" class="req">{{ 'DEFAULTUNIT' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="ingredientForm.controls['defaultUnit'].invalid && (ingredientForm.controls['defaultUnit'].dirty || ingredientForm.controls['defaultUnit'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="ingredientForm.controls['defaultUnit'].errors !== null">
                  <div *ngIf="ingredientForm.controls['defaultUnit'].errors.required">
                   {{ 'DEFAULTUNITREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="ingredientDefaultUnitAr" formControlName="defaultUnitAr" type="text" pInputText [required]="false"> 
                      <label for="ingredientDefaultUnitAr" class="">{{ 'DEFAULTUNITAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="ingredientDefaultUnitFr" formControlName="defaultUnitFr" type="text" pInputText [required]="false"> 
                      <label for="ingredientDefaultUnitFr" class="">{{ 'DEFAULTUNITFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKINGREDIENTS' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="ingredient.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!ingredientForm.valid || disableSubmit"> <!--  [disabled]="ingredientForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!ingredientForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

