import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  public data: any = {
    username: ''
  };

  public reset: any = {
    username: '',
    code: '',
    newPassword: '',
    confirmPassword: '',
  };

  public msgs: Message[] = [];
  public isLoading: boolean = false;
  public showResetDialog: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
        console.log('params', params);
      if (typeof params.email !== 'undefined') {
          this.reset.username = params.email;
        }
        if (typeof params.username !== 'undefined') {
          this.reset.username = params.username;
        }
        if (typeof params.token !== 'undefined') {
          this.reset.code = params.token;
          this.showResetDialog = true;
        }
      }
    );
  }

  ngOnDestroy(): void {
  }

  login(): void {
    this.router.navigate(['/login']);
  }

  forgotPassword(): void {
    console.log('data', this.data);
    if (this.data.username === '') {
      this.msgs = [];
      this.msgs.push({severity: 'warn', summary:'Empty field', detail:'Username or email is required.'});
      return;
    }
    this.isLoading = true;
    this.authService.forgotPassword(this.data).subscribe((response: any) => {
      this.isLoading = false;
      this.reset.username = this.data.username;
      this.msgs = [];
      this.msgs.push({severity: 'success', summary:'Notification', detail:'Reset code sent.'});
      console.log('response', response);
      this.showResetDialog = true;
    }, (error: any) => {
      console.log('error', error);
      this.isLoading = false;
      this.msgs = [];
      this.msgs.push({severity: 'error', summary:'Error', detail:'Reset code not sent.'});
      return;

    });
  }

  resetPassword(): void {
    console.log('reset', this.reset);
    if (this.reset.code === '') {
      this.msgs = [];
      this.msgs.push({severity: 'warn', summary:'Empty field', detail:'Code is required.'});
      return;
    }
    if (this.reset.newPassword === '') {
      this.msgs = [];
      this.msgs.push({severity: 'warn', summary:'Empty field', detail:'Password is required.'});
      return;
    }
    if (this.reset.newPassword !== this.reset.confirmPassword) {
      this.msgs = [];
      this.msgs.push({severity: 'warn', summary:'Not match', detail:'Confirm Password is not the same.'});
      return;
    }
    this.isLoading = true;
    this.authService.resetPassword(this.reset).subscribe((response: any) => {
      this.isLoading = false;
      console.log('response', response);
      this.showResetDialog = false;
      this.msgs = [];
      this.msgs.push({severity: 'success', summary:'Notification', detail:'New password updated.'});
    }, (error: any) => {
      console.log('error', error);
      this.isLoading = false;
      this.msgs = [];
      this.msgs.push({severity: 'error', summary:'Error', detail:'New password not updated.'});
      return;

    });
  }

}
