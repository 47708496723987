import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { CommonService } from '../../../services/common/common.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { SubCategoriesDataService, SubCategoriesColumnsService } from '../../services';
import { DialogSubCategoryFormComponent } from './sub-category-form/sub-category-form.component';
import { DialogSubCategoryDetailsComponent } from './sub-category-details/sub-category-details.component';
import { SubCategory, SubCategoriesResult } from '../../interfaces';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-sub-categories',
  templateUrl: './sub-categories.component.html',
  styleUrls: ['./sub-categories.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class SubCategoriesComponent implements OnInit, OnDestroy {

  private subCategoryAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private subCategoryEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private subCategoryDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  public subCategoryToDelete: SubCategory = {};
  public subCategories: SubCategory[] = [];
  public subCategory: SubCategory = {};
  public selectedSubCategories: SubCategory[] = [];
  public submitted: boolean = false;
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '100'};
  public chunkSize: number = 200;
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  private _selectedColumns: Column[] = [];
  @ViewChild('importer', { static: false })
  public importer?: FileUpload;
  public importChooseLabel: string = 'Import';
  public importLabel: string = 'Import CSV';

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private common: CommonService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private subCategoriesDataService: SubCategoriesDataService,
    private subCategoriesColumnsService: SubCategoriesColumnsService,
  ) {
    this.importChooseLabel = this.translate.instant('IMPORT');
    this.importLabel = this.translate.instant('IMPORTCSV');
  }

  ngOnInit(): void {
    console.log('SubCategoriesComponent', 'ngOnInit');
    this.isInitialized = true;
    this.titleService.setTitle('Sub Categories - Management');
    this.primengConfig.ripple = true;
    this.getSubCategories();
  }

  ngOnDestroy(): void {
    console.log('SubCategoriesComponent', 'ngOnDestroy');
    this.isInitialized = false;
  }

  getSubCategories(): void {
    if (!this.isInitialized) {
      return;
    }
    if (parseInt(this.parameters.offset + '', 10) === 0) {
      this.isLoading = true;
    }
    this.subCategoriesDataService.getSubCategories(this.parameters).subscribe((response: SubCategoriesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        if (parseInt(this.parameters.offset + '', 10) === 0) {
          this.subCategories = response.results;
        } else {
          this.subCategories = this.subCategories.concat(response.results);
        }
        if (response.inlineCount > this.subCategories.length) {
          this.parameters.offset = (parseInt(this.parameters.offset + '', 10) + parseInt(this.parameters.limit + '', 10)) + '';
          this.getSubCategories();
        } else {
          this.parameters.offset = '0';
        }
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SUBCATEGORIESNOTLOADED')});
    });
  }

  importSubCategories(event: any): void {
    if (typeof event.currentFiles === 'undefined') {
      return;
    }
    if (event.currentFiles.length === 0) {
      return;
    }
    const inputFile = event.currentFiles[0];
    const reader = new FileReader();
    let rows: any[] = [];
    reader.onload = (e: any) => {
      if (typeof this.importer !== 'undefined') {
        this.importer.clear();
      }
      const text = <string>e.target.result;
      rows = ToolsService.csvToArray(this.columns, text, ',');
      if (rows.length > 0) {
        let proceed = 0;
        const j = rows.length;
        for (let i = 0; i < j; i += this.chunkSize) {
          this.isLoading = true;
          const chunk = rows.slice(i, i + this.chunkSize);
          if (typeof chunk[0].id === 'undefined') {
            this.subCategoriesDataService.addSubCategories(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('SUBCATEGORIESCREATED')});
                if (proceed === j) {
                  this.getSubCategories();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SUBCATEGORIESNOTCREATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SUBCATEGORIESNOTCREATED') + ' ' + msg});
            });
          } else {
            this.subCategoriesDataService.updateSubCategories(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('SUBCATEGORIESUPDATED')});
                if (proceed === j) {
                  this.getSubCategories();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SUBCATEGORIESNOTUPDATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SUBCATEGORIESNOTCREATED') + ' ' + msg});
            });
          }
        }
      }
    };
    reader.readAsText(inputFile);
  }

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.subCategoriesColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('subCategories.' + this.columns[i].field + '.showFiled', show);
    }
  }

  deleteSelectedSubCategories() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Sub Categories?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.subCategoriesDataService.deleteSubCategories(this.selectedSubCategories).subscribe((response) => {
          if (response === null) {
            this.messageService.clear();
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Sub Categories Deleted', life: 3000});
            this.getSubCategories();
          } else {
            this.messageService.clear();
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Sub Categories not deleted'});
          }
        }, (error: any) => {
          this.messageService.clear();
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Sub Categories not deleted'});
        });
        this.selectedSubCategories = [];
      }
    });
  }

  exportPdf() {
    ToolsService.exportPdf(this.subCategories, this.columns, 'SubCategories');
  }

  exportExcel() {
    ToolsService.exportExcel(this.subCategories, this.columns, 'SubCategories');
  }

  exportCsv() {
    ToolsService.exportCsv(this.subCategories, this.columns, 'SubCategories');
  }

  refresh() {
    this.subCategoriesDataService.clearCache();
    this.getSubCategories();
  }

  subCategoryAdd(): void {
    if (!this.navigation.getParamValue('subCategories.addInDialog', true)) {
      this.router.navigate(['/sub-categories/add']);
      return;
    }
    this.subCategoryAddDialog = this.dialogService.open(DialogSubCategoryFormComponent, {
      header: '', // this.translate.instant('NEWSUBCATEGORY'),
      width: '80%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'add',
        subCategory: {id: null},
        categories: this.subCategoriesColumnsService.categories,
        users: this.subCategoriesColumnsService.users,
      },
    });

    this.subCategoryAddDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'info', summary: 'Success', detail: 'SubCategory added'});
        this.getSubCategories();
      }
    });
  }

  subCategoryEdit(subCategory: SubCategory): void {
    if (!this.navigation.getParamValue('subCategories.editInDialog', true)) {
      this.router.navigate(['/sub-categories/edit/' + subCategory.id]);
      return;
    }
    this.subCategoryEditDialog = this.dialogService.open(DialogSubCategoryFormComponent, {
      header: '', // this.translate.instant('EDITSUBCATEGORY') + ' ' + subCategory.name,
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'edit',
        subCategory: subCategory,
        categories: this.subCategoriesColumnsService.categories,
        users: this.subCategoriesColumnsService.users,
      },
    });

    this.subCategoryEditDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'SubCategory updated'});
        this.getSubCategories();
      }
    });
  }

  subCategoryDetails(subCategory: SubCategory): void {
    if (!this.navigation.getParamValue('subCategories.detailsInDialog', true)) {
      this.router.navigate(['/sub-categories/details/' + subCategory.id]);
      return;
    }
    this.subCategoryDetailsDialog = this.dialogService.open(DialogSubCategoryDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        subCategory: subCategory,
      }
    });
  }

  subCategoryDelete(subCategory: SubCategory): void {
    this.subCategoryToDelete = subCategory;
    this.messageService.clear();
    this.messageService.add({
      key: 'subCategoryDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want delete ' + subCategory.name + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.subCategoriesDataService.deleteSubCategory(this.subCategoryToDelete).subscribe((res: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Sub Category deleted'});
      this.getSubCategories();
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Sub Category not deleted'});
    });
  }

  onDeleteReject(): void {
    this.subCategoryToDelete = {};
    this.messageService.clear('subCategoryDelete');
  }

}
