import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { CompaniesDataService, CompaniesColumnsService } from '../../../services';
import { Company, CompaniesResult } from '../../../interfaces';
import { DialogFilerComponent } from '../../filer/filer.component';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class CompanyFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public company: Company = {};
  public companyForm: FormGroup = new FormGroup({});
  public companies: Company[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;

  private filerDialog: DynamicDialogRef = new DynamicDialogRef();

  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private companiesDataService: CompaniesDataService,
    public companiesColumnsService: CompaniesColumnsService,
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'CompanyFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Company - Edit');
        this.getCompany(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.company !== 'undefined') {
      this.company = this.dynamicDialogConfig.data.company;
    }
    if (typeof this.company.id === 'undefined' || this.company.id === null) {
      this.company.id = null;
      this.company.isActive = false;
    }
    this.getCompanies();
    this.prepareFrom();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'CompanyFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'CompanyFormComponent');
  }

  getCompany(id: number): void {
    this.isLoading = true;
    this.companiesDataService.getCompany(id).subscribe((response: Company|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.company = response;
        this.prepareFrom();
      }
    });
  }

  getCompanies(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.companies !== 'undefined' && this.dynamicDialogConfig.data.companies.length > 0) {
      this.companies = this.dynamicDialogConfig.data.companies;
      return;
    }
    this.companiesDataService.getCompanies(this.parameters).subscribe((response: CompaniesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.companies = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.companyForm = new FormGroup({
      id: new FormControl(this.company.id, []),
      name: new FormControl(this.company.name, [Validators.required, ]),
      picture: new FormControl(this.company.picture, []),
      website: new FormControl(this.company.website, []),
      email: new FormControl(this.company.email, []),
      phone: new FormControl(this.company.phone, []),
      fax: new FormControl(this.company.fax, []),
      isActive: new FormControl(this.company.isActive, [Validators.required, ]),
    });
  }

  list() {
    this.router.navigate(['/companies']);
  }

  showErrors() {
      this.showErrors();
      const invalidFields: string[] = [];
      Object.keys(this.companyForm.controls).forEach((field: any) => {
        const control = this.companyForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.companyForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.company = this.companyForm.value;
    this.disableSubmit = true;
    console.log('company', this.company);
    if (typeof this.company.id !== 'undefined' && this.company.id !== null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.companiesDataService.updateCompany(this.company).subscribe((data: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('COMPANYUPDATED')});
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('COMPANYNOTUPDATED') + ' ' + error.message});
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.companiesDataService.addCompany(this.company).subscribe((data: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('COMPANYCREATED')});
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('COMPANYNOTCREATED') + ' ' + error.message});
        console.warn(error);
    });
  }

  openFilerDialog(field: string): void {
    let url = '';
    const control = this.companyForm.get(field);
    if (control !== null) {
      url = control.value;
    }
    this.filerDialog = this.dialogService.open(DialogFilerComponent, {
      header: '', // this.translate.instant('FILEMANAGER'),
      width: '90%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1500,
      data: {
        path: 'companies',
        field: field,
        url: url,
      },
    });
    this.filerDialog.onClose.subscribe((response: any) => {
      if (response && response.selected) {
        const control = this.companyForm.get(field);
        if (control !== null) {
          control.setValue(response.selected.webPath);
        }
      }
    });
  }

}

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogCompanyFormComponent extends CompanyFormComponent {
}

