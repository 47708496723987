<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="log.id === null">{{ 'NEWLOG' | translate }}</span>
            <span *ngIf="log.id !== null">{{ 'EDITLOG' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="logForm" id="logForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="logForm" >
            <input type="hidden" name="id" id="logId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-table "></i>
                    </span>
                    <span class="p-float-label">
                      <input id="logEntity" formControlName="entity" type="text" pInputText [required]="true"> 
                      <label for="logEntity" class="req">{{ 'ENTITY' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="logForm.controls['entity'].invalid && (logForm.controls['entity'].dirty || logForm.controls['entity'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="logForm.controls['entity'].errors !== null">
                  <div *ngIf="logForm.controls['entity'].errors.required">
                   {{ 'ENTITYREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-project-diagram "></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="logForeignKey" formControlName="foreignKey" [step]="1" [showButtons]="true" [required]="true"></p-inputNumber>
                      <label for="logForeignKey" class="req">{{ 'FOREIGNKEY' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="logForm.controls['foreignKey'].invalid && (logForm.controls['foreignKey'].dirty || logForm.controls['foreignKey'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="logForm.controls['foreignKey'].errors !== null">
                  <div *ngIf="logForm.controls['foreignKey'].errors.required">
                   {{ 'FOREIGNKEYREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="logAction" formControlName="action" type="text" pInputText [required]="true"> 
                      <label for="logAction" class="req">{{ 'ACTION' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="logForm.controls['action'].invalid && (logForm.controls['action'].dirty || logForm.controls['action'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="logForm.controls['action'].errors !== null">
                  <div *ngIf="logForm.controls['action'].errors.required">
                   {{ 'ACTIONREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-apple"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="logIpAddress" formControlName="ipAddress" type="text" pInputText [required]="false"> 
                      <label for="logIpAddress" class="">{{ 'IPADDRESS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-birthday-cake"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="logUserAgent" formControlName="userAgent" type="text" pInputText [required]="false"> 
                      <label for="logUserAgent" class="">{{ 'USERAGENT' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKLOGS' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="log.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!logForm.valid || disableSubmit"> <!--  [disabled]="logForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!logForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

