import { Injectable } from '@angular/core';
import { CacheItem } from '../../MumEat/interfaces/cache-item.interface';

@Injectable({ providedIn: 'root' })
export class CacheService {

    public cacheTtl: number = 36000;

    public storage: Storage = sessionStorage;

    constructor() {
    }

    get(key: string): any|null {
        const cache: any = this.storage.getItem(key);
        if (cache !== null) {
            const item: CacheItem = JSON.parse(cache);
            if ((item.createdAt + this.cacheTtl) > Date.now()) {
                return item.data;

            }
        }
        return null;
    }

    set(key: string, data: any): void {
        const item: CacheItem = {
            createdAt: Date.now(),
            data: data
        };
        const cache = JSON.stringify(item);;
        this.storage.setItem(key, cache);
    }

    remove(key: string): void {
        this.storage.removeItem(key);
    }

}
