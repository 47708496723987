<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="subscription.id === null">{{ 'NEWSUBSCRIPTION' | translate }}</span>
            <span *ngIf="subscription.id !== null">{{ 'EDITSUBSCRIPTION' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="subscriptionForm" id="subscriptionForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="subscriptionForm" >
            <input type="hidden" name="id" id="subscriptionId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="subscriptionVisitId" [autoDisplayFirst]="false" formControlName="visit" [options]="subscriptionsColumnsService.visits" optionValue="id" optionLabel="ip" [required]="false" [filter]="true"></p-dropdown>
                      <label for="subscriptionVisitId" class="">{{ 'VISIT' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="subscriptionPackId" [autoDisplayFirst]="false" formControlName="pack" [options]="subscriptionsColumnsService.packs" optionValue="id" optionLabel="name" [required]="false" [filter]="true"></p-dropdown>
                      <label for="subscriptionPackId" class="">{{ 'PACK' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <label for="subscriptionStatus" class="req">{{ 'STATUS' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of subscriptionsColumnsService.statusesOptions; let i = index">
                      <p-radioButton inputId="subscriptionStatus_{{ i }}" formControlName="status" name="status" [value]="option.value"></p-radioButton>
                      <label for="subscriptionStatus_{{ i }}">{{ option.label | translate }}</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="subscriptionForm.controls['status'].invalid && (subscriptionForm.controls['status'].dirty || subscriptionForm.controls['status'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="subscriptionForm.controls['status'].errors !== null">
                  <div *ngIf="subscriptionForm.controls['status'].errors.required">
                   {{ 'STATUSREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-hashtag"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="subscriptionReference" formControlName="reference" type="text" pInputText [required]="false"> 
                      <label for="subscriptionReference" class="">{{ 'REFERENCE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="subscriptionToken" formControlName="token" type="text" pInputText [required]="false"> 
                      <label for="subscriptionToken" class="">{{ 'TOKEN' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="subscriptionMsisdn" formControlName="msisdn" type="text" pInputText [required]="false"> 
                      <label for="subscriptionMsisdn" class="">{{ 'MSISDN' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="subscriptionVoucher" formControlName="voucher" type="text" pInputText [required]="false"> 
                      <label for="subscriptionVoucher" class="">{{ 'VOUCHER' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-sort-numeric-asc "></i>
                    </span>
                    <span class="p-float-label">
                      <input id="subscriptionSerialNumber" formControlName="serialNumber" type="text" pInputText [required]="false"> 
                      <label for="subscriptionSerialNumber" class="">{{ 'SERIALNUMBER' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="subscriptionAmount" formControlName="amount" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [step]="0.1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="subscriptionAmount" class="">{{ 'AMOUNT' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="subscriptionCurrency" formControlName="currency" type="text" pInputText [required]="false"> 
                      <label for="subscriptionCurrency" class="">{{ 'CURRENCY' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-timer"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="subscriptionDuration" formControlName="duration" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [step]="0.1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="subscriptionDuration" class="">{{ 'DURATION' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-calendar"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="subscriptionStartDate" formControlName="startDate" [showTime]="true" hourFormat="24" [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [required]="false" yearRange="1900:2030" [stepHour]="1" [stepMinute]="15" [stepSecond]="0"></p-calendar>
                      <label for="subscriptionStartDate" class="">{{ 'STARTDATE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-calendar"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="subscriptionEndDate" formControlName="endDate" [showTime]="true" hourFormat="24" [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [required]="false" yearRange="1900:2030" [stepHour]="1" [stepMinute]="15" [stepSecond]="0"></p-calendar>
                      <label for="subscriptionEndDate" class="">{{ 'ENDDATE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-money"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="subscriptionPriceId" [autoDisplayFirst]="false" formControlName="price" [options]="subscriptionsColumnsService.prices" optionValue="id" optionLabel="name" [required]="false" [filter]="true"></p-dropdown>
                      <label for="subscriptionPriceId" class="">{{ 'PRICE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-gem "></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="subscriptionPriceValue" formControlName="priceValue" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [step]="0.1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="subscriptionPriceValue" class="">{{ 'PRICEVALUE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-birthday-cake"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="subscriptionUserAgent" formControlName="userAgent" type="text" pInputText [required]="false"> 
                      <label for="subscriptionUserAgent" class="">{{ 'USERAGENT' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKSUBSCRIPTIONS' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="subscription.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!subscriptionForm.valid || disableSubmit"> <!--  [disabled]="subscriptionForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!subscriptionForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

