<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="pushNotification.id === null">{{ 'NEWPUSHNOTIFICATION' | translate }}</span>
            <span *ngIf="pushNotification.id !== null">{{ 'EDITPUSHNOTIFICATION' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="pushNotificationForm" id="pushNotificationForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="pushNotificationForm" >
            <input type="hidden" name="id" id="pushNotificationId" formControlName="id" />
            <div class="p-fluid p-grid">
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'PUSHNOTIFICATIONA' | translate }}</h2>
              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <label for="pushNotificationType" class="req">{{ 'TYPE' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of pushNotificationsColumnsService.typesOptions; let i = index">
                      <p-radioButton inputId="pushNotificationType_{{ i }}" formControlName="type" name="type" [value]="option.value"></p-radioButton>
                      <label for="pushNotificationType_{{ i }}">{{ option.label | translate }}</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="pushNotificationForm.controls['type'].invalid && (pushNotificationForm.controls['type'].dirty || pushNotificationForm.controls['type'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="pushNotificationForm.controls['type'].errors !== null">
                  <div *ngIf="pushNotificationForm.controls['type'].errors.required">
                   {{ 'TYPEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'PUSHNOTIFICATIONZ' | translate }}</h2>
              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-tags"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushNotificationTitle" formControlName="title" type="text" pInputText [required]="true"> 
                      <label for="pushNotificationTitle" class="req">{{ 'TITLE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="pushNotificationForm.controls['title'].invalid && (pushNotificationForm.controls['title'].dirty || pushNotificationForm.controls['title'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="pushNotificationForm.controls['title'].errors !== null">
                  <div *ngIf="pushNotificationForm.controls['title'].errors.required">
                   {{ 'TITLEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-tags"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushNotificationTitleAr" formControlName="titleAr" type="text" pInputText [required]="false"> 
                      <label for="pushNotificationTitleAr" class="">{{ 'TITLEAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-tags"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushNotificationTitleFr" formControlName="titleFr" type="text" pInputText [required]="false"> 
                      <label for="pushNotificationTitleFr" class="">{{ 'TITLEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-commenting"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushNotificationMessage" formControlName="message" type="text" pInputText [required]="true"> 
                      <label for="pushNotificationMessage" class="req">{{ 'MESSAGE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="pushNotificationForm.controls['message'].invalid && (pushNotificationForm.controls['message'].dirty || pushNotificationForm.controls['message'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="pushNotificationForm.controls['message'].errors !== null">
                  <div *ngIf="pushNotificationForm.controls['message'].errors.required">
                   {{ 'MESSAGEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-commenting"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushNotificationMessageAr" formControlName="messageAr" type="text" pInputText [required]="false"> 
                      <label for="pushNotificationMessageAr" class="">{{ 'MESSAGEAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-commenting"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushNotificationMessageFr" formControlName="messageFr" type="text" pInputText [required]="false"> 
                      <label for="pushNotificationMessageFr" class="">{{ 'MESSAGEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-folder"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushNotificationCategory" formControlName="category" type="text" pInputText [required]="false"> 
                      <label for="pushNotificationCategory" class="">{{ 'CATEGORY' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-certificate"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="pushNotificationBadge" formControlName="badge" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="pushNotificationBadge" class="">{{ 'BADGE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <label for="pushNotificationSound" class="">{{ 'SOUND' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of pushNotificationsColumnsService.soundsOptions; let i = index">
                      <p-radioButton inputId="pushNotificationSound_{{ i }}" formControlName="sound" name="sound" [value]="option.value"></p-radioButton>
                      <label for="pushNotificationSound_{{ i }}">{{ option.label | translate }}</label>
                    </div>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-newspaper"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="pushNotificationRecipeId" [autoDisplayFirst]="false" formControlName="recipe" [options]="pushNotificationsColumnsService.recipes" optionValue="id" optionLabel="title" [required]="false" [filter]="true"></p-dropdown>
                      <label for="pushNotificationRecipeId" class="">{{ 'RECIPE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
              <div class="p-fluid p-grid">
                
                <div class="p-col-6">
              
                  <div class="p-field p-col-12">
                    <div class="p-inputgroup">
                      <span class="p-inputgroup-addon">
                        <i class="far fa-newspaper"></i>
                      </span>
                      <span class="p-float-label">
                        <p-dropdown inputId="pushNotificationPostId" [autoDisplayFirst]="false" formControlName="post"
                          [options]="pushNotificationsColumnsService.posts" optionValue="id" optionLabel="title" [required]="false"
                          [filter]="true"></p-dropdown>
                        <label for="pushNotificationPostId" class="">{{ 'POST' | translate }}</label>
                      </span>
                    </div>
                  </div>
              
                </div>
                <!--/p-col-6-->
              </div>
              <!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-file-video"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="pushNotificationVideoId" [autoDisplayFirst]="false" formControlName="video" [options]="pushNotificationsColumnsService.videos" optionValue="id" optionLabel="name" [required]="false" [filter]="true"></p-dropdown>
                      <label for="pushNotificationVideoId" class="">{{ 'VIDEO' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="pushNotificationSending" formControlName="sending" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="pushNotificationSending" class="p-d-inline-block p-ml-2 ">{{ 'SENDING' | translate }}</label>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-alarm-clock"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="pushNotificationSendingTime" formControlName="sendingTime" [showTime]="true" hourFormat="24" [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [required]="false" yearRange="1900:2030" [stepHour]="1" [stepMinute]="15" [stepSecond]="0"></p-calendar>
                      <label for="pushNotificationSendingTime" class="">{{ 'SENDINGTIME' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKPUSHNOTIFICATIONS' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="pushNotification.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!pushNotificationForm.valid || disableSubmit"> <!--  [disabled]="pushNotificationForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!pushNotificationForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

