import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { CommonService } from '../../../services/common/common.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { QuizAnswersDataService, QuizAnswersColumnsService } from '../../services';
import { DialogQuizAnswerFormComponent } from './quiz-answer-form/quiz-answer-form.component';
import { DialogQuizAnswerDetailsComponent } from './quiz-answer-details/quiz-answer-details.component';
import { QuizAnswer, QuizAnswersResult } from '../../interfaces';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-quiz-answers',
  templateUrl: './quiz-answers.component.html',
  styleUrls: ['./quiz-answers.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class QuizAnswersComponent implements OnInit, OnDestroy {

  private quizAnswerAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private quizAnswerEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private quizAnswerDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  public quizAnswerToDelete: QuizAnswer = {};
  public quizAnswers: QuizAnswer[] = [];
  public quizAnswer: QuizAnswer = {};
  public selectedQuizAnswers: QuizAnswer[] = [];
  public submitted: boolean = false;
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '100'};
  public chunkSize: number = 200;
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  private _selectedColumns: Column[] = [];
  @ViewChild('importer', { static: false })
  public importer?: FileUpload;
  public importChooseLabel: string = 'Import';
  public importLabel: string = 'Import CSV';

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private common: CommonService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private quizAnswersDataService: QuizAnswersDataService,
    private quizAnswersColumnsService: QuizAnswersColumnsService,
  ) {
    this.importChooseLabel = this.translate.instant('IMPORT');
    this.importLabel = this.translate.instant('IMPORTCSV');
  }

  ngOnInit(): void {
    console.log('QuizAnswersComponent', 'ngOnInit');
    this.isInitialized = true;
    this.titleService.setTitle('Quiz Answers - Management');
    this.primengConfig.ripple = true;
    this.getQuizAnswers();
  }

  ngOnDestroy(): void {
    console.log('QuizAnswersComponent', 'ngOnDestroy');
    this.isInitialized = false;
  }

  getQuizAnswers(): void {
    if (!this.isInitialized) {
      return;
    }
    if (parseInt(this.parameters.offset + '', 10) === 0) {
      this.isLoading = true;
    }
    this.quizAnswersDataService.getQuizAnswers(this.parameters).subscribe((response: QuizAnswersResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        if (parseInt(this.parameters.offset + '', 10) === 0) {
          this.quizAnswers = response.results;
        } else {
          this.quizAnswers = this.quizAnswers.concat(response.results);
        }
        if (response.inlineCount > this.quizAnswers.length) {
          this.parameters.offset = (parseInt(this.parameters.offset + '', 10) + parseInt(this.parameters.limit + '', 10)) + '';
          this.getQuizAnswers();
        } else {
          this.parameters.offset = '0';
        }
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('QUIZANSWERSNOTLOADED')});
    });
  }

  importQuizAnswers(event: any): void {
    if (typeof event.currentFiles === 'undefined') {
      return;
    }
    if (event.currentFiles.length === 0) {
      return;
    }
    const inputFile = event.currentFiles[0];
    const reader = new FileReader();
    let rows: any[] = [];
    reader.onload = (e: any) => {
      if (typeof this.importer !== 'undefined') {
        this.importer.clear();
      }
      const text = <string>e.target.result;
      rows = ToolsService.csvToArray(this.columns, text, ',');
      if (rows.length > 0) {
        let proceed = 0;
        const j = rows.length;
        for (let i = 0; i < j; i += this.chunkSize) {
          this.isLoading = true;
          const chunk = rows.slice(i, i + this.chunkSize);
          if (typeof chunk[0].id === 'undefined') {
            this.quizAnswersDataService.addQuizAnswers(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('QUIZANSWERSCREATED')});
                if (proceed === j) {
                  this.getQuizAnswers();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('QUIZANSWERSNOTCREATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('QUIZANSWERSNOTCREATED') + ' ' + msg});
            });
          } else {
            this.quizAnswersDataService.updateQuizAnswers(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('QUIZANSWERSUPDATED')});
                if (proceed === j) {
                  this.getQuizAnswers();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('QUIZANSWERSNOTUPDATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('QUIZANSWERSNOTCREATED') + ' ' + msg});
            });
          }
        }
      }
    };
    reader.readAsText(inputFile);
  }

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.quizAnswersColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('quizAnswers.' + this.columns[i].field + '.showFiled', show);
    }
  }

  deleteSelectedQuizAnswers() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Quiz Answers?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.quizAnswersDataService.deleteQuizAnswers(this.selectedQuizAnswers).subscribe((response) => {
          if (response === null) {
            this.messageService.clear();
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Quiz Answers Deleted', life: 3000});
            this.getQuizAnswers();
          } else {
            this.messageService.clear();
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Quiz Answers not deleted'});
          }
        }, (error: any) => {
          this.messageService.clear();
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Quiz Answers not deleted'});
        });
        this.selectedQuizAnswers = [];
      }
    });
  }

  exportPdf() {
    ToolsService.exportPdf(this.quizAnswers, this.columns, 'QuizAnswers');
  }

  exportExcel() {
    ToolsService.exportExcel(this.quizAnswers, this.columns, 'QuizAnswers');
  }

  exportCsv() {
    ToolsService.exportCsv(this.quizAnswers, this.columns, 'QuizAnswers');
  }

  refresh() {
    this.quizAnswersDataService.clearCache();
    this.getQuizAnswers();
  }

  quizAnswerAdd(): void {
    if (!this.navigation.getParamValue('quizAnswers.addInDialog', true)) {
      this.router.navigate(['/quiz-answers/add']);
      return;
    }
    this.quizAnswerAddDialog = this.dialogService.open(DialogQuizAnswerFormComponent, {
      header: '', // this.translate.instant('NEWQUIZANSWER'),
      width: '80%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'add',
        quizAnswer: {id: null},
        quizzes: this.quizAnswersColumnsService.quizzes,
        users: this.quizAnswersColumnsService.users,
      },
    });

    this.quizAnswerAddDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'info', summary: 'Success', detail: 'QuizAnswer added'});
        this.getQuizAnswers();
      }
    });
  }

  quizAnswerEdit(quizAnswer: QuizAnswer): void {
    if (!this.navigation.getParamValue('quizAnswers.editInDialog', true)) {
      this.router.navigate(['/quiz-answers/edit/' + quizAnswer.id]);
      return;
    }
    this.quizAnswerEditDialog = this.dialogService.open(DialogQuizAnswerFormComponent, {
      header: '', // this.translate.instant('EDITQUIZANSWER') + ' ' + quizAnswer.answer,
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'edit',
        quizAnswer: quizAnswer,
        quizzes: this.quizAnswersColumnsService.quizzes,
        users: this.quizAnswersColumnsService.users,
      },
    });

    this.quizAnswerEditDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'QuizAnswer updated'});
        this.getQuizAnswers();
      }
    });
  }

  quizAnswerDetails(quizAnswer: QuizAnswer): void {
    if (!this.navigation.getParamValue('quizAnswers.detailsInDialog', true)) {
      this.router.navigate(['/quiz-answers/details/' + quizAnswer.id]);
      return;
    }
    this.quizAnswerDetailsDialog = this.dialogService.open(DialogQuizAnswerDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        quizAnswer: quizAnswer,
      }
    });
  }

  quizAnswerDelete(quizAnswer: QuizAnswer): void {
    this.quizAnswerToDelete = quizAnswer;
    this.messageService.clear();
    this.messageService.add({
      key: 'quizAnswerDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want delete ' + quizAnswer.answer + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.quizAnswersDataService.deleteQuizAnswer(this.quizAnswerToDelete).subscribe((res: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Quiz Answer deleted'});
      this.getQuizAnswers();
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Quiz Answer not deleted'});
    });
  }

  onDeleteReject(): void {
    this.quizAnswerToDelete = {};
    this.messageService.clear('quizAnswerDelete');
  }

}
