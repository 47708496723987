import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { CountriesDataService, CountriesColumnsService } from '../../../services';
import { Country, CountriesResult } from '../../../interfaces';
import { DialogFilerComponent } from '../../filer/filer.component';

@Component({
  selector: 'app-country-form',
  templateUrl: './country-form.component.html',
  styleUrls: ['./country-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class CountryFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public country: Country = {};
  public countryForm: FormGroup = new FormGroup({});
  public countries: Country[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;

  private filerDialog: DynamicDialogRef = new DynamicDialogRef();

  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private countriesDataService: CountriesDataService,
    public countriesColumnsService: CountriesColumnsService,
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'CountryFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Country - Edit');
        this.getCountry(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.country !== 'undefined') {
      this.country = this.dynamicDialogConfig.data.country;
    }
    if (typeof this.country.id === 'undefined' || this.country.id === null) {
      this.country.id = null;
      this.country.published = false;
    }
    this.getCountries();
    this.prepareFrom();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'CountryFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'CountryFormComponent');
  }

  getCountry(id: number): void {
    this.isLoading = true;
    this.countriesDataService.getCountry(id).subscribe((response: Country|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.country = response;
        this.prepareFrom();
      }
    });
  }

  getCountries(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.countries !== 'undefined' && this.dynamicDialogConfig.data.countries.length > 0) {
      this.countries = this.dynamicDialogConfig.data.countries;
      return;
    }
    this.countriesDataService.getCountries(this.parameters).subscribe((response: CountriesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.countries = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.countryForm = new FormGroup({
      id: new FormControl(this.country.id, []),
      name: new FormControl(this.country.name, [Validators.required, ]),
      nameAr: new FormControl(this.country.nameAr, []),
      nameFr: new FormControl(this.country.nameFr, []),
      picture: new FormControl(this.country.picture, []),
      code: new FormControl(this.country.code, [Validators.required, ]),
      longCode: new FormControl(this.country.longCode, [Validators.required, ]),
      prefix: new FormControl(this.country.prefix, []),
      published: new FormControl(this.country.published, []),
    });
  }

  list() {
    this.router.navigate(['/countries']);
  }

  showErrors() {
      this.showErrors();
      const invalidFields: string[] = [];
      Object.keys(this.countryForm.controls).forEach((field: any) => {
        const control = this.countryForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.countryForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.country = this.countryForm.value;
    this.disableSubmit = true;
    console.log('country', this.country);
    if (typeof this.country.id !== 'undefined' && this.country.id !== null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.countriesDataService.updateCountry(this.country).subscribe((data: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('COUNTRYUPDATED')});
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('COUNTRYNOTUPDATED') + ' ' + error.message});
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.countriesDataService.addCountry(this.country).subscribe((data: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('COUNTRYCREATED')});
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('COUNTRYNOTCREATED') + ' ' + error.message});
        console.warn(error);
    });
  }

  openFilerDialog(field: string): void {
    let url = '';
    const control = this.countryForm.get(field);
    if (control !== null) {
      url = control.value;
    }
    this.filerDialog = this.dialogService.open(DialogFilerComponent, {
      header: '', // this.translate.instant('FILEMANAGER'),
      width: '90%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1500,
      data: {
        path: 'countries',
        field: field,
        url: url,
      },
    });
    this.filerDialog.onClose.subscribe((response: any) => {
      if (response && response.selected) {
        const control = this.countryForm.get(field);
        if (control !== null) {
          control.setValue(response.selected.webPath);
        }
      }
    });
  }

}

@Component({
  selector: 'app-country-form',
  templateUrl: './country-form.component.html',
  styleUrls: ['./country-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogCountryFormComponent extends CountryFormComponent {
}

