import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { UtensilsDataService, UtensilsColumnsService } from '../../../services';
import { Utensil, UtensilsResult } from '../../../interfaces';
import { DialogFilerComponent } from '../../filer/filer.component';

@Component({
  selector: 'app-utensil-form',
  templateUrl: './utensil-form.component.html',
  styleUrls: ['./utensil-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class UtensilFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public utensil: Utensil = {};
  public utensilForm: FormGroup = new FormGroup({});
  public utensils: Utensil[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;

  private filerDialog: DynamicDialogRef = new DynamicDialogRef();

  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private utensilsDataService: UtensilsDataService,
    public utensilsColumnsService: UtensilsColumnsService,
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'UtensilFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Utensil - Edit');
        this.getUtensil(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.utensil !== 'undefined') {
      this.utensil = this.dynamicDialogConfig.data.utensil;
    }
    if (typeof this.utensil.id === 'undefined' || this.utensil.id === null) {
      this.utensil.id = null;
    }
    this.getUtensils();
    this.prepareFrom();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'UtensilFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'UtensilFormComponent');
  }

  getUtensil(id: number): void {
    this.isLoading = true;
    this.utensilsDataService.getUtensil(id).subscribe((response: Utensil|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.utensil = response;
        this.prepareFrom();
      }
    });
  }

  getUtensils(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.utensils !== 'undefined' && this.dynamicDialogConfig.data.utensils.length > 0) {
      this.utensils = this.dynamicDialogConfig.data.utensils;
      return;
    }
    this.utensilsDataService.getUtensils(this.parameters).subscribe((response: UtensilsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.utensils = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.utensilForm = new FormGroup({
      id: new FormControl(this.utensil.id, []),
      name: new FormControl(this.utensil.name, [Validators.required, ]),
      nameAr: new FormControl(this.utensil.nameAr, []),
      nameFr: new FormControl(this.utensil.nameFr, []),
      // slug: new FormControl(this.utensil.slug, [Validators.required, ]),
      // slugAr: new FormControl(this.utensil.slugAr, []),
      // slugFr: new FormControl(this.utensil.slugFr, []),
      letter: new FormControl(this.utensil.letter, [Validators.required, ]),
      letterAr: new FormControl(this.utensil.letterAr, []),
      letterFr: new FormControl(this.utensil.letterFr, []),
      picture: new FormControl(this.utensil.picture, []),
      description: new FormControl(this.utensil.description, [Validators.required, ]),
      descriptionAr: new FormControl(this.utensil.descriptionAr, []),
      descriptionFr: new FormControl(this.utensil.descriptionFr, []),
    });
  }

  list() {
    this.router.navigate(['/utensils']);
  }

  showErrors() {
      this.showErrors();
      const invalidFields: string[] = [];
      Object.keys(this.utensilForm.controls).forEach((field: any) => {
        const control = this.utensilForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.utensilForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.utensil = this.utensilForm.value;
    this.disableSubmit = true;
    console.log('utensil', this.utensil);
    if (typeof this.utensil.id !== 'undefined' && this.utensil.id !== null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.utensilsDataService.updateUtensil(this.utensil).subscribe((data: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('UTENSILUPDATED')});
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('UTENSILNOTUPDATED') + ' ' + error.message});
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.utensilsDataService.addUtensil(this.utensil).subscribe((data: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('UTENSILCREATED')});
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('UTENSILNOTCREATED') + ' ' + error.message});
        console.warn(error);
    });
  }

  openFilerDialog(field: string): void {
    let url = '';
    const control = this.utensilForm.get(field);
    if (control !== null) {
      url = control.value;
    }
    this.filerDialog = this.dialogService.open(DialogFilerComponent, {
      header: '', // this.translate.instant('FILEMANAGER'),
      width: '90%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1500,
      data: {
        path: 'utensils',
        field: field,
        url: url,
      },
    });
    this.filerDialog.onClose.subscribe((response: any) => {
      if (response && response.selected) {
        const control = this.utensilForm.get(field);
        if (control !== null) {
          control.setValue(response.selected.webPath);
        }
      }
    });
  }

}

@Component({
  selector: 'app-utensil-form',
  templateUrl: './utensil-form.component.html',
  styleUrls: ['./utensil-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogUtensilFormComponent extends UtensilFormComponent {
}

