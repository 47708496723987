
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { FileSaverModule } from 'ngx-filesaver';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../factories/http-loader.factory';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
// <primeng-modules>
import { ConfirmationService, MessageService } from 'primeng/api';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { FocusTrapModule } from 'primeng/focustrap';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
// </primeng-modules>
import { MumEatRoutingModule } from './mum-eat.routing';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { TableCellValueComponent } from './components/table-cell-value/table-cell-value.component';
import { FilerComponent, DialogFilerComponent } from './components/filer/filer.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryFormComponent, DialogCategoryFormComponent } from './components/categories/category-form/category-form.component';
import { CategoryDetailsComponent, DialogCategoryDetailsComponent } from './components/categories/category-details/category-details.component';
import { CitiesComponent } from './components/cities/cities.component';
import { CityFormComponent, DialogCityFormComponent } from './components/cities/city-form/city-form.component';
import { CityDetailsComponent, DialogCityDetailsComponent } from './components/cities/city-details/city-details.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { CompanyFormComponent, DialogCompanyFormComponent } from './components/companies/company-form/company-form.component';
import { CompanyDetailsComponent, DialogCompanyDetailsComponent } from './components/companies/company-details/company-details.component';
import { CountriesComponent } from './components/countries/countries.component';
import { CountryFormComponent, DialogCountryFormComponent } from './components/countries/country-form/country-form.component';
import { CountryDetailsComponent, DialogCountryDetailsComponent } from './components/countries/country-details/country-details.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { FavoriteFormComponent, DialogFavoriteFormComponent } from './components/favorites/favorite-form/favorite-form.component';
import { FavoriteDetailsComponent, DialogFavoriteDetailsComponent } from './components/favorites/favorite-details/favorite-details.component';
import { IngredientsComponent } from './components/ingredients/ingredients.component';
import { IngredientFormComponent, DialogIngredientFormComponent } from './components/ingredients/ingredient-form/ingredient-form.component';
import { IngredientDetailsComponent, DialogIngredientDetailsComponent } from './components/ingredients/ingredient-details/ingredient-details.component';
import { LanguagesComponent } from './components/languages/languages.component';
import { LanguageFormComponent, DialogLanguageFormComponent } from './components/languages/language-form/language-form.component';
import { LanguageDetailsComponent, DialogLanguageDetailsComponent } from './components/languages/language-details/language-details.component';
import { LikesComponent } from './components/likes/likes.component';
import { LikeFormComponent, DialogLikeFormComponent } from './components/likes/like-form/like-form.component';
import { LikeDetailsComponent, DialogLikeDetailsComponent } from './components/likes/like-details/like-details.component';
import { LiveChannelsComponent } from './components/live-channels/live-channels.component';
import { LiveChannelFormComponent, DialogLiveChannelFormComponent } from './components/live-channels/live-channel-form/live-channel-form.component';
import { LiveChannelDetailsComponent, DialogLiveChannelDetailsComponent } from './components/live-channels/live-channel-details/live-channel-details.component';
import { LogsComponent } from './components/logs/logs.component';
import { LogFormComponent, DialogLogFormComponent } from './components/logs/log-form/log-form.component';
import { LogDetailsComponent, DialogLogDetailsComponent } from './components/logs/log-details/log-details.component';
import { PacksComponent } from './components/packs/packs.component';
import { PackFormComponent, DialogPackFormComponent } from './components/packs/pack-form/pack-form.component';
import { PackDetailsComponent, DialogPackDetailsComponent } from './components/packs/pack-details/pack-details.component';
import { PackageTypesComponent } from './components/package-types/package-types.component';
import { PackageTypeFormComponent, DialogPackageTypeFormComponent } from './components/package-types/package-type-form/package-type-form.component';
import { PackageTypeDetailsComponent, DialogPackageTypeDetailsComponent } from './components/package-types/package-type-details/package-type-details.component';
import { PostsComponent } from './components/posts/posts.component';
import { PostFormComponent, DialogPostFormComponent } from './components/posts/post-form/post-form.component';
import { PostDetailsComponent, DialogPostDetailsComponent } from './components/posts/post-details/post-details.component';
import { PostTypesComponent } from './components/post-types/post-types.component';
import { PostTypeFormComponent, DialogPostTypeFormComponent } from './components/post-types/post-type-form/post-type-form.component';
import { PostTypeDetailsComponent, DialogPostTypeDetailsComponent } from './components/post-types/post-type-details/post-type-details.component';
import { PricesComponent } from './components/prices/prices.component';
import { PriceFormComponent, DialogPriceFormComponent } from './components/prices/price-form/price-form.component';
import { PriceDetailsComponent, DialogPriceDetailsComponent } from './components/prices/price-details/price-details.component';
import { PushDevicesComponent } from './components/push-devices/push-devices.component';
import { PushDeviceFormComponent, DialogPushDeviceFormComponent } from './components/push-devices/push-device-form/push-device-form.component';
import { PushDeviceDetailsComponent, DialogPushDeviceDetailsComponent } from './components/push-devices/push-device-details/push-device-details.component';
import { PushMessagesComponent } from './components/push-messages/push-messages.component';
import { PushMessageFormComponent, DialogPushMessageFormComponent } from './components/push-messages/push-message-form/push-message-form.component';
import { PushMessageDetailsComponent, DialogPushMessageDetailsComponent } from './components/push-messages/push-message-details/push-message-details.component';
import { PushNotificationsComponent } from './components/push-notifications/push-notifications.component';
import { PushNotificationFormComponent, DialogPushNotificationFormComponent } from './components/push-notifications/push-notification-form/push-notification-form.component';
import { PushNotificationDetailsComponent, DialogPushNotificationDetailsComponent } from './components/push-notifications/push-notification-details/push-notification-details.component';
import { QuizzesComponent } from './components/quizzes/quizzes.component';
import { QuizFormComponent, DialogQuizFormComponent } from './components/quizzes/quiz-form/quiz-form.component';
import { QuizDetailsComponent, DialogQuizDetailsComponent } from './components/quizzes/quiz-details/quiz-details.component';
import { QuizAnswersComponent } from './components/quiz-answers/quiz-answers.component';
import { QuizAnswerFormComponent, DialogQuizAnswerFormComponent } from './components/quiz-answers/quiz-answer-form/quiz-answer-form.component';
import { QuizAnswerDetailsComponent, DialogQuizAnswerDetailsComponent } from './components/quiz-answers/quiz-answer-details/quiz-answer-details.component';
import { QuizTypesComponent } from './components/quiz-types/quiz-types.component';
import { QuizTypeFormComponent, DialogQuizTypeFormComponent } from './components/quiz-types/quiz-type-form/quiz-type-form.component';
import { QuizTypeDetailsComponent, DialogQuizTypeDetailsComponent } from './components/quiz-types/quiz-type-details/quiz-type-details.component';
import { RecipesComponent } from './components/recipes/recipes.component';
import { RecipeFormComponent, DialogRecipeFormComponent } from './components/recipes/recipe-form/recipe-form.component';
import { RecipeDetailsComponent, DialogRecipeDetailsComponent } from './components/recipes/recipe-details/recipe-details.component';
import { RecipeIngredientsComponent } from './components/recipe-ingredients/recipe-ingredients.component';
import { RecipeIngredientFormComponent, DialogRecipeIngredientFormComponent } from './components/recipe-ingredients/recipe-ingredient-form/recipe-ingredient-form.component';
import { RecipeIngredientDetailsComponent, DialogRecipeIngredientDetailsComponent } from './components/recipe-ingredients/recipe-ingredient-details/recipe-ingredient-details.component';
import { RecipeUtensilsComponent } from './components/recipe-utensils/recipe-utensils.component';
import { RecipeUtensilFormComponent, DialogRecipeUtensilFormComponent } from './components/recipe-utensils/recipe-utensil-form/recipe-utensil-form.component';
import { RecipeUtensilDetailsComponent, DialogRecipeUtensilDetailsComponent } from './components/recipe-utensils/recipe-utensil-details/recipe-utensil-details.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { ReviewFormComponent, DialogReviewFormComponent } from './components/reviews/review-form/review-form.component';
import { ReviewDetailsComponent, DialogReviewDetailsComponent } from './components/reviews/review-details/review-details.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SettingFormComponent, DialogSettingFormComponent } from './components/settings/setting-form/setting-form.component';
import { SettingDetailsComponent, DialogSettingDetailsComponent } from './components/settings/setting-details/setting-details.component';
import { SharingsComponent } from './components/sharings/sharings.component';
import { SharingFormComponent, DialogSharingFormComponent } from './components/sharings/sharing-form/sharing-form.component';
import { SharingDetailsComponent, DialogSharingDetailsComponent } from './components/sharings/sharing-details/sharing-details.component';
import { ShoppingListsComponent } from './components/shopping-lists/shopping-lists.component';
import { ShoppingListFormComponent, DialogShoppingListFormComponent } from './components/shopping-lists/shopping-list-form/shopping-list-form.component';
import { ShoppingListDetailsComponent, DialogShoppingListDetailsComponent } from './components/shopping-lists/shopping-list-details/shopping-list-details.component';
import { ShoppingListIngredientsComponent } from './components/shopping-list-ingredients/shopping-list-ingredients.component';
import { ShoppingListIngredientFormComponent, DialogShoppingListIngredientFormComponent } from './components/shopping-list-ingredients/shopping-list-ingredient-form/shopping-list-ingredient-form.component';
import { ShoppingListIngredientDetailsComponent, DialogShoppingListIngredientDetailsComponent } from './components/shopping-list-ingredients/shopping-list-ingredient-details/shopping-list-ingredient-details.component';
import { ShoppingListRecipesComponent } from './components/shopping-list-recipes/shopping-list-recipes.component';
import { ShoppingListRecipeFormComponent, DialogShoppingListRecipeFormComponent } from './components/shopping-list-recipes/shopping-list-recipe-form/shopping-list-recipe-form.component';
import { ShoppingListRecipeDetailsComponent, DialogShoppingListRecipeDetailsComponent } from './components/shopping-list-recipes/shopping-list-recipe-details/shopping-list-recipe-details.component';
import { ShowsComponent } from './components/shows/shows.component';
import { ShowFormComponent, DialogShowFormComponent } from './components/shows/show-form/show-form.component';
import { ShowDetailsComponent, DialogShowDetailsComponent } from './components/shows/show-details/show-details.component';
import { StepsComponent } from './components/steps/steps.component';
import { StepFormComponent, DialogStepFormComponent } from './components/steps/step-form/step-form.component';
import { StepDetailsComponent, DialogStepDetailsComponent } from './components/steps/step-details/step-details.component';
import { SubCategoriesComponent } from './components/sub-categories/sub-categories.component';
import { SubCategoryFormComponent, DialogSubCategoryFormComponent } from './components/sub-categories/sub-category-form/sub-category-form.component';
import { SubCategoryDetailsComponent, DialogSubCategoryDetailsComponent } from './components/sub-categories/sub-category-details/sub-category-details.component';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { SubscriptionFormComponent, DialogSubscriptionFormComponent } from './components/subscriptions/subscription-form/subscription-form.component';
import { SubscriptionDetailsComponent, DialogSubscriptionDetailsComponent } from './components/subscriptions/subscription-details/subscription-details.component';
import { UsersComponent } from './components/users/users.component';
import { UserFormComponent, DialogUserFormComponent } from './components/users/user-form/user-form.component';
import { UserDetailsComponent, DialogUserDetailsComponent } from './components/users/user-details/user-details.component';
import { UtensilsComponent } from './components/utensils/utensils.component';
import { UtensilFormComponent, DialogUtensilFormComponent } from './components/utensils/utensil-form/utensil-form.component';
import { UtensilDetailsComponent, DialogUtensilDetailsComponent } from './components/utensils/utensil-details/utensil-details.component';
import { VideosComponent } from './components/videos/videos.component';
import { VideoFormComponent, DialogVideoFormComponent } from './components/videos/video-form/video-form.component';
import { VideoDetailsComponent, DialogVideoDetailsComponent } from './components/videos/video-details/video-details.component';
import { VideoConverterComponent, DialogVideoConverterComponent } from './components/videos/video-converter/video-converter.component';
import { VideoTypesComponent } from './components/video-types/video-types.component';
import { VideoTypeFormComponent, DialogVideoTypeFormComponent } from './components/video-types/video-type-form/video-type-form.component';
import { VideoTypeDetailsComponent, DialogVideoTypeDetailsComponent } from './components/video-types/video-type-details/video-type-details.component';
import { VisitsComponent } from './components/visits/visits.component';
import { VisitFormComponent, DialogVisitFormComponent } from './components/visits/visit-form/visit-form.component';
import { VisitDetailsComponent, DialogVisitDetailsComponent } from './components/visits/visit-details/visit-details.component';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    DashboardComponent,
    ProfileComponent,
    ChangePasswordComponent,
    LoginComponent,
    LogoutComponent,
    ForgotPasswordComponent,
    TableCellValueComponent,
    FilerComponent,
    DialogFilerComponent,
    CategoriesComponent,
    CategoryFormComponent,
    DialogCategoryFormComponent,
    CategoryDetailsComponent,
    DialogCategoryDetailsComponent,
    CitiesComponent,
    CityFormComponent,
    DialogCityFormComponent,
    CityDetailsComponent,
    DialogCityDetailsComponent,
    CompaniesComponent,
    CompanyFormComponent,
    DialogCompanyFormComponent,
    CompanyDetailsComponent,
    DialogCompanyDetailsComponent,
    CountriesComponent,
    CountryFormComponent,
    DialogCountryFormComponent,
    CountryDetailsComponent,
    DialogCountryDetailsComponent,
    FavoritesComponent,
    FavoriteFormComponent,
    DialogFavoriteFormComponent,
    FavoriteDetailsComponent,
    DialogFavoriteDetailsComponent,
    IngredientsComponent,
    IngredientFormComponent,
    DialogIngredientFormComponent,
    IngredientDetailsComponent,
    DialogIngredientDetailsComponent,
    LanguagesComponent,
    LanguageFormComponent,
    DialogLanguageFormComponent,
    LanguageDetailsComponent,
    DialogLanguageDetailsComponent,
    LikesComponent,
    LikeFormComponent,
    DialogLikeFormComponent,
    LikeDetailsComponent,
    DialogLikeDetailsComponent,
    LiveChannelsComponent,
    LiveChannelFormComponent,
    DialogLiveChannelFormComponent,
    LiveChannelDetailsComponent,
    DialogLiveChannelDetailsComponent,
    LogsComponent,
    LogFormComponent,
    DialogLogFormComponent,
    LogDetailsComponent,
    DialogLogDetailsComponent,
    PacksComponent,
    PackFormComponent,
    DialogPackFormComponent,
    PackDetailsComponent,
    DialogPackDetailsComponent,
    PackageTypesComponent,
    PackageTypeFormComponent,
    DialogPackageTypeFormComponent,
    PackageTypeDetailsComponent,
    DialogPackageTypeDetailsComponent,
    PostsComponent,
    PostFormComponent,
    DialogPostFormComponent,
    PostDetailsComponent,
    DialogPostDetailsComponent,
    PostTypesComponent,
    PostTypeFormComponent,
    DialogPostTypeFormComponent,
    PostTypeDetailsComponent,
    DialogPostTypeDetailsComponent,
    PricesComponent,
    PriceFormComponent,
    DialogPriceFormComponent,
    PriceDetailsComponent,
    DialogPriceDetailsComponent,
    PushDevicesComponent,
    PushDeviceFormComponent,
    DialogPushDeviceFormComponent,
    PushDeviceDetailsComponent,
    DialogPushDeviceDetailsComponent,
    PushMessagesComponent,
    PushMessageFormComponent,
    DialogPushMessageFormComponent,
    PushMessageDetailsComponent,
    DialogPushMessageDetailsComponent,
    PushNotificationsComponent,
    PushNotificationFormComponent,
    DialogPushNotificationFormComponent,
    PushNotificationDetailsComponent,
    DialogPushNotificationDetailsComponent,
    QuizzesComponent,
    QuizFormComponent,
    DialogQuizFormComponent,
    QuizDetailsComponent,
    DialogQuizDetailsComponent,
    QuizAnswersComponent,
    QuizAnswerFormComponent,
    DialogQuizAnswerFormComponent,
    QuizAnswerDetailsComponent,
    DialogQuizAnswerDetailsComponent,
    QuizTypesComponent,
    QuizTypeFormComponent,
    DialogQuizTypeFormComponent,
    QuizTypeDetailsComponent,
    DialogQuizTypeDetailsComponent,
    RecipesComponent,
    RecipeFormComponent,
    DialogRecipeFormComponent,
    RecipeDetailsComponent,
    DialogRecipeDetailsComponent,
    RecipeIngredientsComponent,
    RecipeIngredientFormComponent,
    DialogRecipeIngredientFormComponent,
    RecipeIngredientDetailsComponent,
    DialogRecipeIngredientDetailsComponent,
    RecipeUtensilsComponent,
    RecipeUtensilFormComponent,
    DialogRecipeUtensilFormComponent,
    RecipeUtensilDetailsComponent,
    DialogRecipeUtensilDetailsComponent,
    ReviewsComponent,
    ReviewFormComponent,
    DialogReviewFormComponent,
    ReviewDetailsComponent,
    DialogReviewDetailsComponent,
    SettingsComponent,
    SettingFormComponent,
    DialogSettingFormComponent,
    SettingDetailsComponent,
    DialogSettingDetailsComponent,
    SharingsComponent,
    SharingFormComponent,
    DialogSharingFormComponent,
    SharingDetailsComponent,
    DialogSharingDetailsComponent,
    ShoppingListsComponent,
    ShoppingListFormComponent,
    DialogShoppingListFormComponent,
    ShoppingListDetailsComponent,
    DialogShoppingListDetailsComponent,
    ShoppingListIngredientsComponent,
    ShoppingListIngredientFormComponent,
    DialogShoppingListIngredientFormComponent,
    ShoppingListIngredientDetailsComponent,
    DialogShoppingListIngredientDetailsComponent,
    ShoppingListRecipesComponent,
    ShoppingListRecipeFormComponent,
    DialogShoppingListRecipeFormComponent,
    ShoppingListRecipeDetailsComponent,
    DialogShoppingListRecipeDetailsComponent,
    ShowsComponent,
    ShowFormComponent,
    DialogShowFormComponent,
    ShowDetailsComponent,
    DialogShowDetailsComponent,
    StepsComponent,
    StepFormComponent,
    DialogStepFormComponent,
    StepDetailsComponent,
    DialogStepDetailsComponent,
    SubCategoriesComponent,
    SubCategoryFormComponent,
    DialogSubCategoryFormComponent,
    SubCategoryDetailsComponent,
    DialogSubCategoryDetailsComponent,
    SubscriptionsComponent,
    SubscriptionFormComponent,
    DialogSubscriptionFormComponent,
    SubscriptionDetailsComponent,
    DialogSubscriptionDetailsComponent,
    UsersComponent,
    UserFormComponent,
    DialogUserFormComponent,
    UserDetailsComponent,
    DialogUserDetailsComponent,
    UtensilsComponent,
    UtensilFormComponent,
    DialogUtensilFormComponent,
    UtensilDetailsComponent,
    DialogUtensilDetailsComponent,
    VideosComponent,
    VideoFormComponent,
    DialogVideoFormComponent,
    VideoDetailsComponent,
    DialogVideoDetailsComponent,
   VideoConverterComponent,
    DialogVideoConverterComponent,
    VideoTypesComponent,
    VideoTypeFormComponent,
    DialogVideoTypeFormComponent,
    VideoTypeDetailsComponent,
    DialogVideoTypeDetailsComponent,
    VisitsComponent,
    VisitFormComponent,
    DialogVisitFormComponent,
    VisitDetailsComponent,
    DialogVisitDetailsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    // FileSaverModule,
    HttpClientModule,
    TranslateModule.forRoot({
        defaultLanguage: 'fr',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
    }),
    CKEditorModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    MumEatRoutingModule,
    AccordionModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    ChartModule,
    CheckboxModule,
    ChipsModule,
    CodeHighlighterModule,
    ConfirmDialogModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DropdownModule,
    DynamicDialogModule,
    EditorModule,
    FieldsetModule,
    FileUploadModule,
    FocusTrapModule,
    FullCalendarModule,
    GalleriaModule,
    InplaceModule,
    InputMaskModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    LightboxModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    RippleModule,
    ScrollPanelModule,
    ScrollTopModule,
    SelectButtonModule,
    SidebarModule,
    SlideMenuModule,
    SliderModule,
    SpinnerModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TerminalModule,
    TieredMenuModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    TriStateCheckboxModule,
    VirtualScrollerModule,
  ],
  exports: [
    SafeHtmlPipe
  ],
  providers: [
    ConfirmationService,
    DialogService,
    MessageService,
  ],
})
export class MumEatModule { }

