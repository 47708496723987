<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="recipeUtensil.id === null">{{ 'NEWRECIPEUTENSIL' | translate }}</span>
            <span *ngIf="recipeUtensil.id !== null">{{ 'EDITRECIPEUTENSIL' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="recipeUtensilForm" id="recipeUtensilForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="recipeUtensilForm" >
            <input type="hidden" name="id" id="recipeUtensilId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="recipeUtensilRecipeId" [autoDisplayFirst]="false" formControlName="recipe" [options]="recipeUtensilsColumnsService.recipes" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="recipeUtensilRecipeId" class="req">{{ 'RECIPE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="recipeUtensilForm.controls['recipe'].invalid && (recipeUtensilForm.controls['recipe'].dirty || recipeUtensilForm.controls['recipe'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="recipeUtensilForm.controls['recipe'].errors !== null">
                  <div *ngIf="recipeUtensilForm.controls['recipe'].errors.required">
                   {{ 'RECIPEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="recipeUtensilUtensilId" [autoDisplayFirst]="false" formControlName="utensil" [options]="recipeUtensilsColumnsService.utensils" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="recipeUtensilUtensilId" class="req">{{ 'UTENSIL' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="recipeUtensilForm.controls['utensil'].invalid && (recipeUtensilForm.controls['utensil'].dirty || recipeUtensilForm.controls['utensil'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="recipeUtensilForm.controls['utensil'].errors !== null">
                  <div *ngIf="recipeUtensilForm.controls['utensil'].errors.required">
                   {{ 'UTENSILREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipeUtensilQuantity" formControlName="quantity" [step]="1" [showButtons]="true" [required]="true"></p-inputNumber>
                      <label for="recipeUtensilQuantity" class="req">{{ 'QUANTITY' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="recipeUtensilForm.controls['quantity'].invalid && (recipeUtensilForm.controls['quantity'].dirty || recipeUtensilForm.controls['quantity'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="recipeUtensilForm.controls['quantity'].errors !== null">
                  <div *ngIf="recipeUtensilForm.controls['quantity'].errors.required">
                   {{ 'QUANTITYREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-list-ol"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipeUtensilOrdering" formControlName="ordering" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="recipeUtensilOrdering" class="">{{ 'ORDERING' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKRECIPEUTENSILS' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="recipeUtensil.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!recipeUtensilForm.valid || disableSubmit"> <!--  [disabled]="recipeUtensilForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!recipeUtensilForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

