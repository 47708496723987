import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CacheService } from '../../services/cache/cache.service';
import { CommonService } from '../../services/common/common.service';
import { Column } from '../interfaces/column.interface';
import moment from 'moment';
import { VisitsDataService } from './visits-data.service';
import { Visit, VisitsResult } from '../interfaces';
import { PacksDataService } from './packs-data.service';
import { Pack, PacksResult } from '../interfaces';
import { PricesDataService } from './prices-data.service';
import { Price, PricesResult } from '../interfaces';
import { UsersDataService } from './users-data.service';
import { User, UsersResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsColumnsService {

  public visits: Visit[] = [];
  public packs: Pack[] = [];
  public prices: Price[] = [];
  public users: User[] = [];
  public statusesOptions = [{
    value: 'Initialized',
    label: 'STATUSESINITIALIZED',
    css: 'primary'
  }, {
    value: 'PaymentSuccess',
    label: 'STATUSESPAYMENTSUCCESS',
    css: 'success'
  }, {
    value: 'PaymentFailed',
    label: 'STATUSESPAYMENTFAILED',
    css: 'warning'
  }, {
    value: 'DeliveredSuccess',
    label: 'STATUSESDELIVEREDSUCCESS',
    css: 'danger'
  }, {
    value: 'DeliveredFailed',
    label: 'STATUSESDELIVEREDFAILED',
    css: 'default'
  }];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private cache: CacheService,
    private common: CommonService,
    private visitsDataService: VisitsDataService,
    private packsDataService: PacksDataService,
    private pricesDataService: PricesDataService,
    private usersDataService: UsersDataService,

  ) {
    this.getVisits()
    this.getPacks()
    this.getPrices()
    this.getUsers()
  }

  getVisits(offset: number = 0): void {
    const cacheKey: string = 'visits.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.visits = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.visitsDataService.getVisits(parameters).subscribe((response: VisitsResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.visits = response.results;
        } else {
          this.visits = this.visits.concat(response.results);
        }
        if (response.inlineCount > this.visits.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getVisits(offset);
        }
        response.results = this.visits;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getPacks(offset: number = 0): void {
    const cacheKey: string = 'packs.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.packs = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.packsDataService.getPacks(parameters).subscribe((response: PacksResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.packs = response.results;
        } else {
          this.packs = this.packs.concat(response.results);
        }
        if (response.inlineCount > this.packs.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getPacks(offset);
        }
        response.results = this.packs;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getPrices(offset: number = 0): void {
    const cacheKey: string = 'prices.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.prices = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.pricesDataService.getPrices(parameters).subscribe((response: PricesResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.prices = response.results;
        } else {
          this.prices = this.prices.concat(response.results);
        }
        if (response.inlineCount > this.prices.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getPrices(offset);
        }
        response.results = this.prices;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getUsers(offset: number = 0): void {
    const cacheKey: string = 'users.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.users = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.usersDataService.getUsers(parameters).subscribe((response: UsersResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.users = response.results;
        } else {
          this.users = this.users.concat(response.results);
        }
        if (response.inlineCount > this.users.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getUsers(offset);
        }
        response.results = this.users;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getColumns(): Column[] {
    return [
      { field: 'id', sortField: 'id', title: this.translate.instant('ID'), show: (this.navigation.getParamValue('subscriptions.id.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'visit', title: this.translate.instant('VISIT'), type: 'linkValue', filterData: this.visits, show: (this.navigation.getParamValue('subscriptions.visitId.showFiled', true) && true), displayInList: true, filterField: 'visit.id', displayField: 'ip', sortField: 'visit.ip', url: 'visits' },
      { field: 'pack', title: this.translate.instant('PACK'), type: 'linkValue', filterData: this.packs, show: (this.navigation.getParamValue('subscriptions.packId.showFiled', true) && true), displayInList: true, filterField: 'pack.id', displayField: 'name', sortField: 'pack.name', url: 'packs' },
      { field: 'status', sortField: 'status', title: this.translate.instant('STATUS'), show: (this.navigation.getParamValue('subscriptions.status.showFiled', true) && true), displayInList: true, type: 'enumValue', filterData: this.statusesOptions},
      { field: 'reference', sortField: 'reference', title: this.translate.instant('REFERENCE'), show: (this.navigation.getParamValue('subscriptions.reference.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'token', sortField: 'token', title: this.translate.instant('TOKEN'), show: (this.navigation.getParamValue('subscriptions.token.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'msisdn', sortField: 'msisdn', title: this.translate.instant('MSISDN'), show: (this.navigation.getParamValue('subscriptions.msisdn.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'voucher', sortField: 'voucher', title: this.translate.instant('VOUCHER'), show: (this.navigation.getParamValue('subscriptions.voucher.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'serialNumber', sortField: 'serialNumber', title: this.translate.instant('SERIALNUMBER'), show: (this.navigation.getParamValue('subscriptions.serialNumber.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'amount', sortField: 'amount', title: this.translate.instant('AMOUNT'), show: (this.navigation.getParamValue('subscriptions.amount.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'currency', sortField: 'currency', title: this.translate.instant('CURRENCY'), show: (this.navigation.getParamValue('subscriptions.currency.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'duration', sortField: 'duration', title: this.translate.instant('DURATION'), show: (this.navigation.getParamValue('subscriptions.duration.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'startDate', sortField: 'startDate', title: this.translate.instant('STARTDATE'), show: (this.navigation.getParamValue('subscriptions.startDate.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'endDate', sortField: 'endDate', title: this.translate.instant('ENDDATE'), show: (this.navigation.getParamValue('subscriptions.endDate.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'price', title: this.translate.instant('PRICE'), type: 'linkValue', filterData: this.prices, show: (this.navigation.getParamValue('subscriptions.price.showFiled', false) && true), displayInList: true, filterField: 'price.id', displayField: 'name', sortField: 'price.name', url: 'prices' },
      { field: 'priceValue', sortField: 'priceValue', title: this.translate.instant('PRICEVALUE'), show: (this.navigation.getParamValue('subscriptions.priceValue.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'userAgent', sortField: 'userAgent', title: this.translate.instant('USERAGENT'), show: (this.navigation.getParamValue('subscriptions.userAgent.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'createdAt', sortField: 'createdAt', title: this.translate.instant('CREATEDAT'), show: (this.navigation.getParamValue('subscriptions.createdAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'creatorUser', title: this.translate.instant('CREATORUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('subscriptions.creatorUser.showFiled', false) && true), displayInList: true, filterField: 'creatorUser.id', displayField: 'username', sortField: 'creatorUser.username', url: 'users' },
      { field: 'modifiedAt', sortField: 'modifiedAt', title: this.translate.instant('MODIFIEDAT'), show: (this.navigation.getParamValue('subscriptions.modifiedAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'modifierUser', title: this.translate.instant('MODIFIERUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('subscriptions.modifierUser.showFiled', false) && true), displayInList: true, filterField: 'modifierUser.id', displayField: 'username', sortField: 'modifierUser.username', url: 'users' },
      { field: 'actions', title: this.translate.instant('ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }

  format(fieldName: string, value: any) {
    if (fieldName.toLowerCase().indexOf('time') > -1) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    } else if (fieldName.toLowerCase().indexOf('date') > -1) {
      return moment(value).format('YYYY-MM-DD');
    }
    return value;
  }

}
