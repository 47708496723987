import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { PushDevicesDataService, PushDevicesColumnsService } from '../../../services';
import { PushDevice, PushDevicesResult } from '../../../interfaces';

@Component({
  selector: 'app-push-device-form',
  templateUrl: './push-device-form.component.html',
  styleUrls: ['./push-device-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class PushDeviceFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public pushDevice: PushDevice = {};
  public pushDeviceForm: FormGroup = new FormGroup({});
  public pushDevices: PushDevice[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;


  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private pushDevicesDataService: PushDevicesDataService,
    public pushDevicesColumnsService: PushDevicesColumnsService,
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'PushDeviceFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Push Device - Edit');
        this.getPushDevice(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.pushDevice !== 'undefined') {
      this.pushDevice = this.dynamicDialogConfig.data.pushDevice;
    }
    if (typeof this.pushDevice.id === 'undefined' || this.pushDevice.id === null) {
      this.pushDevice.id = null;
      this.pushDevice.isEnabledBadge = false;
      this.pushDevice.isEnabledAlert = false;
      this.pushDevice.isEnabledSound = false;
    }
    this.getPushDevices();
    this.prepareFrom();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'PushDeviceFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'PushDeviceFormComponent');
  }

  getPushDevice(id: number): void {
    this.isLoading = true;
    this.pushDevicesDataService.getPushDevice(id).subscribe((response: PushDevice|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.pushDevice = response;
        this.prepareFrom();
      }
    });
  }

  getPushDevices(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.pushDevices !== 'undefined' && this.dynamicDialogConfig.data.pushDevices.length > 0) {
      this.pushDevices = this.dynamicDialogConfig.data.pushDevices;
      return;
    }
    this.pushDevicesDataService.getPushDevices(this.parameters).subscribe((response: PushDevicesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.pushDevices = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.pushDeviceForm = new FormGroup({
      id: new FormControl(this.pushDevice.id, []),
      appName: new FormControl(this.pushDevice.appName, []),
      appVersion: new FormControl(this.pushDevice.appVersion, []),
      deviceUid: new FormControl(this.pushDevice.deviceUid, []),
      deviceReg: new FormControl(this.pushDevice.deviceReg, []),
      deviceToken: new FormControl(this.pushDevice.deviceToken, []),
      deviceName: new FormControl(this.pushDevice.deviceName, []),
      deviceEmail: new FormControl(this.pushDevice.deviceEmail, []),
      deviceModel: new FormControl(this.pushDevice.deviceModel, []),
      deviceVersion: new FormControl(this.pushDevice.deviceVersion, []),
      isEnabledBadge: new FormControl(this.pushDevice.isEnabledBadge, []),
      isEnabledAlert: new FormControl(this.pushDevice.isEnabledAlert, []),
      isEnabledSound: new FormControl(this.pushDevice.isEnabledSound, []),
      development: new FormControl(this.pushDevice.development, [Validators.required, ]),
      status: new FormControl(this.pushDevice.status, [Validators.required, ]),
      ip: new FormControl(this.pushDevice.ip, []),
    });
  }

  list() {
    this.router.navigate(['/push-devices']);
  }

  showErrors() {
      this.showErrors();
      const invalidFields: string[] = [];
      Object.keys(this.pushDeviceForm.controls).forEach((field: any) => {
        const control = this.pushDeviceForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.pushDeviceForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.pushDevice = this.pushDeviceForm.value;
    this.disableSubmit = true;
    console.log('pushDevice', this.pushDevice);
    if (typeof this.pushDevice.id !== 'undefined' && this.pushDevice.id !== null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.pushDevicesDataService.updatePushDevice(this.pushDevice).subscribe((data: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('PUSHDEVICEUPDATED')});
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('PUSHDEVICENOTUPDATED') + ' ' + error.message});
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.pushDevicesDataService.addPushDevice(this.pushDevice).subscribe((data: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('PUSHDEVICECREATED')});
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('PUSHDEVICENOTCREATED') + ' ' + error.message});
        console.warn(error);
    });
  }

}

@Component({
  selector: 'app-push-device-form',
  templateUrl: './push-device-form.component.html',
  styleUrls: ['./push-device-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogPushDeviceFormComponent extends PushDeviceFormComponent {
}

