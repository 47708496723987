import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { QuizzesDataService, QuizzesColumnsService } from '../../../services';
import { Quiz, QuizzesResult } from '../../../interfaces';
import { Editor } from 'primeng/editor';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EditorService } from '../../../../services/editor/editor.service';
import { DialogFilerComponent } from '../../filer/filer.component';

@Component({
  selector: 'app-quiz-form',
  templateUrl: './quiz-form.component.html',
  styleUrls: ['./quiz-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class QuizFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public quiz: Quiz = {};
  public quizForm: FormGroup = new FormGroup({});
  public quizzes: Quiz[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;


  public Editor = ClassicEditor;
  private filerDialog: DynamicDialogRef = new DynamicDialogRef();

  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private quizzesDataService: QuizzesDataService,
    public quizzesColumnsService: QuizzesColumnsService,
    public editorService: EditorService,
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'QuizFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Quiz - Edit');
        this.getQuiz(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.quiz !== 'undefined') {
      this.quiz = this.dynamicDialogConfig.data.quiz;
    }
    if (typeof this.quiz.id === 'undefined' || this.quiz.id === null) {
      this.quiz.id = null;
      this.quiz.choice1IsCorrect = false;
      this.quiz.choice2IsCorrect = false;
      this.quiz.choice3IsCorrect = false;
      this.quiz.choice4IsCorrect = false;
      this.quiz.choice5IsCorrect = false;
      this.quiz.choice6IsCorrect = false;
      this.quiz.choice7IsCorrect = false;
      this.quiz.choice8IsCorrect = false;
      this.quiz.autoPublishing = false;
      this.quiz.startPublishing = moment().set({minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD\THH:mm:ssZ');
      this.quiz.endPublishing = moment().set({minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD\THH:mm:ssZ');
      this.quiz.publishDate = moment().set({minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD\THH:mm:ssZ');
      this.quiz.isFinished = false;
    }
    this.getQuizzes();
    this.prepareFrom();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'QuizFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'QuizFormComponent');
  }

  getQuiz(id: number): void {
    this.isLoading = true;
    this.quizzesDataService.getQuiz(id).subscribe((response: Quiz|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.quiz = response;
        this.prepareFrom();
      }
    });
  }

  getQuizzes(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.quizzes !== 'undefined' && this.dynamicDialogConfig.data.quizzes.length > 0) {
      this.quizzes = this.dynamicDialogConfig.data.quizzes;
      return;
    }
    this.quizzesDataService.getQuizzes(this.parameters).subscribe((response: QuizzesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.quizzes = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.quiz.startPublishing = moment(this.quiz.startPublishing).toDate();
    this.quiz.endPublishing = moment(this.quiz.endPublishing).toDate();
    this.quiz.publishDate = moment(this.quiz.publishDate).toDate();
    this.quizForm = new FormGroup({
      id: new FormControl(this.quiz.id, []),
      quizType: new FormControl((typeof this.quiz.quizType === 'number') ? this.quiz.quizType : this.quiz.quizType?.id, [Validators.required, ]),
      title: new FormControl(this.quiz.title, [Validators.required, ]),
      titleAr: new FormControl(this.quiz.titleAr, []),
      titleFr: new FormControl(this.quiz.titleFr, []),
      slug: new FormControl(this.quiz.slug, [Validators.required, ]),
      slugAr: new FormControl(this.quiz.slugAr, []),
      slugFr: new FormControl(this.quiz.slugFr, []),
      picture: new FormControl(this.quiz.picture, []),
      description: new FormControl(this.quiz.description, [Validators.required, ]),
      descriptionAr: new FormControl(this.quiz.descriptionAr, []),
      descriptionFr: new FormControl(this.quiz.descriptionFr, []),
      kind: new FormControl(this.quiz.kind, [Validators.required, ]),
      question: new FormControl(this.quiz.question, [Validators.required, ]),
      questionAr: new FormControl(this.quiz.questionAr, []),
      questionFr: new FormControl(this.quiz.questionFr, []),
      choice1: new FormControl(this.quiz.choice1, []),
      choice1Ar: new FormControl(this.quiz.choice1Ar, []),
      choice1Fr: new FormControl(this.quiz.choice1Fr, []),
      choice1IsCorrect: new FormControl(this.quiz.choice1IsCorrect, [Validators.required, ]),
      choice2: new FormControl(this.quiz.choice2, []),
      choice2Ar: new FormControl(this.quiz.choice2Ar, []),
      choice2Fr: new FormControl(this.quiz.choice2Fr, []),
      choice2IsCorrect: new FormControl(this.quiz.choice2IsCorrect, [Validators.required, ]),
      choice3: new FormControl(this.quiz.choice3, []),
      choice3Ar: new FormControl(this.quiz.choice3Ar, []),
      choice3Fr: new FormControl(this.quiz.choice3Fr, []),
      choice3IsCorrect: new FormControl(this.quiz.choice3IsCorrect, [Validators.required, ]),
      choice4: new FormControl(this.quiz.choice4, []),
      choice4Ar: new FormControl(this.quiz.choice4Ar, []),
      choice4Fr: new FormControl(this.quiz.choice4Fr, []),
      choice4IsCorrect: new FormControl(this.quiz.choice4IsCorrect, [Validators.required, ]),
      choice5: new FormControl(this.quiz.choice5, []),
      choice5Ar: new FormControl(this.quiz.choice5Ar, []),
      choice5Fr: new FormControl(this.quiz.choice5Fr, []),
      choice5IsCorrect: new FormControl(this.quiz.choice5IsCorrect, [Validators.required, ]),
      choice6: new FormControl(this.quiz.choice6, []),
      choice6Ar: new FormControl(this.quiz.choice6Ar, []),
      choice6Fr: new FormControl(this.quiz.choice6Fr, []),
      choice6IsCorrect: new FormControl(this.quiz.choice6IsCorrect, [Validators.required, ]),
      choice7: new FormControl(this.quiz.choice7, []),
      choice7Ar: new FormControl(this.quiz.choice7Ar, []),
      choice7Fr: new FormControl(this.quiz.choice7Fr, []),
      choice7IsCorrect: new FormControl(this.quiz.choice7IsCorrect, [Validators.required, ]),
      choice8: new FormControl(this.quiz.choice8, []),
      choice8Ar: new FormControl(this.quiz.choice8Ar, []),
      choice8Fr: new FormControl(this.quiz.choice8Fr, []),
      choice8IsCorrect: new FormControl(this.quiz.choice8IsCorrect, [Validators.required, ]),
      autoPublishing: new FormControl(this.quiz.autoPublishing, [Validators.required, ]),
      startPublishing: new FormControl(this.quiz.startPublishing, []),
      endPublishing: new FormControl(this.quiz.endPublishing, []),
      publishDate: new FormControl(this.quiz.publishDate, [Validators.required, ]),
      status: new FormControl(this.quiz.status, [Validators.required, ]),
      countAnswers: new FormControl(this.quiz.countAnswers, []),
      countWinners: new FormControl(this.quiz.countWinners, []),
      isFinished: new FormControl(this.quiz.isFinished, [Validators.required, ]),
      ordering: new FormControl(this.quiz.ordering, []),
    });
  }

  list() {
    this.router.navigate(['/quizzes']);
  }

  showErrors() {
      this.showErrors();
      const invalidFields: string[] = [];
      Object.keys(this.quizForm.controls).forEach((field: any) => {
        const control = this.quizForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.quizForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.quiz = this.quizForm.value;
    this.disableSubmit = true;
    console.log('quiz', this.quiz);
    if (typeof this.quiz.id !== 'undefined' && this.quiz.id !== null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.quizzesDataService.updateQuiz(this.quiz).subscribe((data: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('QUIZUPDATED')});
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('QUIZNOTUPDATED') + ' ' + error.message});
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.quizzesDataService.addQuiz(this.quiz).subscribe((data: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('QUIZCREATED')});
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('QUIZNOTCREATED') + ' ' + error.message});
        console.warn(error);
    });
  }

  openFilerDialog(field: string): void {
    let url = '';
    const control = this.quizForm.get(field);
    if (control !== null) {
      url = control.value;
    }
    this.filerDialog = this.dialogService.open(DialogFilerComponent, {
      header: '', // this.translate.instant('FILEMANAGER'),
      width: '90%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1500,
      data: {
        path: 'quizzes',
        field: field,
        url: url,
      },
    });
    this.filerDialog.onClose.subscribe((response: any) => {
      if (response && response.selected) {
        const control = this.quizForm.get(field);
        if (control !== null) {
          control.setValue(response.selected.webPath);
        }
      }
    });
  }

}

@Component({
  selector: 'app-quiz-form',
  templateUrl: './quiz-form.component.html',
  styleUrls: ['./quiz-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogQuizFormComponent extends QuizFormComponent {
}

