import { INavData } from '../coreui/sidebar';

export const MumEatNavItems: INavData[] = [
  {
    name: 'DASHBOARD',
    url: '/dashboard',
    icon: 'sli-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    title: true,
    name: 'MumEat Manager'
  },
  {
    name: 'KITCHEN',
    url: '/kitchen',
    icon: 'cil-burger', // kitchen
    children: [
      {
        name: 'RECIPES',
        url: '/recipes',
        icon: 'fa fa-cookie-bite'// recipes
      },
      {
        name: 'STEPS',
        url: '/steps',
        icon: 'cil-list-numbered'// steps
      },
      {
        name: 'INGREDIENTS',
        url: '/ingredients',
        icon: 'fa fa-fish'// ingredients
      },
      {
        name: 'UTENSILS',
        url: '/utensils',
        icon: 'fa fa-utensils'// utensils
      },
      {
        name: 'RECIPEINGREDIENTS',
        url: '/recipe-ingredients',
        icon: 'fa fa-carrot'// recipeingredients
      },
      {
        name: 'RECIPEUTENSILS',
        url: '/recipe-utensils',
        icon: 'fa fa-blender'// recipeutensils
      },
      {
        name: 'SHOPPINGLISTS',
        url: '/shopping-lists',
        icon: 'cil-clipboard'// shoppinglists
      },
      {
        name: 'SHOPPINGLISTRECIPES',
        url: '/shopping-list-recipes',
        icon: 'cil-birthday-cake'// shoppinglistrecipes
      },
      {
        name: 'SHOPPINGLISTINGREDIENTS',
        url: '/shopping-list-ingredients',
        icon: 'cil-lemon'// shoppinglistingredients
      },
    ]
  },
  {
    name: 'ACTIVITY', // Activity
    url: '/activity',
    icon: 'sli-badge', // activity
    children: [
    ]

  },
  {
    name: 'WEBTV',
    url: '/webtv',
    icon: 'sli-screen-desktop', // webtv
    children: [
      {
        name: 'VIDEOS',
        url: '/videos',
        icon: 'sli-film'// videos
      },
      {
        name: 'VIDEOTYPES',
        url: '/video-types',
        icon: 'sli-book-open'// videotypes
      },
      {
        name: 'SHOWS',
        url: '/shows',
        icon: 'sli-umbrella'// shows
      },
      {
        name: 'Live Channels',
        url: '/live-channels',
        icon: 'sli-camrecorder'// livechannels
      },
    ]

  },
  {
    name: 'REVIEWS',
    url: '/reviews',
    icon: 'sli-bubbles', // reviews
    children: [
      {
        name: 'LIKES',
        url: '/likes',
        icon: 'sli-like'// likes
      },
      {
        name: 'FAVORITES',
        url: '/favorites',
        icon: 'sli-heart'// favorites
      },
      {
        name: 'REVIEWS',
        url: '/reviews',
        icon: 'sli-bubbles'// reviews
      },
    ]

  },
  {
    name: 'ADSERVING',
    url: '/adserving',
    icon: 'sli-briefcase', // adserving
    children: [
    ]

  },
  {
    name: 'OFFER',
    url: '/offer',
    icon: 'sli-present', // offer
    children: [
      {
        name: 'PACKS',
        url: '/packs',
        icon: 'sli-social-dropbox'// packs
      },
      {
        name: 'SUBSCRIPTIONS',
        url: '/subscriptions',
        icon: 'sli-basket-loaded'// subscriptions
      },
      {
        name: 'PACKAGETYPES',
        url: '/package-types',
        icon: 'sli-bag'// packagetypes
      },
      {
        name: 'PRICES',
        url: '/prices',
        icon: 'sli-tag'// prices
      },
      {
        name: 'SHARINGS',
        url: '/sharings',
        icon: 'sli-wallet'// sharings
      },
    ]

  },
  {
    name: 'ACCESS',
    url: '/access',
    icon: 'sli-lock', // access
    children: [
      {
        name: 'USERS',
        url: '/users',
        icon: 'sli-user'// users
      },
      {
        name: 'LOGS',
        url: '/logs',
        icon: 'sli-notebook'// logs
      },
      {
        name: 'COMPANIES',
        url: '/companies',
        icon: 'sli-organization'// companies
      },
    ]

  },
  {
    name: 'SETTINGS',
    url: '/settings',
    icon: 'sli-settings', // settings
    children: [
      {
        name: 'LANGUAGES',
        url: '/languages',
        icon: 'sli-flag'// languages
      },
      {
        name: 'COUNTRIES',
        url: '/countries',
        icon: 'sli-globe'// countries
      },
      {
        name: 'CITIES',
        url: '/cities',
        icon: 'sli-directions'// cities
      },
      {
        name: 'SETTINGS',
        url: '/settings',
        icon: 'sli-settings'// settings
      },
    ]
  },
  {
    name: 'STATISTICS',
    url: '/statistics',
    icon: 'sli-chart', // statistics
    children: [
      {
        name: 'Visits',
        url: '/visits',
        icon: 'sli-cursor'// visits
      },
    ]

  },

  {
    name: 'NEWS',
    url: '/news',
    icon: 'cil-newspaper', // news
    children: [
      {
        name: 'POSTS',
        url: '/posts',
        icon: 'cil-short-text'// posts
      },
      {
        name: 'CATEGORIES',
        url: '/categories',
        icon: 'cil-list-rich'// categories
      },
      {
        name: 'SUBCATEGORIES',
        url: '/sub-categories',
        icon: 'cil-line-style'// subcategories
      },
      {
        name: 'POSTTYPES',
        url: '/post-types',
        icon: 'cil-view-quilt'// posttypes
      },
    ]

  },
  {
    name: 'Mobile',
    url: '/mobile',
    icon: 'sli-screen-smartphone', // mobile
    children: [
      {
        name: 'Push Notifications',
        url: '/push-notifications',
        icon: 'sli-bell'// pushnotifications
      },
      {
        name: 'Push Devices',
        url: '/push-devices',
        icon: 'sli-screen-tablet '// pushdevices
      },
      {
        name: 'Push Messages',
        url: '/push-messages',
        icon: 'sli-bulb'// pushmessages
      },
    ]

  },
  {
    name: 'QUIZZESMANAGER',
    url: '/quizzesmanager',
    icon: 'sli-game-controller', // quizzesmanager
    children: [
      {
        name: 'QUIZZES',
        url: '/quizzes',
        icon: 'sli-question'// quizzes
      },
      {
        name: 'QUIZANSWERS',
        url: '/quiz-answers',
        icon: 'sli-emotsmile'// quizanswers
      },
      {
        name: 'QUIZTYPES',
        url: '/quiz-types',
        icon: 'sli-eyeglass'// quiztypes
      },
    ]

  },
  {
    name: 'FILEMANGER',
    url: '/filer',
    icon: 'icon-folder',
    badge: {
      variant: 'success',
      text: 'NEW'
    }
  },
];

