import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { PushNotificationsDataService, PushNotificationsColumnsService } from '../../../services';
import { PushNotification, PushNotificationsResult } from '../../../interfaces';

@Component({
  selector: 'app-push-notification-form',
  templateUrl: './push-notification-form.component.html',
  styleUrls: ['./push-notification-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class PushNotificationFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public pushNotification: PushNotification = {};
  public pushNotificationForm: FormGroup = new FormGroup({});
  public pushNotifications: PushNotification[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;


  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private pushNotificationsDataService: PushNotificationsDataService,
    public pushNotificationsColumnsService: PushNotificationsColumnsService,
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'PushNotificationFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Push Notification - Edit');
        this.getPushNotification(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.pushNotification !== 'undefined') {
      this.pushNotification = this.dynamicDialogConfig.data.pushNotification;
    }
    if (typeof this.pushNotification.id === 'undefined' || this.pushNotification.id === null) {
      this.pushNotification.id = null;
      this.pushNotification.sending = false;
      this.pushNotification.sendingTime = moment().set({minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD\THH:mm:ssZ');
    }
    this.getPushNotifications();
    this.prepareFrom();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'PushNotificationFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'PushNotificationFormComponent');
  }

  getPushNotification(id: number): void {
    this.isLoading = true;
    this.pushNotificationsDataService.getPushNotification(id).subscribe((response: PushNotification|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.pushNotification = response;
        this.prepareFrom();
      }
    });
  }

  getPushNotifications(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.pushNotifications !== 'undefined' && this.dynamicDialogConfig.data.pushNotifications.length > 0) {
      this.pushNotifications = this.dynamicDialogConfig.data.pushNotifications;
      return;
    }
    this.pushNotificationsDataService.getPushNotifications(this.parameters).subscribe((response: PushNotificationsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.pushNotifications = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.pushNotification.sendingTime = moment(this.pushNotification.sendingTime).toDate();
    this.pushNotificationForm = new FormGroup({
      type: new FormControl(this.pushNotification.type, [Validators.required, ]),
      id: new FormControl(this.pushNotification.id, []),
      title: new FormControl(this.pushNotification.title, [Validators.required, ]),
      titleAr: new FormControl(this.pushNotification.titleAr, []),
      titleFr: new FormControl(this.pushNotification.titleFr, []),
      message: new FormControl(this.pushNotification.message, [Validators.required, ]),
      messageAr: new FormControl(this.pushNotification.messageAr, []),
      messageFr: new FormControl(this.pushNotification.messageFr, []),
      category: new FormControl(this.pushNotification.category, []),
      badge: new FormControl(this.pushNotification.badge, []),
      sound: new FormControl(this.pushNotification.sound, []),
      recipe: new FormControl((typeof this.pushNotification.recipe === 'number') ? this.pushNotification.recipe : this.pushNotification.recipe?.id, []),
      post: new FormControl((typeof this.pushNotification.post === 'number') ? this.pushNotification.post : this.pushNotification.post?.id, []),
      video: new FormControl((typeof this.pushNotification.video === 'number') ? this.pushNotification.video : this.pushNotification.video?.id, []),
      sending: new FormControl(this.pushNotification.sending, []),
      sendingTime: new FormControl(this.pushNotification.sendingTime, []),
    });
  }

  list() {
    this.router.navigate(['/push-notifications']);
  }

  showErrors() {
      this.showErrors();
      const invalidFields: string[] = [];
      Object.keys(this.pushNotificationForm.controls).forEach((field: any) => {
        const control = this.pushNotificationForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.pushNotificationForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.pushNotification = this.pushNotificationForm.value;
    this.disableSubmit = true;
    console.log('pushNotification', this.pushNotification);
    if (typeof this.pushNotification.id !== 'undefined' && this.pushNotification.id !== null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.pushNotificationsDataService.updatePushNotification(this.pushNotification).subscribe((data: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('PUSHNOTIFICATIONUPDATED')});
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('PUSHNOTIFICATIONNOTUPDATED') + ' ' + error.message});
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.pushNotificationsDataService.addPushNotification(this.pushNotification).subscribe((data: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('PUSHNOTIFICATIONCREATED')});
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('PUSHNOTIFICATIONNOTCREATED') + ' ' + error.message});
        console.warn(error);
    });
  }

}

@Component({
  selector: 'app-push-notification-form',
  templateUrl: './push-notification-form.component.html',
  styleUrls: ['./push-notification-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogPushNotificationFormComponent extends PushNotificationFormComponent {
}

