import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { Price, PricesResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class PricesDataService {

  private uri: string = `${environment.apiUrl}prices`;
  private cacheKey: string = 'prices.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getPrice(id: number): Observable<Price|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getPrices(parameters: {[param: string]: string | string[]}): Observable<PricesResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addPrice(data: Price): Observable<Price|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addPrices(data: Price[]): Observable<Price[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updatePrice(data: Price): Observable<Price|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updatePrices(data: Price[]): Observable<Price[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchPrice(data: Price): Observable<Price|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchPrices(data: Price[]): Observable<Price[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deletePrice(data: Price): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deletePrices(data: Price[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((price) => {
      ids.push(price.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
