import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private uri = `${environment.apiUrl}dashboard`;

  constructor(private http: HttpClient) {

  }

  total(entity: string): Observable<number|any> {
    return this.http.get(this.uri + `/total/${entity}`);
  }

  totalPerDate(entity: string, date: string): Observable<number|any> {
    return this.http.get(this.uri + `/total/${entity}/${date}`);
  }

  totalPerPeriod(entity: string, from: string, to: string): Observable<number|any> {
    return this.http.get(this.uri + `/total/${entity}/${from}/${to}`);
  }

}
