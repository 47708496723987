<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-10 mx-auto">

                <p-messages [(value)]="msgs"></p-messages>

                <div class="card-group pb-2">
                    <div class="card p-4">
                    <div class="card-body">
                        <fieldset>
                        <div class="login-brand mb-4"></div>

                        <h1>{{ 'FORGOTPASSWORD' | translate }}?</h1>

                        <p class="text-muted mb-5">{{ 'ENTERYOUREMAILORYOURUSERNAME' | translate }}</p>

                        <div class="p-fluid p-grid">

                            <div class="p-field p-col-12">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                                </span>
                                <span class="p-float-label">
                                <input type="text" id="username" pInputText  [(ngModel)]="data.username" [required]="true">
                                <label for="username">{{ 'USERNAMEEMAIL' | translate }}</label>
                                </span>
                            </div>
                            </div>

                            <div class="p-col-6">
                            <button type="button" class="btn btn-primary px-2" (click)="forgotPassword()" [disabled]="isLoading">
                                {{ 'SENDMERESETCODE' | translate }}
                                <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm float-right ml-2 text-light" role="status"></span>
                            </button>
                            </div>
                            <div class="p-col-6 text-right">
                            <button type="button" class="btn btn-link px-0" (click)="login()">
                                {{ 'LOGIN' | translate }}
                            </button>
                            </div>

                        </div>

                        </fieldset>
                    </div>
                    </div>
                    <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
                    <div class="card-body text-center">
                        <div>
                        </div>
                    </div>
                    </div>
                </div>

                </div>
            </div>
        </div>
    </main>
</div>

<p-dialog header="{{ 'SETNEWPASSWORD' | translate }}" [(visible)]="showResetDialog" [maximizable]="true" [style]="{width: '600px'}">
    <div class="p-fluid p-grid mb-5">

        <div class="p-field p-col-12">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>
            <span class="p-float-label">
              <input type="text" id="username_email" pInputText  [(ngModel)]="reset.username" [required]="true" [readonly]="false">
              <label for="username_email">{{ 'USERNAMEEMAIL' | translate }}</label>
            </span>
          </div>
        </div>

        <div class="p-field p-col-12">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-shield"></i>
            </span>
            <span class="p-float-label">
              <input type="text" id="code" pInputText  [(ngModel)]="reset.code" [required]="true" [readonly]="false">
              <label for="code">{{ 'CODE' | translate }}</label>
            </span>
          </div>
        </div>

        <div class="p-field p-col-12">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-lock"></i>
            </span>
            <span class="p-float-label">
                <p-password id="newPassword" [(ngModel)]="reset.newPassword" [toggleMask]="true" [required]="true">
                    <ng-template pTemplate="header">
                        <h6>Pick a new password</h6>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <p class="p-mt-2">Suggestions</p>
                        <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                            <li>At least one lowercase</li>
                            <li>At least one uppercase</li>
                            <li>At least one numeric</li>
                            <li>Minimum 8 characters</li>
                        </ul>
                    </ng-template>
                </p-password>
              <label for="newPassword">{{ 'NEWPASSWORD' | translate }}</label>
            </span>
          </div>
        </div>

        <div class="p-field p-col-12">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-lock"></i>
            </span>
            <span class="p-float-label">
              <p-password id="confirmPassword" [(ngModel)]="reset.confirmPassword" [toggleMask]="true" [required]="true"></p-password>
              <label for="confirmPassword">{{ 'CONFIRMPASSWORD' | translate }}</label>
            </span>
          </div>
        </div>

        <div class="p-col-6">
          <button type="button" class="btn btn-primary px-2" (click)="resetPassword()" [disabled]="isLoading">
            {{ 'SETMYNEWPASSWORD' | translate }}
            <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm float-right ml-2 text-light" role="status"></span>
          </button>
        </div>

      </div>
</p-dialog>

