import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { FilerComponent } from './components/filer/filer.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryFormComponent } from './components/categories/category-form/category-form.component';
import { CategoryDetailsComponent } from './components/categories/category-details/category-details.component';
import { CitiesComponent } from './components/cities/cities.component';
import { CityFormComponent } from './components/cities/city-form/city-form.component';
import { CityDetailsComponent } from './components/cities/city-details/city-details.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { CompanyFormComponent } from './components/companies/company-form/company-form.component';
import { CompanyDetailsComponent } from './components/companies/company-details/company-details.component';
import { CountriesComponent } from './components/countries/countries.component';
import { CountryFormComponent } from './components/countries/country-form/country-form.component';
import { CountryDetailsComponent } from './components/countries/country-details/country-details.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { FavoriteFormComponent } from './components/favorites/favorite-form/favorite-form.component';
import { FavoriteDetailsComponent } from './components/favorites/favorite-details/favorite-details.component';
import { IngredientsComponent } from './components/ingredients/ingredients.component';
import { IngredientFormComponent } from './components/ingredients/ingredient-form/ingredient-form.component';
import { IngredientDetailsComponent } from './components/ingredients/ingredient-details/ingredient-details.component';
import { LanguagesComponent } from './components/languages/languages.component';
import { LanguageFormComponent } from './components/languages/language-form/language-form.component';
import { LanguageDetailsComponent } from './components/languages/language-details/language-details.component';
import { LikesComponent } from './components/likes/likes.component';
import { LikeFormComponent } from './components/likes/like-form/like-form.component';
import { LikeDetailsComponent } from './components/likes/like-details/like-details.component';
import { LiveChannelsComponent } from './components/live-channels/live-channels.component';
import { LiveChannelFormComponent } from './components/live-channels/live-channel-form/live-channel-form.component';
import { LiveChannelDetailsComponent } from './components/live-channels/live-channel-details/live-channel-details.component';
import { LogsComponent } from './components/logs/logs.component';
import { LogFormComponent } from './components/logs/log-form/log-form.component';
import { LogDetailsComponent } from './components/logs/log-details/log-details.component';
import { PacksComponent } from './components/packs/packs.component';
import { PackFormComponent } from './components/packs/pack-form/pack-form.component';
import { PackDetailsComponent } from './components/packs/pack-details/pack-details.component';
import { PackageTypesComponent } from './components/package-types/package-types.component';
import { PackageTypeFormComponent } from './components/package-types/package-type-form/package-type-form.component';
import { PackageTypeDetailsComponent } from './components/package-types/package-type-details/package-type-details.component';
import { PostsComponent } from './components/posts/posts.component';
import { PostFormComponent } from './components/posts/post-form/post-form.component';
import { PostDetailsComponent } from './components/posts/post-details/post-details.component';
import { PostTypesComponent } from './components/post-types/post-types.component';
import { PostTypeFormComponent } from './components/post-types/post-type-form/post-type-form.component';
import { PostTypeDetailsComponent } from './components/post-types/post-type-details/post-type-details.component';
import { PricesComponent } from './components/prices/prices.component';
import { PriceFormComponent } from './components/prices/price-form/price-form.component';
import { PriceDetailsComponent } from './components/prices/price-details/price-details.component';
import { PushDevicesComponent } from './components/push-devices/push-devices.component';
import { PushDeviceFormComponent } from './components/push-devices/push-device-form/push-device-form.component';
import { PushDeviceDetailsComponent } from './components/push-devices/push-device-details/push-device-details.component';
import { PushMessagesComponent } from './components/push-messages/push-messages.component';
import { PushMessageFormComponent } from './components/push-messages/push-message-form/push-message-form.component';
import { PushMessageDetailsComponent } from './components/push-messages/push-message-details/push-message-details.component';
import { PushNotificationsComponent } from './components/push-notifications/push-notifications.component';
import { PushNotificationFormComponent } from './components/push-notifications/push-notification-form/push-notification-form.component';
import { PushNotificationDetailsComponent } from './components/push-notifications/push-notification-details/push-notification-details.component';
import { QuizzesComponent } from './components/quizzes/quizzes.component';
import { QuizFormComponent } from './components/quizzes/quiz-form/quiz-form.component';
import { QuizDetailsComponent } from './components/quizzes/quiz-details/quiz-details.component';
import { QuizAnswersComponent } from './components/quiz-answers/quiz-answers.component';
import { QuizAnswerFormComponent } from './components/quiz-answers/quiz-answer-form/quiz-answer-form.component';
import { QuizAnswerDetailsComponent } from './components/quiz-answers/quiz-answer-details/quiz-answer-details.component';
import { QuizTypesComponent } from './components/quiz-types/quiz-types.component';
import { QuizTypeFormComponent } from './components/quiz-types/quiz-type-form/quiz-type-form.component';
import { QuizTypeDetailsComponent } from './components/quiz-types/quiz-type-details/quiz-type-details.component';
import { RecipesComponent } from './components/recipes/recipes.component';
import { RecipeFormComponent } from './components/recipes/recipe-form/recipe-form.component';
import { RecipeDetailsComponent } from './components/recipes/recipe-details/recipe-details.component';
import { RecipeIngredientsComponent } from './components/recipe-ingredients/recipe-ingredients.component';
import { RecipeIngredientFormComponent } from './components/recipe-ingredients/recipe-ingredient-form/recipe-ingredient-form.component';
import { RecipeIngredientDetailsComponent } from './components/recipe-ingredients/recipe-ingredient-details/recipe-ingredient-details.component';
import { RecipeUtensilsComponent } from './components/recipe-utensils/recipe-utensils.component';
import { RecipeUtensilFormComponent } from './components/recipe-utensils/recipe-utensil-form/recipe-utensil-form.component';
import { RecipeUtensilDetailsComponent } from './components/recipe-utensils/recipe-utensil-details/recipe-utensil-details.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { ReviewFormComponent } from './components/reviews/review-form/review-form.component';
import { ReviewDetailsComponent } from './components/reviews/review-details/review-details.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SettingFormComponent } from './components/settings/setting-form/setting-form.component';
import { SettingDetailsComponent } from './components/settings/setting-details/setting-details.component';
import { SharingsComponent } from './components/sharings/sharings.component';
import { SharingFormComponent } from './components/sharings/sharing-form/sharing-form.component';
import { SharingDetailsComponent } from './components/sharings/sharing-details/sharing-details.component';
import { ShoppingListsComponent } from './components/shopping-lists/shopping-lists.component';
import { ShoppingListFormComponent } from './components/shopping-lists/shopping-list-form/shopping-list-form.component';
import { ShoppingListDetailsComponent } from './components/shopping-lists/shopping-list-details/shopping-list-details.component';
import { ShoppingListIngredientsComponent } from './components/shopping-list-ingredients/shopping-list-ingredients.component';
import { ShoppingListIngredientFormComponent } from './components/shopping-list-ingredients/shopping-list-ingredient-form/shopping-list-ingredient-form.component';
import { ShoppingListIngredientDetailsComponent } from './components/shopping-list-ingredients/shopping-list-ingredient-details/shopping-list-ingredient-details.component';
import { ShoppingListRecipesComponent } from './components/shopping-list-recipes/shopping-list-recipes.component';
import { ShoppingListRecipeFormComponent } from './components/shopping-list-recipes/shopping-list-recipe-form/shopping-list-recipe-form.component';
import { ShoppingListRecipeDetailsComponent } from './components/shopping-list-recipes/shopping-list-recipe-details/shopping-list-recipe-details.component';
import { ShowsComponent } from './components/shows/shows.component';
import { ShowFormComponent } from './components/shows/show-form/show-form.component';
import { ShowDetailsComponent } from './components/shows/show-details/show-details.component';
import { StepsComponent } from './components/steps/steps.component';
import { StepFormComponent } from './components/steps/step-form/step-form.component';
import { StepDetailsComponent } from './components/steps/step-details/step-details.component';
import { SubCategoriesComponent } from './components/sub-categories/sub-categories.component';
import { SubCategoryFormComponent } from './components/sub-categories/sub-category-form/sub-category-form.component';
import { SubCategoryDetailsComponent } from './components/sub-categories/sub-category-details/sub-category-details.component';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { SubscriptionFormComponent } from './components/subscriptions/subscription-form/subscription-form.component';
import { SubscriptionDetailsComponent } from './components/subscriptions/subscription-details/subscription-details.component';
import { UsersComponent } from './components/users/users.component';
import { UserFormComponent } from './components/users/user-form/user-form.component';
import { UserDetailsComponent } from './components/users/user-details/user-details.component';
import { UtensilsComponent } from './components/utensils/utensils.component';
import { UtensilFormComponent } from './components/utensils/utensil-form/utensil-form.component';
import { UtensilDetailsComponent } from './components/utensils/utensil-details/utensil-details.component';
import { VideosComponent } from './components/videos/videos.component';
import { VideoFormComponent } from './components/videos/video-form/video-form.component';
import { VideoDetailsComponent } from './components/videos/video-details/video-details.component';
import { VideoConverterComponent } from './components/videos/video-converter/video-converter.component';
import { VideoTypesComponent } from './components/video-types/video-types.component';
import { VideoTypeFormComponent } from './components/video-types/video-type-form/video-type-form.component';
import { VideoTypeDetailsComponent } from './components/video-types/video-type-details/video-type-details.component';
import { VisitsComponent } from './components/visits/visits.component';
import { VisitFormComponent } from './components/visits/visit-form/visit-form.component';
import { VisitDetailsComponent } from './components/visits/visit-details/visit-details.component';

// Import Containers
import { DefaultLayoutComponent } from '../containers';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Dashboard'
        },
      },
      {
        path: 'profile',
        component: ProfileComponent,
        data: {
          title: 'My Profile'
        },
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        data: {
          title: 'Change My Password'
        },
      },
      {
        path: 'filer',
        component: FilerComponent,
        data: {
          title: 'File Manger'
        },
      },
      {
        path: 'filer/:path',
        component: FilerComponent,
        data: {
          title: 'File Manger'
        },
      },
      {
        path: 'categories',
        component: CategoriesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Categories'
        },
      },
      {
        path: 'categories/add',
        component: CategoryFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Category'
        },
      },
      {
        path: 'categories/edit/:id',
        component: CategoryFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Category'
        },
      },
      {
        path: 'categories/details/:id',
        component: CategoryDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Category Details'
        },
      },
      {
        path: 'cities',
        component: CitiesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Cities'
        },
      },
      {
        path: 'cities/add',
        component: CityFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New City'
        },
      },
      {
        path: 'cities/edit/:id',
        component: CityFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit City'
        },
      },
      {
        path: 'cities/details/:id',
        component: CityDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'City Details'
        },
      },
      {
        path: 'companies',
        component: CompaniesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Companies'
        },
      },
      {
        path: 'companies/add',
        component: CompanyFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Company'
        },
      },
      {
        path: 'companies/edit/:id',
        component: CompanyFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Company'
        },
      },
      {
        path: 'companies/details/:id',
        component: CompanyDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Company Details'
        },
      },
      {
        path: 'countries',
        component: CountriesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Countries'
        },
      },
      {
        path: 'countries/add',
        component: CountryFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Country'
        },
      },
      {
        path: 'countries/edit/:id',
        component: CountryFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Country'
        },
      },
      {
        path: 'countries/details/:id',
        component: CountryDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Country Details'
        },
      },
      {
        path: 'favorites',
        component: FavoritesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Favorites'
        },
      },
      {
        path: 'favorites/add',
        component: FavoriteFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Favorite'
        },
      },
      {
        path: 'favorites/edit/:id',
        component: FavoriteFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Favorite'
        },
      },
      {
        path: 'favorites/details/:id',
        component: FavoriteDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Favorite Details'
        },
      },
      {
        path: 'ingredients',
        component: IngredientsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Ingredients'
        },
      },
      {
        path: 'ingredients/add',
        component: IngredientFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Ingredient'
        },
      },
      {
        path: 'ingredients/edit/:id',
        component: IngredientFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Ingredient'
        },
      },
      {
        path: 'ingredients/details/:id',
        component: IngredientDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Ingredient Details'
        },
      },
      {
        path: 'languages',
        component: LanguagesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Languages'
        },
      },
      {
        path: 'languages/add',
        component: LanguageFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Language'
        },
      },
      {
        path: 'languages/edit/:id',
        component: LanguageFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Language'
        },
      },
      {
        path: 'languages/details/:id',
        component: LanguageDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Language Details'
        },
      },
      {
        path: 'likes',
        component: LikesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Likes'
        },
      },
      {
        path: 'likes/add',
        component: LikeFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Like'
        },
      },
      {
        path: 'likes/edit/:id',
        component: LikeFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Like'
        },
      },
      {
        path: 'likes/details/:id',
        component: LikeDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Like Details'
        },
      },
      {
        path: 'live-channels',
        component: LiveChannelsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Live Channels'
        },
      },
      {
        path: 'live-channels/add',
        component: LiveChannelFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Live Channel'
        },
      },
      {
        path: 'live-channels/edit/:id',
        component: LiveChannelFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Live Channel'
        },
      },
      {
        path: 'live-channels/details/:id',
        component: LiveChannelDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Live Channel Details'
        },
      },
      {
        path: 'logs',
        component: LogsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Logs'
        },
      },
      {
        path: 'logs/add',
        component: LogFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Log'
        },
      },
      {
        path: 'logs/edit/:id',
        component: LogFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Log'
        },
      },
      {
        path: 'logs/details/:id',
        component: LogDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Log Details'
        },
      },
      {
        path: 'packs',
        component: PacksComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Packs'
        },
      },
      {
        path: 'packs/add',
        component: PackFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Pack'
        },
      },
      {
        path: 'packs/edit/:id',
        component: PackFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Pack'
        },
      },
      {
        path: 'packs/details/:id',
        component: PackDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Pack Details'
        },
      },
      {
        path: 'package-types',
        component: PackageTypesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Package Types'
        },
      },
      {
        path: 'package-types/add',
        component: PackageTypeFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Package Type'
        },
      },
      {
        path: 'package-types/edit/:id',
        component: PackageTypeFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Package Type'
        },
      },
      {
        path: 'package-types/details/:id',
        component: PackageTypeDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Package Type Details'
        },
      },
      {
        path: 'posts',
        component: PostsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Posts'
        },
      },
      {
        path: 'posts/add',
        component: PostFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Post'
        },
      },
      {
        path: 'posts/edit/:id',
        component: PostFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Post'
        },
      },
      {
        path: 'posts/details/:id',
        component: PostDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Post Details'
        },
      },
      {
        path: 'post-types',
        component: PostTypesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Post Types'
        },
      },
      {
        path: 'post-types/add',
        component: PostTypeFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Post Type'
        },
      },
      {
        path: 'post-types/edit/:id',
        component: PostTypeFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Post Type'
        },
      },
      {
        path: 'post-types/details/:id',
        component: PostTypeDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Post Type Details'
        },
      },
      {
        path: 'prices',
        component: PricesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Prices'
        },
      },
      {
        path: 'prices/add',
        component: PriceFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Price'
        },
      },
      {
        path: 'prices/edit/:id',
        component: PriceFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Price'
        },
      },
      {
        path: 'prices/details/:id',
        component: PriceDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Price Details'
        },
      },
      {
        path: 'push-devices',
        component: PushDevicesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Push Devices'
        },
      },
      {
        path: 'push-devices/add',
        component: PushDeviceFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Push Device'
        },
      },
      {
        path: 'push-devices/edit/:id',
        component: PushDeviceFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Push Device'
        },
      },
      {
        path: 'push-devices/details/:id',
        component: PushDeviceDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Push Device Details'
        },
      },
      {
        path: 'push-messages',
        component: PushMessagesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Push Messages'
        },
      },
      {
        path: 'push-messages/add',
        component: PushMessageFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Push Message'
        },
      },
      {
        path: 'push-messages/edit/:id',
        component: PushMessageFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Push Message'
        },
      },
      {
        path: 'push-messages/details/:id',
        component: PushMessageDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Push Message Details'
        },
      },
      {
        path: 'push-notifications',
        component: PushNotificationsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Push Notifications'
        },
      },
      {
        path: 'push-notifications/add',
        component: PushNotificationFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Push Notification'
        },
      },
      {
        path: 'push-notifications/edit/:id',
        component: PushNotificationFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Push Notification'
        },
      },
      {
        path: 'push-notifications/details/:id',
        component: PushNotificationDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Push Notification Details'
        },
      },
      {
        path: 'quizzes',
        component: QuizzesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Quizzes'
        },
      },
      {
        path: 'quizzes/add',
        component: QuizFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Quiz'
        },
      },
      {
        path: 'quizzes/edit/:id',
        component: QuizFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Quiz'
        },
      },
      {
        path: 'quizzes/details/:id',
        component: QuizDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Quiz Details'
        },
      },
      {
        path: 'quiz-answers',
        component: QuizAnswersComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Quiz Answers'
        },
      },
      {
        path: 'quiz-answers/add',
        component: QuizAnswerFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Quiz Answer'
        },
      },
      {
        path: 'quiz-answers/edit/:id',
        component: QuizAnswerFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Quiz Answer'
        },
      },
      {
        path: 'quiz-answers/details/:id',
        component: QuizAnswerDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Quiz Answer Details'
        },
      },
      {
        path: 'quiz-types',
        component: QuizTypesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Quiz Types'
        },
      },
      {
        path: 'quiz-types/add',
        component: QuizTypeFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Quiz Type'
        },
      },
      {
        path: 'quiz-types/edit/:id',
        component: QuizTypeFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Quiz Type'
        },
      },
      {
        path: 'quiz-types/details/:id',
        component: QuizTypeDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Quiz Type Details'
        },
      },
      {
        path: 'recipes',
        component: RecipesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Recipes'
        },
      },
      {
        path: 'recipes/add',
        component: RecipeFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Recipe'
        },
      },
      {
        path: 'recipes/edit/:id',
        component: RecipeFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Recipe'
        },
      },
      {
        path: 'recipes/details/:id',
        component: RecipeDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Recipe Details'
        },
      },
      {
        path: 'recipe-ingredients',
        component: RecipeIngredientsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Recipe Ingredients'
        },
      },
      {
        path: 'recipe-ingredients/add',
        component: RecipeIngredientFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Recipe Ingredient'
        },
      },
      {
        path: 'recipe-ingredients/edit/:id',
        component: RecipeIngredientFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Recipe Ingredient'
        },
      },
      {
        path: 'recipe-ingredients/details/:id',
        component: RecipeIngredientDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Recipe Ingredient Details'
        },
      },
      {
        path: 'recipe-utensils',
        component: RecipeUtensilsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Recipe Utensils'
        },
      },
      {
        path: 'recipe-utensils/add',
        component: RecipeUtensilFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Recipe Utensil'
        },
      },
      {
        path: 'recipe-utensils/edit/:id',
        component: RecipeUtensilFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Recipe Utensil'
        },
      },
      {
        path: 'recipe-utensils/details/:id',
        component: RecipeUtensilDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Recipe Utensil Details'
        },
      },
      {
        path: 'reviews',
        component: ReviewsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Reviews'
        },
      },
      {
        path: 'reviews/add',
        component: ReviewFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Review'
        },
      },
      {
        path: 'reviews/edit/:id',
        component: ReviewFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Review'
        },
      },
      {
        path: 'reviews/details/:id',
        component: ReviewDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Review Details'
        },
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Settings'
        },
      },
      {
        path: 'settings/add',
        component: SettingFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Setting'
        },
      },
      {
        path: 'settings/edit/:id',
        component: SettingFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Setting'
        },
      },
      {
        path: 'settings/details/:id',
        component: SettingDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Setting Details'
        },
      },
      {
        path: 'sharings',
        component: SharingsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Sharings'
        },
      },
      {
        path: 'sharings/add',
        component: SharingFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Sharing'
        },
      },
      {
        path: 'sharings/edit/:id',
        component: SharingFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Sharing'
        },
      },
      {
        path: 'sharings/details/:id',
        component: SharingDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Sharing Details'
        },
      },
      {
        path: 'shopping-lists',
        component: ShoppingListsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Shopping Lists'
        },
      },
      {
        path: 'shopping-lists/add',
        component: ShoppingListFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Shopping List'
        },
      },
      {
        path: 'shopping-lists/edit/:id',
        component: ShoppingListFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Shopping List'
        },
      },
      {
        path: 'shopping-lists/details/:id',
        component: ShoppingListDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Shopping List Details'
        },
      },
      {
        path: 'shopping-list-ingredients',
        component: ShoppingListIngredientsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Shopping List Ingredients'
        },
      },
      {
        path: 'shopping-list-ingredients/add',
        component: ShoppingListIngredientFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Shopping List Ingredient'
        },
      },
      {
        path: 'shopping-list-ingredients/edit/:id',
        component: ShoppingListIngredientFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Shopping List Ingredient'
        },
      },
      {
        path: 'shopping-list-ingredients/details/:id',
        component: ShoppingListIngredientDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Shopping List Ingredient Details'
        },
      },
      {
        path: 'shopping-list-recipes',
        component: ShoppingListRecipesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Shopping List Recipes'
        },
      },
      {
        path: 'shopping-list-recipes/add',
        component: ShoppingListRecipeFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Shopping List Recipe'
        },
      },
      {
        path: 'shopping-list-recipes/edit/:id',
        component: ShoppingListRecipeFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Shopping List Recipe'
        },
      },
      {
        path: 'shopping-list-recipes/details/:id',
        component: ShoppingListRecipeDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Shopping List Recipe Details'
        },
      },
      {
        path: 'shows',
        component: ShowsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Shows'
        },
      },
      {
        path: 'shows/add',
        component: ShowFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Show'
        },
      },
      {
        path: 'shows/edit/:id',
        component: ShowFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Show'
        },
      },
      {
        path: 'shows/details/:id',
        component: ShowDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Show Details'
        },
      },
      {
        path: 'steps',
        component: StepsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Steps'
        },
      },
      {
        path: 'steps/add',
        component: StepFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Step'
        },
      },
      {
        path: 'steps/edit/:id',
        component: StepFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Step'
        },
      },
      {
        path: 'steps/details/:id',
        component: StepDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Step Details'
        },
      },
      {
        path: 'sub-categories',
        component: SubCategoriesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Sub Categories'
        },
      },
      {
        path: 'sub-categories/add',
        component: SubCategoryFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Sub Category'
        },
      },
      {
        path: 'sub-categories/edit/:id',
        component: SubCategoryFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Sub Category'
        },
      },
      {
        path: 'sub-categories/details/:id',
        component: SubCategoryDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Sub Category Details'
        },
      },
      {
        path: 'subscriptions',
        component: SubscriptionsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Subscriptions'
        },
      },
      {
        path: 'subscriptions/add',
        component: SubscriptionFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Subscription'
        },
      },
      {
        path: 'subscriptions/edit/:id',
        component: SubscriptionFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Subscription'
        },
      },
      {
        path: 'subscriptions/details/:id',
        component: SubscriptionDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Subscription Details'
        },
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Users'
        },
      },
      {
        path: 'users/add',
        component: UserFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New User'
        },
      },
      {
        path: 'users/edit/:id',
        component: UserFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit User'
        },
      },
      {
        path: 'users/details/:id',
        component: UserDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'User Details'
        },
      },
      {
        path: 'utensils',
        component: UtensilsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Utensils'
        },
      },
      {
        path: 'utensils/add',
        component: UtensilFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Utensil'
        },
      },
      {
        path: 'utensils/edit/:id',
        component: UtensilFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Utensil'
        },
      },
      {
        path: 'utensils/details/:id',
        component: UtensilDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Utensil Details'
        },
      },
      {
        path: 'videos',
        component: VideosComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Videos'
        },
      },
      {
        path: 'videos/add',
        component: VideoFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Video'
        },
      },
      {
        path: 'videos/edit/:id',
        component: VideoFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Video'
        },
      },
      {
        path: 'videos/details/:id',
        component: VideoDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Video Details'
        },
      },
      {
        path: 'videos/converter/:id',
        component: VideoConverterComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Video Converter'
        },
      },
      {
        path: 'video-types',
        component: VideoTypesComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Video Types'
        },
      },
      {
        path: 'video-types/add',
        component: VideoTypeFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Video Type'
        },
      },
      {
        path: 'video-types/edit/:id',
        component: VideoTypeFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Video Type'
        },
      },
      {
        path: 'video-types/details/:id',
        component: VideoTypeDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Video Type Details'
        },
      },
      {
        path: 'visits',
        component: VisitsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Visits'
        },
      },
      {
        path: 'visits/add',
        component: VisitFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'New Visit'
        },
      },
      {
        path: 'visits/edit/:id',
        component: VisitFormComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Edit Visit'
        },
      },
      {
        path: 'visits/details/:id',
        component: VisitDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Visit Details'
        },
      },
    ]
  },
  { path: '**', component: DefaultLayoutComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class MumEatRoutingModule {}

