import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CacheService } from '../../services/cache/cache.service';
import { CommonService } from '../../services/common/common.service';
import { Column } from '../interfaces/column.interface';
import moment from 'moment';
import { PricesDataService } from './prices-data.service';
import { Price, PricesResult } from '../interfaces';
import { SharingsDataService } from './sharings-data.service';
import { Sharing, SharingsResult } from '../interfaces';
import { UsersDataService } from './users-data.service';
import { User, UsersResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class LiveChannelsColumnsService {

  public prices: Price[] = [];
  public sharings: Sharing[] = [];
  public users: User[] = [];
  public statusesOptions = [{
    value: 'Draft',
    label: 'STATUSESDRAFT',
    css: 'primary'
  }, {
    value: 'Online',
    label: 'STATUSESONLINE',
    css: 'success'
  }, {
    value: 'Deactivated',
    label: 'STATUSESDEACTIVATED',
    css: 'warning'
  }, {
    value: 'Offline',
    label: 'STATUSESOFFLINE',
    css: 'danger'
  }, {
    value: 'Deleted',
    label: 'STATUSESDELETED',
    css: 'default'
  }, {
    value: 'Archived',
    label: 'STATUSESARCHIVED',
    css: 'info'
  }];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private cache: CacheService,
    private common: CommonService,
    private pricesDataService: PricesDataService,
    private sharingsDataService: SharingsDataService,
    private usersDataService: UsersDataService,

  ) {
    this.getPrices()
    this.getSharings()
    this.getUsers()
  }

  getPrices(offset: number = 0): void {
    const cacheKey: string = 'prices.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.prices = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.pricesDataService.getPrices(parameters).subscribe((response: PricesResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.prices = response.results;
        } else {
          this.prices = this.prices.concat(response.results);
        }
        if (response.inlineCount > this.prices.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getPrices(offset);
        }
        response.results = this.prices;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getSharings(offset: number = 0): void {
    const cacheKey: string = 'sharings.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.sharings = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.sharingsDataService.getSharings(parameters).subscribe((response: SharingsResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.sharings = response.results;
        } else {
          this.sharings = this.sharings.concat(response.results);
        }
        if (response.inlineCount > this.sharings.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getSharings(offset);
        }
        response.results = this.sharings;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getUsers(offset: number = 0): void {
    const cacheKey: string = 'users.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.users = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.usersDataService.getUsers(parameters).subscribe((response: UsersResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.users = response.results;
        } else {
          this.users = this.users.concat(response.results);
        }
        if (response.inlineCount > this.users.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getUsers(offset);
        }
        response.results = this.users;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getColumns(): Column[] {
    return [
      { field: 'id', sortField: 'id', title: this.translate.instant('ID'), show: (this.navigation.getParamValue('liveChannels.id.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'price', title: this.translate.instant('PRICE'), type: 'linkValue', filterData: this.prices, show: (this.navigation.getParamValue('liveChannels.priceId.showFiled', true) && true), displayInList: true, filterField: 'price.id', displayField: 'name', sortField: 'price.name', url: 'prices' },
      { field: 'sharing', title: this.translate.instant('SHARING'), type: 'linkValue', filterData: this.sharings, show: (this.navigation.getParamValue('liveChannels.sharingId.showFiled', true) && true), displayInList: true, filterField: 'sharing.id', displayField: 'name', sortField: 'sharing.name', url: 'sharings' },
      { field: 'name', sortField: 'name', title: this.translate.instant('NAME'), show: (this.navigation.getParamValue('liveChannels.name.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'nameAr', sortField: 'nameAr', title: this.translate.instant('NAMEAR'), show: (this.navigation.getParamValue('liveChannels.nameAr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'nameFr', sortField: 'nameFr', title: this.translate.instant('NAMEFR'), show: (this.navigation.getParamValue('liveChannels.nameFr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'slug', sortField: 'slug', title: this.translate.instant('SLUG'), show: (this.navigation.getParamValue('liveChannels.slug.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'slugAr', sortField: 'slugAr', title: this.translate.instant('SLUGAR'), show: (this.navigation.getParamValue('liveChannels.slugAr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'slugFr', sortField: 'slugFr', title: this.translate.instant('SLUGFR'), show: (this.navigation.getParamValue('liveChannels.slugFr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'streamName', sortField: 'streamName', title: this.translate.instant('STREAMNAME'), show: (this.navigation.getParamValue('liveChannels.streamName.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'putOnHome', sortField: 'putOnHome', title: this.translate.instant('PUTONHOME'), show: (this.navigation.getParamValue('liveChannels.putOnHome.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'status', sortField: 'status', title: this.translate.instant('STATUS'), show: (this.navigation.getParamValue('liveChannels.status.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_PUBLISHER') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_PUBLISHER') > -1), type: 'enumValue', filterData: this.statusesOptions},
      { field: 'totalViews', sortField: 'totalViews', title: this.translate.instant('TOTALVIEWS'), show: (this.navigation.getParamValue('liveChannels.totalViews.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'totalHits', sortField: 'totalHits', title: this.translate.instant('TOTALHITS'), show: (this.navigation.getParamValue('liveChannels.totalHits.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'totalComments', sortField: 'totalComments', title: this.translate.instant('TOTALCOMMENTS'), show: (this.navigation.getParamValue('liveChannels.totalComments.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'totalRatings', sortField: 'totalRatings', title: this.translate.instant('TOTALRATINGS'), show: (this.navigation.getParamValue('liveChannels.totalRatings.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'averageRatings', sortField: 'averageRatings', title: this.translate.instant('AVERAGERATINGS'), show: (this.navigation.getParamValue('liveChannels.averageRatings.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'totalLikes', sortField: 'totalLikes', title: this.translate.instant('TOTALLIKES'), show: (this.navigation.getParamValue('liveChannels.totalLikes.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'totalDislikes', sortField: 'totalDislikes', title: this.translate.instant('TOTALDISLIKES'), show: (this.navigation.getParamValue('liveChannels.totalDislikes.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'totalBookmarks', sortField: 'totalBookmarks', title: this.translate.instant('TOTALBOOKMARKS'), show: (this.navigation.getParamValue('liveChannels.totalBookmarks.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'enableStreaming', sortField: 'enableStreaming', title: this.translate.instant('ENABLESTREAMING'), show: (this.navigation.getParamValue('liveChannels.enableStreaming.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'autoPublishing', sortField: 'autoPublishing', title: this.translate.instant('AUTOPUBLISHING'), show: (this.navigation.getParamValue('liveChannels.autoPublishing.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'startPublishing', sortField: 'startPublishing', title: this.translate.instant('STARTPUBLISHING'), show: (this.navigation.getParamValue('liveChannels.startPublishing.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'endPublishing', sortField: 'endPublishing', title: this.translate.instant('ENDPUBLISHING'), show: (this.navigation.getParamValue('liveChannels.endPublishing.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'createdAt', sortField: 'createdAt', title: this.translate.instant('CREATEDAT'), show: (this.navigation.getParamValue('liveChannels.createdAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'creatorUser', title: this.translate.instant('CREATORUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('liveChannels.creatorUser.showFiled', false) && true), displayInList: true, filterField: 'creatorUser.id', displayField: 'username', sortField: 'creatorUser.username', url: 'users' },
      { field: 'modifiedAt', sortField: 'modifiedAt', title: this.translate.instant('MODIFIEDAT'), show: (this.navigation.getParamValue('liveChannels.modifiedAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'modifierUser', title: this.translate.instant('MODIFIERUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('liveChannels.modifierUser.showFiled', false) && true), displayInList: true, filterField: 'modifierUser.id', displayField: 'username', sortField: 'modifierUser.username', url: 'users' },
      { field: 'actions', title: this.translate.instant('ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }

  format(fieldName: string, value: any) {
    if (fieldName.toLowerCase().indexOf('time') > -1) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    } else if (fieldName.toLowerCase().indexOf('date') > -1) {
      return moment(value).format('YYYY-MM-DD');
    }
    return value;
  }

}
