import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CacheService } from '../../services/cache/cache.service';
import { CommonService } from '../../services/common/common.service';
import { Column } from '../interfaces/column.interface';
import moment from 'moment';
import { QuizTypesDataService } from './quiz-types-data.service';
import { QuizType, QuizTypesResult } from '../interfaces';
import { UsersDataService } from './users-data.service';
import { User, UsersResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class QuizzesColumnsService {

  public quizTypes: QuizType[] = [];
  public users: User[] = [];
  public kindsOptions = [{
    value: 'SingleChoice',
    label: 'KINDSSINGLECHOICE',
    css: 'primary'
  }, {
    value: 'MultipleChoice',
    label: 'KINDSMULTIPLECHOICE',
    css: 'success'
  }];
  public statusesOptions = [{
    value: 'Draft',
    label: 'STATUSESDRAFT',
    css: 'primary'
  }, {
    value: 'Online',
    label: 'STATUSESONLINE',
    css: 'success'
  }, {
    value: 'Deactivated',
    label: 'STATUSESDEACTIVATED',
    css: 'warning'
  }, {
    value: 'Offline',
    label: 'STATUSESOFFLINE',
    css: 'danger'
  }, {
    value: 'Deleted',
    label: 'STATUSESDELETED',
    css: 'default'
  }, {
    value: 'Archived',
    label: 'STATUSESARCHIVED',
    css: 'info'
  }];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private cache: CacheService,
    private common: CommonService,
    private quizTypesDataService: QuizTypesDataService,
    private usersDataService: UsersDataService,

  ) {
    this.getQuizTypes()
    this.getUsers()
  }

  getQuizTypes(offset: number = 0): void {
    const cacheKey: string = 'quizTypes.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.quizTypes = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.quizTypesDataService.getQuizTypes(parameters).subscribe((response: QuizTypesResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.quizTypes = response.results;
        } else {
          this.quizTypes = this.quizTypes.concat(response.results);
        }
        if (response.inlineCount > this.quizTypes.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getQuizTypes(offset);
        }
        response.results = this.quizTypes;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getUsers(offset: number = 0): void {
    const cacheKey: string = 'users.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.users = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.usersDataService.getUsers(parameters).subscribe((response: UsersResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.users = response.results;
        } else {
          this.users = this.users.concat(response.results);
        }
        if (response.inlineCount > this.users.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getUsers(offset);
        }
        response.results = this.users;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getColumns(): Column[] {
    return [
      { field: 'id', sortField: 'id', title: this.translate.instant('ID'), show: (this.navigation.getParamValue('quizzes.id.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'quizType', title: this.translate.instant('QUIZTYPE'), type: 'linkValue', filterData: this.quizTypes, show: (this.navigation.getParamValue('quizzes.quizTypeId.showFiled', true) && true), displayInList: true, filterField: 'quizType.id', displayField: 'name', sortField: 'quizType.name', url: 'quiz-types' },
      { field: 'title', sortField: 'title', title: this.translate.instant('TITLE'), show: (this.navigation.getParamValue('quizzes.title.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'titleAr', sortField: 'titleAr', title: this.translate.instant('TITLEAR'), show: (this.navigation.getParamValue('quizzes.titleAr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'titleFr', sortField: 'titleFr', title: this.translate.instant('TITLEFR'), show: (this.navigation.getParamValue('quizzes.titleFr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'slug', sortField: 'slug', title: this.translate.instant('SLUG'), show: (this.navigation.getParamValue('quizzes.slug.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'slugAr', sortField: 'slugAr', title: this.translate.instant('SLUGAR'), show: (this.navigation.getParamValue('quizzes.slugAr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'slugFr', sortField: 'slugFr', title: this.translate.instant('SLUGFR'), show: (this.navigation.getParamValue('quizzes.slugFr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'picture', sortField: 'picture', title: this.translate.instant('PICTURE'), show: (this.navigation.getParamValue('quizzes.picture.showFiled', false) && true), displayInList: true, type: 'imgValue'},
      { field: 'description', sortField: 'description', title: this.translate.instant('DESCRIPTION'), show: (this.navigation.getParamValue('quizzes.description.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'descriptionAr', sortField: 'descriptionAr', title: this.translate.instant('DESCRIPTIONAR'), show: (this.navigation.getParamValue('quizzes.descriptionAr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'descriptionFr', sortField: 'descriptionFr', title: this.translate.instant('DESCRIPTIONFR'), show: (this.navigation.getParamValue('quizzes.descriptionFr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'kind', sortField: 'kind', title: this.translate.instant('KIND'), show: (this.navigation.getParamValue('quizzes.kind.showFiled', false) && true), displayInList: true, type: 'enumValue', filterData: this.kindsOptions},
      { field: 'question', sortField: 'question', title: this.translate.instant('QUESTION'), show: (this.navigation.getParamValue('quizzes.question.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'questionAr', sortField: 'questionAr', title: this.translate.instant('QUESTIONAR'), show: (this.navigation.getParamValue('quizzes.questionAr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'questionFr', sortField: 'questionFr', title: this.translate.instant('QUESTIONFR'), show: (this.navigation.getParamValue('quizzes.questionFr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice1', sortField: 'choice1', title: this.translate.instant('CHOICE1'), show: (this.navigation.getParamValue('quizzes.choice1.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice1Ar', sortField: 'choice1Ar', title: this.translate.instant('CHOICE1AR'), show: (this.navigation.getParamValue('quizzes.choice1Ar.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice1Fr', sortField: 'choice1Fr', title: this.translate.instant('CHOICE1FR'), show: (this.navigation.getParamValue('quizzes.choice1Fr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice1IsCorrect', sortField: 'choice1IsCorrect', title: this.translate.instant('CHOICE1ISCORRECT'), show: (this.navigation.getParamValue('quizzes.choice1IsCorrect.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'choice2', sortField: 'choice2', title: this.translate.instant('CHOICE2'), show: (this.navigation.getParamValue('quizzes.choice2.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice2Ar', sortField: 'choice2Ar', title: this.translate.instant('CHOICE2AR'), show: (this.navigation.getParamValue('quizzes.choice2Ar.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice2Fr', sortField: 'choice2Fr', title: this.translate.instant('CHOICE2FR'), show: (this.navigation.getParamValue('quizzes.choice2Fr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice2IsCorrect', sortField: 'choice2IsCorrect', title: this.translate.instant('CHOICE2ISCORRECT'), show: (this.navigation.getParamValue('quizzes.choice2IsCorrect.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'choice3', sortField: 'choice3', title: this.translate.instant('CHOICE3'), show: (this.navigation.getParamValue('quizzes.choice3.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice3Ar', sortField: 'choice3Ar', title: this.translate.instant('CHOICE3AR'), show: (this.navigation.getParamValue('quizzes.choice3Ar.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice3Fr', sortField: 'choice3Fr', title: this.translate.instant('CHOICE3FR'), show: (this.navigation.getParamValue('quizzes.choice3Fr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice3IsCorrect', sortField: 'choice3IsCorrect', title: this.translate.instant('CHOICE3ISCORRECT'), show: (this.navigation.getParamValue('quizzes.choice3IsCorrect.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'choice4', sortField: 'choice4', title: this.translate.instant('CHOICE4'), show: (this.navigation.getParamValue('quizzes.choice4.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice4Ar', sortField: 'choice4Ar', title: this.translate.instant('CHOICE4AR'), show: (this.navigation.getParamValue('quizzes.choice4Ar.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice4Fr', sortField: 'choice4Fr', title: this.translate.instant('CHOICE4FR'), show: (this.navigation.getParamValue('quizzes.choice4Fr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice4IsCorrect', sortField: 'choice4IsCorrect', title: this.translate.instant('CHOICE4ISCORRECT'), show: (this.navigation.getParamValue('quizzes.choice4IsCorrect.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'choice5', sortField: 'choice5', title: this.translate.instant('CHOICE5'), show: (this.navigation.getParamValue('quizzes.choice5.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice5Ar', sortField: 'choice5Ar', title: this.translate.instant('CHOICE5AR'), show: (this.navigation.getParamValue('quizzes.choice5Ar.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice5Fr', sortField: 'choice5Fr', title: this.translate.instant('CHOICE5FR'), show: (this.navigation.getParamValue('quizzes.choice5Fr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice5IsCorrect', sortField: 'choice5IsCorrect', title: this.translate.instant('CHOICE5ISCORRECT'), show: (this.navigation.getParamValue('quizzes.choice5IsCorrect.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'choice6', sortField: 'choice6', title: this.translate.instant('CHOICE6'), show: (this.navigation.getParamValue('quizzes.choice6.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice6Ar', sortField: 'choice6Ar', title: this.translate.instant('CHOICE6AR'), show: (this.navigation.getParamValue('quizzes.choice6Ar.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice6Fr', sortField: 'choice6Fr', title: this.translate.instant('CHOICE6FR'), show: (this.navigation.getParamValue('quizzes.choice6Fr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice6IsCorrect', sortField: 'choice6IsCorrect', title: this.translate.instant('CHOICE6ISCORRECT'), show: (this.navigation.getParamValue('quizzes.choice6IsCorrect.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'choice7', sortField: 'choice7', title: this.translate.instant('CHOICE7'), show: (this.navigation.getParamValue('quizzes.choice7.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice7Ar', sortField: 'choice7Ar', title: this.translate.instant('CHOICE7AR'), show: (this.navigation.getParamValue('quizzes.choice7Ar.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice7Fr', sortField: 'choice7Fr', title: this.translate.instant('CHOICE7FR'), show: (this.navigation.getParamValue('quizzes.choice7Fr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice7IsCorrect', sortField: 'choice7IsCorrect', title: this.translate.instant('CHOICE7ISCORRECT'), show: (this.navigation.getParamValue('quizzes.choice7IsCorrect.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'choice8', sortField: 'choice8', title: this.translate.instant('CHOICE8'), show: (this.navigation.getParamValue('quizzes.choice8.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice8Ar', sortField: 'choice8Ar', title: this.translate.instant('CHOICE8AR'), show: (this.navigation.getParamValue('quizzes.choice8Ar.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice8Fr', sortField: 'choice8Fr', title: this.translate.instant('CHOICE8FR'), show: (this.navigation.getParamValue('quizzes.choice8Fr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'choice8IsCorrect', sortField: 'choice8IsCorrect', title: this.translate.instant('CHOICE8ISCORRECT'), show: (this.navigation.getParamValue('quizzes.choice8IsCorrect.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'autoPublishing', sortField: 'autoPublishing', title: this.translate.instant('AUTOPUBLISHING'), show: (this.navigation.getParamValue('quizzes.autoPublishing.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'startPublishing', sortField: 'startPublishing', title: this.translate.instant('STARTPUBLISHING'), show: (this.navigation.getParamValue('quizzes.startPublishing.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'endPublishing', sortField: 'endPublishing', title: this.translate.instant('ENDPUBLISHING'), show: (this.navigation.getParamValue('quizzes.endPublishing.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'publishDate', sortField: 'publishDate', title: this.translate.instant('PUBLISHDATE'), show: (this.navigation.getParamValue('quizzes.publishDate.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATEFORMAT')},
      { field: 'status', sortField: 'status', title: this.translate.instant('STATUS'), show: (this.navigation.getParamValue('quizzes.status.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_PUBLISHER') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_PUBLISHER') > -1), type: 'enumValue', filterData: this.statusesOptions},
      { field: 'countAnswers', sortField: 'countAnswers', title: this.translate.instant('COUNTANSWERS'), show: (this.navigation.getParamValue('quizzes.countAnswers.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'countWinners', sortField: 'countWinners', title: this.translate.instant('COUNTWINNERS'), show: (this.navigation.getParamValue('quizzes.countWinners.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'isFinished', sortField: 'isFinished', title: this.translate.instant('ISFINISHED'), show: (this.navigation.getParamValue('quizzes.isFinished.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'ordering', sortField: 'ordering', title: this.translate.instant('ORDERING'), show: (this.navigation.getParamValue('quizzes.ordering.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'createdAt', sortField: 'createdAt', title: this.translate.instant('CREATEDAT'), show: (this.navigation.getParamValue('quizzes.createdAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'creatorUser', title: this.translate.instant('CREATORUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('quizzes.creatorUser.showFiled', false) && true), displayInList: true, filterField: 'creatorUser.id', displayField: 'username', sortField: 'creatorUser.username', url: 'users' },
      { field: 'modifiedAt', sortField: 'modifiedAt', title: this.translate.instant('MODIFIEDAT'), show: (this.navigation.getParamValue('quizzes.modifiedAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'modifierUser', title: this.translate.instant('MODIFIERUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('quizzes.modifierUser.showFiled', false) && true), displayInList: true, filterField: 'modifierUser.id', displayField: 'username', sortField: 'modifierUser.username', url: 'users' },
      { field: 'actions', title: this.translate.instant('ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }

  format(fieldName: string, value: any) {
    if (fieldName.toLowerCase().indexOf('time') > -1) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    } else if (fieldName.toLowerCase().indexOf('date') > -1) {
      return moment(value).format('YYYY-MM-DD');
    }
    return value;
  }

}
