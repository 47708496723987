import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CacheService } from '../../services/cache/cache.service';
import { CommonService } from '../../services/common/common.service';
import { Column } from '../interfaces/column.interface';
import moment from 'moment';
import { PostTypesDataService } from './post-types-data.service';
import { PostType, PostTypesResult } from '../interfaces';
import { CategoriesDataService } from './categories-data.service';
import { Category, CategoriesResult } from '../interfaces';
import { SubCategoriesDataService } from './sub-categories-data.service';
import { SubCategory, SubCategoriesResult } from '../interfaces';
import { UsersDataService } from './users-data.service';
import { User, UsersResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class PostsColumnsService {

  public postTypes: PostType[] = [];
  public categories: Category[] = [];
  public subCategories: SubCategory[] = [];
  public users: User[] = [];
  public statusesOptions = [{
    value: 'Draft',
    label: 'STATUSESDRAFT',
    css: 'primary'
  }, {
    value: 'Online',
    label: 'STATUSESONLINE',
    css: 'success'
  }, {
    value: 'Deactivated',
    label: 'STATUSESDEACTIVATED',
    css: 'warning'
  }, {
    value: 'Offline',
    label: 'STATUSESOFFLINE',
    css: 'danger'
  }, {
    value: 'Deleted',
    label: 'STATUSESDELETED',
    css: 'default'
  }, {
    value: 'Archived',
    label: 'STATUSESARCHIVED',
    css: 'info'
  }];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private cache: CacheService,
    private common: CommonService,
    private postTypesDataService: PostTypesDataService,
    private categoriesDataService: CategoriesDataService,
    private subCategoriesDataService: SubCategoriesDataService,
    private usersDataService: UsersDataService,

  ) {
    this.getPostTypes()
    this.getCategories()
    this.getSubCategories()
    this.getUsers()
  }

  getPostTypes(offset: number = 0): void {
    const cacheKey: string = 'postTypes.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.postTypes = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.postTypesDataService.getPostTypes(parameters).subscribe((response: PostTypesResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.postTypes = response.results;
        } else {
          this.postTypes = this.postTypes.concat(response.results);
        }
        if (response.inlineCount > this.postTypes.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getPostTypes(offset);
        }
        response.results = this.postTypes;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getCategories(offset: number = 0): void {
    const cacheKey: string = 'categories.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.categories = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.categoriesDataService.getCategories(parameters).subscribe((response: CategoriesResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.categories = response.results;
        } else {
          this.categories = this.categories.concat(response.results);
        }
        if (response.inlineCount > this.categories.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getCategories(offset);
        }
        response.results = this.categories;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getSubCategories(offset: number = 0): void {
    const cacheKey: string = 'subCategories.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.subCategories = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.subCategoriesDataService.getSubCategories(parameters).subscribe((response: SubCategoriesResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.subCategories = response.results;
        } else {
          this.subCategories = this.subCategories.concat(response.results);
        }
        if (response.inlineCount > this.subCategories.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getSubCategories(offset);
        }
        response.results = this.subCategories;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getUsers(offset: number = 0): void {
    const cacheKey: string = 'users.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.users = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.usersDataService.getUsers(parameters).subscribe((response: UsersResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.users = response.results;
        } else {
          this.users = this.users.concat(response.results);
        }
        if (response.inlineCount > this.users.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getUsers(offset);
        }
        response.results = this.users;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getColumns(): Column[] {
    return [
      { field: 'id', sortField: 'id', title: this.translate.instant('ID'), show: (this.navigation.getParamValue('posts.id.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'postType', title: this.translate.instant('POSTTYPE'), type: 'linkValue', filterData: this.postTypes, show: (this.navigation.getParamValue('posts.postTypeId.showFiled', true) && true), displayInList: true, filterField: 'postType.id', displayField: 'name', sortField: 'postType.name', url: 'post-types' },
      { field: 'category', title: this.translate.instant('CATEGORY'), type: 'linkValue', filterData: this.categories, show: (this.navigation.getParamValue('posts.categoryId.showFiled', true) && true), displayInList: true, filterField: 'category.id', displayField: 'name', sortField: 'category.name', url: 'categories' },
      { field: 'subCategory', title: this.translate.instant('SUBCATEGORY'), type: 'linkValue', filterData: this.subCategories, show: (this.navigation.getParamValue('posts.subCategoryId.showFiled', true) && true), displayInList: true, filterField: 'subCategory.id', displayField: 'name', sortField: 'subCategory.name', url: 'sub-categories' },
      { field: 'title', sortField: 'title', title: this.translate.instant('TITLE'), show: (this.navigation.getParamValue('posts.title.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'titleAr', sortField: 'titleAr', title: this.translate.instant('TITLEAR'), show: (this.navigation.getParamValue('posts.titleAr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'titleFr', sortField: 'titleFr', title: this.translate.instant('TITLEFR'), show: (this.navigation.getParamValue('posts.titleFr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'slug', sortField: 'slug', title: this.translate.instant('SLUG'), show: (this.navigation.getParamValue('posts.slug.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'slugAr', sortField: 'slugAr', title: this.translate.instant('SLUGAR'), show: (this.navigation.getParamValue('posts.slugAr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'slugFr', sortField: 'slugFr', title: this.translate.instant('SLUGFR'), show: (this.navigation.getParamValue('posts.slugFr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'picture', sortField: 'picture', title: this.translate.instant('PICTURE'), show: (this.navigation.getParamValue('posts.picture.showFiled', false) && true), displayInList: true, type: 'imgValue'},
      { field: 'content', sortField: 'content', title: this.translate.instant('CONTENT'), show: (this.navigation.getParamValue('posts.content.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'contentAr', sortField: 'contentAr', title: this.translate.instant('CONTENTAR'), show: (this.navigation.getParamValue('posts.contentAr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'contentFr', sortField: 'contentFr', title: this.translate.instant('CONTENTFR'), show: (this.navigation.getParamValue('posts.contentFr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'isHeadline', sortField: 'isHeadline', title: this.translate.instant('ISHEADLINE'), show: (this.navigation.getParamValue('posts.isHeadline.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'autoPublishing', sortField: 'autoPublishing', title: this.translate.instant('AUTOPUBLISHING'), show: (this.navigation.getParamValue('posts.autoPublishing.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'startPublishing', sortField: 'startPublishing', title: this.translate.instant('STARTPUBLISHING'), show: (this.navigation.getParamValue('posts.startPublishing.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'endPublishing', sortField: 'endPublishing', title: this.translate.instant('ENDPUBLISHING'), show: (this.navigation.getParamValue('posts.endPublishing.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'publishDate', sortField: 'publishDate', title: this.translate.instant('PUBLISHDATE'), show: (this.navigation.getParamValue('posts.publishDate.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'metaTitle', sortField: 'metaTitle', title: this.translate.instant('METATITLE'), show: (this.navigation.getParamValue('posts.metaTitle.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1), type: 'textValue'},
      { field: 'metaDescription', sortField: 'metaDescription', title: this.translate.instant('METADESCRIPTION'), show: (this.navigation.getParamValue('posts.metaDescription.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1), type: 'textValue'},
      { field: 'metaKeywords', sortField: 'metaKeywords', title: this.translate.instant('METAKEYWORDS'), show: (this.navigation.getParamValue('posts.metaKeywords.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1), type: 'textValue'},
      { field: 'status', sortField: 'status', title: this.translate.instant('STATUS'), show: (this.navigation.getParamValue('posts.status.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_PUBLISHER') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_PUBLISHER') > -1), type: 'enumValue', filterData: this.statusesOptions},
      { field: 'totalPrints', sortField: 'totalPrints', title: this.translate.instant('TOTALPRINTS'), show: (this.navigation.getParamValue('posts.totalPrints.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1), type: 'numericValue'},
      { field: 'totalHits', sortField: 'totalHits', title: this.translate.instant('TOTALHITS'), show: (this.navigation.getParamValue('posts.totalHits.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1), type: 'numericValue'},
      { field: 'totalComments', sortField: 'totalComments', title: this.translate.instant('TOTALCOMMENTS'), show: (this.navigation.getParamValue('posts.totalComments.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1), type: 'numericValue'},
      { field: 'totalRatings', sortField: 'totalRatings', title: this.translate.instant('TOTALRATINGS'), show: (this.navigation.getParamValue('posts.totalRatings.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1), type: 'numericValue'},
      { field: 'averageRatings', sortField: 'averageRatings', title: this.translate.instant('AVERAGERATINGS'), show: (this.navigation.getParamValue('posts.averageRatings.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1), type: 'numericValue'},
      { field: 'totalLikes', sortField: 'totalLikes', title: this.translate.instant('TOTALLIKES'), show: (this.navigation.getParamValue('posts.totalLikes.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1), type: 'numericValue'},
      { field: 'totalDislikes', sortField: 'totalDislikes', title: this.translate.instant('TOTALDISLIKES'), show: (this.navigation.getParamValue('posts.totalDislikes.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1), type: 'numericValue'},
      { field: 'totalBookmarks', sortField: 'totalBookmarks', title: this.translate.instant('TOTALBOOKMARKS'), show: (this.navigation.getParamValue('posts.totalBookmarks.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') > -1), type: 'numericValue'},
      { field: 'isTop', sortField: 'isTop', title: this.translate.instant('ISTOP'), show: (this.navigation.getParamValue('posts.isTop.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'isNew', sortField: 'isNew', title: this.translate.instant('ISNEW'), show: (this.navigation.getParamValue('posts.isNew.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'ordering', sortField: 'ordering', title: this.translate.instant('ORDERING'), show: (this.navigation.getParamValue('posts.ordering.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'createdAt', sortField: 'createdAt', title: this.translate.instant('CREATEDAT'), show: (this.navigation.getParamValue('posts.createdAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'creatorUser', title: this.translate.instant('CREATORUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('posts.creatorUser.showFiled', false) && true), displayInList: true, filterField: 'creatorUser.id', displayField: 'username', sortField: 'creatorUser.username', url: 'users' },
      { field: 'modifiedAt', sortField: 'modifiedAt', title: this.translate.instant('MODIFIEDAT'), show: (this.navigation.getParamValue('posts.modifiedAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'modifierUser', title: this.translate.instant('MODIFIERUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('posts.modifierUser.showFiled', false) && true), displayInList: true, filterField: 'modifierUser.id', displayField: 'username', sortField: 'modifierUser.username', url: 'users' },
      { field: 'actions', title: this.translate.instant('ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }

  format(fieldName: string, value: any) {
    if (fieldName.toLowerCase().indexOf('time') > -1) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    } else if (fieldName.toLowerCase().indexOf('date') > -1) {
      return moment(value).format('YYYY-MM-DD');
    }
    return value;
  }

}
