

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogFilerComponent } from '../../MumEat/components/filer/filer.component';

// import Quill from 'quill';
// import { ImageResize } from 'quill-image-resize';
// import htmlEditButton from 'quill-html-edit-button';
// Quill.register('modules/imageResize', ImageResize);
// Quill.register('modules/htmlEditButton', htmlEditButton);



@Injectable({
  providedIn: 'root'
})
export class EditorService {

  public config: any = {
    toolbar: {
      items: [
        'undo',
        'redo',
        '|',
        'heading',
        'fontFamily',
        'fontSize',
        '|',
        'bold',
        'italic',
        'underline',
        'fontColor',
        'fontBackgroundColor',
        'highlight',
        '|',
        'link',
        'CKFinder',
        'imageUpload',
        'mediaEmbed',
        '|',
        'alignment',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        'insertTable',
        'blockQuote',
        'specialCharacters',
      ],
      shouldNotGroupWhenFull: true
    }
  };

  private filerDialog: DynamicDialogRef = new DynamicDialogRef();
  /*
  public modules: any = {
    toolbar: [
      [{ 'font': ['sofia', 'slabo', 'roboto', 'inconsolata', 'ubuntu'] }, { 'size': ['small', 'normal', 'large', 'huge'] }],  // custom dropdown
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      [{ 'header': [1, 2, 3, 4, 5, 6, 7] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'align': [] }],
      [ 'link', 'image', 'video', 'audio', 'formula' ],
      ['clean']                                         // remove formatting button
    ],
    htmlEditButton: {},
    ImageResize: {}
  };
  */

  constructor(
    private translate: TranslateService,
    private dialogService: DialogService,
  ) {

  }

  selectMediaFromFiler(type: string, editor: any): void {
    const header = this.translate.instant('FILEMANAGER');
    this.filerDialog = this.dialogService.open(DialogFilerComponent, {
      header: '', // header,
      width: '90%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1500,
      data: {
        path: 'sports',
        filter: type,
        url: null,
      },
    });
    this.filerDialog.onClose.subscribe((response: any) => {
      if (response && response.selected) {
        const selection = editor.getSelection(true);
        console.log('selection', selection);
        let index = 0;
        if (selection) {
          index = selection.index;
        }
        if (type === 'image') {
          editor.insertEmbed(index, 'image', response.selected.url, 'silent');
        } else if (type === 'video') {
          editor.insertEmbed(index, 'video', response.selected.url, 'silent');
        } else if (type === 'audio') {
          editor.insertEmbed(index, 'audio', response.selected.url, 'silent');
        }
      }
    });
  }

}
