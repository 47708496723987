import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { VisitsDataService, VisitsColumnsService } from '../../../services';
import { Visit, VisitsResult } from '../../../interfaces';

@Component({
  selector: 'app-visit-form',
  templateUrl: './visit-form.component.html',
  styleUrls: ['./visit-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class VisitFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public visit: Visit = {};
  public visitForm: FormGroup = new FormGroup({});
  public visits: Visit[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;


  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private visitsDataService: VisitsDataService,
    public visitsColumnsService: VisitsColumnsService,
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'VisitFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Visit - Edit');
        this.getVisit(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.visit !== 'undefined') {
      this.visit = this.dynamicDialogConfig.data.visit;
    }
    if (typeof this.visit.id === 'undefined' || this.visit.id === null) {
      this.visit.id = null;
      this.visit.isValid = false;
    }
    this.getVisits();
    this.prepareFrom();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'VisitFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'VisitFormComponent');
  }

  getVisit(id: number): void {
    this.isLoading = true;
    this.visitsDataService.getVisit(id).subscribe((response: Visit|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.visit = response;
        this.prepareFrom();
      }
    });
  }

  getVisits(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.visits !== 'undefined' && this.dynamicDialogConfig.data.visits.length > 0) {
      this.visits = this.dynamicDialogConfig.data.visits;
      return;
    }
    this.visitsDataService.getVisits(this.parameters).subscribe((response: VisitsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.visits = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.visitForm = new FormGroup({
      id: new FormControl(this.visit.id, []),
      ip: new FormControl(this.visit.ip, [Validators.required, ]),
      isValid: new FormControl(this.visit.isValid, [Validators.required, ]),
      userAgent: new FormControl(this.visit.userAgent, [Validators.required, ]),
    });
  }

  list() {
    this.router.navigate(['/visits']);
  }

  showErrors() {
      this.showErrors();
      const invalidFields: string[] = [];
      Object.keys(this.visitForm.controls).forEach((field: any) => {
        const control = this.visitForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.visitForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.visit = this.visitForm.value;
    this.disableSubmit = true;
    console.log('visit', this.visit);
    if (typeof this.visit.id !== 'undefined' && this.visit.id !== null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.visitsDataService.updateVisit(this.visit).subscribe((data: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('VISITUPDATED')});
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('VISITNOTUPDATED') + ' ' + error.message});
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.visitsDataService.addVisit(this.visit).subscribe((data: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('VISITCREATED')});
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('VISITNOTCREATED') + ' ' + error.message});
        console.warn(error);
    });
  }

}

@Component({
  selector: 'app-visit-form',
  templateUrl: './visit-form.component.html',
  styleUrls: ['./visit-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogVisitFormComponent extends VisitFormComponent {
}

