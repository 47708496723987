<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="videoType.id === null">{{ 'NEWVIDEOTYPE' | translate }}</span>
            <span *ngIf="videoType.id !== null">{{ 'EDITVIDEOTYPE' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="videoTypeForm" id="videoTypeForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="videoTypeForm" >
            <input type="hidden" name="id" id="videoTypeId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-tag"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoTypeName" formControlName="name" type="text" pInputText [required]="true"> 
                      <label for="videoTypeName" class="req">{{ 'NAME' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="videoTypeForm.controls['name'].invalid && (videoTypeForm.controls['name'].dirty || videoTypeForm.controls['name'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoTypeForm.controls['name'].errors !== null">
                  <div *ngIf="videoTypeForm.controls['name'].errors.required">
                   {{ 'NAMEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoTypeNameAr" formControlName="nameAr" type="text" pInputText [required]="false"> 
                      <label for="videoTypeNameAr" class="">{{ 'NAMEAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoTypeNameFr" formControlName="nameFr" type="text" pInputText [required]="false"> 
                      <label for="videoTypeNameFr" class="">{{ 'NAMEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoTypeSlug" formControlName="slug" type="text" pInputText [required]="true"> 
                      <label for="videoTypeSlug" class="req">{{ 'SLUG' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="videoTypeForm.controls['slug'].invalid && (videoTypeForm.controls['slug'].dirty || videoTypeForm.controls['slug'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoTypeForm.controls['slug'].errors !== null">
                  <div *ngIf="videoTypeForm.controls['slug'].errors.required">
                   {{ 'SLUGREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoTypeSlugAr" formControlName="slugAr" type="text" pInputText [required]="false"> 
                      <label for="videoTypeSlugAr" class="">{{ 'SLUGAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoTypeSlugFr" formControlName="slugFr" type="text" pInputText [required]="false"> 
                      <label for="videoTypeSlugFr" class="">{{ 'SLUGFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoTypeExtensions" formControlName="extensions" type="text" pInputText [required]="true"> 
                      <label for="videoTypeExtensions" class="req">{{ 'EXTENSIONS' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="videoTypeForm.controls['extensions'].invalid && (videoTypeForm.controls['extensions'].dirty || videoTypeForm.controls['extensions'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoTypeForm.controls['extensions'].errors !== null">
                  <div *ngIf="videoTypeForm.controls['extensions'].errors.required">
                   {{ 'EXTENSIONSREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-cog"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoTypeMimeTypes" formControlName="mimeTypes" type="text" pInputText [required]="true"> 
                      <label for="videoTypeMimeTypes" class="req">{{ 'MIMETYPES' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="videoTypeForm.controls['mimeTypes'].invalid && (videoTypeForm.controls['mimeTypes'].dirty || videoTypeForm.controls['mimeTypes'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoTypeForm.controls['mimeTypes'].errors !== null">
                  <div *ngIf="videoTypeForm.controls['mimeTypes'].errors.required">
                   {{ 'MIMETYPESREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="videoTypeIsPublished" formControlName="isPublished" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="videoTypeIsPublished" class="p-d-inline-block p-ml-2 req">{{ 'ISPUBLISHED' | translate }}</label>
                </div>
                <div *ngIf="videoTypeForm.controls['isPublished'].invalid && (videoTypeForm.controls['isPublished'].dirty || videoTypeForm.controls['isPublished'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoTypeForm.controls['isPublished'].errors !== null">
                  <div *ngIf="videoTypeForm.controls['isPublished'].errors.required">
                   {{ 'ISPUBLISHEDREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKVIDEOTYPES' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="videoType.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!videoTypeForm.valid || disableSubmit"> <!--  [disabled]="videoTypeForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!videoTypeForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

