import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { CommonService } from '../../../services/common/common.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { UtensilsDataService, UtensilsColumnsService } from '../../services';
import { DialogUtensilFormComponent } from './utensil-form/utensil-form.component';
import { DialogUtensilDetailsComponent } from './utensil-details/utensil-details.component';
import { Utensil, UtensilsResult } from '../../interfaces';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-utensils',
  templateUrl: './utensils.component.html',
  styleUrls: ['./utensils.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class UtensilsComponent implements OnInit, OnDestroy {

  private utensilAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private utensilEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private utensilDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  public utensilToDelete: Utensil = {};
  public utensils: Utensil[] = [];
  public utensil: Utensil = {};
  public selectedUtensils: Utensil[] = [];
  public submitted: boolean = false;
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '100'};
  public chunkSize: number = 200;
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  private _selectedColumns: Column[] = [];
  @ViewChild('importer', { static: false })
  public importer?: FileUpload;
  public importChooseLabel: string = 'Import';
  public importLabel: string = 'Import CSV';

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private common: CommonService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private utensilsDataService: UtensilsDataService,
    private utensilsColumnsService: UtensilsColumnsService,
  ) {
    this.importChooseLabel = this.translate.instant('IMPORT');
    this.importLabel = this.translate.instant('IMPORTCSV');
  }

  ngOnInit(): void {
    console.log('UtensilsComponent', 'ngOnInit');
    this.isInitialized = true;
    this.titleService.setTitle('Utensils - Management');
    this.primengConfig.ripple = true;
    this.getUtensils();
  }

  ngOnDestroy(): void {
    console.log('UtensilsComponent', 'ngOnDestroy');
    this.isInitialized = false;
  }

  getUtensils(): void {
    if (!this.isInitialized) {
      return;
    }
    if (parseInt(this.parameters.offset + '', 10) === 0) {
      this.isLoading = true;
    }
    this.utensilsDataService.getUtensils(this.parameters).subscribe((response: UtensilsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        if (parseInt(this.parameters.offset + '', 10) === 0) {
          this.utensils = response.results;
        } else {
          this.utensils = this.utensils.concat(response.results);
        }
        if (response.inlineCount > this.utensils.length) {
          this.parameters.offset = (parseInt(this.parameters.offset + '', 10) + parseInt(this.parameters.limit + '', 10)) + '';
          this.getUtensils();
        } else {
          this.parameters.offset = '0';
        }
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('UTENSILSNOTLOADED')});
    });
  }

  importUtensils(event: any): void {
    if (typeof event.currentFiles === 'undefined') {
      return;
    }
    if (event.currentFiles.length === 0) {
      return;
    }
    const inputFile = event.currentFiles[0];
    const reader = new FileReader();
    let rows: any[] = [];
    reader.onload = (e: any) => {
      if (typeof this.importer !== 'undefined') {
        this.importer.clear();
      }
      const text = <string>e.target.result;
      rows = ToolsService.csvToArray(this.columns, text, ',');
      if (rows.length > 0) {
        let proceed = 0;
        const j = rows.length;
        for (let i = 0; i < j; i += this.chunkSize) {
          this.isLoading = true;
          const chunk = rows.slice(i, i + this.chunkSize);
          if (typeof chunk[0].id === 'undefined') {
            this.utensilsDataService.addUtensils(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('UTENSILSCREATED')});
                if (proceed === j) {
                  this.getUtensils();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('UTENSILSNOTCREATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('UTENSILSNOTCREATED') + ' ' + msg});
            });
          } else {
            this.utensilsDataService.updateUtensils(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('UTENSILSUPDATED')});
                if (proceed === j) {
                  this.getUtensils();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('UTENSILSNOTUPDATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('UTENSILSNOTCREATED') + ' ' + msg});
            });
          }
        }
      }
    };
    reader.readAsText(inputFile);
  }

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.utensilsColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('utensils.' + this.columns[i].field + '.showFiled', show);
    }
  }

  deleteSelectedUtensils() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Utensils?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.utensilsDataService.deleteUtensils(this.selectedUtensils).subscribe((response) => {
          if (response === null) {
            this.messageService.clear();
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Utensils Deleted', life: 3000});
            this.getUtensils();
          } else {
            this.messageService.clear();
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Utensils not deleted'});
          }
        }, (error: any) => {
          this.messageService.clear();
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Utensils not deleted'});
        });
        this.selectedUtensils = [];
      }
    });
  }

  exportPdf() {
    ToolsService.exportPdf(this.utensils, this.columns, 'Utensils');
  }

  exportExcel() {
    ToolsService.exportExcel(this.utensils, this.columns, 'Utensils');
  }

  exportCsv() {
    ToolsService.exportCsv(this.utensils, this.columns, 'Utensils');
  }

  refresh() {
    this.utensilsDataService.clearCache();
    this.getUtensils();
  }

  utensilAdd(): void {
    if (!this.navigation.getParamValue('utensils.addInDialog', true)) {
      this.router.navigate(['/utensils/add']);
      return;
    }
    this.utensilAddDialog = this.dialogService.open(DialogUtensilFormComponent, {
      header: '', // this.translate.instant('NEWUTENSIL'),
      width: '80%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'add',
        utensil: {id: null},
        users: this.utensilsColumnsService.users,
      },
    });

    this.utensilAddDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'info', summary: 'Success', detail: 'Utensil added'});
        this.getUtensils();
      }
    });
  }

  utensilEdit(utensil: Utensil): void {
    if (!this.navigation.getParamValue('utensils.editInDialog', true)) {
      this.router.navigate(['/utensils/edit/' + utensil.id]);
      return;
    }
    this.utensilEditDialog = this.dialogService.open(DialogUtensilFormComponent, {
      header: '', // this.translate.instant('EDITUTENSIL') + ' ' + utensil.name,
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'edit',
        utensil: utensil,
        users: this.utensilsColumnsService.users,
      },
    });

    this.utensilEditDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Utensil updated'});
        this.getUtensils();
      }
    });
  }

  utensilDetails(utensil: Utensil): void {
    if (!this.navigation.getParamValue('utensils.detailsInDialog', true)) {
      this.router.navigate(['/utensils/details/' + utensil.id]);
      return;
    }
    this.utensilDetailsDialog = this.dialogService.open(DialogUtensilDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        utensil: utensil,
      }
    });
  }

  utensilDelete(utensil: Utensil): void {
    this.utensilToDelete = utensil;
    this.messageService.clear();
    this.messageService.add({
      key: 'utensilDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want delete ' + utensil.name + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.utensilsDataService.deleteUtensil(this.utensilToDelete).subscribe((res: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Utensil deleted'});
      this.getUtensils();
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Utensil not deleted'});
    });
  }

  onDeleteReject(): void {
    this.utensilToDelete = {};
    this.messageService.clear('utensilDelete');
  }

}
