<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="subCategory.id === null">{{ 'NEWSUBCATEGORY' | translate }}</span>
            <span *ngIf="subCategory.id !== null">{{ 'EDITSUBCATEGORY' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="subCategoryForm" id="subCategoryForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="subCategoryForm" >
            <input type="hidden" name="id" id="subCategoryId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-tag"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="subCategoryName" formControlName="name" type="text" pInputText [required]="true"> 
                      <label for="subCategoryName" class="req">{{ 'NAME' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="subCategoryForm.controls['name'].invalid && (subCategoryForm.controls['name'].dirty || subCategoryForm.controls['name'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="subCategoryForm.controls['name'].errors !== null">
                  <div *ngIf="subCategoryForm.controls['name'].errors.required">
                   {{ 'NAMEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="subCategoryNameAr" formControlName="nameAr" type="text" pInputText [required]="false"> 
                      <label for="subCategoryNameAr" class="">{{ 'NAMEAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="subCategoryNameFr" formControlName="nameFr" type="text" pInputText [required]="false"> 
                      <label for="subCategoryNameFr" class="">{{ 'NAMEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="subCategorySlug" formControlName="slug" type="text" pInputText [required]="true"> 
                      <label for="subCategorySlug" class="req">{{ 'SLUG' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="subCategoryForm.controls['slug'].invalid && (subCategoryForm.controls['slug'].dirty || subCategoryForm.controls['slug'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="subCategoryForm.controls['slug'].errors !== null">
                  <div *ngIf="subCategoryForm.controls['slug'].errors.required">
                   {{ 'SLUGREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="subCategorySlugAr" formControlName="slugAr" type="text" pInputText [required]="false"> 
                      <label for="subCategorySlugAr" class="">{{ 'SLUGAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="subCategorySlugFr" formControlName="slugFr" type="text" pInputText [required]="false"> 
                      <label for="subCategorySlugFr" class="">{{ 'SLUGFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-image"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="subCategoryPicture" formControlName="picture" type="text" pInputText [required]="false"> 
                      <label for="subCategoryPicture" class="">{{ 'PICTURE' | translate }}</label>
                    </span>
                    <button type="button" pButton pRipple icon="pi pi-folder" styleClass="p-button-info" (click)="openFilerDialog('picture')"></button> 
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-palette"></i>
                    </span>
                    <span class="p-inputgroup-addon" style="max-width: 1em;">
                      <p-colorPicker (onChange)="setColor($event)" formControlName="color"></p-colorPicker>
                    </span>
                    <span class="p-float-label">
                      <input type="text" pInputText id="subCategoryColor" formControlName="color" [required]="false">
                      <label for="subCategoryColor" class="p-d-inline-block p-ml-2 ">{{ 'COLOR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-info-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="subCategoryDescription" formControlName="description" type="text" pInputText [required]="true"> 
                      <label for="subCategoryDescription" class="req">{{ 'DESCRIPTION' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="subCategoryForm.controls['description'].invalid && (subCategoryForm.controls['description'].dirty || subCategoryForm.controls['description'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="subCategoryForm.controls['description'].errors !== null">
                  <div *ngIf="subCategoryForm.controls['description'].errors.required">
                   {{ 'DESCRIPTIONREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-align-justify"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="subCategoryDescriptionAr" formControlName="descriptionAr" type="text" pInputText [required]="false"> 
                      <label for="subCategoryDescriptionAr" class="">{{ 'DESCRIPTIONAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-align-justify"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="subCategoryDescriptionFr" formControlName="descriptionFr" type="text" pInputText [required]="false"> 
                      <label for="subCategoryDescriptionFr" class="">{{ 'DESCRIPTIONFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-folder"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="subCategoryCategoryId" [autoDisplayFirst]="false" formControlName="category" [options]="subCategoriesColumnsService.categories" optionValue="id" optionLabel="name" [required]="false" [filter]="true"></p-dropdown>
                      <label for="subCategoryCategoryId" class="">{{ 'CATEGORY' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-list-ol"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="subCategoryOrdering" formControlName="ordering" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="subCategoryOrdering" class="">{{ 'ORDERING' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_PUBLISHER') === -1" >
                  <label for="subCategoryStatus" class="req">{{ 'STATUS' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of subCategoriesColumnsService.statusesOptions; let i = index">
                      <p-radioButton inputId="subCategoryStatus_{{ i }}" formControlName="status" name="status" [value]="option.value"></p-radioButton>
                      <label for="subCategoryStatus_{{ i }}">{{ option.label | translate }}</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="subCategoryForm.controls['status'].invalid && (subCategoryForm.controls['status'].dirty || subCategoryForm.controls['status'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="subCategoryForm.controls['status'].errors !== null">
                  <div *ngIf="subCategoryForm.controls['status'].errors.required">
                   {{ 'STATUSREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKSUBCATEGORIES' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="subCategory.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!subCategoryForm.valid || disableSubmit"> <!--  [disabled]="subCategoryForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!subCategoryForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

