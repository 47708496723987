import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { Utensil, UtensilsResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class UtensilsDataService {

  private uri: string = `${environment.apiUrl}utensils`;
  private cacheKey: string = 'utensils.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getUtensil(id: number): Observable<Utensil|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getUtensils(parameters: {[param: string]: string | string[]}): Observable<UtensilsResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addUtensil(data: Utensil): Observable<Utensil|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addUtensils(data: Utensil[]): Observable<Utensil[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updateUtensil(data: Utensil): Observable<Utensil|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updateUtensils(data: Utensil[]): Observable<Utensil[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchUtensil(data: Utensil): Observable<Utensil|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchUtensils(data: Utensil[]): Observable<Utensil[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deleteUtensil(data: Utensil): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deleteUtensils(data: Utensil[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((utensil) => {
      ids.push(utensil.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
