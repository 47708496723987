import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { CommonService } from '../../../services/common/common.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { PackageTypesDataService, PackageTypesColumnsService } from '../../services';
import { DialogPackageTypeFormComponent } from './package-type-form/package-type-form.component';
import { DialogPackageTypeDetailsComponent } from './package-type-details/package-type-details.component';
import { PackageType, PackageTypesResult } from '../../interfaces';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-package-types',
  templateUrl: './package-types.component.html',
  styleUrls: ['./package-types.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class PackageTypesComponent implements OnInit, OnDestroy {

  private packageTypeAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private packageTypeEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private packageTypeDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  public packageTypeToDelete: PackageType = {};
  public packageTypes: PackageType[] = [];
  public packageType: PackageType = {};
  public selectedPackageTypes: PackageType[] = [];
  public submitted: boolean = false;
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '100'};
  public chunkSize: number = 200;
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  private _selectedColumns: Column[] = [];
  @ViewChild('importer', { static: false })
  public importer?: FileUpload;
  public importChooseLabel: string = 'Import';
  public importLabel: string = 'Import CSV';

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private common: CommonService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private packageTypesDataService: PackageTypesDataService,
    private packageTypesColumnsService: PackageTypesColumnsService,
  ) {
    this.importChooseLabel = this.translate.instant('IMPORT');
    this.importLabel = this.translate.instant('IMPORTCSV');
  }

  ngOnInit(): void {
    console.log('PackageTypesComponent', 'ngOnInit');
    this.isInitialized = true;
    this.titleService.setTitle('Package Types - Management');
    this.primengConfig.ripple = true;
    this.getPackageTypes();
  }

  ngOnDestroy(): void {
    console.log('PackageTypesComponent', 'ngOnDestroy');
    this.isInitialized = false;
  }

  getPackageTypes(): void {
    if (!this.isInitialized) {
      return;
    }
    if (parseInt(this.parameters.offset + '', 10) === 0) {
      this.isLoading = true;
    }
    this.packageTypesDataService.getPackageTypes(this.parameters).subscribe((response: PackageTypesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        if (parseInt(this.parameters.offset + '', 10) === 0) {
          this.packageTypes = response.results;
        } else {
          this.packageTypes = this.packageTypes.concat(response.results);
        }
        if (response.inlineCount > this.packageTypes.length) {
          this.parameters.offset = (parseInt(this.parameters.offset + '', 10) + parseInt(this.parameters.limit + '', 10)) + '';
          this.getPackageTypes();
        } else {
          this.parameters.offset = '0';
        }
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('PACKAGETYPESNOTLOADED')});
    });
  }

  importPackageTypes(event: any): void {
    if (typeof event.currentFiles === 'undefined') {
      return;
    }
    if (event.currentFiles.length === 0) {
      return;
    }
    const inputFile = event.currentFiles[0];
    const reader = new FileReader();
    let rows: any[] = [];
    reader.onload = (e: any) => {
      if (typeof this.importer !== 'undefined') {
        this.importer.clear();
      }
      const text = <string>e.target.result;
      rows = ToolsService.csvToArray(this.columns, text, ',');
      if (rows.length > 0) {
        let proceed = 0;
        const j = rows.length;
        for (let i = 0; i < j; i += this.chunkSize) {
          this.isLoading = true;
          const chunk = rows.slice(i, i + this.chunkSize);
          if (typeof chunk[0].id === 'undefined') {
            this.packageTypesDataService.addPackageTypes(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('PACKAGETYPESCREATED')});
                if (proceed === j) {
                  this.getPackageTypes();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('PACKAGETYPESNOTCREATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('PACKAGETYPESNOTCREATED') + ' ' + msg});
            });
          } else {
            this.packageTypesDataService.updatePackageTypes(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('PACKAGETYPESUPDATED')});
                if (proceed === j) {
                  this.getPackageTypes();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('PACKAGETYPESNOTUPDATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('PACKAGETYPESNOTCREATED') + ' ' + msg});
            });
          }
        }
      }
    };
    reader.readAsText(inputFile);
  }

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.packageTypesColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('packageTypes.' + this.columns[i].field + '.showFiled', show);
    }
  }

  deleteSelectedPackageTypes() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Package Types?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.packageTypesDataService.deletePackageTypes(this.selectedPackageTypes).subscribe((response) => {
          if (response === null) {
            this.messageService.clear();
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Package Types Deleted', life: 3000});
            this.getPackageTypes();
          } else {
            this.messageService.clear();
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Package Types not deleted'});
          }
        }, (error: any) => {
          this.messageService.clear();
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Package Types not deleted'});
        });
        this.selectedPackageTypes = [];
      }
    });
  }

  exportPdf() {
    ToolsService.exportPdf(this.packageTypes, this.columns, 'PackageTypes');
  }

  exportExcel() {
    ToolsService.exportExcel(this.packageTypes, this.columns, 'PackageTypes');
  }

  exportCsv() {
    ToolsService.exportCsv(this.packageTypes, this.columns, 'PackageTypes');
  }

  refresh() {
    this.packageTypesDataService.clearCache();
    this.getPackageTypes();
  }

  packageTypeAdd(): void {
    if (!this.navigation.getParamValue('packageTypes.addInDialog', true)) {
      this.router.navigate(['/package-types/add']);
      return;
    }
    this.packageTypeAddDialog = this.dialogService.open(DialogPackageTypeFormComponent, {
      header: '', // this.translate.instant('NEWPACKAGETYPE'),
      width: '80%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'add',
        packageType: {id: null},
        users: this.packageTypesColumnsService.users,
      },
    });

    this.packageTypeAddDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'info', summary: 'Success', detail: 'PackageType added'});
        this.getPackageTypes();
      }
    });
  }

  packageTypeEdit(packageType: PackageType): void {
    if (!this.navigation.getParamValue('packageTypes.editInDialog', true)) {
      this.router.navigate(['/package-types/edit/' + packageType.id]);
      return;
    }
    this.packageTypeEditDialog = this.dialogService.open(DialogPackageTypeFormComponent, {
      header: '', // this.translate.instant('EDITPACKAGETYPE') + ' ' + packageType.name,
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'edit',
        packageType: packageType,
        users: this.packageTypesColumnsService.users,
      },
    });

    this.packageTypeEditDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'PackageType updated'});
        this.getPackageTypes();
      }
    });
  }

  packageTypeDetails(packageType: PackageType): void {
    if (!this.navigation.getParamValue('packageTypes.detailsInDialog', true)) {
      this.router.navigate(['/package-types/details/' + packageType.id]);
      return;
    }
    this.packageTypeDetailsDialog = this.dialogService.open(DialogPackageTypeDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        packageType: packageType,
      }
    });
  }

  packageTypeDelete(packageType: PackageType): void {
    this.packageTypeToDelete = packageType;
    this.messageService.clear();
    this.messageService.add({
      key: 'packageTypeDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want delete ' + packageType.name + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.packageTypesDataService.deletePackageType(this.packageTypeToDelete).subscribe((res: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Package Type deleted'});
      this.getPackageTypes();
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Package Type not deleted'});
    });
  }

  onDeleteReject(): void {
    this.packageTypeToDelete = {};
    this.messageService.clear('packageTypeDelete');
  }

}
