import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { LanguagesDataService, LanguagesColumnsService } from '../../../services';
import { Language, LanguagesResult } from '../../../interfaces';

@Component({
  selector: 'app-language-form',
  templateUrl: './language-form.component.html',
  styleUrls: ['./language-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class LanguageFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public language: Language = {};
  public languageForm: FormGroup = new FormGroup({});
  public languages: Language[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;


  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private languagesDataService: LanguagesDataService,
    public languagesColumnsService: LanguagesColumnsService,
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'LanguageFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Language - Edit');
        this.getLanguage(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.language !== 'undefined') {
      this.language = this.dynamicDialogConfig.data.language;
    }
    if (typeof this.language.id === 'undefined' || this.language.id === null) {
      this.language.id = null;
      this.language.preferred = false;
    }
    this.getLanguages();
    this.prepareFrom();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'LanguageFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'LanguageFormComponent');
  }

  getLanguage(id: number): void {
    this.isLoading = true;
    this.languagesDataService.getLanguage(id).subscribe((response: Language|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.language = response;
        this.prepareFrom();
      }
    });
  }

  getLanguages(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.languages !== 'undefined' && this.dynamicDialogConfig.data.languages.length > 0) {
      this.languages = this.dynamicDialogConfig.data.languages;
      return;
    }
    this.languagesDataService.getLanguages(this.parameters).subscribe((response: LanguagesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.languages = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.languageForm = new FormGroup({
      id: new FormControl(this.language.id, []),
      name: new FormControl(this.language.name, [Validators.required, ]),
      locale: new FormControl(this.language.locale, [Validators.required, ]),
      code: new FormControl(this.language.code, [Validators.required, ]),
      preferred: new FormControl(this.language.preferred, [Validators.required, ]),
    });
  }

  list() {
    this.router.navigate(['/languages']);
  }

  showErrors() {
      this.showErrors();
      const invalidFields: string[] = [];
      Object.keys(this.languageForm.controls).forEach((field: any) => {
        const control = this.languageForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.languageForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.language = this.languageForm.value;
    this.disableSubmit = true;
    console.log('language', this.language);
    if (typeof this.language.id !== 'undefined' && this.language.id !== null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.languagesDataService.updateLanguage(this.language).subscribe((data: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('LANGUAGEUPDATED')});
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('LANGUAGENOTUPDATED') + ' ' + error.message});
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.languagesDataService.addLanguage(this.language).subscribe((data: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('LANGUAGECREATED')});
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('LANGUAGENOTCREATED') + ' ' + error.message});
        console.warn(error);
    });
  }

}

@Component({
  selector: 'app-language-form',
  templateUrl: './language-form.component.html',
  styleUrls: ['./language-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogLanguageFormComponent extends LanguageFormComponent {
}

