<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="pushDevice.id === null">{{ 'NEWPUSHDEVICE' | translate }}</span>
            <span *ngIf="pushDevice.id !== null">{{ 'EDITPUSHDEVICE' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="pushDeviceForm" id="pushDeviceForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="pushDeviceForm" >
            <input type="hidden" name="id" id="pushDeviceId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushDeviceAppName" formControlName="appName" type="text" pInputText [required]="false"> 
                      <label for="pushDeviceAppName" class="">{{ 'APPNAME' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushDeviceAppVersion" formControlName="appVersion" type="text" pInputText [required]="false"> 
                      <label for="pushDeviceAppVersion" class="">{{ 'APPVERSION' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushDeviceDeviceUid" formControlName="deviceUid" type="text" pInputText [required]="false"> 
                      <label for="pushDeviceDeviceUid" class="">{{ 'DEVICEUID' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushDeviceDeviceReg" formControlName="deviceReg" type="text" pInputText [required]="false"> 
                      <label for="pushDeviceDeviceReg" class="">{{ 'DEVICEREG' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushDeviceDeviceToken" formControlName="deviceToken" type="text" pInputText [required]="false"> 
                      <label for="pushDeviceDeviceToken" class="">{{ 'DEVICETOKEN' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushDeviceDeviceName" formControlName="deviceName" type="text" pInputText [required]="false"> 
                      <label for="pushDeviceDeviceName" class="">{{ 'DEVICENAME' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushDeviceDeviceEmail" formControlName="deviceEmail" type="text" pInputText [required]="false"> 
                      <label for="pushDeviceDeviceEmail" class="">{{ 'DEVICEEMAIL' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushDeviceDeviceModel" formControlName="deviceModel" type="text" pInputText [required]="false"> 
                      <label for="pushDeviceDeviceModel" class="">{{ 'DEVICEMODEL' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushDeviceDeviceVersion" formControlName="deviceVersion" type="text" pInputText [required]="false"> 
                      <label for="pushDeviceDeviceVersion" class="">{{ 'DEVICEVERSION' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="pushDeviceIsEnabledBadge" formControlName="isEnabledBadge" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="pushDeviceIsEnabledBadge" class="p-d-inline-block p-ml-2 ">{{ 'ISENABLEDBADGE' | translate }}</label>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="pushDeviceIsEnabledAlert" formControlName="isEnabledAlert" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="pushDeviceIsEnabledAlert" class="p-d-inline-block p-ml-2 ">{{ 'ISENABLEDALERT' | translate }}</label>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="pushDeviceIsEnabledSound" formControlName="isEnabledSound" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="pushDeviceIsEnabledSound" class="p-d-inline-block p-ml-2 ">{{ 'ISENABLEDSOUND' | translate }}</label>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <label for="pushDeviceDevelopment" class="req">{{ 'DEVELOPMENT' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of pushDevicesColumnsService.developmentsOptions; let i = index">
                      <p-radioButton inputId="pushDeviceDevelopment_{{ i }}" formControlName="development" name="development" [value]="option.value"></p-radioButton>
                      <label for="pushDeviceDevelopment_{{ i }}">{{ option.label | translate }}</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="pushDeviceForm.controls['development'].invalid && (pushDeviceForm.controls['development'].dirty || pushDeviceForm.controls['development'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="pushDeviceForm.controls['development'].errors !== null">
                  <div *ngIf="pushDeviceForm.controls['development'].errors.required">
                   {{ 'DEVELOPMENTREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <label for="pushDeviceStatus" class="req">{{ 'STATUS' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of pushDevicesColumnsService.statusesOptions; let i = index">
                      <p-radioButton inputId="pushDeviceStatus_{{ i }}" formControlName="status" name="status" [value]="option.value"></p-radioButton>
                      <label for="pushDeviceStatus_{{ i }}">{{ option.label | translate }}</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="pushDeviceForm.controls['status'].invalid && (pushDeviceForm.controls['status'].dirty || pushDeviceForm.controls['status'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="pushDeviceForm.controls['status'].errors !== null">
                  <div *ngIf="pushDeviceForm.controls['status'].errors.required">
                   {{ 'STATUSREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushDeviceIp" formControlName="ip" type="text" pInputText [required]="false"> 
                      <label for="pushDeviceIp" class="">{{ 'IP' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKPUSHDEVICES' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="pushDevice.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!pushDeviceForm.valid || disableSubmit"> <!--  [disabled]="pushDeviceForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!pushDeviceForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

