
<div class="animated fadeIn">
  <div class="d-flex align-items-center">
      <h1>{{ 'CATEGORYDETAILS' | translate }}</h1>
      <div [hidden]="!isLoading" class="spinner-border text-info  ml-auto" role="status">
        <span class="sr-only">{{ 'LOADING' | translate }}...</span>
      </div>
  </div>
  <p-toast></p-toast>

  <p-card *ngIf="category.id">
    <div class="p-grid">
      <div class="p-col-12" *ngFor="let col of columns">
        <div class="p-grid">
          <div class="p-col-4 text-info">
            {{ col.title | translate }}
          </div>
          <div class="p-col-8">
            <app-table-cell-value [value]="category[col.field]" [column]="col"></app-table-cell-value>
          </div>
        </div>
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col-4">
        <button class="btn btn-primary pull-left"  (click)="list()">
          <i class="fa fa-list-alt"></i>
          <span class="p-ml-1">{{ 'BACKCATEGORIES' | translate }}</span>
        </button>
      </div>
      <div class="p-col-4 text-center">
        <button (click)="add()" class="btn btn-primary">
          <i class="fas fa-plus"></i>
          <span class="p-ml-1"> {{ 'NEW' | translate }}</span>
        </button>
      </div>
      <div class="p-col-4">
        <button class="btn btn-primary pull-right" (click)="edit()">
          <i class="fa fa-pencil"></i>
          <span class="p-ml-1">{{ 'EDIT' | translate }}</span>
        </button>
      </div>
    </div>
  </p-card>

</div>

