<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="review.id === null">{{ 'NEWREVIEW' | translate }}</span>
            <span *ngIf="review.id !== null">{{ 'EDITREVIEW' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="reviewForm" id="reviewForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="reviewForm" >
            <input type="hidden" name="id" id="reviewId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-table "></i>
                    </span>
                    <span class="p-float-label">
                      <input id="reviewEntity" formControlName="entity" type="text" pInputText [required]="true"> 
                      <label for="reviewEntity" class="req">{{ 'ENTITY' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="reviewForm.controls['entity'].invalid && (reviewForm.controls['entity'].dirty || reviewForm.controls['entity'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="reviewForm.controls['entity'].errors !== null">
                  <div *ngIf="reviewForm.controls['entity'].errors.required">
                   {{ 'ENTITYREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-project-diagram "></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="reviewForeignKey" formControlName="foreignKey" [step]="1" [showButtons]="true" [required]="true"></p-inputNumber>
                      <label for="reviewForeignKey" class="req">{{ 'FOREIGNKEY' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="reviewForm.controls['foreignKey'].invalid && (reviewForm.controls['foreignKey'].dirty || reviewForm.controls['foreignKey'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="reviewForm.controls['foreignKey'].errors !== null">
                  <div *ngIf="reviewForm.controls['foreignKey'].errors.required">
                   {{ 'FOREIGNKEYREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-gem "></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="reviewValue" formControlName="value" [step]="1" [showButtons]="true" [required]="true"></p-inputNumber>
                      <label for="reviewValue" class="req">{{ 'VALUE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="reviewForm.controls['value'].invalid && (reviewForm.controls['value'].dirty || reviewForm.controls['value'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="reviewForm.controls['value'].errors !== null">
                  <div *ngIf="reviewForm.controls['value'].errors.required">
                   {{ 'VALUEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-file-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="reviewSubject" formControlName="subject" type="text" pInputText [required]="true"> 
                      <label for="reviewSubject" class="req">{{ 'SUBJECT' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="reviewForm.controls['subject'].invalid && (reviewForm.controls['subject'].dirty || reviewForm.controls['subject'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="reviewForm.controls['subject'].errors !== null">
                  <div *ngIf="reviewForm.controls['subject'].errors.required">
                   {{ 'SUBJECTREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-calculator"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="reviewBody" formControlName="body" type="text" pInputText [required]="false"> 
                      <label for="reviewBody" class="">{{ 'BODY' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <label for="reviewStatus" class="req">{{ 'STATUS' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of reviewsColumnsService.statusesOptions; let i = index">
                      <p-radioButton inputId="reviewStatus_{{ i }}" formControlName="status" name="status" [value]="option.value"></p-radioButton>
                      <label for="reviewStatus_{{ i }}">{{ option.label | translate }}</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="reviewForm.controls['status'].invalid && (reviewForm.controls['status'].dirty || reviewForm.controls['status'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="reviewForm.controls['status'].errors !== null">
                  <div *ngIf="reviewForm.controls['status'].errors.required">
                   {{ 'STATUSREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKREVIEWS' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="review.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!reviewForm.valid || disableSubmit"> <!--  [disabled]="reviewForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!reviewForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

