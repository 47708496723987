import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { RecipesDataService, RecipesColumnsService } from '../../../services';
import { Recipe, RecipesResult } from '../../../interfaces';
import { DialogFilerComponent } from '../../filer/filer.component';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-recipe-form',
  templateUrl: './recipe-form.component.html',
  styleUrls: ['./recipe-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class RecipeFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public recipe: Recipe = {};
  public recipeForm: FormGroup = new FormGroup({});
  public recipes: Recipe[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;
  public uploadUrl: string = '';

  private filerDialog: DynamicDialogRef = new DynamicDialogRef();

  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private recipesDataService: RecipesDataService,
    public recipesColumnsService: RecipesColumnsService,
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'RecipeFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Recipe - Edit');
        this.getRecipe(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.recipe !== 'undefined') {
      this.recipe = this.dynamicDialogConfig.data.recipe;
    }
    if (typeof this.recipe.id === 'undefined' || this.recipe.id === null) {
      this.recipe.id = null;
      this.recipe.remoteSource = false;
      this.recipe.isTop = false;
      this.recipe.isNew = false;
      this.recipe.enableStreaming = false;
      this.recipe.autoPublishing = false;
      this.recipe.startPublishing = moment().set({minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD\THH:mm:ssZ');
      this.recipe.endPublishing = moment().set({minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD\THH:mm:ssZ');
    }
    this.getRecipes();
    this.prepareFrom();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'RecipeFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'RecipeFormComponent');
  }

  getRecipe(id: number): void {
    this.isLoading = true;
    this.recipesDataService.getRecipe(id).subscribe((response: Recipe|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.recipe = response;
        this.prepareFrom();
      }
    });
  }

  getRecipes(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.recipes !== 'undefined' && this.dynamicDialogConfig.data.recipes.length > 0) {
      this.recipes = this.dynamicDialogConfig.data.recipes;
      return;
    }
    this.recipesDataService.getRecipes(this.parameters).subscribe((response: RecipesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.recipes = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.recipe.startPublishing = moment(this.recipe.startPublishing).toDate();
    this.recipe.endPublishing = moment(this.recipe.endPublishing).toDate();
    this.recipeForm = new FormGroup({
      name: new FormControl(this.recipe.name, [Validators.required, ]),
      nameAr: new FormControl(this.recipe.nameAr, []),
      nameFr: new FormControl(this.recipe.nameFr, []),
      difficultyLevel: new FormControl(this.recipe.difficultyLevel, []),
      preparationTime: new FormControl(this.recipe.preparationTime, []),
      cookingTime: new FormControl(this.recipe.cookingTime, []),
      servingSize: new FormControl(this.recipe.servingSize, []),
      ordering: new FormControl(this.recipe.ordering, []),
      // picture: new FormControl(this.recipe.picture, []),
      description: new FormControl(this.recipe.description, [Validators.required, ]),
      descriptionAr: new FormControl(this.recipe.descriptionAr, []),
      descriptionFr: new FormControl(this.recipe.descriptionFr, []),
      enableStreaming: new FormControl(this.recipe.enableStreaming, [Validators.required, ]),
      image: new FormControl(this.recipe.image, []),
      file: new FormControl(this.recipe.file, []),
      fileSize: new FormControl(this.recipe.fileSize, []),
      watermarkText: new FormControl(this.recipe.watermarkText, []),
      duration: new FormControl(this.recipe.duration, []),
      isTop: new FormControl(this.recipe.isTop, [Validators.required, ]),
      isNew: new FormControl(this.recipe.isNew, [Validators.required, ]),
      status: new FormControl(this.recipe.status, [Validators.required, ]),
      autoPublishing: new FormControl(this.recipe.autoPublishing, [Validators.required, ]),
      startPublishing: new FormControl(this.recipe.startPublishing, []),
      endPublishing: new FormControl(this.recipe.endPublishing, []),
      // totalPreviewed: new FormControl(this.recipe.totalPreviewed, []),
      // totalDownloads: new FormControl(this.recipe.totalDownloads, []),
      // totalHits: new FormControl(this.recipe.totalHits, []),
      // totalComments: new FormControl(this.recipe.totalComments, []),
      // totalRatings: new FormControl(this.recipe.totalRatings, []),
      // averageRatings: new FormControl(this.recipe.averageRatings, []),
      // totalLikes: new FormControl(this.recipe.totalLikes, []),
      // totalDislikes: new FormControl(this.recipe.totalDislikes, []),
      // totalBookmarks: new FormControl(this.recipe.totalBookmarks, []),
      // remoteSource: new FormControl(this.recipe.remoteSource, [Validators.required, ]),
      // url: new FormControl(this.recipe.url, []),
      // alternativeUrl: new FormControl(this.recipe.alternativeUrl, []),
      id: new FormControl(this.recipe.id, []),
      category: new FormControl((typeof this.recipe.category === 'number') ? this.recipe.category : this.recipe.category?.id, [Validators.required, ]),
      // subCategory: new FormControl((typeof this.recipe.subCategory === 'number') ? this.recipe.subCategory : this.recipe.subCategory?.id, [Validators.required, ]),
      // slug: new FormControl(this.recipe.slug, [Validators.required, ]),
      // slugAr: new FormControl(this.recipe.slugAr, []),
      // slugFr: new FormControl(this.recipe.slugFr, []),
    });
  }

  list() {
    this.router.navigate(['/recipes']);
  }

  showErrors() {
      this.showErrors();
      const invalidFields: string[] = [];
      Object.keys(this.recipeForm.controls).forEach((field: any) => {
        const control = this.recipeForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.recipeForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.recipe = this.recipeForm.value;
    this.disableSubmit = true;
    console.log('recipe', this.recipe);
    if (typeof this.recipe.id !== 'undefined' && this.recipe.id !== null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.recipesDataService.updateRecipe(this.recipe).subscribe((data: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('RECIPEUPDATED')});
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('RECIPENOTUPDATED') + ' ' + error.message});
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.recipesDataService.addRecipe(this.recipe).subscribe((data: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('RECIPECREATED')});
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('RECIPENOTCREATED') + ' ' + error.message});
        console.warn(error);
    });
  }

  openFilerDialog(field: string): void {
    console.log('field', field)
    let url = '';
    const control = this.recipeForm.get(field);
    if (control !== null) {
      url = control.value;
    }
    this.filerDialog = this.dialogService.open(DialogFilerComponent, {
      header: '', // this.translate.instant('FILEMANAGER'),
      width: '90%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1500,
      data: {
        path: 'recipes',
        field: field,
        url: url,
      },
    });
    this.filerDialog.onClose.subscribe((response: any) => {
      console.log('responsefilerDialog', response)
      if (response && response.selected) {
        const control = this.recipeForm.get(field);
        if (control !== null) {
          control.setValue(response.selected.webPath);
        }
      }
    });
  }

  openFilerMultiDialog(field: string): void {
    this.uploadUrl = `${environment.apiUrl}filer/upload`;
    console.log('field', field)
    let url = '';
    const control = this.recipeForm.get(field);
    if (control !== null) {
      url = control.value;
    }
    this.filerDialog = this.dialogService.open(DialogFilerComponent, {
      header: '', // this.translate.instant('FILEMANAGER'),
      width: '90%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1500,
      data: {
        path: 'recipes',
        field: field,
        url: url,
      },
    });
    this.filerDialog.onClose.subscribe((response: any) => {
      console.log('responsefilerDialog', JSON.stringify(response), response)
      if (response) {
        const control = this.recipeForm.get(field);
        if (control !== null) {
          if (response.selected != undefined) {
            let url = '[{"url":"' + response.selected.webPath + '"}]';
            control.setValue(url);
          } else {
            control.setValue(JSON.stringify(response));
          }
        }
      }
    });
  }
}

@Component({
  selector: 'app-recipe-form',
  templateUrl: './recipe-form.component.html',
  styleUrls: ['./recipe-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogRecipeFormComponent extends RecipeFormComponent {
}

