import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  public statistics = [
    
  //   {
  //   label: 'USERS',
  //   entity: 'User',
  //   bg: 'blue',
  //   icon: 'cil-voice-over-record',
  //   count: 0,
  //   url: '/users',
  // }, 
  {
    label: 'RECIPES',
    entity: 'Recipe',
    bg: 'purple',
    icon: 'fa fa-cookie-bite',
    count: 0,
    url: '/recipes',
  }, {
    label: 'STEPS',
    entity: 'Step',
    bg: 'yellow',
    icon: 'cil-list-numbered',
    count: 0,
    url: '/steps',
  }, {
    label: 'INGREDIENTS',
    entity: 'Ingredient',
    bg: 'success',
    icon: 'fa fa-fish',
    count: 0,
    url: '/ingredients',
  }, {
    label: 'UTENSILS',
    entity: 'Utensil',
    bg: 'teal',
    icon: 'fa fa-utensils',
    count: 0,
    url: '/utensils',
  }, {
    label: 'SHOPPINGLISTS',
    entity: 'ShoppingList',
    bg: 'indigo',
    icon: 'cil-clipboard',
    count: 0,
    url: '/shopping-lists',
  },
  //  {
  //   label: 'POSTS',
  //   entity: 'Post',
  //   bg: 'cyan',
  //   icon: 'cil-newspaper',
  //   count: 0,
  //   url: '/posts',
  // }, {
  //   label: 'VIDEOS',
  //   entity: 'Video',
  //   bg: 'danger',
  //   icon: 'sli-film',
  //   count: 0,
  //   url: '/videos',
  // },
  {
    label: 'CATEGORIES',
    entity: 'Category',
    bg: 'warning',
    icon: 'cil-list-rich',
    count: 0,
    url: '/categories',
  }, {
    label: 'SUBCATEGORIES',
    entity: 'SubCategory',
    bg: 'secondary',
    icon: 'cil-line-style',
    count: 0,
    url: '/sub-categories',
  }, {
    label: 'LIKES',
    entity: 'Like',
    bg: 'green',
    icon: 'cil-thumb-up',
    count: 0,
    url: '/likes',
  }, {
    label: 'FAVORITES',
    entity: 'Favorite',
    bg: 'light-blue',
    icon: 'sli-heart',
    count: 0,
    url: '/favorite',
  }, {
    label: 'REVIEWS',
    entity: 'Review',
    bg: 'pink',
    icon: 'sli-bubbles',
    count: 0,
    url: '/reviews',
  }];

  constructor(private dashboardService: DashboardService) {

  }

  ngOnInit(): void {
    this.getStatistics();
  }

  ngOnDestroy(): void {
  }

  getStatistics() {
    this.dashboardService.total(this.statistics[0].entity).subscribe((response: any) => {
      this.statistics[0].count = response.count;
    });
    this.dashboardService.total(this.statistics[1].entity).subscribe((response: any) => {
      this.statistics[1].count = response.count;
    });
    this.dashboardService.total(this.statistics[2].entity).subscribe((response: any) => {
      this.statistics[2].count = response.count;
    });
    this.dashboardService.total(this.statistics[3].entity).subscribe((response: any) => {
      this.statistics[3].count = response.count;
    });
    this.dashboardService.total(this.statistics[4].entity).subscribe((response: any) => {
      this.statistics[4].count = response.count;
    });
    this.dashboardService.total(this.statistics[5].entity).subscribe((response: any) => {
      this.statistics[5].count = response.count;
    });
    this.dashboardService.total(this.statistics[6].entity).subscribe((response: any) => {
      this.statistics[6].count = response.count;
    });
    this.dashboardService.total(this.statistics[7].entity).subscribe((response: any) => {
      this.statistics[7].count = response.count;
    });
  }

}
