import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { Show, ShowsResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ShowsDataService {

  private uri: string = `${environment.apiUrl}shows`;
  private cacheKey: string = 'shows.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getShow(id: number): Observable<Show|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getShows(parameters: {[param: string]: string | string[]}): Observable<ShowsResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addShow(data: Show): Observable<Show|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addShows(data: Show[]): Observable<Show[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updateShow(data: Show): Observable<Show|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updateShows(data: Show[]): Observable<Show[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchShow(data: Show): Observable<Show|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchShows(data: Show[]): Observable<Show[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deleteShow(data: Show): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deleteShows(data: Show[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((show) => {
      ids.push(show.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
