import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { SubscriptionsDataService, SubscriptionsColumnsService } from '../../../services';
import { Subscription, SubscriptionsResult } from '../../../interfaces';

@Component({
  selector: 'app-subscription-form',
  templateUrl: './subscription-form.component.html',
  styleUrls: ['./subscription-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class SubscriptionFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public subscription: Subscription = {};
  public subscriptionForm: FormGroup = new FormGroup({});
  public subscriptions: Subscription[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;


  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private subscriptionsDataService: SubscriptionsDataService,
    public subscriptionsColumnsService: SubscriptionsColumnsService,
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'SubscriptionFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Subscription - Edit');
        this.getSubscription(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.subscription !== 'undefined') {
      this.subscription = this.dynamicDialogConfig.data.subscription;
    }
    if (typeof this.subscription.id === 'undefined' || this.subscription.id === null) {
      this.subscription.id = null;
      this.subscription.startDate = moment().set({minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD\THH:mm:ssZ');
      this.subscription.endDate = moment().set({minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD\THH:mm:ssZ');
    }
    this.getSubscriptions();
    this.prepareFrom();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'SubscriptionFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'SubscriptionFormComponent');
  }

  getSubscription(id: number): void {
    this.isLoading = true;
    this.subscriptionsDataService.getSubscription(id).subscribe((response: Subscription|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.subscription = response;
        this.prepareFrom();
      }
    });
  }

  getSubscriptions(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.subscriptions !== 'undefined' && this.dynamicDialogConfig.data.subscriptions.length > 0) {
      this.subscriptions = this.dynamicDialogConfig.data.subscriptions;
      return;
    }
    this.subscriptionsDataService.getSubscriptions(this.parameters).subscribe((response: SubscriptionsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.subscriptions = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.subscription.startDate = moment(this.subscription.startDate).toDate();
    this.subscription.endDate = moment(this.subscription.endDate).toDate();
    this.subscriptionForm = new FormGroup({
      id: new FormControl(this.subscription.id, []),
      visit: new FormControl((typeof this.subscription.visit === 'number') ? this.subscription.visit : this.subscription.visit?.id, []),
      pack: new FormControl((typeof this.subscription.pack === 'number') ? this.subscription.pack : this.subscription.pack?.id, []),
      status: new FormControl(this.subscription.status, [Validators.required, ]),
      reference: new FormControl(this.subscription.reference, []),
      token: new FormControl(this.subscription.token, []),
      msisdn: new FormControl(this.subscription.msisdn, []),
      voucher: new FormControl(this.subscription.voucher, []),
      serialNumber: new FormControl(this.subscription.serialNumber, []),
      amount: new FormControl(this.subscription.amount, []),
      currency: new FormControl(this.subscription.currency, []),
      duration: new FormControl(this.subscription.duration, []),
      startDate: new FormControl(this.subscription.startDate, []),
      endDate: new FormControl(this.subscription.endDate, []),
      price: new FormControl((typeof this.subscription.price === 'number') ? this.subscription.price : this.subscription.price?.id, []),
      priceValue: new FormControl(this.subscription.priceValue, []),
      userAgent: new FormControl(this.subscription.userAgent, []),
    });
  }

  list() {
    this.router.navigate(['/subscriptions']);
  }

  showErrors() {
      this.showErrors();
      const invalidFields: string[] = [];
      Object.keys(this.subscriptionForm.controls).forEach((field: any) => {
        const control = this.subscriptionForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.subscriptionForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.subscription = this.subscriptionForm.value;
    this.disableSubmit = true;
    console.log('subscription', this.subscription);
    if (typeof this.subscription.id !== 'undefined' && this.subscription.id !== null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.subscriptionsDataService.updateSubscription(this.subscription).subscribe((data: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('SUBSCRIPTIONUPDATED')});
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SUBSCRIPTIONNOTUPDATED') + ' ' + error.message});
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.subscriptionsDataService.addSubscription(this.subscription).subscribe((data: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('SUBSCRIPTIONCREATED')});
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SUBSCRIPTIONNOTCREATED') + ' ' + error.message});
        console.warn(error);
    });
  }

}

@Component({
  selector: 'app-subscription-form',
  templateUrl: './subscription-form.component.html',
  styleUrls: ['./subscription-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogSubscriptionFormComponent extends SubscriptionFormComponent {
}

