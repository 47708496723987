import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { City, CitiesResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class CitiesDataService {

  private uri: string = `${environment.apiUrl}cities`;
  private cacheKey: string = 'cities.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getCity(id: number): Observable<City|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getCities(parameters: {[param: string]: string | string[]}): Observable<CitiesResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addCity(data: City): Observable<City|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addCities(data: City[]): Observable<City[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updateCity(data: City): Observable<City|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updateCities(data: City[]): Observable<City[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchCity(data: City): Observable<City|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchCities(data: City[]): Observable<City[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deleteCity(data: City): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deleteCities(data: City[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((city) => {
      ids.push(city.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
