import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { VideoType, VideoTypesResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class VideoTypesDataService {

  private uri: string = `${environment.apiUrl}video-types`;
  private cacheKey: string = 'videoTypes.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getVideoType(id: number): Observable<VideoType|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getVideoTypes(parameters: {[param: string]: string | string[]}): Observable<VideoTypesResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addVideoType(data: VideoType): Observable<VideoType|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addVideoTypes(data: VideoType[]): Observable<VideoType[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updateVideoType(data: VideoType): Observable<VideoType|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updateVideoTypes(data: VideoType[]): Observable<VideoType[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchVideoType(data: VideoType): Observable<VideoType|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchVideoTypes(data: VideoType[]): Observable<VideoType[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deleteVideoType(data: VideoType): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deleteVideoTypes(data: VideoType[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((videoType) => {
      ids.push(videoType.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
