import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { Favorite, FavoritesResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class FavoritesDataService {

  private uri: string = `${environment.apiUrl}favorites`;
  private cacheKey: string = 'favorites.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getFavorite(id: number): Observable<Favorite|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getFavorites(parameters: {[param: string]: string | string[]}): Observable<FavoritesResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addFavorite(data: Favorite): Observable<Favorite|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addFavorites(data: Favorite[]): Observable<Favorite[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updateFavorite(data: Favorite): Observable<Favorite|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updateFavorites(data: Favorite[]): Observable<Favorite[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchFavorite(data: Favorite): Observable<Favorite|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchFavorites(data: Favorite[]): Observable<Favorite[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deleteFavorite(data: Favorite): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deleteFavorites(data: Favorite[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((favorite) => {
      ids.push(favorite.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
