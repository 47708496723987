import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CacheService } from '../../services/cache/cache.service';
import { CommonService } from '../../services/common/common.service';
import { Column } from '../interfaces/column.interface';
import moment from 'moment';
import { PackageTypesDataService } from './package-types-data.service';
import { PackageType, PackageTypesResult } from '../interfaces';
import { PricesDataService } from './prices-data.service';
import { Price, PricesResult } from '../interfaces';
import { UsersDataService } from './users-data.service';
import { User, UsersResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class PacksColumnsService {

  public packageTypes: PackageType[] = [];
  public prices: Price[] = [];
  public users: User[] = [];
  public modesOptions = [{
    value: 'Product',
    label: 'MODESPRODUCT',
    css: 'primary'
  }, {
    value: 'Subscription',
    label: 'MODESSUBSCRIPTION',
    css: 'success'
  }];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private cache: CacheService,
    private common: CommonService,
    private packageTypesDataService: PackageTypesDataService,
    private pricesDataService: PricesDataService,
    private usersDataService: UsersDataService,

  ) {
    this.getPackageTypes()
    this.getPrices()
    this.getUsers()
  }

  getPackageTypes(offset: number = 0): void {
    const cacheKey: string = 'packageTypes.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.packageTypes = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.packageTypesDataService.getPackageTypes(parameters).subscribe((response: PackageTypesResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.packageTypes = response.results;
        } else {
          this.packageTypes = this.packageTypes.concat(response.results);
        }
        if (response.inlineCount > this.packageTypes.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getPackageTypes(offset);
        }
        response.results = this.packageTypes;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getPrices(offset: number = 0): void {
    const cacheKey: string = 'prices.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.prices = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.pricesDataService.getPrices(parameters).subscribe((response: PricesResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.prices = response.results;
        } else {
          this.prices = this.prices.concat(response.results);
        }
        if (response.inlineCount > this.prices.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getPrices(offset);
        }
        response.results = this.prices;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getUsers(offset: number = 0): void {
    const cacheKey: string = 'users.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.users = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.usersDataService.getUsers(parameters).subscribe((response: UsersResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.users = response.results;
        } else {
          this.users = this.users.concat(response.results);
        }
        if (response.inlineCount > this.users.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getUsers(offset);
        }
        response.results = this.users;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getColumns(): Column[] {
    return [
      { field: 'id', sortField: 'id', title: this.translate.instant('ID'), show: (this.navigation.getParamValue('packs.id.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'packageType', title: this.translate.instant('PACKAGETYPE'), type: 'linkValue', filterData: this.packageTypes, show: (this.navigation.getParamValue('packs.packageTypeId.showFiled', true) && true), displayInList: true, filterField: 'packageType.id', displayField: 'name', sortField: 'packageType.name', url: 'package-types' },
      { field: 'name', sortField: 'name', title: this.translate.instant('NAME'), show: (this.navigation.getParamValue('packs.name.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'nameAr', sortField: 'nameAr', title: this.translate.instant('NAMEAR'), show: (this.navigation.getParamValue('packs.nameAr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'nameFr', sortField: 'nameFr', title: this.translate.instant('NAMEFR'), show: (this.navigation.getParamValue('packs.nameFr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'picture', sortField: 'picture', title: this.translate.instant('PICTURE'), show: (this.navigation.getParamValue('packs.picture.showFiled', true) && true), displayInList: true, type: 'imgValue'},
      { field: 'description', sortField: 'description', title: this.translate.instant('DESCRIPTION'), show: (this.navigation.getParamValue('packs.description.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'descriptionAr', sortField: 'descriptionAr', title: this.translate.instant('DESCRIPTIONAR'), show: (this.navigation.getParamValue('packs.descriptionAr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'descriptionFr', sortField: 'descriptionFr', title: this.translate.instant('DESCRIPTIONFR'), show: (this.navigation.getParamValue('packs.descriptionFr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'price', title: this.translate.instant('PRICE'), type: 'linkValue', filterData: this.prices, show: (this.navigation.getParamValue('packs.price.showFiled', false) && true), displayInList: true, filterField: 'price.id', displayField: 'name', sortField: 'price.name', url: 'prices' },
      { field: 'operatorKey', sortField: 'operatorKey', title: this.translate.instant('OPERATORKEY'), show: (this.navigation.getParamValue('packs.operatorKey.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'mode', sortField: 'mode', title: this.translate.instant('MODE'), show: (this.navigation.getParamValue('packs.mode.showFiled', false) && true), displayInList: true, type: 'enumValue', filterData: this.modesOptions},
      { field: 'isPublished', sortField: 'isPublished', title: this.translate.instant('ISPUBLISHED'), show: (this.navigation.getParamValue('packs.isPublished.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'duration', sortField: 'duration', title: this.translate.instant('DURATION'), show: (this.navigation.getParamValue('packs.duration.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'createdAt', sortField: 'createdAt', title: this.translate.instant('CREATEDAT'), show: (this.navigation.getParamValue('packs.createdAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'creatorUser', title: this.translate.instant('CREATORUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('packs.creatorUser.showFiled', false) && true), displayInList: true, filterField: 'creatorUser.id', displayField: 'username', sortField: 'creatorUser.username', url: 'users' },
      { field: 'modifiedAt', sortField: 'modifiedAt', title: this.translate.instant('MODIFIEDAT'), show: (this.navigation.getParamValue('packs.modifiedAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'modifierUser', title: this.translate.instant('MODIFIERUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('packs.modifierUser.showFiled', false) && true), displayInList: true, filterField: 'modifierUser.id', displayField: 'username', sortField: 'modifierUser.username', url: 'users' },
      { field: 'actions', title: this.translate.instant('ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }

  format(fieldName: string, value: any) {
    if (fieldName.toLowerCase().indexOf('time') > -1) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    } else if (fieldName.toLowerCase().indexOf('date') > -1) {
      return moment(value).format('YYYY-MM-DD');
    }
    return value;
  }

}
