<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="liveChannel.id === null">{{ 'NEWLIVECHANNEL' | translate }}</span>
            <span *ngIf="liveChannel.id !== null">{{ 'EDITLIVECHANNEL' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="liveChannelForm" id="liveChannelForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="liveChannelForm" >
            <input type="hidden" name="id" id="liveChannelId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-money"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="liveChannelPriceId" [autoDisplayFirst]="false" formControlName="price" [options]="liveChannelsColumnsService.prices" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="liveChannelPriceId" class="req">{{ 'PRICE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="liveChannelForm.controls['price'].invalid && (liveChannelForm.controls['price'].dirty || liveChannelForm.controls['price'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="liveChannelForm.controls['price'].errors !== null">
                  <div *ngIf="liveChannelForm.controls['price'].errors.required">
                   {{ 'PRICEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-pie-chart"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="liveChannelSharingId" [autoDisplayFirst]="false" formControlName="sharing" [options]="liveChannelsColumnsService.sharings" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="liveChannelSharingId" class="req">{{ 'SHARING' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="liveChannelForm.controls['sharing'].invalid && (liveChannelForm.controls['sharing'].dirty || liveChannelForm.controls['sharing'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="liveChannelForm.controls['sharing'].errors !== null">
                  <div *ngIf="liveChannelForm.controls['sharing'].errors.required">
                   {{ 'SHARINGREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-tag"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="liveChannelName" formControlName="name" type="text" pInputText [required]="true"> 
                      <label for="liveChannelName" class="req">{{ 'NAME' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="liveChannelForm.controls['name'].invalid && (liveChannelForm.controls['name'].dirty || liveChannelForm.controls['name'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="liveChannelForm.controls['name'].errors !== null">
                  <div *ngIf="liveChannelForm.controls['name'].errors.required">
                   {{ 'NAMEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="liveChannelNameAr" formControlName="nameAr" type="text" pInputText [required]="false"> 
                      <label for="liveChannelNameAr" class="">{{ 'NAMEAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="liveChannelNameFr" formControlName="nameFr" type="text" pInputText [required]="false"> 
                      <label for="liveChannelNameFr" class="">{{ 'NAMEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="liveChannelSlug" formControlName="slug" type="text" pInputText [required]="true"> 
                      <label for="liveChannelSlug" class="req">{{ 'SLUG' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="liveChannelForm.controls['slug'].invalid && (liveChannelForm.controls['slug'].dirty || liveChannelForm.controls['slug'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="liveChannelForm.controls['slug'].errors !== null">
                  <div *ngIf="liveChannelForm.controls['slug'].errors.required">
                   {{ 'SLUGREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="liveChannelSlugAr" formControlName="slugAr" type="text" pInputText [required]="false"> 
                      <label for="liveChannelSlugAr" class="">{{ 'SLUGAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="liveChannelSlugFr" formControlName="slugFr" type="text" pInputText [required]="false"> 
                      <label for="liveChannelSlugFr" class="">{{ 'SLUGFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="liveChannelStreamName" formControlName="streamName" type="text" pInputText [required]="false"> 
                      <label for="liveChannelStreamName" class="">{{ 'STREAMNAME' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="liveChannelPutOnHome" formControlName="putOnHome" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="liveChannelPutOnHome" class="p-d-inline-block p-ml-2 req">{{ 'PUTONHOME' | translate }}</label>
                </div>
                <div *ngIf="liveChannelForm.controls['putOnHome'].invalid && (liveChannelForm.controls['putOnHome'].dirty || liveChannelForm.controls['putOnHome'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="liveChannelForm.controls['putOnHome'].errors !== null">
                  <div *ngIf="liveChannelForm.controls['putOnHome'].errors.required">
                   {{ 'PUTONHOMEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_PUBLISHER') === -1" >
                  <label for="liveChannelStatus" class="req">{{ 'STATUS' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of liveChannelsColumnsService.statusesOptions; let i = index">
                      <p-radioButton inputId="liveChannelStatus_{{ i }}" formControlName="status" name="status" [value]="option.value"></p-radioButton>
                      <label for="liveChannelStatus_{{ i }}">{{ option.label | translate }}</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="liveChannelForm.controls['status'].invalid && (liveChannelForm.controls['status'].dirty || liveChannelForm.controls['status'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="liveChannelForm.controls['status'].errors !== null">
                  <div *ngIf="liveChannelForm.controls['status'].errors.required">
                   {{ 'STATUSREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-eye"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="liveChannelTotalViews" formControlName="totalViews" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="liveChannelTotalViews" class="">{{ 'TOTALVIEWS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-eye"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="liveChannelTotalHits" formControlName="totalHits" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="liveChannelTotalHits" class="">{{ 'TOTALHITS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-comments"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="liveChannelTotalComments" formControlName="totalComments" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="liveChannelTotalComments" class="">{{ 'TOTALCOMMENTS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-star-half-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="liveChannelTotalRatings" formControlName="totalRatings" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="liveChannelTotalRatings" class="">{{ 'TOTALRATINGS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-pie-chart"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="liveChannelAverageRatings" formControlName="averageRatings" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [step]="0.1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="liveChannelAverageRatings" class="">{{ 'AVERAGERATINGS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-thumbs-down"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="liveChannelTotalLikes" formControlName="totalLikes" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="liveChannelTotalLikes" class="">{{ 'TOTALLIKES' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-thumbs-up"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="liveChannelTotalDislikes" formControlName="totalDislikes" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="liveChannelTotalDislikes" class="">{{ 'TOTALDISLIKES' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-bookmark"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="liveChannelTotalBookmarks" formControlName="totalBookmarks" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="liveChannelTotalBookmarks" class="">{{ 'TOTALBOOKMARKS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="liveChannelEnableStreaming" formControlName="enableStreaming" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="liveChannelEnableStreaming" class="p-d-inline-block p-ml-2 req">{{ 'ENABLESTREAMING' | translate }}</label>
                </div>
                <div *ngIf="liveChannelForm.controls['enableStreaming'].invalid && (liveChannelForm.controls['enableStreaming'].dirty || liveChannelForm.controls['enableStreaming'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="liveChannelForm.controls['enableStreaming'].errors !== null">
                  <div *ngIf="liveChannelForm.controls['enableStreaming'].errors.required">
                   {{ 'ENABLESTREAMINGREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="liveChannelAutoPublishing" formControlName="autoPublishing" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="liveChannelAutoPublishing" class="p-d-inline-block p-ml-2 req">{{ 'AUTOPUBLISHING' | translate }}</label>
                </div>
                <div *ngIf="liveChannelForm.controls['autoPublishing'].invalid && (liveChannelForm.controls['autoPublishing'].dirty || liveChannelForm.controls['autoPublishing'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="liveChannelForm.controls['autoPublishing'].errors !== null">
                  <div *ngIf="liveChannelForm.controls['autoPublishing'].errors.required">
                   {{ 'AUTOPUBLISHINGREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-control-skip-forward"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="liveChannelStartPublishing" formControlName="startPublishing" [showTime]="true" hourFormat="24" [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [required]="false" yearRange="1900:2030" [stepHour]="1" [stepMinute]="15" [stepSecond]="0"></p-calendar>
                      <label for="liveChannelStartPublishing" class="">{{ 'STARTPUBLISHING' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-control-skip-backward"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="liveChannelEndPublishing" formControlName="endPublishing" [showTime]="true" hourFormat="24" [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [required]="false" yearRange="1900:2030" [stepHour]="1" [stepMinute]="15" [stepSecond]="0"></p-calendar>
                      <label for="liveChannelEndPublishing" class="">{{ 'ENDPUBLISHING' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKLIVECHANNELS' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="liveChannel.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!liveChannelForm.valid || disableSubmit"> <!--  [disabled]="liveChannelForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!liveChannelForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

