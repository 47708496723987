import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { CommonService } from '../../../services/common/common.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { SettingsDataService, SettingsColumnsService } from '../../services';
import { DialogSettingFormComponent } from './setting-form/setting-form.component';
import { DialogSettingDetailsComponent } from './setting-details/setting-details.component';
import { Setting, SettingsResult } from '../../interfaces';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class SettingsComponent implements OnInit, OnDestroy {

  private settingAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private settingEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private settingDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  public settingToDelete: Setting = {};
  public settings: Setting[] = [];
  public setting: Setting = {};
  public selectedSettings: Setting[] = [];
  public submitted: boolean = false;
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '100'};
  public chunkSize: number = 200;
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  private _selectedColumns: Column[] = [];
  @ViewChild('importer', { static: false })
  public importer?: FileUpload;
  public importChooseLabel: string = 'Import';
  public importLabel: string = 'Import CSV';

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private common: CommonService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private settingsDataService: SettingsDataService,
    private settingsColumnsService: SettingsColumnsService,
  ) {
    this.importChooseLabel = this.translate.instant('IMPORT');
    this.importLabel = this.translate.instant('IMPORTCSV');
  }

  ngOnInit(): void {
    console.log('SettingsComponent', 'ngOnInit');
    this.isInitialized = true;
    this.titleService.setTitle('Settings - Management');
    this.primengConfig.ripple = true;
    this.getSettings();
  }

  ngOnDestroy(): void {
    console.log('SettingsComponent', 'ngOnDestroy');
    this.isInitialized = false;
  }

  getSettings(): void {
    if (!this.isInitialized) {
      return;
    }
    if (parseInt(this.parameters.offset + '', 10) === 0) {
      this.isLoading = true;
    }
    this.settingsDataService.getSettings(this.parameters).subscribe((response: SettingsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        if (parseInt(this.parameters.offset + '', 10) === 0) {
          this.settings = response.results;
        } else {
          this.settings = this.settings.concat(response.results);
        }
        if (response.inlineCount > this.settings.length) {
          this.parameters.offset = (parseInt(this.parameters.offset + '', 10) + parseInt(this.parameters.limit + '', 10)) + '';
          this.getSettings();
        } else {
          this.parameters.offset = '0';
        }
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SETTINGSNOTLOADED')});
    });
  }

  importSettings(event: any): void {
    if (typeof event.currentFiles === 'undefined') {
      return;
    }
    if (event.currentFiles.length === 0) {
      return;
    }
    const inputFile = event.currentFiles[0];
    const reader = new FileReader();
    let rows: any[] = [];
    reader.onload = (e: any) => {
      if (typeof this.importer !== 'undefined') {
        this.importer.clear();
      }
      const text = <string>e.target.result;
      rows = ToolsService.csvToArray(this.columns, text, ',');
      if (rows.length > 0) {
        let proceed = 0;
        const j = rows.length;
        for (let i = 0; i < j; i += this.chunkSize) {
          this.isLoading = true;
          const chunk = rows.slice(i, i + this.chunkSize);
          if (typeof chunk[0].id === 'undefined') {
            this.settingsDataService.addSettings(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('SETTINGSCREATED')});
                if (proceed === j) {
                  this.getSettings();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SETTINGSNOTCREATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SETTINGSNOTCREATED') + ' ' + msg});
            });
          } else {
            this.settingsDataService.updateSettings(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('SETTINGSUPDATED')});
                if (proceed === j) {
                  this.getSettings();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SETTINGSNOTUPDATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SETTINGSNOTCREATED') + ' ' + msg});
            });
          }
        }
      }
    };
    reader.readAsText(inputFile);
  }

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.settingsColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('settings.' + this.columns[i].field + '.showFiled', show);
    }
  }

  deleteSelectedSettings() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Settings?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.settingsDataService.deleteSettings(this.selectedSettings).subscribe((response) => {
          if (response === null) {
            this.messageService.clear();
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Settings Deleted', life: 3000});
            this.getSettings();
          } else {
            this.messageService.clear();
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Settings not deleted'});
          }
        }, (error: any) => {
          this.messageService.clear();
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Settings not deleted'});
        });
        this.selectedSettings = [];
      }
    });
  }

  exportPdf() {
    ToolsService.exportPdf(this.settings, this.columns, 'Settings');
  }

  exportExcel() {
    ToolsService.exportExcel(this.settings, this.columns, 'Settings');
  }

  exportCsv() {
    ToolsService.exportCsv(this.settings, this.columns, 'Settings');
  }

  refresh() {
    this.settingsDataService.clearCache();
    this.getSettings();
  }

  settingAdd(): void {
    if (!this.navigation.getParamValue('settings.addInDialog', true)) {
      this.router.navigate(['/settings/add']);
      return;
    }
    this.settingAddDialog = this.dialogService.open(DialogSettingFormComponent, {
      header: '', // this.translate.instant('NEWSETTING'),
      width: '80%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'add',
        setting: {id: null},
        users: this.settingsColumnsService.users,
      },
    });

    this.settingAddDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'info', summary: 'Success', detail: 'Setting added'});
        this.getSettings();
      }
    });
  }

  settingEdit(setting: Setting): void {
    if (!this.navigation.getParamValue('settings.editInDialog', true)) {
      this.router.navigate(['/settings/edit/' + setting.id]);
      return;
    }
    this.settingEditDialog = this.dialogService.open(DialogSettingFormComponent, {
      header: '', // this.translate.instant('EDITSETTING') + ' ' + setting.name,
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'edit',
        setting: setting,
        users: this.settingsColumnsService.users,
      },
    });

    this.settingEditDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Setting updated'});
        this.getSettings();
      }
    });
  }

  settingDetails(setting: Setting): void {
    if (!this.navigation.getParamValue('settings.detailsInDialog', true)) {
      this.router.navigate(['/settings/details/' + setting.id]);
      return;
    }
    this.settingDetailsDialog = this.dialogService.open(DialogSettingDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        setting: setting,
      }
    });
  }

  settingDelete(setting: Setting): void {
    this.settingToDelete = setting;
    this.messageService.clear();
    this.messageService.add({
      key: 'settingDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want delete ' + setting.name + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.settingsDataService.deleteSetting(this.settingToDelete).subscribe((res: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Setting deleted'});
      this.getSettings();
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Setting not deleted'});
    });
  }

  onDeleteReject(): void {
    this.settingToDelete = {};
    this.messageService.clear('settingDelete');
  }

}
