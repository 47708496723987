
<div class="animated fadeIn p-4">
    <div class="d-flex align-items-center">
        <h1>{{ 'LOGOUT' | translate }}</h1>
        <div [hidden]="!isLoading" class="spinner-border text-info  ml-auto" role="status">
          <span class="sr-only">Loading...</span>
        </div>
    </div>
    <p-toast></p-toast>
    <p-messages [(value)]="msgs"></p-messages>
</div>
