import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { PostsDataService, PostsColumnsService } from '../../../services';
import { Post, PostsResult } from '../../../interfaces';
import { Editor } from 'primeng/editor';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EditorService } from '../../../../services/editor/editor.service';
import { DialogFilerComponent } from '../../filer/filer.component';

@Component({
  selector: 'app-post-form',
  templateUrl: './post-form.component.html',
  styleUrls: ['./post-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class PostFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public post: Post = {};
  public postForm: FormGroup = new FormGroup({});
  public posts: Post[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;


  public Editor = ClassicEditor;
  private filerDialog: DynamicDialogRef = new DynamicDialogRef();

  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private postsDataService: PostsDataService,
    public postsColumnsService: PostsColumnsService,
    public editorService: EditorService,
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'PostFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Post - Edit');
        this.getPost(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.post !== 'undefined') {
      this.post = this.dynamicDialogConfig.data.post;
    }
    if (typeof this.post.id === 'undefined' || this.post.id === null) {
      this.post.id = null;
      this.post.isHeadline = false;
      this.post.autoPublishing = false;
      this.post.startPublishing = moment().set({minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD\THH:mm:ssZ');
      this.post.endPublishing = moment().set({minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD\THH:mm:ssZ');
      this.post.publishDate = moment().set({minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD\THH:mm:ssZ');
      this.post.isTop = false;
      this.post.isNew = false;
    }
    this.getPosts();
    this.prepareFrom();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'PostFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'PostFormComponent');
  }

  getPost(id: number): void {
    this.isLoading = true;
    this.postsDataService.getPost(id).subscribe((response: Post|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.post = response;
        this.prepareFrom();
      }
    });
  }

  getPosts(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.posts !== 'undefined' && this.dynamicDialogConfig.data.posts.length > 0) {
      this.posts = this.dynamicDialogConfig.data.posts;
      return;
    }
    this.postsDataService.getPosts(this.parameters).subscribe((response: PostsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.posts = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.post.startPublishing = moment(this.post.startPublishing).toDate();
    this.post.endPublishing = moment(this.post.endPublishing).toDate();
    this.post.publishDate = moment(this.post.publishDate).toDate();
    this.postForm = new FormGroup({
      id: new FormControl(this.post.id, []),
      postType: new FormControl((typeof this.post.postType === 'number') ? this.post.postType : this.post.postType?.id, [Validators.required, ]),
      category: new FormControl((typeof this.post.category === 'number') ? this.post.category : this.post.category?.id, [Validators.required, ]),
      subCategory: new FormControl((typeof this.post.subCategory === 'number') ? this.post.subCategory : this.post.subCategory?.id, [Validators.required, ]),
      title: new FormControl(this.post.title, []),
      titleAr: new FormControl(this.post.titleAr, []),
      titleFr: new FormControl(this.post.titleFr, []),
      slug: new FormControl(this.post.slug, []),
      slugAr: new FormControl(this.post.slugAr, []),
      slugFr: new FormControl(this.post.slugFr, []),
      picture: new FormControl(this.post.picture, []),
      content: new FormControl(this.post.content, []),
      contentAr: new FormControl(this.post.contentAr, []),
      contentFr: new FormControl(this.post.contentFr, []),
      isHeadline: new FormControl(this.post.isHeadline, [Validators.required, ]),
      autoPublishing: new FormControl(this.post.autoPublishing, [Validators.required, ]),
      startPublishing: new FormControl(this.post.startPublishing, []),
      endPublishing: new FormControl(this.post.endPublishing, []),
      publishDate: new FormControl(this.post.publishDate, [Validators.required, ]),
      metaTitle: new FormControl(this.post.metaTitle, []),
      metaDescription: new FormControl(this.post.metaDescription, []),
      metaKeywords: new FormControl(this.post.metaKeywords, []),
      status: new FormControl(this.post.status, [Validators.required, ]),
      totalPrints: new FormControl(this.post.totalPrints, []),
      totalHits: new FormControl(this.post.totalHits, []),
      totalComments: new FormControl(this.post.totalComments, []),
      totalRatings: new FormControl(this.post.totalRatings, []),
      averageRatings: new FormControl(this.post.averageRatings, []),
      totalLikes: new FormControl(this.post.totalLikes, []),
      totalDislikes: new FormControl(this.post.totalDislikes, []),
      totalBookmarks: new FormControl(this.post.totalBookmarks, []),
      isTop: new FormControl(this.post.isTop, [Validators.required, ]),
      isNew: new FormControl(this.post.isNew, [Validators.required, ]),
      ordering: new FormControl(this.post.ordering, []),
    });
  }

  list() {
    this.router.navigate(['/posts']);
  }

  showErrors() {
      this.showErrors();
      const invalidFields: string[] = [];
      Object.keys(this.postForm.controls).forEach((field: any) => {
        const control = this.postForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.postForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.post = this.postForm.value;
    this.disableSubmit = true;
    console.log('post', this.post);
    if (typeof this.post.id !== 'undefined' && this.post.id !== null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.postsDataService.updatePost(this.post).subscribe((data: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('POSTUPDATED')});
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('POSTNOTUPDATED') + ' ' + error.message});
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.postsDataService.addPost(this.post).subscribe((data: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('POSTCREATED')});
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('POSTNOTCREATED') + ' ' + error.message});
        console.warn(error);
    });
  }

  openFilerDialog(field: string): void {
    let url = '';
    const control = this.postForm.get(field);
    if (control !== null) {
      url = control.value;
    }
    this.filerDialog = this.dialogService.open(DialogFilerComponent, {
      header: '', // this.translate.instant('FILEMANAGER'),
      width: '90%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1500,
      data: {
        path: 'posts',
        field: field,
        url: url,
      },
    });
    this.filerDialog.onClose.subscribe((response: any) => {
      if (response && response.selected) {
        const control = this.postForm.get(field);
        if (control !== null) {
          control.setValue(response.selected.webPath);
        }
      }
    });
  }

}

@Component({
  selector: 'app-post-form',
  templateUrl: './post-form.component.html',
  styleUrls: ['./post-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogPostFormComponent extends PostFormComponent {
}

