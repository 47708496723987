import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { Review, ReviewsResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ReviewsDataService {

  private uri: string = `${environment.apiUrl}reviews`;
  private cacheKey: string = 'reviews.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getReview(id: number): Observable<Review|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getReviews(parameters: {[param: string]: string | string[]}): Observable<ReviewsResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addReview(data: Review): Observable<Review|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addReviews(data: Review[]): Observable<Review[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updateReview(data: Review): Observable<Review|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updateReviews(data: Review[]): Observable<Review[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchReview(data: Review): Observable<Review|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchReviews(data: Review[]): Observable<Review[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deleteReview(data: Review): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deleteReviews(data: Review[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((review) => {
      ids.push(review.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
