<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="shoppingListRecipe.id === null">{{ 'NEWSHOPPINGLISTRECIPE' | translate }}</span>
            <span *ngIf="shoppingListRecipe.id !== null">{{ 'EDITSHOPPINGLISTRECIPE' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="shoppingListRecipeForm" id="shoppingListRecipeForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="shoppingListRecipeForm" >
            <input type="hidden" name="id" id="shoppingListRecipeId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="shoppingListRecipeShoppingListId" [autoDisplayFirst]="false" formControlName="shoppingList" [options]="shoppingListRecipesColumnsService.shoppingLists" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="shoppingListRecipeShoppingListId" class="req">{{ 'SHOPPINGLIST' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="shoppingListRecipeForm.controls['shoppingList'].invalid && (shoppingListRecipeForm.controls['shoppingList'].dirty || shoppingListRecipeForm.controls['shoppingList'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="shoppingListRecipeForm.controls['shoppingList'].errors !== null">
                  <div *ngIf="shoppingListRecipeForm.controls['shoppingList'].errors.required">
                   {{ 'SHOPPINGLISTREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="shoppingListRecipeRecipeId" [autoDisplayFirst]="false" formControlName="recipe" [options]="shoppingListRecipesColumnsService.recipes" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="shoppingListRecipeRecipeId" class="req">{{ 'RECIPE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="shoppingListRecipeForm.controls['recipe'].invalid && (shoppingListRecipeForm.controls['recipe'].dirty || shoppingListRecipeForm.controls['recipe'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="shoppingListRecipeForm.controls['recipe'].errors !== null">
                  <div *ngIf="shoppingListRecipeForm.controls['recipe'].errors.required">
                   {{ 'RECIPEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="shoppingListRecipeServingSize" formControlName="servingSize" [step]="1" [showButtons]="true" [required]="true"></p-inputNumber>
                      <label for="shoppingListRecipeServingSize" class="req">{{ 'SERVINGSIZE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="shoppingListRecipeForm.controls['servingSize'].invalid && (shoppingListRecipeForm.controls['servingSize'].dirty || shoppingListRecipeForm.controls['servingSize'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="shoppingListRecipeForm.controls['servingSize'].errors !== null">
                  <div *ngIf="shoppingListRecipeForm.controls['servingSize'].errors.required">
                   {{ 'SERVINGSIZEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-list-ol"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="shoppingListRecipeOrdering" formControlName="ordering" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="shoppingListRecipeOrdering" class="">{{ 'ORDERING' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKSHOPPINGLISTRECIPES' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="shoppingListRecipe.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!shoppingListRecipeForm.valid || disableSubmit"> <!--  [disabled]="shoppingListRecipeForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!shoppingListRecipeForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

