import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CacheService } from '../../services/cache/cache.service';
import { CommonService } from '../../services/common/common.service';
import { Column } from '../interfaces/column.interface';
import moment from 'moment';
import { RecipesDataService } from './recipes-data.service';
import { Recipe, RecipesResult } from '../interfaces';
import { PostsDataService } from './posts-data.service';
import { Post, PostsResult } from '../interfaces';
import { VideosDataService } from './videos-data.service';
import { Video, VideosResult } from '../interfaces';
import { UsersDataService } from './users-data.service';
import { User, UsersResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsColumnsService {
  public recipes: Recipe[] = [];
  public posts: Post[] = [];
  public videos: Video[] = [];
  public users: User[] = [];
  public typesOptions = [{
    value: 'NewRecipe',
    label: 'NEWRECIPE',
    css: 'primary'
  }
    ,{
    value: 'NewPost',
    label: 'TYPESNEWPOST',
    css: 'primary'
  }, {
    value: 'NewVideo',
    label: 'TYPESNEWVIDEO',
    css: 'success'
  }, {
    value: 'NewLive',
    label: 'TYPESNEWLIVE',
    css: 'warning'
  }, {
    value: 'Other',
    label: 'TYPESOTHER',
    css: 'danger'
  }];
  public soundsOptions = [{
    value: 'notify',
    label: 'SOUNDSNOTIFY',
    css: 'primary'
  }, {
    value: 'cliffhanger',
    label: 'SOUNDSCLIFFHANGER',
    css: 'success'
  }, {
    value: 'jubel',
    label: 'SOUNDSJUBEL',
    css: 'warning'
  }, {
    value: 'ohhhh',
    label: 'SOUNDSOHHHH',
    css: 'danger'
  }, {
    value: 'trillerpfeife',
    label: 'SOUNDSTRILLERPFEIFE',
    css: 'default'
  }, {
    value: 'pop',
    label: 'SOUNDSPOP',
    css: 'info'
  }, {
    value: 'failure',
    label: 'SOUNDSFAILURE',
    css: 'primary'
  }, {
    value: 'camera_shutter',
    label: 'SOUNDSCAMERA_SHUTTER',
    css: 'success'
  }];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private cache: CacheService,
    private common: CommonService,
    private postsDataService: PostsDataService,
    private videosDataService: VideosDataService,
    private usersDataService: UsersDataService,
    public recipesDataService: RecipesDataService

  ) {
    this.getRecipes()
    this.getPosts()
    this.getVideos()
    this.getUsers()
  }

  getRecipes(offset: number = 0): void {
    const cacheKey: string = 'recipes.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.recipes = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, { offset: offset + '' });
    this.recipesDataService.getRecipes(parameters).subscribe((response: RecipesResult | any) => {
      if (response !== null) {
        if (offset === 0) {
          this.recipes = response.results;
        } else {
          this.recipes = this.recipes.concat(response.results);
        }
        if (response.inlineCount > this.recipes.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getRecipes(offset);
        }
        response.results = this.recipes;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getPosts(offset: number = 0): void {
    const cacheKey: string = 'posts.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.posts = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.postsDataService.getPosts(parameters).subscribe((response: PostsResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.posts = response.results;
        } else {
          this.posts = this.posts.concat(response.results);
        }
        if (response.inlineCount > this.posts.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getPosts(offset);
        }
        response.results = this.posts;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getVideos(offset: number = 0): void {
    const cacheKey: string = 'videos.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.videos = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.videosDataService.getVideos(parameters).subscribe((response: VideosResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.videos = response.results;
        } else {
          this.videos = this.videos.concat(response.results);
        }
        if (response.inlineCount > this.videos.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getVideos(offset);
        }
        response.results = this.videos;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getUsers(offset: number = 0): void {
    const cacheKey: string = 'users.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.users = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.usersDataService.getUsers(parameters).subscribe((response: UsersResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.users = response.results;
        } else {
          this.users = this.users.concat(response.results);
        }
        if (response.inlineCount > this.users.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getUsers(offset);
        }
        response.results = this.users;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getColumns(): Column[] {
    return [
      { field: 'id', sortField: 'id', title: this.translate.instant('ID'), show: (this.navigation.getParamValue('pushNotifications.id.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'type', sortField: 'type', title: this.translate.instant('TYPE'), show: (this.navigation.getParamValue('pushNotifications.type.showFiled', true) && true), displayInList: true, type: 'enumValue', filterData: this.typesOptions},
      { field: 'title', sortField: 'title', title: this.translate.instant('TITLE'), show: (this.navigation.getParamValue('pushNotifications.title.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'titleAr', sortField: 'titleAr', title: this.translate.instant('TITLEAR'), show: (this.navigation.getParamValue('pushNotifications.titleAr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'titleFr', sortField: 'titleFr', title: this.translate.instant('TITLEFR'), show: (this.navigation.getParamValue('pushNotifications.titleFr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'message', sortField: 'message', title: this.translate.instant('MESSAGE'), show: (this.navigation.getParamValue('pushNotifications.message.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'messageAr', sortField: 'messageAr', title: this.translate.instant('MESSAGEAR'), show: (this.navigation.getParamValue('pushNotifications.messageAr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'messageFr', sortField: 'messageFr', title: this.translate.instant('MESSAGEFR'), show: (this.navigation.getParamValue('pushNotifications.messageFr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'category', sortField: 'category', title: this.translate.instant('CATEGORY'), show: (this.navigation.getParamValue('pushNotifications.category.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'badge', sortField: 'badge', title: this.translate.instant('BADGE'), show: (this.navigation.getParamValue('pushNotifications.badge.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'sound', sortField: 'sound', title: this.translate.instant('SOUND'), show: (this.navigation.getParamValue('pushNotifications.sound.showFiled', false) && true), displayInList: true, type: 'enumValue', filterData: this.soundsOptions},
      { field: 'recipe', title: this.translate.instant('RECIPE'), type: 'linkValue', filterData: this.recipes, show: (this.navigation.getParamValue('pushNotifications.recipe.showFiled', false) && true), displayInList: true, filterField: 'recipe.id', displayField: 'title', sortField: 'recipe.title', url: 'recipes' },

      { field: 'post', title: this.translate.instant('POST'), type: 'linkValue', filterData: this.posts, show: (this.navigation.getParamValue('pushNotifications.post.showFiled', false) && true), displayInList: true, filterField: 'post.id', displayField: 'title', sortField: 'post.title', url: 'posts' },
      { field: 'video', title: this.translate.instant('VIDEO'), type: 'linkValue', filterData: this.videos, show: (this.navigation.getParamValue('pushNotifications.video.showFiled', false) && true), displayInList: true, filterField: 'video.id', displayField: 'name', sortField: 'video.name', url: 'videos' },
      { field: 'sending', sortField: 'sending', title: this.translate.instant('SENDING'), show: (this.navigation.getParamValue('pushNotifications.sending.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'sendingTime', sortField: 'sendingTime', title: this.translate.instant('SENDINGTIME'), show: (this.navigation.getParamValue('pushNotifications.sendingTime.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'createdAt', sortField: 'createdAt', title: this.translate.instant('CREATEDAT'), show: (this.navigation.getParamValue('pushNotifications.createdAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'creatorUser', title: this.translate.instant('CREATORUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('pushNotifications.creatorUser.showFiled', false) && true), displayInList: true, filterField: 'creatorUser.id', displayField: 'username', sortField: 'creatorUser.username', url: 'users' },
      { field: 'modifiedAt', sortField: 'modifiedAt', title: this.translate.instant('MODIFIEDAT'), show: (this.navigation.getParamValue('pushNotifications.modifiedAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'modifierUser', title: this.translate.instant('MODIFIERUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('pushNotifications.modifierUser.showFiled', false) && true), displayInList: true, filterField: 'modifierUser.id', displayField: 'username', sortField: 'modifierUser.username', url: 'users' },
      { field: 'actions', title: this.translate.instant('ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }

  format(fieldName: string, value: any) {
    if (fieldName.toLowerCase().indexOf('time') > -1) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    } else if (fieldName.toLowerCase().indexOf('date') > -1) {
      return moment(value).format('YYYY-MM-DD');
    }
    return value;
  }

}
