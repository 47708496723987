import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { CommonService } from '../../../services/common/common.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private common: CommonService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    console.log('ChangePasswordComponent', 'ngOnInit');
    this.titleService.setTitle('Change My Password');
    this.primengConfig.ripple = true;
  }

  ngOnDestroy(): void {
    console.log('ChangePasswordComponent', 'ngOnDestroy');
  }
}