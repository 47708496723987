<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="post.id === null">{{ 'NEWPOST' | translate }}</span>
            <span *ngIf="post.id !== null">{{ 'EDITPOST' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="postForm" id="postForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="postForm" >
            <input type="hidden" name="id" id="postId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-newspaper"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="postPostTypeId" [autoDisplayFirst]="false" formControlName="postType" [options]="postsColumnsService.postTypes" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="postPostTypeId" class="req">{{ 'POSTTYPE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="postForm.controls['postType'].invalid && (postForm.controls['postType'].dirty || postForm.controls['postType'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="postForm.controls['postType'].errors !== null">
                  <div *ngIf="postForm.controls['postType'].errors.required">
                   {{ 'POSTTYPEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-folder"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="postCategoryId" [autoDisplayFirst]="false" formControlName="category" [options]="postsColumnsService.categories" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="postCategoryId" class="req">{{ 'CATEGORY' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="postForm.controls['category'].invalid && (postForm.controls['category'].dirty || postForm.controls['category'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="postForm.controls['category'].errors !== null">
                  <div *ngIf="postForm.controls['category'].errors.required">
                   {{ 'CATEGORYREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-folder"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="postSubCategoryId" [autoDisplayFirst]="false" formControlName="subCategory" [options]="postsColumnsService.subCategories" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="postSubCategoryId" class="req">{{ 'SUBCATEGORY' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="postForm.controls['subCategory'].invalid && (postForm.controls['subCategory'].dirty || postForm.controls['subCategory'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="postForm.controls['subCategory'].errors !== null">
                  <div *ngIf="postForm.controls['subCategory'].errors.required">
                   {{ 'SUBCATEGORYREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-tags"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="postTitle" formControlName="title" type="text" pInputText [required]="false"> 
                      <label for="postTitle" class="">{{ 'TITLE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-tags"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="postTitleAr" formControlName="titleAr" type="text" pInputText [required]="false"> 
                      <label for="postTitleAr" class="">{{ 'TITLEAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-tags"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="postTitleFr" formControlName="titleFr" type="text" pInputText [required]="false"> 
                      <label for="postTitleFr" class="">{{ 'TITLEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="postSlug" formControlName="slug" type="text" pInputText [required]="false"> 
                      <label for="postSlug" class="">{{ 'SLUG' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="postSlugAr" formControlName="slugAr" type="text" pInputText [required]="false"> 
                      <label for="postSlugAr" class="">{{ 'SLUGAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="postSlugFr" formControlName="slugFr" type="text" pInputText [required]="false"> 
                      <label for="postSlugFr" class="">{{ 'SLUGFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-image"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="postPicture" formControlName="picture" type="text" pInputText [required]="false"> 
                      <label for="postPicture" class="">{{ 'PICTURE' | translate }}</label>
                    </span>
                    <button type="button" pButton pRipple icon="pi pi-folder" styleClass="p-button-info" (click)="openFilerDialog('picture')"></button> 
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">

                <div class="p-field p-col-12">
                    <label for="postContent" class="">{{ 'CONTENT' | translate }}</label>
                    <ckeditor formControlName="content" [editor]="Editor" id="postContent" name="content" [config]="editorService.config"></ckeditor>
                </div>

              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">

                <div class="p-field p-col-12">
                    <label for="postContentAr" class="">{{ 'CONTENTAR' | translate }}</label>
                    <ckeditor formControlName="contentAr" [editor]="Editor" id="postContentAr" name="contentAr" [config]="editorService.config"></ckeditor>
                </div>

              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">

                <div class="p-field p-col-12">
                    <label for="postContentFr" class="">{{ 'CONTENTFR' | translate }}</label>
                    <ckeditor formControlName="contentFr" [editor]="Editor" id="postContentFr" name="contentFr" [config]="editorService.config"></ckeditor>
                </div>

              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="postIsHeadline" formControlName="isHeadline" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="postIsHeadline" class="p-d-inline-block p-ml-2 req">{{ 'ISHEADLINE' | translate }}</label>
                </div>
                <div *ngIf="postForm.controls['isHeadline'].invalid && (postForm.controls['isHeadline'].dirty || postForm.controls['isHeadline'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="postForm.controls['isHeadline'].errors !== null">
                  <div *ngIf="postForm.controls['isHeadline'].errors.required">
                   {{ 'ISHEADLINEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="postAutoPublishing" formControlName="autoPublishing" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="postAutoPublishing" class="p-d-inline-block p-ml-2 req">{{ 'AUTOPUBLISHING' | translate }}</label>
                </div>
                <div *ngIf="postForm.controls['autoPublishing'].invalid && (postForm.controls['autoPublishing'].dirty || postForm.controls['autoPublishing'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="postForm.controls['autoPublishing'].errors !== null">
                  <div *ngIf="postForm.controls['autoPublishing'].errors.required">
                   {{ 'AUTOPUBLISHINGREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-control-skip-forward"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="postStartPublishing" formControlName="startPublishing" [showTime]="true" hourFormat="24" [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [required]="false" yearRange="1900:2030" [stepHour]="1" [stepMinute]="15" [stepSecond]="0"></p-calendar>
                      <label for="postStartPublishing" class="">{{ 'STARTPUBLISHING' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-control-skip-backward"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="postEndPublishing" formControlName="endPublishing" [showTime]="true" hourFormat="24" [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [required]="false" yearRange="1900:2030" [stepHour]="1" [stepMinute]="15" [stepSecond]="0"></p-calendar>
                      <label for="postEndPublishing" class="">{{ 'ENDPUBLISHING' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-calendar"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="postPublishDate" formControlName="publishDate" [showTime]="true" hourFormat="24" [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [required]="true" yearRange="1900:2030" [stepHour]="1" [stepMinute]="15" [stepSecond]="0"></p-calendar>
                      <label for="postPublishDate" class="req">{{ 'PUBLISHDATE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="postForm.controls['publishDate'].invalid && (postForm.controls['publishDate'].dirty || postForm.controls['publishDate'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="postForm.controls['publishDate'].errors !== null">
                  <div *ngIf="postForm.controls['publishDate'].errors.required">
                   {{ 'PUBLISHDATEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') === -1" >
                  <span class="p-float-label">
                    <textarea id="postMetaTitle" formControlName="metaTitle" [rows]="5" [cols]="30" pInputTextarea [autoResize]="true" [required]="false"></textarea>
                    <label for="postMetaTitle" class="">{{ 'METATITLE' | translate }}</label>
                  </span>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') === -1" >
                  <span class="p-float-label">
                    <textarea id="postMetaDescription" formControlName="metaDescription" [rows]="5" [cols]="30" pInputTextarea [autoResize]="true" [required]="false"></textarea>
                    <label for="postMetaDescription" class="">{{ 'METADESCRIPTION' | translate }}</label>
                  </span>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') === -1" >
                  <span class="p-float-label">
                    <textarea id="postMetaKeywords" formControlName="metaKeywords" [rows]="5" [cols]="30" pInputTextarea [autoResize]="true" [required]="false"></textarea>
                    <label for="postMetaKeywords" class="">{{ 'METAKEYWORDS' | translate }}</label>
                  </span>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_PUBLISHER') === -1" >
                  <label for="postStatus" class="req">{{ 'STATUS' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of postsColumnsService.statusesOptions; let i = index">
                      <p-radioButton inputId="postStatus_{{ i }}" formControlName="status" name="status" [value]="option.value"></p-radioButton>
                      <label for="postStatus_{{ i }}">{{ option.label | translate }}</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="postForm.controls['status'].invalid && (postForm.controls['status'].dirty || postForm.controls['status'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="postForm.controls['status'].errors !== null">
                  <div *ngIf="postForm.controls['status'].errors.required">
                   {{ 'STATUSREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') === -1" >
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-print"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="postTotalPrints" formControlName="totalPrints" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="postTotalPrints" class="">{{ 'TOTALPRINTS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') === -1" >
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-eye"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="postTotalHits" formControlName="totalHits" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="postTotalHits" class="">{{ 'TOTALHITS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') === -1" >
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-comments"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="postTotalComments" formControlName="totalComments" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="postTotalComments" class="">{{ 'TOTALCOMMENTS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') === -1" >
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-star-half-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="postTotalRatings" formControlName="totalRatings" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="postTotalRatings" class="">{{ 'TOTALRATINGS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') === -1" >
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-pie-chart"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="postAverageRatings" formControlName="averageRatings" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [step]="0.1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="postAverageRatings" class="">{{ 'AVERAGERATINGS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') === -1" >
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-thumbs-down"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="postTotalLikes" formControlName="totalLikes" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="postTotalLikes" class="">{{ 'TOTALLIKES' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') === -1" >
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-thumbs-up"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="postTotalDislikes" formControlName="totalDislikes" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="postTotalDislikes" class="">{{ 'TOTALDISLIKES' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') === -1" >
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-bookmark"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="postTotalBookmarks" formControlName="totalBookmarks" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="postTotalBookmarks" class="">{{ 'TOTALBOOKMARKS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="postIsTop" formControlName="isTop" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="postIsTop" class="p-d-inline-block p-ml-2 req">{{ 'ISTOP' | translate }}</label>
                </div>
                <div *ngIf="postForm.controls['isTop'].invalid && (postForm.controls['isTop'].dirty || postForm.controls['isTop'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="postForm.controls['isTop'].errors !== null">
                  <div *ngIf="postForm.controls['isTop'].errors.required">
                   {{ 'ISTOPREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="postIsNew" formControlName="isNew" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="postIsNew" class="p-d-inline-block p-ml-2 req">{{ 'ISNEW' | translate }}</label>
                </div>
                <div *ngIf="postForm.controls['isNew'].invalid && (postForm.controls['isNew'].dirty || postForm.controls['isNew'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="postForm.controls['isNew'].errors !== null">
                  <div *ngIf="postForm.controls['isNew'].errors.required">
                   {{ 'ISNEWREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-list-ol"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="postOrdering" formControlName="ordering" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="postOrdering" class="">{{ 'ORDERING' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKPOSTS' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="post.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!postForm.valid || disableSubmit"> <!--  [disabled]="postForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!postForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

