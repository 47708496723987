<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="user.id === null">{{ 'NEWUSER' | translate }}</span>
            <span *ngIf="user.id !== null">{{ 'EDITUSER' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="userForm" id="userForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="userForm" >
            <input type="hidden" name="id" id="userId" formControlName="id" />
            <div class="p-fluid p-grid">
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'USERA' | translate }}</h2>
              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-id-badge"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="userFirstName" formControlName="firstName" type="text" pInputText [required]="false"> 
                      <label for="userFirstName" class="">{{ 'FIRSTNAME' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-id-badge"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="userLastName" formControlName="lastName" type="text" pInputText [required]="false"> 
                      <label for="userLastName" class="">{{ 'LASTNAME' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <label for="userGender" class="">{{ 'GENDER' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of usersColumnsService.gendersOptions; let i = index">
                      <p-radioButton inputId="userGender_{{ i }}" formControlName="gender" name="gender" [value]="option.value"></p-radioButton>
                      <label for="userGender_{{ i }}">{{ option.label | translate }}</label>
                    </div>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-image"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="userPicture" formControlName="picture" type="text" pInputText [required]="false"> 
                      <label for="userPicture" class="">{{ 'PICTURE' | translate }}</label>
                    </span>
                    <button type="button" pButton pRipple icon="pi pi-folder" styleClass="p-button-info" (click)="openFilerDialog('picture')"></button> 
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-gift"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="userBirthDate" formControlName="birthDate" [showTime]="false" [showIcon]="true" view="date" [yearNavigator]="true" [monthNavigator]="true" [required]="false" yearRange="1900:2030"></p-calendar>
                      <label for="userBirthDate" class="">{{ 'BIRTHDATE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="userProfile" formControlName="profile" type="text" pInputText [required]="false"> 
                      <label for="userProfile" class="">{{ 'PROFILE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-home"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="userAddress" formControlName="address" type="text" pInputText [required]="false"> 
                      <label for="userAddress" class="">{{ 'ADDRESS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-shortcode"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="userZipCode" formControlName="zipCode" type="text" pInputText [required]="false"> 
                      <label for="userZipCode" class="">{{ 'ZIPCODE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-flag"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  (onChange)="changeCountry($event)" inputId="userCountryId" [autoDisplayFirst]="false" formControlName="country" [options]="usersColumnsService.countries" optionValue="id" optionLabel="name" [required]="false" [filter]="true"></p-dropdown>
                      <label for="userCountryId" class="">{{ 'COUNTRY' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-direction"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="userCityId" [autoDisplayFirst]="false" formControlName="city" [options]="usersColumnsService.cities" optionValue="id" optionLabel="name" [required]="false" [filter]="true"></p-dropdown>
                      <label for="userCityId" class="">{{ 'CITY' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-briefcase"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="userCompanyId" [autoDisplayFirst]="false" formControlName="company" [options]="usersColumnsService.companies" optionValue="id" optionLabel="name" [required]="false" [filter]="true"></p-dropdown>
                      <label for="userCompanyId" class="">{{ 'COMPANY' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-rocket"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="userJob" formControlName="job" type="text" pInputText [required]="false"> 
                      <label for="userJob" class="">{{ 'JOB' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-language"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="userLanguageId" [autoDisplayFirst]="false" formControlName="language" [options]="usersColumnsService.languages" optionValue="id" optionLabel="name" [required]="false" [filter]="true"></p-dropdown>
                      <label for="userLanguageId" class="">{{ 'LANGUAGE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'USERB' | translate }}</h2>
              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <label for="userType" class="">{{ 'TYPE' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of usersColumnsService.typesOptions; let i = index">
                      <p-radioButton inputId="userType_{{ i }}" formControlName="type" name="type" [value]="option.value"></p-radioButton>
                      <label for="userType_{{ i }}">{{ option.label | translate }}</label>
                    </div>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_SUPER_ADMIN') === -1" >
                    <p-toggleButton inputId="userEnabled" formControlName="enabled" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="userEnabled" class="p-d-inline-block p-ml-2 req">{{ 'ENABLED' | translate }}</label>
                </div>
                <div *ngIf="userForm.controls['enabled'].invalid && (userForm.controls['enabled'].dirty || userForm.controls['enabled'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="userForm.controls['enabled'].errors !== null">
                  <div *ngIf="userForm.controls['enabled'].errors.required">
                   {{ 'ENABLEDREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-user"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="userUsername" formControlName="username" type="text" pInputText [required]="true"> 
                      <label for="userUsername" class="req">{{ 'USERNAME' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="userForm.controls['username'].invalid && (userForm.controls['username'].dirty || userForm.controls['username'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="userForm.controls['username'].errors !== null">
                  <div *ngIf="userForm.controls['username'].errors.required">
                   {{ 'USERNAMEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-lock"></i>
                    </span>
                    <span class="p-float-label">
                      <p-password id="userPassword" formControlName="password" [toggleMask]="true" pInputText [required]="true"> 
                        <ng-template pTemplate="header">
                          <h6>Pick a password</h6>
                        </ng-template>
                        <ng-template pTemplate="footer">
                          <p class="p-mt-2">Suggestions</p>
                          <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                            <li>At least one lowercase</li>
                            <li>At least one uppercase</li>
                            <li>At least one numeric</li>
                            <li>Minimum 8 characters</li>
                          </ul>
                        </ng-template>
                      </p-password>
                      <label for="userPassword" class="req">{{ 'PASSWORD' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="userForm.controls['password'].invalid && (userForm.controls['password'].dirty || userForm.controls['password'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="userForm.controls['password'].errors !== null">
                  <div *ngIf="userForm.controls['password'].errors.required">
                   {{ 'PASSWORDREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-phone"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="userPhone" formControlName="phone" type="text" pInputText [required]="false"> 
                      <label for="userPhone" class="">{{ 'PHONE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-email"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="userEmail" formControlName="email" type="text" pInputText [required]="true"> 
                      <label for="userEmail" class="req">{{ 'EMAIL' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="userForm.controls['email'].invalid && (userForm.controls['email'].dirty || userForm.controls['email'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="userForm.controls['email'].errors !== null">
                  <div *ngIf="userForm.controls['email'].errors.required">
                   {{ 'EMAILREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="userSalt" formControlName="salt" type="text" pInputText [required]="false"> 
                      <label for="userSalt" class="">{{ 'SALT' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-facebook"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="userFacebookKey" formControlName="facebookKey" type="text" pInputText [required]="false"> 
                      <label for="userFacebookKey" class="">{{ 'FACEBOOKKEY' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'USERD' | translate }}</h2>
              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_SUPER_ADMIN') === -1" >
                    <p-toggleButton inputId="userLocked" formControlName="locked" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="userLocked" class="p-d-inline-block p-ml-2 req">{{ 'LOCKED' | translate }}</label>
                </div>
                <div *ngIf="userForm.controls['locked'].invalid && (userForm.controls['locked'].dirty || userForm.controls['locked'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="userForm.controls['locked'].errors !== null">
                  <div *ngIf="userForm.controls['locked'].errors.required">
                   {{ 'LOCKEDREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_SUPER_ADMIN') === -1" >
                    <p-toggleButton inputId="userExpired" formControlName="expired" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="userExpired" class="p-d-inline-block p-ml-2 req">{{ 'EXPIRED' | translate }}</label>
                </div>
                <div *ngIf="userForm.controls['expired'].invalid && (userForm.controls['expired'].dirty || userForm.controls['expired'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="userForm.controls['expired'].errors !== null">
                  <div *ngIf="userForm.controls['expired'].errors.required">
                   {{ 'EXPIREDREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'USERZ' | translate }}</h2>
              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <label for="userRoles" class="req">{{ 'ROLES' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of usersColumnsService.rolesOptions; let i = index">
                      <p-checkbox name="roles" [formControl]="$any(userForm).controls['roles']" [value]="option.value" label="{{ option.label | translate }}"></p-checkbox>
                    </div>
                  </div>
                </div>
                <div *ngIf="userForm.controls['roles'].invalid && (userForm.controls['roles'].dirty || userForm.controls['roles'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="userForm.controls['roles'].errors !== null">
                  <div *ngIf="userForm.controls['roles'].errors.required">
                   {{ 'ROLESREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKUSERS' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="user.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!userForm.valid || disableSubmit"> <!--  [disabled]="userForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!userForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

