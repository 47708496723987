import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { ShoppingListRecipe, ShoppingListRecipesResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ShoppingListRecipesDataService {

  private uri: string = `${environment.apiUrl}shopping-list-recipes`;
  private cacheKey: string = 'shoppingListRecipes.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getShoppingListRecipe(id: number): Observable<ShoppingListRecipe|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getShoppingListRecipes(parameters: {[param: string]: string | string[]}): Observable<ShoppingListRecipesResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addShoppingListRecipe(data: ShoppingListRecipe): Observable<ShoppingListRecipe|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addShoppingListRecipes(data: ShoppingListRecipe[]): Observable<ShoppingListRecipe[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updateShoppingListRecipe(data: ShoppingListRecipe): Observable<ShoppingListRecipe|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updateShoppingListRecipes(data: ShoppingListRecipe[]): Observable<ShoppingListRecipe[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchShoppingListRecipe(data: ShoppingListRecipe): Observable<ShoppingListRecipe|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchShoppingListRecipes(data: ShoppingListRecipe[]): Observable<ShoppingListRecipe[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deleteShoppingListRecipe(data: ShoppingListRecipe): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deleteShoppingListRecipes(data: ShoppingListRecipe[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((shoppingListRecipe) => {
      ids.push(shoppingListRecipe.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
