import { Injectable } from '@angular/core';
import { HttpXhrBackend, HttpResponse, HttpHeaders, HttpClient, HttpClientJsonpModule, HttpHandler } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Credentials } from '../../MumEat/interfaces/credentials.interface';
@Injectable({ providedIn: 'root' })
export class AuthService {

  public static readonly ATUH_STORAGE_KEY = 'auth';

  public token: string|null = null;

  constructor(
    private http: HttpClient,
    public router: Router
  ) {

    let currentUser: any = null;
    let jsonAuth = localStorage.getItem(AuthService.ATUH_STORAGE_KEY);
    if (jsonAuth !== null) {
      const data = JSON.parse(jsonAuth);
      this.token = data.token;
    } else {
      this.token = null;
    }
  }

  login(credentials: Credentials): Observable<any> {
    return this.http.post(`${environment.apiUrl}login`, credentials);
  }

  public redirectToLogin(path: string, queryParams: any): void {
    this.router.navigate([path], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  logout(): void {
    this.token = null;
    localStorage.removeItem(AuthService.ATUH_STORAGE_KEY);
  }

  private handelError(error: Response): Observable<never> {
    return Observable.throw(error.json() || 'server error');
  }

  forgotPassword(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}forgot-password`, data);
  }

  resetPassword(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}reset-password`, data);
  }

}
