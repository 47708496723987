import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig, SelectItem, MenuItem } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { CommonService } from '../../../services/common/common.service';
import { FilerFile } from '../../interfaces/filer-file.interface';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { FilerService } from '../../../services/filer/filer.service';
import { ToolsService } from '../../../services/tools/tools.service';

@Component({
  selector: 'app-filer',
  templateUrl: './filer.component.html',
  styleUrls: ['./filer.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService, DynamicDialogRef, DynamicDialogConfig],
})
export class FilerComponent implements OnInit, OnDestroy {

  public children: FilerFile[] = [];
  public isLoading: boolean = false;
  public path: string = '.';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;

  public typeFilter: string = '';

  public sortOptions: SelectItem[] = [];
  public sortOrder: number = 0;
  public sortField: string = '';
  public sortKey: string = '';

  public uploadUrl: string = '';
  public uploadFieldName: string = 'file';
  public multiple: boolean = false;
  public maxFileSize = 100 * 1024 * 1024;
  public accept: string = 'image/*';
  public mimeTypes: string[] = [
    'image/*',
    'video/*',
    'audio/*',
    /*
    'application/pdf',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    */
    'text/plain'
  ];
  public uploadedFiles: any[] = [];
  public selectedItems: string[] = [];

  public copiedItems: string[] = [];
  public cuttedItems: string[] = [];
  public fromPath: string = '';

  public displayNewDirDialog: boolean = false;
  public newDir: string = '';

  public displayRenameDialog: boolean = false;
  public isEnabled: boolean = false;
  public oldName: string = '';
  public newName: string = '';

  public displayNewFileDialog: boolean = false;
  public newFile: string = '';
  public fileContent: string = '';

  public displayEditFileDialog: boolean = false;
  public editFileName: string = '';
  public editFileContent: string = '';

  public preSelectedPath: string = '';

  public selectAll: boolean = false;
  public list = []
  constructor(
    private translate: TranslateService,
    private common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private filerService: FilerService,
  ) {
    this.uploadUrl = `${environment.apiUrl}filer/upload`;
    this.accept = this.mimeTypes.join(',');
  }

  onSortChange(event: any) {
      let value = event.value;
      if (value.indexOf('!') === 0) {
          this.sortOrder = -1;
          this.sortField = value.substring(1, value.length);
      } else {
          this.sortOrder = 1;
          this.sortField = value;
      }
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'FilerComponent');
    this.route.params.subscribe((params: any) => {
      console.log('params', params);
      console.log('data', this.dynamicDialogConfig.data);
      if (this.dynamicDialogConfig.data && this.dynamicDialogConfig.data.path === 'recipes') {
        this.isEnabled = true;
      }
      if (this.dynamicDialogConfig.data && this.dynamicDialogConfig.data.url !== 'undefined') {
        this.preSelectedPath = this.dynamicDialogConfig.data.url;
      }
      if (this.dynamicDialogConfig.data && this.dynamicDialogConfig.data.filter !== 'undefined') {
        this.typeFilter = this.dynamicDialogConfig.data.filter;
      }
      if (typeof params['path'] !== 'undefined') {4
        this.path = params['path'];
        this.getChildren();
      } else if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.path !== 'undefined') {
        this.path = this.dynamicDialogConfig.data.path;
        this.getChildren();
      } else {
        this.path = '';
        this.getChildren();
      }
    });
    this.sortOptions = [
      {label: 'File Name A to Z', value: 'filename'},
      {label: 'File Name Z to A', value: '!filename'},
      {label: 'File Size High to Low', value: '!size'},
      {label: 'File Size Low to High', value: 'size'},
    ];

    this.primengConfig.ripple = true;
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'FilerComponent');
  }

  getChildren() {
    this.selectAll = false;
    this.isLoading = true;
    this.children = [];
    this.selectedItems = [];
    this.filerService.children({path: this.path}).subscribe((response: any) => {
      this.isLoading = false;
      if (typeof response.error !== 'undefined') {
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: response.error});
      } else if (typeof response.length !== 'undefined') {
        if (response.length > 0) {
            this.children = this.formatFiles(response);
        }
        console.log('children', this.children);
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.children.length + ' items'});
      }
    });
  }

  formatFiles(files: FilerFile[]): FilerFile[] {
    const newFiles: FilerFile[] = [];
    const timeFormat = 'YYYY-DD-MM HH:mm:ss'; this.translate.instant('DATETIMEFORMAT');
    let i = 0;
    files.map((file: FilerFile) => {
      file.index = i++;
      file.selected = (this.preSelectedPath === file.webPath);
      file.humanSize = ToolsService.humanFileSize(file.size);
      file.humanATime = moment(file.aTime, 'X').format(timeFormat);
      file.humanCTime = moment(file.cTime, 'X').format(timeFormat);
      file.humanMTime = moment(file.mTime, 'X').format(timeFormat);
      if (file.isDir) {
        file.size = 0;
        file.childrenCount = file.directoriesCount + file.filesCount;
      }
      file.isText = false;
      if (file.mimeType.startsWith('text/')) {
        file.isText = true;
      }
      file.isImage = false;
      if (file.mimeType.startsWith('image/')) {
        file.isImage = true;
      }
      if (file.isDir || this.typeFilter === '' || this.typeFilter === null || this.typeFilter === undefined || file.mimeType.startsWith(this.typeFilter)) {
        newFiles.push(file);
      }
    });
    return newFiles;
  }

  openChild(dir: FilerFile) {
    if (!dir.isDir) {
        return;
    }
    this.path += '/' + dir.basename;
    this.getChildren();
  }

  openParent() {
    if (this.path.indexOf('/') === -1) {
        return;
    }
    if (this.path[0] === '/') {
      this.path = this.path.substring(1);
    }
    const dirs = this.path.split('/');
    dirs.pop()
    this.path = '/' + dirs.join('/');
    this.getChildren();
  }

  download(file: FilerFile): void {
    console.log('download', file.url);
    window.open(file.url, '_blank');
  }

  onUpload(event: any) {
    console.log('onUpload', event);
    for (let file of event.files) {
        this.uploadedFiles.push(file);
    }
    this.messageService.add({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
    this.getChildren();
  }

  onBeforeUpload(event: any) {
    console.log('onBeforeUpload', event);
    if (typeof event.formData !== 'undefined') {
      event.formData.append('path', this.path);
    }
  }

  onError(event: any) {
    console.log('onError', event);
    this.messageService.add({severity: 'error', summary: 'Error', detail: 'File Upload failed'});
  }

  rename(item: FilerFile) {
    this.oldName = item.filename;
    this.newName = item.filename;
    this.displayRenameDialog = true;
  }

  confirmRename() {
    if (!this.newName) {
      return;
    }
    this.displayRenameDialog = false;
    const from = this.path + '/' + this.oldName;
    const to = this.path + '/' + this.newName;
    this.filerService.rename({from, to}).subscribe((result) => {
      console.log('onError', result);
      if (typeof result.message !== 'undefined') {
        this.messageService.add({severity: 'info', summary: 'Info', detail: 'Item Renamed'});
        this.getChildren();
      } else {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Item Not Renamed'});
      }
    });
  }

  delete(item: FilerFile) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete "' + item.filename + '"?',
      accept: () => {
        this.remove(item);
      }
    });
  }

  remove(item: FilerFile) {
    const filePath = this.path + '/' + item.filename;
    this.filerService.remove([filePath]).subscribe((result) => {
      console.log('onError', result);
      if (typeof result.message !== 'undefined') {
        this.messageService.add({severity: 'info', summary: 'Info', detail: 'Item Removed'});
        this.getChildren();
      } else {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Item Not Removed'});
      }
    });
  }

  editFile(item: FilerFile) {
    this.editFileName = item.filename;
    this.editFileContent = '';
    this.displayEditFileDialog = true;
    const filePath = this.path + '/' + item.filename;
    this.filerService.content({file: filePath}).subscribe((response: any) => {
      if (typeof response.content !== 'undefined') {
        this.editFileContent = response.content;
      }
    });
  }

  save() {
    this.displayEditFileDialog = false;
    const filePath = this.path + '/' + this.editFileName;
    this.filerService.dump({file: filePath, content: this.editFileContent}).subscribe((result) => {
      console.log('save', result);
      if (typeof result.message !== 'undefined') {
        this.messageService.add({severity: 'info', summary: 'Info', detail: 'File Updated'});
        this.getChildren();
      } else {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'File Not Updated'});
      }
    });
  }

  createNewFile() {
    this.newFile = '';
    this.fileContent = '';
    this.displayNewFileDialog = true;
  }

  dump() {
    if (!this.newFile) {
      return;
    }
    this.displayNewFileDialog = false;
    const filePath = this.path + '/' + this.newFile;
    this.filerService.dump({file: filePath, content: this.fileContent}).subscribe((result) => {
      console.log('dump', result);
      if (typeof result.message !== 'undefined') {
        this.messageService.add({severity: 'info', summary: 'Info', detail: 'File Created'});
        this.getChildren();
      } else {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'File Not Created'});
      }
    });
  }


  createDirecory() {
    this.newDir = '';
    this.displayNewDirDialog = true;
  }

  mkdir() {
    if (!this.newDir) {
      return;
    }
    this.displayNewDirDialog = false;
    const dirPath = this.path + '/' + this.newDir;
    this.filerService.mkdir({path: dirPath}).subscribe((result) => {
      console.log('mkdir', result);
      if (typeof result.message !== 'undefined') {
        this.messageService.add({severity: 'info', summary: 'Info', detail: 'Directory Created'});
        this.getChildren();
      } else {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Directory Not Created'});
      }
    });
  }

  cutSelected() {
    this.cuttedItems = Object.assign([], this.selectedItems);
    this.copiedItems = [];
    this.selectedItems = [];
    this.fromPath = this.path;
  }

  copySelected() {
    this.cuttedItems = [];
    this.copiedItems = Object.assign([], this.selectedItems);
    this.selectedItems = [];
    this.fromPath = this.path;
  }

  pasteCutted() {
    if (this.cuttedItems.length > 0) {
      this.messageService.add({severity: 'info', summary: 'Moving', detail: 'Moving ' + this.cuttedItems.length + ' items.'});
      let proceed = 0;
      const total = this.cuttedItems.length;
      this.cuttedItems.map((file: string) => {
        const from = this.fromPath + '/' + file;
        const to = this.path + '/' + file;
        console.log('rename', from, to);
        this.filerService.rename({from, to}).subscribe((response: any) => {
          console.log('rename response', response);
          proceed++;
          if (proceed === total) {
            this.getChildren();
          }
        });
      });
      this.cuttedItems = [];
    }
  }

  pasteCopied() {
    if (this.copiedItems.length > 0) {
      this.messageService.add({severity: 'info', summary: 'Coping', detail: 'Coping ' + this.copiedItems.length + ' items.'});
      let proceed = 0;
      const total = this.copiedItems.length;
      this.copiedItems.map((file: string) => {
        const from = this.fromPath + '/' + file;
        const to = this.path + '/' + file;
        console.log('copy', from, to);
        this.filerService.copy({from, to}).subscribe((response: any) => {
          console.log('copy response', response);
          proceed++;
          if (proceed === total) {
            this.getChildren();
          }
        });
      });
      this.copiedItems = [];
    }
  }

  deleteSelected() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete the ' + this.selectedItems.length + ' selected items?',
      accept: () => {
        this.removeSelectedItems();
      }
    });
  }

  removeSelectedItems() {
    const paths: string[] = [];
    this.selectedItems.map((item) => {
      paths.push(this.path + '/' + item);
    });
    console.log('paths', paths);
    this.filerService.remove(paths).subscribe((result) => {
      this.selectedItems = [];
      console.log('archiveSelected', result);
      if (typeof result.message !== 'undefined') {
        this.messageService.add({severity: 'info', summary: 'Info', detail: 'Items Removed'});
        this.getChildren();
      } else {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Items Not Removed'});
      }
    });
  }

  archiveSelected() {
    const paths: string[] = [];
    this.selectedItems.map((item) => {
      paths.push(this.path + '/' + item);
    });
    console.log('paths', paths);
    this.filerService.archive(paths).subscribe((result) => {
      this.selectedItems = [];
      console.log('archiveSelected', result);
      if (typeof result.message !== 'undefined') {
        this.messageService.add({severity: 'info', summary: 'Info', detail: 'Archive Created'});
        this.getChildren();
      } else {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Archive Not Created'});
      }
    });
  }

  toggleAll() {
    if (this.selectAll) {
      this.children.map((item: FilerFile) => {
        this.selectedItems.push(item.filename);
        item.selected = true;
      });
    } else {
      this.selectedItems = [];
      this.children.map((item: FilerFile) => {
        item.selected = false;
      });
    }
    console.log('selectedItems', this.selectedItems);
  }

  toggle(item: FilerFile) {
    const index = this.selectedItems.indexOf(item.filename);
    if (index === -1) {
      this.selectedItems.push(item.filename);
      item.selected = true;
    } else {
      this.selectedItems.splice(index, 1);
      item.selected = false;
    }
    console.log('selectedItems', this.selectedItems);
  }

  selectChild(item: FilerFile) {
    console.log('item', item)
    if (typeof this.dynamicDialogRef.close !== 'undefined') {
      const response = {selected: item};
      console.log('response', response, );
      this.dynamicDialogRef.close(response);
    } else {
      this.download(item);
    }
  }

  chooseImage() {
    
    // const index = this.selectedItems.indexOf(item.filename);
    // if (index === -1) {
    //   this.selectedItems.push(item.filename);
    //   item.selected = true;
    // } else {
    //   this.selectedItems.splice(index, 1);
    //   item.selected = false;
    // }
    const result: any[] = []

    for (let item of this.selectedItems) {
      
      item = '/uploads/recipes/' + item      
      console.log('response', item);
      result.push({url :item})
    }
   
    console.log('selectedItems', result);
    this.dynamicDialogRef.close(result);
  }
}

@Component({
  selector: 'app-filer',
  templateUrl: './filer.component.html',
  styleUrls: ['./filer.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogFilerComponent extends FilerComponent {
}

