
<div class="animated fadeIn">
    <div class="d-flex align-items-center">
        <h1>{{ 'CHANGEPASSWORD' | translate }}</h1>
        <div [hidden]="!isLoading" class="spinner-border text-info  ml-auto" role="status">
          <span class="sr-only">{{ 'LOADING'' | translate }}...</span>
        </div>
    </div>
    <p-toast></p-toast>

    <div class="card">
    </div>
</div>