<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="recipe.id === null">{{ 'NEWRECIPE' | translate }}</span>
            <span *ngIf="recipe.id !== null">{{ 'EDITRECIPE' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="recipeForm" id="recipeForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="recipeForm" >
            <input type="hidden" name="id" id="recipeId" formControlName="id" />
            <div class="p-fluid p-grid">
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'RECIPEA' | translate }}</h2>
              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-tag"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="recipeName" formControlName="name" type="text" pInputText [required]="true"> 
                      <label for="recipeName" class="req">{{ 'NAME' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="recipeForm.controls['name'].invalid && (recipeForm.controls['name'].dirty || recipeForm.controls['name'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="recipeForm.controls['name'].errors !== null">
                  <div *ngIf="recipeForm.controls['name'].errors.required">
                   {{ 'NAMEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="recipeNameAr" formControlName="nameAr" type="text" pInputText [required]="false"> 
                      <label for="recipeNameAr" class="">{{ 'NAMEAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="recipeNameFr" formControlName="nameFr" type="text" pInputText [required]="false"> 
                      <label for="recipeNameFr" class="">{{ 'NAMEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <!-- <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipeDifficultyLevel" formControlName="difficultyLevel" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="recipeDifficultyLevel" class="">{{ 'DIFFICULTYLEVEL' | translate }}</label>
                    </span>
                  </div>
                </div> -->
              <div class="p-field p-col-12">
                <label for="recipeDifficulty_" class="req">{{ 'DIFFICULTYLEVEL' | translate }}</label>
                <div class="p-formgroup-inline">
                  <div class="p-field-checkbox" *ngFor="let option of recipesColumnsService.difficultyLevelOptions; let i = index">
                    <p-radioButton inputId="recipeDifficulty_{{ i }}" formControlName="difficultyLevel" name="difficultyLevel" [value]="option.value">
                    </p-radioButton>
                    <label for="recipeDifficulty__{{ i }}">{{ option.label | translate }}</label>
                  </div>
                </div>
              </div>


              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-alarm-clock"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipePreparationTime" formControlName="preparationTime" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="recipePreparationTime" class="">{{ 'PREPARATIONTIME' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-alarm-clock"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipeCookingTime" formControlName="cookingTime" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="recipeCookingTime" class="">{{ 'COOKINGTIME' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipeServingSize" formControlName="servingSize" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="recipeServingSize" class="">{{ 'SERVINGSIZE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-list-ol"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipeOrdering" formControlName="ordering" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="recipeOrdering" class="">{{ 'ORDERING' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <!-- <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-image"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="recipePicture" formControlName="picture" type="text" multiple pInputText [required]="false"> 
                      <label for="recipePicture" class="">{{ 'PICTURE' | translate }}</label>
                    </span>
                    <button type="button" pButton pRipple icon="pi pi-folder" styleClass="p-button-info" (click)="openFilerDialog('picture')"></button> 
                  </div>
                </div>

              </div> -->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-info-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="recipeDescription" formControlName="description" type="text" pInputText [required]="true"> 
                      <label for="recipeDescription" class="req">{{ 'DESCRIPTION' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="recipeForm.controls['description'].invalid && (recipeForm.controls['description'].dirty || recipeForm.controls['description'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="recipeForm.controls['description'].errors !== null">
                  <div *ngIf="recipeForm.controls['description'].errors.required">
                   {{ 'DESCRIPTIONREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-align-justify"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="recipeDescriptionAr" formControlName="descriptionAr" type="text" pInputText [required]="false"> 
                      <label for="recipeDescriptionAr" class="">{{ 'DESCRIPTIONAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-align-justify"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="recipeDescriptionFr" formControlName="descriptionFr" type="text" pInputText [required]="false"> 
                      <label for="recipeDescriptionFr" class="">{{ 'DESCRIPTIONFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="recipeEnableStreaming" formControlName="enableStreaming" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="recipeEnableStreaming" class="p-d-inline-block p-ml-2 req">{{ 'ENABLESTREAMING' | translate }}</label>
                </div>
                <div *ngIf="recipeForm.controls['enableStreaming'].invalid && (recipeForm.controls['enableStreaming'].dirty || recipeForm.controls['enableStreaming'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="recipeForm.controls['enableStreaming'].errors !== null">
                  <div *ngIf="recipeForm.controls['enableStreaming'].errors.required">
                   {{ 'ENABLESTREAMINGREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-image"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="recipeImage" formControlName="image" type="text"  pInputText [required]="false"> 
                      <label for="recipeImage" class="">{{ 'IMAGE' | translate }}</label>
                    </span>
                    <button type="button" pButton pRipple icon="pi pi-folder" styleClass="p-button-info" (click)="openFilerMultiDialog('image')"></button> 
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-folder"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="recipeFile" formControlName="file" type="text" pInputText [required]="false"> 
                      <label for="recipeFile" class="">{{ 'VIDEO' | translate }}</label>
                    </span>
                    <button type="button" pButton pRipple icon="pi pi-folder" styleClass="p-button-info" (click)="openFilerDialog('file')"></button> 
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-paperclip"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipeFileSize" formControlName="fileSize" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="recipeFileSize" class="">{{ 'FILESIZE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-text"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="recipeWatermarkText" formControlName="watermarkText" type="text" pInputText [required]="false"> 
                      <label for="recipeWatermarkText" class="">{{ 'WATERMARKTEXT' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-timer"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipeDuration" formControlName="duration" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="recipeDuration" class="">{{ 'DURATION' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'RECIPEB' | translate }}</h2>
              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="recipeIsTop" formControlName="isTop" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="recipeIsTop" class="p-d-inline-block p-ml-2 req">{{ 'ISTOP' | translate }}</label>
                </div>
                <div *ngIf="recipeForm.controls['isTop'].invalid && (recipeForm.controls['isTop'].dirty || recipeForm.controls['isTop'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="recipeForm.controls['isTop'].errors !== null">
                  <div *ngIf="recipeForm.controls['isTop'].errors.required">
                   {{ 'ISTOPREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="recipeIsNew" formControlName="isNew" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="recipeIsNew" class="p-d-inline-block p-ml-2 req">{{ 'ISNEW' | translate }}</label>
                </div>
                <div *ngIf="recipeForm.controls['isNew'].invalid && (recipeForm.controls['isNew'].dirty || recipeForm.controls['isNew'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="recipeForm.controls['isNew'].errors !== null">
                  <div *ngIf="recipeForm.controls['isNew'].errors.required">
                   {{ 'ISNEWREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_PUBLISHER') === -1" >
                  <label for="recipeStatus" class="req">{{ 'STATUS' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of recipesColumnsService.statusesOptions; let i = index">
                      <p-radioButton inputId="recipeStatus_{{ i }}" formControlName="status" name="status" [value]="option.value"></p-radioButton>
                      <label for="recipeStatus_{{ i }}">{{ option.label | translate }}</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="recipeForm.controls['status'].invalid && (recipeForm.controls['status'].dirty || recipeForm.controls['status'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="recipeForm.controls['status'].errors !== null">
                  <div *ngIf="recipeForm.controls['status'].errors.required">
                   {{ 'STATUSREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="recipeAutoPublishing" formControlName="autoPublishing" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="recipeAutoPublishing" class="p-d-inline-block p-ml-2 req">{{ 'AUTOPUBLISHING' | translate }}</label>
                </div>
                <div *ngIf="recipeForm.controls['autoPublishing'].invalid && (recipeForm.controls['autoPublishing'].dirty || recipeForm.controls['autoPublishing'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="recipeForm.controls['autoPublishing'].errors !== null">
                  <div *ngIf="recipeForm.controls['autoPublishing'].errors.required">
                   {{ 'AUTOPUBLISHINGREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-control-skip-forward"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="recipeStartPublishing" formControlName="startPublishing" [showTime]="true" hourFormat="24" [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [required]="false" yearRange="1900:2030" [stepHour]="1" [stepMinute]="15" [stepSecond]="0"></p-calendar>
                      <label for="recipeStartPublishing" class="">{{ 'STARTPUBLISHING' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-control-skip-backward"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="recipeEndPublishing" formControlName="endPublishing" [showTime]="true" hourFormat="24" [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [required]="false" yearRange="1900:2030" [stepHour]="1" [stepMinute]="15" [stepSecond]="0"></p-calendar>
                      <label for="recipeEndPublishing" class="">{{ 'ENDPUBLISHING' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'RECIPEC' | translate }}</h2>
              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <!--<div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-search"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipeTotalPreviewed" formControlName="totalPreviewed" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="recipeTotalPreviewed" class="">{{ 'TOTALPREVIEWED' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div>
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-download"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipeTotalDownloads" formControlName="totalDownloads" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="recipeTotalDownloads" class="">{{ 'TOTALDOWNLOADS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-eye"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipeTotalHits" formControlName="totalHits" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="recipeTotalHits" class="">{{ 'TOTALHITS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div>
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-comments"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipeTotalComments" formControlName="totalComments" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="recipeTotalComments" class="">{{ 'TOTALCOMMENTS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-star-half-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipeTotalRatings" formControlName="totalRatings" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="recipeTotalRatings" class="">{{ 'TOTALRATINGS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div>
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-pie-chart"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipeAverageRatings" formControlName="averageRatings" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="recipeAverageRatings" class="">{{ 'AVERAGERATINGS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-thumbs-down"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipeTotalLikes" formControlName="totalLikes" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="recipeTotalLikes" class="">{{ 'TOTALLIKES' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div>
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-thumbs-up"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipeTotalDislikes" formControlName="totalDislikes" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="recipeTotalDislikes" class="">{{ 'TOTALDISLIKES' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-bookmark"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="recipeTotalBookmarks" formControlName="totalBookmarks" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="recipeTotalBookmarks" class="">{{ 'TOTALBOOKMARKS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div>
            </div>-->
            <!-- <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'RECIPED' | translate }}</h2>
              </div>
            </div> -->
            <!-- <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="recipeRemoteSource" formControlName="remoteSource" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="recipeRemoteSource" class="p-d-inline-block p-ml-2 req">{{ 'REMOTESOURCE' | translate }}</label>
                </div>
                <div *ngIf="recipeForm.controls['remoteSource'].invalid && (recipeForm.controls['remoteSource'].dirty || recipeForm.controls['remoteSource'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="recipeForm.controls['remoteSource'].errors !== null">
                  <div *ngIf="recipeForm.controls['remoteSource'].errors.required">
                   {{ 'REMOTESOURCEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div>
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <span class="p-float-label">
                    <textarea id="recipeUrl" formControlName="url" [rows]="5" [cols]="30" pInputTextarea [autoResize]="true" [required]="false"></textarea>
                    <label for="recipeUrl" class="">{{ 'URL' | translate }}</label>
                  </span>
                </div>

              </div>
            </div> -->
            <!-- <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <span class="p-float-label">
                    <textarea id="recipeAlternativeUrl" formControlName="alternativeUrl" [rows]="5" [cols]="30" pInputTextarea [autoResize]="true" [required]="false"></textarea>
                    <label for="recipeAlternativeUrl" class="">{{ 'ALTERNATIVEURL' | translate }}</label>
                  </span>
                </div>

              </div>
            </div> -->
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'RECIPEZ' | translate }}</h2>
              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-folder"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="recipeCategoryId" [autoDisplayFirst]="false" formControlName="category" [options]="recipesColumnsService.categories" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="recipeCategoryId" class="req">{{ 'CATEGORY' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="recipeForm.controls['category'].invalid && (recipeForm.controls['category'].dirty || recipeForm.controls['category'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="recipeForm.controls['category'].errors !== null">
                  <div *ngIf="recipeForm.controls['category'].errors.required">
                   {{ 'CATEGORYREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <!-- <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-folder"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="recipeSubCategoryId" [autoDisplayFirst]="false" formControlName="subCategory" [options]="recipesColumnsService.subCategories" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="recipeSubCategoryId" class="req">{{ 'SUBCATEGORY' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="recipeForm.controls['subCategory'].invalid && (recipeForm.controls['subCategory'].dirty || recipeForm.controls['subCategory'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="recipeForm.controls['subCategory'].errors !== null">
                  <div *ngIf="recipeForm.controls['subCategory'].errors.required">
                   {{ 'SUBCATEGORYREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div> -->
              <!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <!-- <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="recipeSlug" formControlName="slug" type="text" pInputText [required]="true"> 
                      <label for="recipeSlug" class="req">{{ 'SLUG' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="recipeForm.controls['slug'].invalid && (recipeForm.controls['slug'].dirty || recipeForm.controls['slug'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="recipeForm.controls['slug'].errors !== null">
                  <div *ngIf="recipeForm.controls['slug'].errors.required">
                   {{ 'SLUGREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div>
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="recipeSlugAr" formControlName="slugAr" type="text" pInputText [required]="false"> 
                      <label for="recipeSlugAr" class="">{{ 'SLUGAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="recipeSlugFr" formControlName="slugFr" type="text" pInputText [required]="false"> 
                      <label for="recipeSlugFr" class="">{{ 'SLUGFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div>
            </div> -->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKRECIPES' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="recipe.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!recipeForm.valid || disableSubmit"> <!--  [disabled]="recipeForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!recipeForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

