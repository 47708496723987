<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="quizType.id === null">{{ 'NEWQUIZTYPE' | translate }}</span>
            <span *ngIf="quizType.id !== null">{{ 'EDITQUIZTYPE' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="quizTypeForm" id="quizTypeForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="quizTypeForm" >
            <input type="hidden" name="id" id="quizTypeId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-tag"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizTypeName" formControlName="name" type="text" pInputText [required]="true"> 
                      <label for="quizTypeName" class="req">{{ 'NAME' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="quizTypeForm.controls['name'].invalid && (quizTypeForm.controls['name'].dirty || quizTypeForm.controls['name'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizTypeForm.controls['name'].errors !== null">
                  <div *ngIf="quizTypeForm.controls['name'].errors.required">
                   {{ 'NAMEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizTypeNameAr" formControlName="nameAr" type="text" pInputText [required]="false"> 
                      <label for="quizTypeNameAr" class="">{{ 'NAMEAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizTypeNameFr" formControlName="nameFr" type="text" pInputText [required]="false"> 
                      <label for="quizTypeNameFr" class="">{{ 'NAMEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizTypeSlug" formControlName="slug" type="text" pInputText [required]="true"> 
                      <label for="quizTypeSlug" class="req">{{ 'SLUG' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="quizTypeForm.controls['slug'].invalid && (quizTypeForm.controls['slug'].dirty || quizTypeForm.controls['slug'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizTypeForm.controls['slug'].errors !== null">
                  <div *ngIf="quizTypeForm.controls['slug'].errors.required">
                   {{ 'SLUGREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizTypeSlugAr" formControlName="slugAr" type="text" pInputText [required]="false"> 
                      <label for="quizTypeSlugAr" class="">{{ 'SLUGAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizTypeSlugFr" formControlName="slugFr" type="text" pInputText [required]="false"> 
                      <label for="quizTypeSlugFr" class="">{{ 'SLUGFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-alarm-clock"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="quizTypeMaxQuestionTime" formControlName="maxQuestionTime" [step]="1" [showButtons]="true" [required]="true"></p-inputNumber>
                      <label for="quizTypeMaxQuestionTime" class="req">{{ 'MAXQUESTIONTIME' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="quizTypeForm.controls['maxQuestionTime'].invalid && (quizTypeForm.controls['maxQuestionTime'].dirty || quizTypeForm.controls['maxQuestionTime'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizTypeForm.controls['maxQuestionTime'].errors !== null">
                  <div *ngIf="quizTypeForm.controls['maxQuestionTime'].errors.required">
                   {{ 'MAXQUESTIONTIMEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-help"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="quizTypeMaxQuestions" formControlName="maxQuestions" [step]="1" [showButtons]="true" [required]="true"></p-inputNumber>
                      <label for="quizTypeMaxQuestions" class="req">{{ 'MAXQUESTIONS' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="quizTypeForm.controls['maxQuestions'].invalid && (quizTypeForm.controls['maxQuestions'].dirty || quizTypeForm.controls['maxQuestions'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizTypeForm.controls['maxQuestions'].errors !== null">
                  <div *ngIf="quizTypeForm.controls['maxQuestions'].errors.required">
                   {{ 'MAXQUESTIONSREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-help"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="quizTypeQuestionPoints" formControlName="questionPoints" [step]="1" [showButtons]="true" [required]="true"></p-inputNumber>
                      <label for="quizTypeQuestionPoints" class="req">{{ 'QUESTIONPOINTS' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="quizTypeForm.controls['questionPoints'].invalid && (quizTypeForm.controls['questionPoints'].dirty || quizTypeForm.controls['questionPoints'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizTypeForm.controls['questionPoints'].errors !== null">
                  <div *ngIf="quizTypeForm.controls['questionPoints'].errors.required">
                   {{ 'QUESTIONPOINTSREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-info-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizTypeDescription" formControlName="description" type="text" pInputText [required]="false"> 
                      <label for="quizTypeDescription" class="">{{ 'DESCRIPTION' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-align-justify"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizTypeDescriptionAr" formControlName="descriptionAr" type="text" pInputText [required]="false"> 
                      <label for="quizTypeDescriptionAr" class="">{{ 'DESCRIPTIONAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-align-justify"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizTypeDescriptionFr" formControlName="descriptionFr" type="text" pInputText [required]="false"> 
                      <label for="quizTypeDescriptionFr" class="">{{ 'DESCRIPTIONFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-image"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizTypePicture" formControlName="picture" type="text" pInputText [required]="false"> 
                      <label for="quizTypePicture" class="">{{ 'PICTURE' | translate }}</label>
                    </span>
                    <button type="button" pButton pRipple icon="pi pi-folder" styleClass="p-button-info" (click)="openFilerDialog('picture')"></button> 
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="quizTypeIsPublished" formControlName="isPublished" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="quizTypeIsPublished" class="p-d-inline-block p-ml-2 req">{{ 'ISPUBLISHED' | translate }}</label>
                </div>
                <div *ngIf="quizTypeForm.controls['isPublished'].invalid && (quizTypeForm.controls['isPublished'].dirty || quizTypeForm.controls['isPublished'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizTypeForm.controls['isPublished'].errors !== null">
                  <div *ngIf="quizTypeForm.controls['isPublished'].errors.required">
                   {{ 'ISPUBLISHEDREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKQUIZTYPES' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="quizType.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!quizTypeForm.valid || disableSubmit"> <!--  [disabled]="quizTypeForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!quizTypeForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

