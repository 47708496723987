import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  DialogService,
  DynamicDialogRef,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';
import {
  MessageService,
  ConfirmationService,
  PrimeNGConfig,
} from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../../services/navigation/navigation.service';
import { CommonService } from '../../../../services/common/common.service';
import { VideosDataService } from '../../../services';
import { Video, VideosResult } from '../../../interfaces';
import { environment } from '../../../../../environments/environment';
import { DialogFilerComponent } from '../../filer/filer.component';
import { VideoConverterService } from '../../../services/video-converter.service';
import { VideoRatio } from '../../../interfaces/video-ratio.interface';
import { VideoSegment } from '../../../interfaces/video-segment.interface';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
  selector: 'app-video-converter',
  templateUrl: './video-converter.component.html',
  styleUrls: ['./video-converter.component.scss'],
  providers: [
    MessageService,
    ConfirmationService,
    DynamicDialogRef,
    DynamicDialogConfig,
  ],
})
export class VideoConverterComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public video: Video = {};
  public defaultParameters: { [param: string]: string | string[] } = {
    offset: '0',
    limit: '1000',
  };
  public parameters: { [param: string]: string | string[] } = {
    offset: '0',
    limit: '1000',
  };
  public isLoading: boolean = false;
  public locale: string = localStorage.language ? localStorage.language : 'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;

  private filerDialog: DynamicDialogRef = new DynamicDialogRef();

  public recompile = {
    player: false,
  };

  public currentTime: number = 0;
  public duration: number = 0;
  public max: number = 0;
  public step : number= 0.5;
  public showSegments: boolean = false;
  public rate: number = -1;

  public ratios: VideoRatio[] = [
    {
      id: 1,
      aspect_ratio: '16:9',
      frame_size: '1920x1080',
      name: 'Full HD (FHD), 1080p',
    },
    { id: 2, aspect_ratio: '16:9', frame_size: '1280x720', name: 'HD or 720p' },
    {
      id: 3,
      aspect_ratio: '16:9',
      frame_size: '1024x576',
      name: 'Wide Super VGA (WSVGA)',
    },
    {
      id: 4,
      aspect_ratio: '4:3',
      frame_size: '800x600',
      name: 'Super VGA (SVGA)',
    },
    { id: 5, aspect_ratio: '4:3', frame_size: '640x480', name: 'VGA' },
    {
      id: 6,
      aspect_ratio: '4:3',
      frame_size: '320x240',
      name: 'Quarter VGA (QVGA)',
    },
  ];

  public ratio: VideoRatio = { id: 2, aspect_ratio: '16:9', frame_size: '1280x720', name: 'HD or 720p' };

  public config = {
    preload: 'none',
    sources: [
      {
        type: 'video/mp4',
        src: '',
      },
    ],
    plugins: {
      poster: '',
    },
  };

  public probs = [
    {
      p: 1,
      symbol: '1 sec',
    },
    {
      p: 5,
      symbol: '5 sec',
    },
  ];

  public otherProbs = {
    key1: {
      p: 0,
    },
    key2: {
      p: 2,
    },
  };

  public seconds: number = 0;

  public progressInterval: number|null = null;

  public format: string = 'x264';

  public segments: VideoSegment[] = [];
  public ranges: any[] = [];

  public preload: string = 'auto';
  public api: VgApiService = new VgApiService();

  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private videosDataService: VideosDataService,
    private videoConverterService: VideoConverterService,
  ) {
    this.ratio = this.ratios[1];
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'VideoFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.getVideo(parseInt(params['id'], 10));
      }
    });
    if (
      this.dynamicDialogConfig.data &&
      typeof this.dynamicDialogConfig.data.video !== 'undefined'
    ) {
      this.video = this.dynamicDialogConfig.data.video;
      this.initVideo();
    }
    if (typeof this.video.id === 'undefined' || this.video.id === null) {
    }
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'VideoFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'VideoFormComponent');
  }

  getVideo(id: number): void {
    this.isLoading = true;
    this.videosDataService.getVideo(id).subscribe((response: Video | any) => {
      this.isLoading = false;
      if (response !== null) {
        this.video = response;
        this.initVideo();
      }
    });
  }

  list() {
    this.router.navigate(['/videos']);
  }

  openFilerDialog(field: string): void {
    let url = this.video.file;
    this.filerDialog = this.dialogService.open(DialogFilerComponent, {
      header: '', // this.translate.instant('FILEMANAGER'),
      width: '90%',
      contentStyle: { height: '800px', overflow: 'auto' },
      baseZIndex: 1500,
      data: {
        path: 'videos',
        field: field,
        url: url,
      },
    });
    this.filerDialog.onClose.subscribe((response: any) => {
      if (response && response.selected) {
        console.log(response.selected.webPath);
      }
    });
  }

  initVideo(): void {
    let src = environment.filesUrl + this.video.file;
    // src = src.replace('//', '/');
    this.config.sources[0].src = src;
    this.config.plugins.poster = environment.filesUrl + this.video.picture;
    setTimeout(() => {
      this.recompile.player = true;
    }, 500);
  }

  onPlayerReady(api: VgApiService): void {
    this.api = api;
    this.api.getDefaultMedia().subscriptions.loadedMetadata.subscribe((event: any) => {
        console.log('loadedMetadata', event);
    });
    this.api.getDefaultMedia().subscriptions.durationChange.subscribe((event: any) => {
        console.log('durationChange', event);
        this.duration = this.max = Math.round(event.target.duration);
    });
    this.api.getDefaultMedia().subscriptions.ended.subscribe((event: any) => {
        console.log('ended', event);
        // Set the video to the beginning
        this.api.getDefaultMedia().currentTime = 0;
        this.seconds = -1;
    });
    this.api.getDefaultMedia().subscriptions.timeUpdate.subscribe((event: any) => {
        console.log('timeUpdate', event);
        this.currentTime = event.target.currentTime;
    });
  }

  setThumbnail(): void {
    if (this.currentTime == -1) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translate.instant('WARNING'),
        detail: this.translate.instant('PLEASEPLAYVIDEOBEFORE'),
      });
    } else {
      this.api.pause();
      this.videoConverterService
        .generateThumbnail({ id: this.video.id, time: this.currentTime })
        .subscribe((data: any) => {
          if (data.status) {
            this.video.picture = data.picture;
            this.messageService.add({
              severity: 'success',
              summary: this.translate.instant('NOTIFICATION'),
              detail: this.translate.instant(
                'THUMBNAILGENERATED'
              ),
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: this.translate.instant('ERROR'),
              detail: this.translate.instant(
                'THUMBNAILNOTGENERATED'
              ),
            });
          }
          console.log(data);
        });
    }
  }

  setDuration(): void {
    if (this.duration == 0) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translate.instant('WARNING'),
        detail: this.translate.instant('PLEASEPLAYVIDEOBEFORE'),
      });
    } else {
      this.videoConverterService
        .setDuration({ id: this.video.id, duration: this.duration })
        .subscribe((data: any) => {
          if (data.status) {
            this.video.duration = Math.round(data.duration);
            this.messageService.add({
              severity: 'success',
              summary: this.translate.instant('NOTIFICATION'),
              detail: this.translate.instant('DURATIONUPDATED'),
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: this.translate.instant('ERROR'),
              detail: this.translate.instant(
                'DURATIONNOTUPDATED'
              ),
            });
          }
          console.log(data);
        });
    }
  }

  progress(): void {
    this.videoConverterService.getLogFile(this.video.file + '.json').subscribe(
      (response: any) => {
        if (response.data.percentage) {
          this.rate = response.data.percentage;
          if (response.data.percentage >= 99) {
            setTimeout(() => {
              if (this.progressInterval != null) {
                clearInterval(this.progressInterval);
                this.progressInterval = null;
              }
            }, 120000);
          }
        }
      },
      (error: any) => {
        if (this.progressInterval != null) {
          clearInterval(this.progressInterval);
          this.progressInterval = null;
        }
      }
    );
  }

  encode(): void {
    setTimeout(() => {
      this.progressInterval = setInterval(() => {
        this.progress();
      }, 1000);
    }, 5000);
    this.videoConverterService
      .encode({
        id: this.video.id,
        size: this.ratio.frame_size,
        format: this.format,
      })
      .subscribe((data: any) => {
        this.rate = -1;
        if (data.status) {
          this.video.file = data.file;
          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('NOTIFICATION'),
            detail: this.translate.instant('VIDEOENCODED'),
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('ERROR'),
            detail: this.translate.instant('VIDEONOTENCODED'),
          });
        }
        console.log(data);
      });
  }

  removeSegments(): void {
    if (this.segments.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translate.instant('WARNING'),
        detail: this.translate.instant('NOSEGMENTSTOREMOVE'),
      });
      return;
    }
    this.videoConverterService
      .removeSegments({
        id: this.video.id,
        segments: this.segments,
        size: this.ratio.frame_size,
        format: this.format,
      })
      .subscribe((data: any) => {
        if (data.status) {
          this.video.file = data.file;
          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('NOTIFICATION'),
            detail: this.translate.instant('SEGMENTSREMOVED'),
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('ERROR'),
            detail: this.translate.instant('SEGMENTSNOTREMOVED'),
          });
        }
        console.log(data);
      });
  }

  addItem(): void {
    if (this.duration == 0) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translate.instant('WARNING'),
        detail: this.translate.instant('PLEASEPLAYVIDEOBEFORE'),
      });
      return;
    }
    this.ranges.push([1, 6]);
    this.segments.push({ start: 1, duration: 5 });
  }

  removeItem(i: number): void {
    /*
    this.ranges.pop();
    this.segments.pop();
    */
    this.ranges.splice(i, 1);
    this.segments.splice(i, 1);
  }

  handleSegmentChange(ev: any, index: number): void {
    console.log('handleSegmentChange', ev.values, index);
    this.segments[index] = { start: ev.values[0], duration: (ev.values[1] - ev.values[1]) };
  }

}

@Component({
  selector: 'app-video-converter',
  templateUrl: './video-converter.component.html',
  styleUrls: ['./video-converter.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogVideoConverterComponent extends VideoConverterComponent {}
