import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CacheService } from '../../services/cache/cache.service';
import { CommonService } from '../../services/common/common.service';
import { Column } from '../interfaces/column.interface';
import moment from 'moment';
import { CategoriesDataService } from './categories-data.service';
import { Category, CategoriesResult } from '../interfaces';
import { SubCategoriesDataService } from './sub-categories-data.service';
import { SubCategory, SubCategoriesResult } from '../interfaces';
import { UsersDataService } from './users-data.service';
import { User, UsersResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class RecipesColumnsService {

  public categories: Category[] = [];
  public subCategories: SubCategory[] = [];
  public users: User[] = [];
  public statusesOptions = [{
    value: 'Draft',
    label: 'STATUSESDRAFT',
    css: 'primary'
  }, {
    value: 'Online',
    label: 'STATUSESONLINE',
    css: 'success'
  }, {
    value: 'Deactivated',
    label: 'STATUSESDEACTIVATED',
    css: 'warning'
  }, {
    value: 'Offline',
    label: 'STATUSESOFFLINE',
    css: 'danger'
  }, {
    value: 'Deleted',
    label: 'STATUSESDELETED',
    css: 'default'
  }, {
    value: 'Archived',
    label: 'STATUSESARCHIVED',
    css: 'info'
  }];

  public difficultyLevelOptions = [{
    value: 'Easy',
    label: 'DIFFICULTYLEVELF',
    css: 'primary'
  }, {
    value: 'Average',
    label: 'DIFFICULTYLEVELM',
    css: 'success'
  }, {
    value: 'Difficult',
    label: 'DIFFICULTYLEVELD',
    css: 'warning'
  }];

  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private cache: CacheService,
    private common: CommonService,
    private categoriesDataService: CategoriesDataService,
    private subCategoriesDataService: SubCategoriesDataService,
    private usersDataService: UsersDataService,

  ) {
    this.getCategories()
    this.getSubCategories()
    this.getUsers()
  }

  getCategories(offset: number = 0): void {
    const cacheKey: string = 'categories.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.categories = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.categoriesDataService.getCategories(parameters).subscribe((response: CategoriesResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.categories = response.results;
        } else {
          this.categories = this.categories.concat(response.results);
        }
        if (response.inlineCount > this.categories.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getCategories(offset);
        }
        response.results = this.categories;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getSubCategories(offset: number = 0): void {
    const cacheKey: string = 'subCategories.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.subCategories = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.subCategoriesDataService.getSubCategories(parameters).subscribe((response: SubCategoriesResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.subCategories = response.results;
        } else {
          this.subCategories = this.subCategories.concat(response.results);
        }
        if (response.inlineCount > this.subCategories.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getSubCategories(offset);
        }
        response.results = this.subCategories;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getUsers(offset: number = 0): void {
    const cacheKey: string = 'users.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.users = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.usersDataService.getUsers(parameters).subscribe((response: UsersResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.users = response.results;
        } else {
          this.users = this.users.concat(response.results);
        }
        if (response.inlineCount > this.users.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getUsers(offset);
        }
        response.results = this.users;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getColumns(): Column[] {
    return [
      { field: 'id', sortField: 'id', title: this.translate.instant('ID'), show: (this.navigation.getParamValue('recipes.id.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'name', sortField: 'name', title: this.translate.instant('NAME'), show: (this.navigation.getParamValue('recipes.name.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'nameAr', sortField: 'nameAr', title: this.translate.instant('NAMEAR'), show: (this.navigation.getParamValue('recipes.nameAr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'nameFr', sortField: 'nameFr', title: this.translate.instant('NAMEFR'), show: (this.navigation.getParamValue('recipes.nameFr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'difficultyLevel', sortField: 'difficultyLevel', title: this.translate.instant('DIFFICULTYLEVEL'), show: (this.navigation.getParamValue('recipes.difficultyLevel.showFiled', false) && true), displayInList: true, type: 'enumValue', filterData: this.difficultyLevelOptions },
      { field: 'preparationTime', sortField: 'preparationTime', title: this.translate.instant('PREPARATIONTIME'), show: (this.navigation.getParamValue('recipes.preparationTime.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'cookingTime', sortField: 'cookingTime', title: this.translate.instant('COOKINGTIME'), show: (this.navigation.getParamValue('recipes.cookingTime.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'servingSize', sortField: 'servingSize', title: this.translate.instant('SERVINGSIZE'), show: (this.navigation.getParamValue('recipes.servingSize.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'ordering', sortField: 'ordering', title: this.translate.instant('ORDERING'), show: (this.navigation.getParamValue('recipes.ordering.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'picture', sortField: 'picture', title: this.translate.instant('PICTURE'), show: (this.navigation.getParamValue('recipes.picture.showFiled', false) && true), displayInList: true, type: 'imgValue'},
      { field: 'description', sortField: 'description', title: this.translate.instant('DESCRIPTION'), show: (this.navigation.getParamValue('recipes.description.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'descriptionAr', sortField: 'descriptionAr', title: this.translate.instant('DESCRIPTIONAR'), show: (this.navigation.getParamValue('recipes.descriptionAr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'descriptionFr', sortField: 'descriptionFr', title: this.translate.instant('DESCRIPTIONFR'), show: (this.navigation.getParamValue('recipes.descriptionFr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'enableStreaming', sortField: 'enableStreaming', title: this.translate.instant('ENABLESTREAMING'), show: (this.navigation.getParamValue('recipes.enableStreaming.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'images', sortField: 'image', title: this.translate.instant('IMAGE'), show: (this.navigation.getParamValue('recipes.image.showFiled', false) && true), displayInList: true, type: 'imgValue'},
      { field: 'file', sortField: 'file', title: this.translate.instant('FILE'), show: (this.navigation.getParamValue('recipes.file.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'fileSize', sortField: 'fileSize', title: this.translate.instant('FILESIZE'), show: (this.navigation.getParamValue('recipes.fileSize.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'watermarkText', sortField: 'watermarkText', title: this.translate.instant('WATERMARKTEXT'), show: (this.navigation.getParamValue('recipes.watermarkText.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'duration', sortField: 'duration', title: this.translate.instant('DURATION'), show: (this.navigation.getParamValue('recipes.duration.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'isTop', sortField: 'isTop', title: this.translate.instant('ISTOP'), show: (this.navigation.getParamValue('recipes.isTop.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'isNew', sortField: 'isNew', title: this.translate.instant('ISNEW'), show: (this.navigation.getParamValue('recipes.isNew.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'status', sortField: 'status', title: this.translate.instant('STATUS'), show: (this.navigation.getParamValue('recipes.status.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_PUBLISHER') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_PUBLISHER') > -1), type: 'enumValue', filterData: this.statusesOptions},
      { field: 'autoPublishing', sortField: 'autoPublishing', title: this.translate.instant('AUTOPUBLISHING'), show: (this.navigation.getParamValue('recipes.autoPublishing.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'startPublishing', sortField: 'startPublishing', title: this.translate.instant('STARTPUBLISHING'), show: (this.navigation.getParamValue('recipes.startPublishing.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'endPublishing', sortField: 'endPublishing', title: this.translate.instant('ENDPUBLISHING'), show: (this.navigation.getParamValue('recipes.endPublishing.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'totalPreviewed', sortField: 'totalPreviewed', title: this.translate.instant('TOTALPREVIEWED'), show: (this.navigation.getParamValue('recipes.totalPreviewed.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'totalDownloads', sortField: 'totalDownloads', title: this.translate.instant('TOTALDOWNLOADS'), show: (this.navigation.getParamValue('recipes.totalDownloads.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'totalHits', sortField: 'totalHits', title: this.translate.instant('TOTALHITS'), show: (this.navigation.getParamValue('recipes.totalHits.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'totalComments', sortField: 'totalComments', title: this.translate.instant('TOTALCOMMENTS'), show: (this.navigation.getParamValue('recipes.totalComments.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'totalRatings', sortField: 'totalRatings', title: this.translate.instant('TOTALRATINGS'), show: (this.navigation.getParamValue('recipes.totalRatings.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'averageRatings', sortField: 'averageRatings', title: this.translate.instant('AVERAGERATINGS'), show: (this.navigation.getParamValue('recipes.averageRatings.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'totalLikes', sortField: 'totalLikes', title: this.translate.instant('TOTALLIKES'), show: (this.navigation.getParamValue('recipes.totalLikes.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'totalDislikes', sortField: 'totalDislikes', title: this.translate.instant('TOTALDISLIKES'), show: (this.navigation.getParamValue('recipes.totalDislikes.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'totalBookmarks', sortField: 'totalBookmarks', title: this.translate.instant('TOTALBOOKMARKS'), show: (this.navigation.getParamValue('recipes.totalBookmarks.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'remoteSource', sortField: 'remoteSource', title: this.translate.instant('REMOTESOURCE'), show: (this.navigation.getParamValue('recipes.remoteSource.showFiled', false) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'url', sortField: 'url', title: this.translate.instant('URL'), show: (this.navigation.getParamValue('recipes.url.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'alternativeUrl', sortField: 'alternativeUrl', title: this.translate.instant('ALTERNATIVEURL'), show: (this.navigation.getParamValue('recipes.alternativeUrl.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'category', title: this.translate.instant('CATEGORY'), type: 'linkValue', filterData: this.categories, show: (this.navigation.getParamValue('recipes.category.showFiled', false) && true), displayInList: true, filterField: 'category.id', displayField: 'name', sortField: 'category.name', url: 'categories' },
      { field: 'subCategory', title: this.translate.instant('SUBCATEGORY'), type: 'linkValue', filterData: this.subCategories, show: (this.navigation.getParamValue('recipes.subCategory.showFiled', false) && true), displayInList: true, filterField: 'subCategory.id', displayField: 'name', sortField: 'subCategory.name', url: 'sub-categories' },
      { field: 'slug', sortField: 'slug', title: this.translate.instant('SLUG'), show: (this.navigation.getParamValue('recipes.slug.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'slugAr', sortField: 'slugAr', title: this.translate.instant('SLUGAR'), show: (this.navigation.getParamValue('recipes.slugAr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'slugFr', sortField: 'slugFr', title: this.translate.instant('SLUGFR'), show: (this.navigation.getParamValue('recipes.slugFr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'createdAt', sortField: 'createdAt', title: this.translate.instant('CREATEDAT'), show: (this.navigation.getParamValue('recipes.createdAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'creatorUser', title: this.translate.instant('CREATORUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('recipes.creatorUser.showFiled', false) && true), displayInList: true, filterField: 'creatorUser.id', displayField: 'username', sortField: 'creatorUser.username', url: 'users' },
      { field: 'modifiedAt', sortField: 'modifiedAt', title: this.translate.instant('MODIFIEDAT'), show: (this.navigation.getParamValue('recipes.modifiedAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'modifierUser', title: this.translate.instant('MODIFIERUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('recipes.modifierUser.showFiled', false) && true), displayInList: true, filterField: 'modifierUser.id', displayField: 'username', sortField: 'modifierUser.username', url: 'users' },
      { field: 'actions', title: this.translate.instant('ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }

  format(fieldName: string, value: any) {
    if (fieldName.toLowerCase().indexOf('time') > -1) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    } else if (fieldName.toLowerCase().indexOf('date') > -1) {
      return moment(value).format('YYYY-MM-DD');
    }
    return value;
  }

}
