import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { PostType, PostTypesResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class PostTypesDataService {

  private uri: string = `${environment.apiUrl}post-types`;
  private cacheKey: string = 'postTypes.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getPostType(id: number): Observable<PostType|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getPostTypes(parameters: {[param: string]: string | string[]}): Observable<PostTypesResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addPostType(data: PostType): Observable<PostType|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addPostTypes(data: PostType[]): Observable<PostType[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updatePostType(data: PostType): Observable<PostType|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updatePostTypes(data: PostType[]): Observable<PostType[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchPostType(data: PostType): Observable<PostType|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchPostTypes(data: PostType[]): Observable<PostType[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deletePostType(data: PostType): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deletePostTypes(data: PostType[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((postType) => {
      ids.push(postType.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
