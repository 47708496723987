import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CacheService } from '../../services/cache/cache.service';
import { CommonService } from '../../services/common/common.service';
import { Column } from '../interfaces/column.interface';
import moment from 'moment';
import { CompaniesDataService } from './companies-data.service';
import { Company, CompaniesResult } from '../interfaces';
import { CountriesDataService } from './countries-data.service';
import { Country, CountriesResult } from '../interfaces';
import { CitiesDataService } from './cities-data.service';
import { City, CitiesResult } from '../interfaces';
import { LanguagesDataService } from './languages-data.service';
import { Language, LanguagesResult } from '../interfaces';
import { UsersDataService } from './users-data.service';
import { User, UsersResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class UsersColumnsService {

  public companies: Company[] = [];
  public countries: Country[] = [];
  public cities: City[] = [];
  public languages: Language[] = [];
  public users: User[] = [];
  public typesOptions = [{
    value: 'Guest',
    label: 'TYPESGUEST',
    css: 'primary'
  }, {
    value: 'Subscriber',
    label: 'TYPESSUBSCRIBER',
    css: 'success'
  }, {
    value: 'Administrator',
    label: 'TYPESADMINISTRATOR',
    css: 'warning'
  }, {
    value: 'Publisher',
    label: 'TYPESPUBLISHER',
    css: 'danger'
  }, {
    value: 'ContentProvider',
    label: 'TYPESCONTENTPROVIDER',
    css: 'default'
  }, {
    value: 'ServiceProvider',
    label: 'TYPESSERVICEPROVIDER',
    css: 'info'
  }, {
    value: 'Coach',
    label: 'TYPESCOACH',
    css: 'primary'
  }, {
    value: 'Member',
    label: 'TYPESMEMBER',
    css: 'success'
  }];
  public gendersOptions = [{
    value: 'Male',
    label: 'GENDERSMALE',
    css: 'primary'
  }, {
    value: 'Female',
    label: 'GENDERSFEMALE',
    css: 'success'
  }];
  public authenticationModesOptions = [{
    value: 'Database',
    label: 'AUTHENTICATIONMODESDATABASE',
    css: 'primary'
  }, {
    value: 'ActiveDirectory',
    label: 'AUTHENTICATIONMODESACTIVEDIRECTORY',
    css: 'success'
  }, {
    value: 'Webservice',
    label: 'AUTHENTICATIONMODESWEBSERVICE',
    css: 'warning'
  }];
  public rolesOptions = [{
    value: 'ROLE_API',
    label: 'ROLESROLE_API',
    css: 'primary'
  }, {
    value: 'ROLE_SUBSCRIBER',
    label: 'ROLESROLE_SUBSCRIBER',
    css: 'success'
  }, {
    value: 'ROLE_ADMIN',
    label: 'ROLESROLE_ADMIN',
    css: 'warning'
  }, {
    value: 'ROLE_ADMIN_PUBLISHER',
    label: 'ROLESROLE_ADMIN_PUBLISHER',
    css: 'danger'
  }, {
    value: 'ROLE_ADMIN_EXPERT',
    label: 'ROLESROLE_ADMIN_EXPERT',
    css: 'default'
  }, {
    value: 'ROLE_SUPER_ADMIN',
    label: 'ROLESROLE_SUPER_ADMIN',
    css: 'info'
  }, {
    value: 'ROLE_COACH',
    label: 'ROLESROLE_COACH',
    css: 'primary'
  }, {
    value: 'ROLE_MEMBER',
    label: 'ROLESROLE_MEMBER',
    css: 'success'
  }];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private cache: CacheService,
    private common: CommonService,
    private companiesDataService: CompaniesDataService,
    private countriesDataService: CountriesDataService,
    private citiesDataService: CitiesDataService,
    private languagesDataService: LanguagesDataService,
    private usersDataService: UsersDataService,

  ) {
    this.getCompanies()
    this.getCountries()
    this.getCities()
    this.getLanguages()
    this.getUsers()
  }

  getCompanies(offset: number = 0): void {
    const cacheKey: string = 'companies.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.companies = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.companiesDataService.getCompanies(parameters).subscribe((response: CompaniesResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.companies = response.results;
        } else {
          this.companies = this.companies.concat(response.results);
        }
        if (response.inlineCount > this.companies.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getCompanies(offset);
        }
        response.results = this.companies;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getCountries(offset: number = 0): void {
    const cacheKey: string = 'countries.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.countries = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.countriesDataService.getCountries(parameters).subscribe((response: CountriesResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.countries = response.results;
        } else {
          this.countries = this.countries.concat(response.results);
        }
        if (response.inlineCount > this.countries.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getCountries(offset);
        }
        response.results = this.countries;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getCities(offset: number = 0): void {
    const cacheKey: string = 'cities.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.cities = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.citiesDataService.getCities(parameters).subscribe((response: CitiesResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.cities = response.results;
        } else {
          this.cities = this.cities.concat(response.results);
        }
        if (response.inlineCount > this.cities.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getCities(offset);
        }
        response.results = this.cities;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getLanguages(offset: number = 0): void {
    const cacheKey: string = 'languages.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.languages = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.languagesDataService.getLanguages(parameters).subscribe((response: LanguagesResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.languages = response.results;
        } else {
          this.languages = this.languages.concat(response.results);
        }
        if (response.inlineCount > this.languages.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getLanguages(offset);
        }
        response.results = this.languages;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getUsers(offset: number = 0): void {
    const cacheKey: string = 'users.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.users = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.usersDataService.getUsers(parameters).subscribe((response: UsersResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.users = response.results;
        } else {
          this.users = this.users.concat(response.results);
        }
        if (response.inlineCount > this.users.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getUsers(offset);
        }
        response.results = this.users;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getColumns(): Column[] {
    return [
      { field: 'id', sortField: 'id', title: this.translate.instant('ID'), show: (this.navigation.getParamValue('users.id.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'firstName', sortField: 'firstName', title: this.translate.instant('FIRSTNAME'), show: (this.navigation.getParamValue('users.firstName.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'lastName', sortField: 'lastName', title: this.translate.instant('LASTNAME'), show: (this.navigation.getParamValue('users.lastName.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'gender', sortField: 'gender', title: this.translate.instant('GENDER'), show: (this.navigation.getParamValue('users.gender.showFiled', true) && true), displayInList: true, type: 'enumValue', filterData: this.gendersOptions},
      { field: 'picture', sortField: 'picture', title: this.translate.instant('PICTURE'), show: (this.navigation.getParamValue('users.picture.showFiled', true) && true), displayInList: true, type: 'imgValue'},
      { field: 'birthDate', sortField: 'birthDate', title: this.translate.instant('BIRTHDATE'), show: (this.navigation.getParamValue('users.birthDate.showFiled', true) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATEFORMAT')},
      { field: 'profile', sortField: 'profile', title: this.translate.instant('PROFILE'), show: (this.navigation.getParamValue('users.profile.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'address', sortField: 'address', title: this.translate.instant('ADDRESS'), show: (this.navigation.getParamValue('users.address.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'zipCode', sortField: 'zipCode', title: this.translate.instant('ZIPCODE'), show: (this.navigation.getParamValue('users.zipCode.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'country', title: this.translate.instant('COUNTRY'), type: 'linkValue', filterData: this.countries, show: (this.navigation.getParamValue('users.country.showFiled', false) && true), displayInList: true, filterField: 'country.id', displayField: 'name', sortField: 'country.name', url: 'countries' },
      { field: 'city', title: this.translate.instant('CITY'), type: 'linkValue', filterData: this.cities, show: (this.navigation.getParamValue('users.city.showFiled', false) && true), displayInList: true, filterField: 'city.id', displayField: 'name', sortField: 'city.name', url: 'cities' },
      { field: 'company', title: this.translate.instant('COMPANY'), type: 'linkValue', filterData: this.companies, show: (this.navigation.getParamValue('users.company.showFiled', false) && true), displayInList: true, filterField: 'company.id', displayField: 'name', sortField: 'company.name', url: 'companies' },
      { field: 'job', sortField: 'job', title: this.translate.instant('JOB'), show: (this.navigation.getParamValue('users.job.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'language', title: this.translate.instant('LANGUAGE'), type: 'linkValue', filterData: this.languages, show: (this.navigation.getParamValue('users.language.showFiled', false) && true), displayInList: true, filterField: 'language.id', displayField: 'name', sortField: 'language.name', url: 'languages' },
      { field: 'type', sortField: 'type', title: this.translate.instant('TYPE'), show: (this.navigation.getParamValue('users.type.showFiled', false) && true), displayInList: true, type: 'enumValue', filterData: this.typesOptions},
      { field: 'enabled', sortField: 'enabled', title: this.translate.instant('ENABLED'), show: (this.navigation.getParamValue('users.enabled.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_SUPER_ADMIN') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_SUPER_ADMIN') > -1), type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'username', sortField: 'username', title: this.translate.instant('USERNAME'), show: (this.navigation.getParamValue('users.username.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'password', sortField: 'password', title: this.translate.instant('PASSWORD'), show: (this.navigation.getParamValue('users.password.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'phone', sortField: 'phone', title: this.translate.instant('PHONE'), show: (this.navigation.getParamValue('users.phone.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'email', sortField: 'email', title: this.translate.instant('EMAIL'), show: (this.navigation.getParamValue('users.email.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'salt', sortField: 'salt', title: this.translate.instant('SALT'), show: (this.navigation.getParamValue('users.salt.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'facebookKey', sortField: 'facebookKey', title: this.translate.instant('FACEBOOKKEY'), show: (this.navigation.getParamValue('users.facebookKey.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'locked', sortField: 'locked', title: this.translate.instant('LOCKED'), show: (this.navigation.getParamValue('users.locked.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_SUPER_ADMIN') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_SUPER_ADMIN') > -1), type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'expired', sortField: 'expired', title: this.translate.instant('EXPIRED'), show: (this.navigation.getParamValue('users.expired.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_SUPER_ADMIN') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_SUPER_ADMIN') > -1), type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'roles', sortField: 'roles', title: this.translate.instant('ROLES'), show: (this.navigation.getParamValue('users.roles.showFiled', false) && true), displayInList: true, type: 'arrayValue'},
      { field: 'createdAt', sortField: 'createdAt', title: this.translate.instant('CREATEDAT'), show: (this.navigation.getParamValue('users.createdAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'creatorUser', title: this.translate.instant('CREATORUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('users.creatorUser.showFiled', false) && true), displayInList: true, filterField: 'creatorUser.id', displayField: 'username', sortField: 'creatorUser.username', url: 'users' },
      { field: 'modifiedAt', sortField: 'modifiedAt', title: this.translate.instant('MODIFIEDAT'), show: (this.navigation.getParamValue('users.modifiedAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'modifierUser', title: this.translate.instant('MODIFIERUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('users.modifierUser.showFiled', false) && true), displayInList: true, filterField: 'modifierUser.id', displayField: 'username', sortField: 'modifierUser.username', url: 'users' },
      { field: 'actions', title: this.translate.instant('ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }

  format(fieldName: string, value: any) {
    if (fieldName.toLowerCase().indexOf('time') > -1) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    } else if (fieldName.toLowerCase().indexOf('date') > -1) {
      return moment(value).format('YYYY-MM-DD');
    }
    return value;
  }

}
