import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { LiveChannel, LiveChannelsResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class LiveChannelsDataService {

  private uri: string = `${environment.apiUrl}live-channels`;
  private cacheKey: string = 'liveChannels.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getLiveChannel(id: number): Observable<LiveChannel|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getLiveChannels(parameters: {[param: string]: string | string[]}): Observable<LiveChannelsResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addLiveChannel(data: LiveChannel): Observable<LiveChannel|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addLiveChannels(data: LiveChannel[]): Observable<LiveChannel[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updateLiveChannel(data: LiveChannel): Observable<LiveChannel|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updateLiveChannels(data: LiveChannel[]): Observable<LiveChannel[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchLiveChannel(data: LiveChannel): Observable<LiveChannel|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchLiveChannels(data: LiveChannel[]): Observable<LiveChannel[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deleteLiveChannel(data: LiveChannel): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deleteLiveChannels(data: LiveChannel[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((liveChannel) => {
      ids.push(liveChannel.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
