import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router
  ) {

  }

  // tslint:disable-next-line:typedef
  canActivate() {
    let jsonAuth = localStorage.getItem(AuthService.ATUH_STORAGE_KEY);
    if (jsonAuth !== null) {
      const auth = JSON.parse(jsonAuth);
      if (typeof auth.data === 'undefined') {
        this.router.navigate(['/login']);
        return false;
      }
      if (typeof auth.data.expires === 'undefined') {
        this.router.navigate(['/login']);
        return false;
      }
      const now = Math.floor(Date.now() / 1000)
      console.log('now', now, 'expires', auth.data.expires);
      if (now >= auth.data.expires) {
        this.router.navigate(['/login']);
        return false;
      }
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page
    this.router.navigate(['/login']);
    return false;
  }
}
