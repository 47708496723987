import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../../services/navigation/navigation.service';
import { CommonService } from '../../../../services/common/common.service';
import { SettingsDataService, SettingsColumnsService } from '../../../services';
import { Setting, SettingsResult } from '../../../interfaces';
import { Column } from '../../../interfaces/column.interface';

@Component({
  selector: 'app-setting-details',
  templateUrl: './setting-details.component.html',
  styleUrls: ['./setting-details.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class SettingDetailsComponent implements OnInit, OnDestroy {

  public setting: Setting = {};
  public settings: Setting[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public columns: Column[] = [];

  constructor(
    private translate: TranslateService,
    private common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private settingsDataService: SettingsDataService,
    private settingsColumnsService: SettingsColumnsService,

  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Setting - Details');
        this.getSetting(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.setting !== 'undefined') {
      this.setting = this.dynamicDialogConfig.data.setting;
    }
    this.columns = this.settingsColumnsService.getColumns();
  }

  ngOnDestroy(): void {
  }

  getSetting(id: number): void {
    this.isLoading = true;
    this.settingsDataService.getSetting(id).subscribe((response: Setting|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setting = response;
      }
    });
  }

  list() {
    this.router.navigate(['/settings']);
  }

  add() {
    this.router.navigate(['/settings/add']);
  }

  edit() {
    this.router.navigate(['/settings/edit/' + this.setting.id]);
  }

}
@Component({
  selector: 'app-setting-details',
  templateUrl: './setting-details.component.html',
  styleUrls: ['./setting-details.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogSettingDetailsComponent extends SettingDetailsComponent {
}

