import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { Category, CategoriesResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class CategoriesDataService {

  private uri: string = `${environment.apiUrl}categories`;
  private cacheKey: string = 'categories.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getCategory(id: number): Observable<Category|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getCategories(parameters: {[param: string]: string | string[]}): Observable<CategoriesResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addCategory(data: Category): Observable<Category|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addCategories(data: Category[]): Observable<Category[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updateCategory(data: Category): Observable<Category|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updateCategories(data: Category[]): Observable<Category[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchCategory(data: Category): Observable<Category|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchCategories(data: Category[]): Observable<Category[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deleteCategory(data: Category): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deleteCategories(data: Category[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((category) => {
      ids.push(category.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
