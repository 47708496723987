import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { PackageType, PackageTypesResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class PackageTypesDataService {

  private uri: string = `${environment.apiUrl}package-types`;
  private cacheKey: string = 'packageTypes.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getPackageType(id: number): Observable<PackageType|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getPackageTypes(parameters: {[param: string]: string | string[]}): Observable<PackageTypesResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addPackageType(data: PackageType): Observable<PackageType|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addPackageTypes(data: PackageType[]): Observable<PackageType[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updatePackageType(data: PackageType): Observable<PackageType|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updatePackageTypes(data: PackageType[]): Observable<PackageType[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchPackageType(data: PackageType): Observable<PackageType|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchPackageTypes(data: PackageType[]): Observable<PackageType[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deletePackageType(data: PackageType): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deletePackageTypes(data: PackageType[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((packageType) => {
      ids.push(packageType.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
