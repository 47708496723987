import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { CommonService } from '../../../services/common/common.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { RecipeIngredientsDataService, RecipeIngredientsColumnsService } from '../../services';
import { DialogRecipeIngredientFormComponent } from './recipe-ingredient-form/recipe-ingredient-form.component';
import { DialogRecipeIngredientDetailsComponent } from './recipe-ingredient-details/recipe-ingredient-details.component';
import { RecipeIngredient, RecipeIngredientsResult } from '../../interfaces';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-recipe-ingredients',
  templateUrl: './recipe-ingredients.component.html',
  styleUrls: ['./recipe-ingredients.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class RecipeIngredientsComponent implements OnInit, OnDestroy {

  private recipeIngredientAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private recipeIngredientEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private recipeIngredientDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  public recipeIngredientToDelete: RecipeIngredient = {};
  public recipeIngredients: RecipeIngredient[] = [];
  public recipeIngredient: RecipeIngredient = {};
  public selectedRecipeIngredients: RecipeIngredient[] = [];
  public submitted: boolean = false;
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '100'};
  public chunkSize: number = 200;
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  private _selectedColumns: Column[] = [];
  @ViewChild('importer', { static: false })
  public importer?: FileUpload;
  public importChooseLabel: string = 'Import';
  public importLabel: string = 'Import CSV';

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private common: CommonService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private recipeIngredientsDataService: RecipeIngredientsDataService,
    private recipeIngredientsColumnsService: RecipeIngredientsColumnsService,
  ) {
    this.importChooseLabel = this.translate.instant('IMPORT');
    this.importLabel = this.translate.instant('IMPORTCSV');
  }

  ngOnInit(): void {
    console.log('RecipeIngredientsComponent', 'ngOnInit');
    this.isInitialized = true;
    this.titleService.setTitle('Recipe Ingredients - Management');
    this.primengConfig.ripple = true;
    this.getRecipeIngredients();
  }

  ngOnDestroy(): void {
    console.log('RecipeIngredientsComponent', 'ngOnDestroy');
    this.isInitialized = false;
  }

  getRecipeIngredients(): void {
    if (!this.isInitialized) {
      return;
    }
    if (parseInt(this.parameters.offset + '', 10) === 0) {
      this.isLoading = true;
    }
    this.recipeIngredientsDataService.getRecipeIngredients(this.parameters).subscribe((response: RecipeIngredientsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        if (parseInt(this.parameters.offset + '', 10) === 0) {
          this.recipeIngredients = response.results;
        } else {
          this.recipeIngredients = this.recipeIngredients.concat(response.results);
        }
        if (response.inlineCount > this.recipeIngredients.length) {
          this.parameters.offset = (parseInt(this.parameters.offset + '', 10) + parseInt(this.parameters.limit + '', 10)) + '';
          this.getRecipeIngredients();
        } else {
          this.parameters.offset = '0';
        }
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('RECIPEINGREDIENTSNOTLOADED')});
    });
  }

  importRecipeIngredients(event: any): void {
    if (typeof event.currentFiles === 'undefined') {
      return;
    }
    if (event.currentFiles.length === 0) {
      return;
    }
    const inputFile = event.currentFiles[0];
    const reader = new FileReader();
    let rows: any[] = [];
    reader.onload = (e: any) => {
      if (typeof this.importer !== 'undefined') {
        this.importer.clear();
      }
      const text = <string>e.target.result;
      rows = ToolsService.csvToArray(this.columns, text, ',');
      if (rows.length > 0) {
        let proceed = 0;
        const j = rows.length;
        for (let i = 0; i < j; i += this.chunkSize) {
          this.isLoading = true;
          const chunk = rows.slice(i, i + this.chunkSize);
          if (typeof chunk[0].id === 'undefined') {
            this.recipeIngredientsDataService.addRecipeIngredients(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('RECIPEINGREDIENTSCREATED')});
                if (proceed === j) {
                  this.getRecipeIngredients();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('RECIPEINGREDIENTSNOTCREATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('RECIPEINGREDIENTSNOTCREATED') + ' ' + msg});
            });
          } else {
            this.recipeIngredientsDataService.updateRecipeIngredients(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('RECIPEINGREDIENTSUPDATED')});
                if (proceed === j) {
                  this.getRecipeIngredients();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('RECIPEINGREDIENTSNOTUPDATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('RECIPEINGREDIENTSNOTCREATED') + ' ' + msg});
            });
          }
        }
      }
    };
    reader.readAsText(inputFile);
  }

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.recipeIngredientsColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('recipeIngredients.' + this.columns[i].field + '.showFiled', show);
    }
  }

  deleteSelectedRecipeIngredients() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Recipe Ingredients?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.recipeIngredientsDataService.deleteRecipeIngredients(this.selectedRecipeIngredients).subscribe((response) => {
          if (response === null) {
            this.messageService.clear();
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Recipe Ingredients Deleted', life: 3000});
            this.getRecipeIngredients();
          } else {
            this.messageService.clear();
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Recipe Ingredients not deleted'});
          }
        }, (error: any) => {
          this.messageService.clear();
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Recipe Ingredients not deleted'});
        });
        this.selectedRecipeIngredients = [];
      }
    });
  }

  exportPdf() {
    ToolsService.exportPdf(this.recipeIngredients, this.columns, 'RecipeIngredients');
  }

  exportExcel() {
    ToolsService.exportExcel(this.recipeIngredients, this.columns, 'RecipeIngredients');
  }

  exportCsv() {
    ToolsService.exportCsv(this.recipeIngredients, this.columns, 'RecipeIngredients');
  }

  refresh() {
    this.recipeIngredientsDataService.clearCache();
    this.getRecipeIngredients();
  }

  recipeIngredientAdd(): void {
    if (!this.navigation.getParamValue('recipeIngredients.addInDialog', true)) {
      this.router.navigate(['/recipe-ingredients/add']);
      return;
    }
    this.recipeIngredientAddDialog = this.dialogService.open(DialogRecipeIngredientFormComponent, {
      header: '', // this.translate.instant('NEWRECIPEINGREDIENT'),
      width: '80%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'add',
        recipeIngredient: {id: null},
        recipes: this.recipeIngredientsColumnsService.recipes,
        ingredients: this.recipeIngredientsColumnsService.ingredients,
        users: this.recipeIngredientsColumnsService.users,
      },
    });

    this.recipeIngredientAddDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'info', summary: 'Success', detail: 'RecipeIngredient added'});
        this.getRecipeIngredients();
      }
    });
  }

  recipeIngredientEdit(recipeIngredient: RecipeIngredient): void {
    if (!this.navigation.getParamValue('recipeIngredients.editInDialog', true)) {
      this.router.navigate(['/recipe-ingredients/edit/' + recipeIngredient.id]);
      return;
    }
    this.recipeIngredientEditDialog = this.dialogService.open(DialogRecipeIngredientFormComponent, {
      header: '', // this.translate.instant('EDITRECIPEINGREDIENT') + ' ' + recipeIngredient.unit,
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'edit',
        recipeIngredient: recipeIngredient,
        recipes: this.recipeIngredientsColumnsService.recipes,
        ingredients: this.recipeIngredientsColumnsService.ingredients,
        users: this.recipeIngredientsColumnsService.users,
      },
    });

    this.recipeIngredientEditDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'RecipeIngredient updated'});
        this.getRecipeIngredients();
      }
    });
  }

  recipeIngredientDetails(recipeIngredient: RecipeIngredient): void {
    if (!this.navigation.getParamValue('recipeIngredients.detailsInDialog', true)) {
      this.router.navigate(['/recipe-ingredients/details/' + recipeIngredient.id]);
      return;
    }
    this.recipeIngredientDetailsDialog = this.dialogService.open(DialogRecipeIngredientDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        recipeIngredient: recipeIngredient,
      }
    });
  }

  recipeIngredientDelete(recipeIngredient: RecipeIngredient): void {
    this.recipeIngredientToDelete = recipeIngredient;
    this.messageService.clear();
    this.messageService.add({
      key: 'recipeIngredientDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want delete ' + recipeIngredient.unit + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.recipeIngredientsDataService.deleteRecipeIngredient(this.recipeIngredientToDelete).subscribe((res: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Recipe Ingredient deleted'});
      this.getRecipeIngredients();
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Recipe Ingredient not deleted'});
    });
  }

  onDeleteReject(): void {
    this.recipeIngredientToDelete = {};
    this.messageService.clear('recipeIngredientDelete');
  }

}
