<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="quiz.id === null">{{ 'NEWQUIZ' | translate }}</span>
            <span *ngIf="quiz.id !== null">{{ 'EDITQUIZ' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="quizForm" id="quizForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="quizForm" >
            <input type="hidden" name="id" id="quizId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-cog"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="quizQuizTypeId" [autoDisplayFirst]="false" formControlName="quizType" [options]="quizzesColumnsService.quizTypes" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="quizQuizTypeId" class="req">{{ 'QUIZTYPE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="quizForm.controls['quizType'].invalid && (quizForm.controls['quizType'].dirty || quizForm.controls['quizType'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['quizType'].errors !== null">
                  <div *ngIf="quizForm.controls['quizType'].errors.required">
                   {{ 'QUIZTYPEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-tags"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizTitle" formControlName="title" type="text" pInputText [required]="true"> 
                      <label for="quizTitle" class="req">{{ 'TITLE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="quizForm.controls['title'].invalid && (quizForm.controls['title'].dirty || quizForm.controls['title'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['title'].errors !== null">
                  <div *ngIf="quizForm.controls['title'].errors.required">
                   {{ 'TITLEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-tags"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizTitleAr" formControlName="titleAr" type="text" pInputText [required]="false"> 
                      <label for="quizTitleAr" class="">{{ 'TITLEAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-tags"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizTitleFr" formControlName="titleFr" type="text" pInputText [required]="false"> 
                      <label for="quizTitleFr" class="">{{ 'TITLEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizSlug" formControlName="slug" type="text" pInputText [required]="true"> 
                      <label for="quizSlug" class="req">{{ 'SLUG' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="quizForm.controls['slug'].invalid && (quizForm.controls['slug'].dirty || quizForm.controls['slug'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['slug'].errors !== null">
                  <div *ngIf="quizForm.controls['slug'].errors.required">
                   {{ 'SLUGREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizSlugAr" formControlName="slugAr" type="text" pInputText [required]="false"> 
                      <label for="quizSlugAr" class="">{{ 'SLUGAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizSlugFr" formControlName="slugFr" type="text" pInputText [required]="false"> 
                      <label for="quizSlugFr" class="">{{ 'SLUGFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-image"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizPicture" formControlName="picture" type="text" pInputText [required]="false"> 
                      <label for="quizPicture" class="">{{ 'PICTURE' | translate }}</label>
                    </span>
                    <button type="button" pButton pRipple icon="pi pi-folder" styleClass="p-button-info" (click)="openFilerDialog('picture')"></button> 
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">

                <div class="p-field p-col-12">
                    <label for="quizDescription" class="req">{{ 'DESCRIPTION' | translate }}</label>
                    <ckeditor formControlName="description" [editor]="Editor" id="quizDescription" name="description" [config]="editorService.config"></ckeditor>
                </div>
                <div *ngIf="quizForm.controls['description'].invalid && (quizForm.controls['description'].dirty || quizForm.controls['description'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['description'].errors !== null">
                  <div *ngIf="quizForm.controls['description'].errors.required">
                   {{ 'DESCRIPTIONREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">

                <div class="p-field p-col-12">
                    <label for="quizDescriptionAr" class="">{{ 'DESCRIPTIONAR' | translate }}</label>
                    <ckeditor formControlName="descriptionAr" [editor]="Editor" id="quizDescriptionAr" name="descriptionAr" [config]="editorService.config"></ckeditor>
                </div>

              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">

                <div class="p-field p-col-12">
                    <label for="quizDescriptionFr" class="">{{ 'DESCRIPTIONFR' | translate }}</label>
                    <ckeditor formControlName="descriptionFr" [editor]="Editor" id="quizDescriptionFr" name="descriptionFr" [config]="editorService.config"></ckeditor>
                </div>

              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <label for="quizKind" class="req">{{ 'KIND' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of quizzesColumnsService.kindsOptions; let i = index">
                      <p-radioButton inputId="quizKind_{{ i }}" formControlName="kind" name="kind" [value]="option.value"></p-radioButton>
                      <label for="quizKind_{{ i }}">{{ option.label | translate }}</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="quizForm.controls['kind'].invalid && (quizForm.controls['kind'].dirty || quizForm.controls['kind'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['kind'].errors !== null">
                  <div *ngIf="quizForm.controls['kind'].errors.required">
                   {{ 'KINDREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-help"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizQuestion" formControlName="question" type="text" pInputText [required]="true"> 
                      <label for="quizQuestion" class="req">{{ 'QUESTION' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="quizForm.controls['question'].invalid && (quizForm.controls['question'].dirty || quizForm.controls['question'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['question'].errors !== null">
                  <div *ngIf="quizForm.controls['question'].errors.required">
                   {{ 'QUESTIONREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-help"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizQuestionAr" formControlName="questionAr" type="text" pInputText [required]="false"> 
                      <label for="quizQuestionAr" class="">{{ 'QUESTIONAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-help"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizQuestionFr" formControlName="questionFr" type="text" pInputText [required]="false"> 
                      <label for="quizQuestionFr" class="">{{ 'QUESTIONFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice1" formControlName="choice1" type="text" pInputText [required]="false"> 
                      <label for="quizChoice1" class="">{{ 'CHOICE1' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice1Ar" formControlName="choice1Ar" type="text" pInputText [required]="false"> 
                      <label for="quizChoice1Ar" class="">{{ 'CHOICE1AR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice1Fr" formControlName="choice1Fr" type="text" pInputText [required]="false"> 
                      <label for="quizChoice1Fr" class="">{{ 'CHOICE1FR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="quizChoice1IsCorrect" formControlName="choice1IsCorrect" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="quizChoice1IsCorrect" class="p-d-inline-block p-ml-2 req">{{ 'CHOICE1ISCORRECT' | translate }}</label>
                </div>
                <div *ngIf="quizForm.controls['choice1IsCorrect'].invalid && (quizForm.controls['choice1IsCorrect'].dirty || quizForm.controls['choice1IsCorrect'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['choice1IsCorrect'].errors !== null">
                  <div *ngIf="quizForm.controls['choice1IsCorrect'].errors.required">
                   {{ 'CHOICE1ISCORRECTREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice2" formControlName="choice2" type="text" pInputText [required]="false"> 
                      <label for="quizChoice2" class="">{{ 'CHOICE2' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice2Ar" formControlName="choice2Ar" type="text" pInputText [required]="false"> 
                      <label for="quizChoice2Ar" class="">{{ 'CHOICE2AR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice2Fr" formControlName="choice2Fr" type="text" pInputText [required]="false"> 
                      <label for="quizChoice2Fr" class="">{{ 'CHOICE2FR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="quizChoice2IsCorrect" formControlName="choice2IsCorrect" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="quizChoice2IsCorrect" class="p-d-inline-block p-ml-2 req">{{ 'CHOICE2ISCORRECT' | translate }}</label>
                </div>
                <div *ngIf="quizForm.controls['choice2IsCorrect'].invalid && (quizForm.controls['choice2IsCorrect'].dirty || quizForm.controls['choice2IsCorrect'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['choice2IsCorrect'].errors !== null">
                  <div *ngIf="quizForm.controls['choice2IsCorrect'].errors.required">
                   {{ 'CHOICE2ISCORRECTREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice3" formControlName="choice3" type="text" pInputText [required]="false"> 
                      <label for="quizChoice3" class="">{{ 'CHOICE3' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice3Ar" formControlName="choice3Ar" type="text" pInputText [required]="false"> 
                      <label for="quizChoice3Ar" class="">{{ 'CHOICE3AR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice3Fr" formControlName="choice3Fr" type="text" pInputText [required]="false"> 
                      <label for="quizChoice3Fr" class="">{{ 'CHOICE3FR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="quizChoice3IsCorrect" formControlName="choice3IsCorrect" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="quizChoice3IsCorrect" class="p-d-inline-block p-ml-2 req">{{ 'CHOICE3ISCORRECT' | translate }}</label>
                </div>
                <div *ngIf="quizForm.controls['choice3IsCorrect'].invalid && (quizForm.controls['choice3IsCorrect'].dirty || quizForm.controls['choice3IsCorrect'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['choice3IsCorrect'].errors !== null">
                  <div *ngIf="quizForm.controls['choice3IsCorrect'].errors.required">
                   {{ 'CHOICE3ISCORRECTREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice4" formControlName="choice4" type="text" pInputText [required]="false"> 
                      <label for="quizChoice4" class="">{{ 'CHOICE4' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice4Ar" formControlName="choice4Ar" type="text" pInputText [required]="false"> 
                      <label for="quizChoice4Ar" class="">{{ 'CHOICE4AR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice4Fr" formControlName="choice4Fr" type="text" pInputText [required]="false"> 
                      <label for="quizChoice4Fr" class="">{{ 'CHOICE4FR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="quizChoice4IsCorrect" formControlName="choice4IsCorrect" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="quizChoice4IsCorrect" class="p-d-inline-block p-ml-2 req">{{ 'CHOICE4ISCORRECT' | translate }}</label>
                </div>
                <div *ngIf="quizForm.controls['choice4IsCorrect'].invalid && (quizForm.controls['choice4IsCorrect'].dirty || quizForm.controls['choice4IsCorrect'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['choice4IsCorrect'].errors !== null">
                  <div *ngIf="quizForm.controls['choice4IsCorrect'].errors.required">
                   {{ 'CHOICE4ISCORRECTREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice5" formControlName="choice5" type="text" pInputText [required]="false"> 
                      <label for="quizChoice5" class="">{{ 'CHOICE5' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice5Ar" formControlName="choice5Ar" type="text" pInputText [required]="false"> 
                      <label for="quizChoice5Ar" class="">{{ 'CHOICE5AR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice5Fr" formControlName="choice5Fr" type="text" pInputText [required]="false"> 
                      <label for="quizChoice5Fr" class="">{{ 'CHOICE5FR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="quizChoice5IsCorrect" formControlName="choice5IsCorrect" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="quizChoice5IsCorrect" class="p-d-inline-block p-ml-2 req">{{ 'CHOICE5ISCORRECT' | translate }}</label>
                </div>
                <div *ngIf="quizForm.controls['choice5IsCorrect'].invalid && (quizForm.controls['choice5IsCorrect'].dirty || quizForm.controls['choice5IsCorrect'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['choice5IsCorrect'].errors !== null">
                  <div *ngIf="quizForm.controls['choice5IsCorrect'].errors.required">
                   {{ 'CHOICE5ISCORRECTREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice6" formControlName="choice6" type="text" pInputText [required]="false"> 
                      <label for="quizChoice6" class="">{{ 'CHOICE6' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice6Ar" formControlName="choice6Ar" type="text" pInputText [required]="false"> 
                      <label for="quizChoice6Ar" class="">{{ 'CHOICE6AR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice6Fr" formControlName="choice6Fr" type="text" pInputText [required]="false"> 
                      <label for="quizChoice6Fr" class="">{{ 'CHOICE6FR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="quizChoice6IsCorrect" formControlName="choice6IsCorrect" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="quizChoice6IsCorrect" class="p-d-inline-block p-ml-2 req">{{ 'CHOICE6ISCORRECT' | translate }}</label>
                </div>
                <div *ngIf="quizForm.controls['choice6IsCorrect'].invalid && (quizForm.controls['choice6IsCorrect'].dirty || quizForm.controls['choice6IsCorrect'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['choice6IsCorrect'].errors !== null">
                  <div *ngIf="quizForm.controls['choice6IsCorrect'].errors.required">
                   {{ 'CHOICE6ISCORRECTREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice7" formControlName="choice7" type="text" pInputText [required]="false"> 
                      <label for="quizChoice7" class="">{{ 'CHOICE7' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice7Ar" formControlName="choice7Ar" type="text" pInputText [required]="false"> 
                      <label for="quizChoice7Ar" class="">{{ 'CHOICE7AR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice7Fr" formControlName="choice7Fr" type="text" pInputText [required]="false"> 
                      <label for="quizChoice7Fr" class="">{{ 'CHOICE7FR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="quizChoice7IsCorrect" formControlName="choice7IsCorrect" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="quizChoice7IsCorrect" class="p-d-inline-block p-ml-2 req">{{ 'CHOICE7ISCORRECT' | translate }}</label>
                </div>
                <div *ngIf="quizForm.controls['choice7IsCorrect'].invalid && (quizForm.controls['choice7IsCorrect'].dirty || quizForm.controls['choice7IsCorrect'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['choice7IsCorrect'].errors !== null">
                  <div *ngIf="quizForm.controls['choice7IsCorrect'].errors.required">
                   {{ 'CHOICE7ISCORRECTREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice8" formControlName="choice8" type="text" pInputText [required]="false"> 
                      <label for="quizChoice8" class="">{{ 'CHOICE8' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice8Ar" formControlName="choice8Ar" type="text" pInputText [required]="false"> 
                      <label for="quizChoice8Ar" class="">{{ 'CHOICE8AR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-dot-circle"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizChoice8Fr" formControlName="choice8Fr" type="text" pInputText [required]="false"> 
                      <label for="quizChoice8Fr" class="">{{ 'CHOICE8FR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="quizChoice8IsCorrect" formControlName="choice8IsCorrect" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="quizChoice8IsCorrect" class="p-d-inline-block p-ml-2 req">{{ 'CHOICE8ISCORRECT' | translate }}</label>
                </div>
                <div *ngIf="quizForm.controls['choice8IsCorrect'].invalid && (quizForm.controls['choice8IsCorrect'].dirty || quizForm.controls['choice8IsCorrect'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['choice8IsCorrect'].errors !== null">
                  <div *ngIf="quizForm.controls['choice8IsCorrect'].errors.required">
                   {{ 'CHOICE8ISCORRECTREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="quizAutoPublishing" formControlName="autoPublishing" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="quizAutoPublishing" class="p-d-inline-block p-ml-2 req">{{ 'AUTOPUBLISHING' | translate }}</label>
                </div>
                <div *ngIf="quizForm.controls['autoPublishing'].invalid && (quizForm.controls['autoPublishing'].dirty || quizForm.controls['autoPublishing'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['autoPublishing'].errors !== null">
                  <div *ngIf="quizForm.controls['autoPublishing'].errors.required">
                   {{ 'AUTOPUBLISHINGREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-control-skip-forward"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="quizStartPublishing" formControlName="startPublishing" [showTime]="true" hourFormat="24" [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [required]="false" yearRange="1900:2030" [stepHour]="1" [stepMinute]="15" [stepSecond]="0"></p-calendar>
                      <label for="quizStartPublishing" class="">{{ 'STARTPUBLISHING' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-control-skip-backward"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="quizEndPublishing" formControlName="endPublishing" [showTime]="true" hourFormat="24" [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [required]="false" yearRange="1900:2030" [stepHour]="1" [stepMinute]="15" [stepSecond]="0"></p-calendar>
                      <label for="quizEndPublishing" class="">{{ 'ENDPUBLISHING' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-calendar"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="quizPublishDate" formControlName="publishDate" [showTime]="false" [showIcon]="true" view="date" [yearNavigator]="true" [monthNavigator]="true" [required]="true" yearRange="1900:2030"></p-calendar>
                      <label for="quizPublishDate" class="req">{{ 'PUBLISHDATE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="quizForm.controls['publishDate'].invalid && (quizForm.controls['publishDate'].dirty || quizForm.controls['publishDate'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['publishDate'].errors !== null">
                  <div *ngIf="quizForm.controls['publishDate'].errors.required">
                   {{ 'PUBLISHDATEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_PUBLISHER') === -1" >
                  <label for="quizStatus" class="req">{{ 'STATUS' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of quizzesColumnsService.statusesOptions; let i = index">
                      <p-radioButton inputId="quizStatus_{{ i }}" formControlName="status" name="status" [value]="option.value"></p-radioButton>
                      <label for="quizStatus_{{ i }}">{{ option.label | translate }}</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="quizForm.controls['status'].invalid && (quizForm.controls['status'].dirty || quizForm.controls['status'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['status'].errors !== null">
                  <div *ngIf="quizForm.controls['status'].errors.required">
                   {{ 'STATUSREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="quizCountAnswers" formControlName="countAnswers" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="quizCountAnswers" class="">{{ 'COUNTANSWERS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-trophy"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="quizCountWinners" formControlName="countWinners" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="quizCountWinners" class="">{{ 'COUNTWINNERS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="quizIsFinished" formControlName="isFinished" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="quizIsFinished" class="p-d-inline-block p-ml-2 req">{{ 'ISFINISHED' | translate }}</label>
                </div>
                <div *ngIf="quizForm.controls['isFinished'].invalid && (quizForm.controls['isFinished'].dirty || quizForm.controls['isFinished'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizForm.controls['isFinished'].errors !== null">
                  <div *ngIf="quizForm.controls['isFinished'].errors.required">
                   {{ 'ISFINISHEDREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-list-ol"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="quizOrdering" formControlName="ordering" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="quizOrdering" class="">{{ 'ORDERING' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKQUIZZES' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="quiz.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!quizForm.valid || disableSubmit"> <!--  [disabled]="quizForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!quizForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

