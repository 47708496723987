<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-10 mx-auto">

          <p-messages [(value)]="msgs"></p-messages>

          <div class="card-group pb-2">
            <div class="card p-4">
              <div class="card-body">
                <fieldset>
                  <div class="login-brand mb-4"></div>

                  <h1>{{ 'LOGIN' | translate }}</h1>

                  <p class="text-muted mb-5">{{ 'SIGNIN' | translate }}</p>

                  <div class="p-fluid p-grid">

                    <div class="p-field p-col-12">
                      <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi-user"></i>
                        </span>
                        <span class="p-float-label">
                          <input type="text" id="username" pInputText  [(ngModel)]="credentials.username" [required]="true">
                          <label for="username">{{ 'USERNAME' | translate }}</label>
                        </span>
                      </div>
                    </div>

                    <div class="p-field p-col-12">
                      <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi-lock"></i>
                        </span>
                        <span class="p-float-label">
                          <p-password id="password" [(ngModel)]="credentials.password" [toggleMask]="true" [required]="true"></p-password>
                          <label for="password">{{ 'PASSWORD' | translate }}</label>
                        </span>
                      </div>
                    </div>

                    <div class="p-col-6">
                      <button type="button" class="btn btn-primary px-2" (click)="login()" [disabled]="isLoading">
                        {{ 'LOGIN' | translate }}
                        <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm float-right ml-2 text-light" role="status"></span>
                      </button>
                    </div>

                    <div class="p-col-6 text-right">
                      <button type="button" class="btn btn-link px-0" (click)="forgotPassword()">
                        {{ 'FORGOTPASSWORD' | translate }}
                      </button>
                    </div>

                  </div>

                </fieldset>
              </div>
            </div>
            <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
              <div class="card-body text-center">
                <div>
                  <!--
                  <h2>Sign up</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                  <button type="button" class="btn btn-primary active mt-3">Register Now!</button>
                  -->
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </main>
</div>
