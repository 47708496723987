import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CacheService } from '../../services/cache/cache.service';
import { CommonService } from '../../services/common/common.service';
import { Column } from '../interfaces/column.interface';
import moment from 'moment';
import { ShoppingListsDataService } from './shopping-lists-data.service';
import { ShoppingList, ShoppingListsResult } from '../interfaces';
import { IngredientsDataService } from './ingredients-data.service';
import { Ingredient, IngredientsResult } from '../interfaces';
import { UsersDataService } from './users-data.service';
import { User, UsersResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ShoppingListIngredientsColumnsService {

  public shoppingLists: ShoppingList[] = [];
  public ingredients: Ingredient[] = [];
  public users: User[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private cache: CacheService,
    private common: CommonService,
    private shoppingListsDataService: ShoppingListsDataService,
    private ingredientsDataService: IngredientsDataService,
    private usersDataService: UsersDataService,

  ) {
    this.getShoppingLists()
    this.getIngredients()
    this.getUsers()
  }

  getShoppingLists(offset: number = 0): void {
    const cacheKey: string = 'shoppingLists.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.shoppingLists = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.shoppingListsDataService.getShoppingLists(parameters).subscribe((response: ShoppingListsResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.shoppingLists = response.results;
        } else {
          this.shoppingLists = this.shoppingLists.concat(response.results);
        }
        if (response.inlineCount > this.shoppingLists.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getShoppingLists(offset);
        }
        response.results = this.shoppingLists;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getIngredients(offset: number = 0): void {
    const cacheKey: string = 'ingredients.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.ingredients = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.ingredientsDataService.getIngredients(parameters).subscribe((response: IngredientsResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.ingredients = response.results;
        } else {
          this.ingredients = this.ingredients.concat(response.results);
        }
        if (response.inlineCount > this.ingredients.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getIngredients(offset);
        }
        response.results = this.ingredients;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getUsers(offset: number = 0): void {
    const cacheKey: string = 'users.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.users = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.usersDataService.getUsers(parameters).subscribe((response: UsersResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.users = response.results;
        } else {
          this.users = this.users.concat(response.results);
        }
        if (response.inlineCount > this.users.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getUsers(offset);
        }
        response.results = this.users;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getColumns(): Column[] {
    return [
      { field: 'id', sortField: 'id', title: this.translate.instant('ID'), show: (this.navigation.getParamValue('shoppingListIngredients.id.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'shoppingList', title: this.translate.instant('SHOPPINGLIST'), type: 'linkValue', filterData: this.shoppingLists, show: (this.navigation.getParamValue('shoppingListIngredients.shoppingListId.showFiled', true) && true), displayInList: true, filterField: 'shoppingList.id', displayField: 'name', sortField: 'shoppingList.name', url: 'shopping-lists' },
      { field: 'ingredient', title: this.translate.instant('INGREDIENT'), type: 'linkValue', filterData: this.ingredients, show: (this.navigation.getParamValue('shoppingListIngredients.ingredientId.showFiled', true) && true), displayInList: true, filterField: 'ingredient.id', displayField: 'name', sortField: 'ingredient.name', url: 'ingredients' },
      { field: 'quantity', sortField: 'quantity', title: this.translate.instant('QUANTITY'), show: (this.navigation.getParamValue('shoppingListIngredients.quantity.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'checked', sortField: 'checked', title: this.translate.instant('CHECKED'), show: (this.navigation.getParamValue('shoppingListIngredients.checked.showFiled', true) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'ordering', sortField: 'ordering', title: this.translate.instant('ORDERING'), show: (this.navigation.getParamValue('shoppingListIngredients.ordering.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'createdAt', sortField: 'createdAt', title: this.translate.instant('CREATEDAT'), show: (this.navigation.getParamValue('shoppingListIngredients.createdAt.showFiled', true) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'creatorUser', title: this.translate.instant('CREATORUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('shoppingListIngredients.creatorUser.showFiled', false) && true), displayInList: true, filterField: 'creatorUser.id', displayField: 'username', sortField: 'creatorUser.username', url: 'users' },
      { field: 'modifiedAt', sortField: 'modifiedAt', title: this.translate.instant('MODIFIEDAT'), show: (this.navigation.getParamValue('shoppingListIngredients.modifiedAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'modifierUser', title: this.translate.instant('MODIFIERUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('shoppingListIngredients.modifierUser.showFiled', false) && true), displayInList: true, filterField: 'modifierUser.id', displayField: 'username', sortField: 'modifierUser.username', url: 'users' },
      { field: 'actions', title: this.translate.instant('ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }

  format(fieldName: string, value: any) {
    if (fieldName.toLowerCase().indexOf('time') > -1) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    } else if (fieldName.toLowerCase().indexOf('date') > -1) {
      return moment(value).format('YYYY-MM-DD');
    }
    return value;
  }

}
