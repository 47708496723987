<div class="animated fadeIn">


  <div class="d-flex align-items-center">
    <h1>
      {{ 'FILEMANAGER' | translate }}
      <ng-container *ngIf="typeFilter !== '' && typeFilter !== null && typeFilter !== undefined">[{{ 'TYPEFILTER' | translate }} - {{ typeFilter }}]</ng-container>
      :
      <ng-container *ngIf="path === '' || path === '/'">root</ng-container>
      <ng-container *ngIf="path !== '' && path !== '/'">{{ path }}</ng-container>
    </h1>
    <div [hidden]="!isLoading" class="spinner-border text-info  ml-auto" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <p-toast></p-toast>

  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

  <div class="card">

    <p-fileUpload [name]="uploadFieldName" [url]="uploadUrl" [multiple]="multiple" [accept]="accept" [maxFileSize]="maxFileSize"
      (onUpload)="onUpload($event)" (onBeforeUpload)="onBeforeUpload($event)" (onError)="onError($event)">

      <ng-template pTemplate="toolbar">
        <div class="float-right">
          <p-button icon="fas fa-cut" styleClass="text-light p-button-warning p-mr-2" (click)="cutSelected()" pTooltip="Cut Selected Items" [disabled]="selectedItems.length === 0"></p-button>
          <p-button icon="fas fa-copy" styleClass="text-light p-button-help p-mr-2" (click)="copySelected()" pTooltip="Copy Selected Items" [disabled]="selectedItems.length === 0"></p-button>
          <p-button icon="fas fa-paste" styleClass="text-light p-button-dark p-mr-2" (click)="pasteCutted()" pTooltip="Paste {{cuttedItems.length}} Cutted Items" *ngIf="(cuttedItems.length > 0 && path != fromPath)"></p-button>
          <p-button icon="fas fa-paste" styleClass="text-light p-button-dark p-mr-2" (click)="pasteCopied()" pTooltip="Paste {{copiedItems.length}} Copied Items" *ngIf="(copiedItems.length > 0 && path != fromPath)"></p-button>
          <p-button icon="fas fa-trash-alt" styleClass="text-light p-button-danger p-mr-2" (click)="deleteSelected()" pTooltip="Delete Selected Items" [disabled]="selectedItems.length === 0"></p-button>
          <p-button icon="fas fa-file-archive" styleClass="text-light p-button-success p-mr-2" (click)="archiveSelected()" pTooltip="Archive Selected Items" [disabled]="selectedItems.length === 0"></p-button>
          <p-button icon="fas fa-folder-plus" styleClass="text-light p-button-info p-mr-2" (click)="createDirecory()" pTooltip="Create New Direcory"></p-button>
          <p-button icon="fas fa-file" styleClass="text-light p-button-help p-mr-2" (click)="createNewFile()" pTooltip="Create New File"></p-button>
          <p-button icon="fas fa-sync" styleClass="text-light p-button-dark" (click)="getChildren()" pTooltip="Refresh" [disabled]="isLoading"></p-button>
          <p-button icon="fas fa-level-up-alt" styleClass="text-light p-button-warning" (click)="openParent()" pTooltip="Open Parent Direcory" [disabled]="path === ''"></p-button>
        </div>
      </ng-template>

      <ng-template pTemplate="content">
        <ul *ngIf="uploadedFiles.length">
          <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
        </ul>
      </ng-template>

    </p-fileUpload>

    <p-dataView #dv [value]="children" [paginator]="true" [rows]="60" filterBy="filename" [sortField]="sortField"
      [sortOrder]="sortOrder" layout="grid">
      <ng-template pTemplate="header">
        <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
          <div>
            <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By"
              (onChange)="onSortChange($event)" styleClass="p-mb-2 p-mb-md-0 p-mr-2"></p-dropdown>

              <p-checkbox (onChange)="toggleAll()" [binary]="true" [(ngModel)]="selectAll" label="Select All" styleClass="p-mb-2"></p-checkbox>
          </div>
        <div class="p-col-12 p-mb-2" *ngIf="isEnabled">
          <p-button icon="pi pi-check" label="{{ 'DOWNLOADSELECTEDPICTURE' | translate }}" (click)="chooseImage()"
            styleClass="p-button-primary p-button-sm"></p-button>
        </div>
          <span class="p-input-icon-left p-mb-2 p-mb-md-0">
            <i class="pi pi-search"></i>
            <input type="search" pInputText placeholder="Search by Name" (input)="dv.filter(($event.target)?$event.target['value']:'')">
          </span>

          <p-dataViewLayoutOptions></p-dataViewLayoutOptions>

        </div>
      </ng-template>
      <ng-template let-child pTemplate="listItem">
        
        <div class="p-col-12 p-mb-2">
          
          <div class="child-list-item selected-{{ child.selected }}">

            <i *ngIf="child.isDir" class="fiv-cla fiv-icon-folder" (click)="toggle(child)" (dblclick)="openChild(child)"></i>
            <i *ngIf="child.isFile && !child.isImage" class="fiv-cla fiv-icon-{{child.extension}}" (click)="toggle(child)" (dblclick)="selectChild(child)"></i>
            <strong *ngIf="child.isFile && child.isImage" [style.background-image]="'url(' + child.url + ')'" pTooltip="{{ child.imageSize }}"  (click)="toggle(child)" (dblclick)="selectChild(child)"></strong>
            <div class="child-list-detail">
              <div class="child-name" pTooltip="{{child.filename}}">{{child.filename}}</div>
              <div class="child-description">
                <ng-container *ngIf="child.isFile">{{child.humanSize}}</ng-container>
                <ng-container *ngIf="child.isDir">{{ child.childrenCount }} {{ 'ITEMS' | translate }}</ng-container>
              </div>
              <p-checkbox (onChange)="toggle(child)" [binary]="true" [(ngModel)]="child.selected"></p-checkbox>
              <!--
              <span class="pi pi-tag child-category-icon"></span>
              <span class="child-category">{{child.extension}}</span>
              -->
            </div>
            <div class="child-list-action">

              <p-button icon="pi pi-pencil" label="{{ 'RENAME' | translate }}" (click)="rename(child)"
                styleClass="p-button-success p-button-sm"></p-button>
              <p-button icon="pi pi-times" label="{{ 'DELETE' | translate }}" (click)="delete(child)"
                styleClass="p-button-danger p-button-sm"></p-button>

              <p-button icon="pi pi-download" label="{{ 'DOWNLOAD' | translate }}" (click)="download(child)"
                styleClass="p-button-primary p-button-sm" *ngIf="child.isFile"></p-button>
              <p-button icon="fas fa-file-alt" label="{{ 'EDITCONTENT' | translate }}" (click)="editFile(child)"
                styleClass="p-button-warning p-button-sm" *ngIf="child.isFile && child.isText"></p-button>
              <p-button icon="pi pi-folder-open" label="{{ 'OPEN' | translate }}" (click)="openChild(child)"
                styleClass="p-button-primary p-button-sm" *ngIf="child.isDir"></p-button>
              <span
                [class]="'child-badge status-' + child.isReadable">{{child.humanCTime}}</span>
            </div>
          </div>
        </div>
      </ng-template>
      
      <ng-template let-child pTemplate="gridItem">
        
        <div class="p-col-12 p-lg-3 p-xl-2">
          <div class="child-grid-item card p-p-2 selected-{{ child.selected }}">
            <div class="child-grid-item-top">
              <div>
                <p-checkbox (onChange)="toggle(child)" [binary]="true" [(ngModel)]="child.selected"></p-checkbox>
                <!--
                <span class="pi pi-tag child-category-icon"></span>
                <span class="child-category">{{child.extension}}</span>
                -->
              </div>
              <span [class]="'child-badge status-' + child.isReadable">
                {{child.humanCTime}}
              </span>
            </div>
            <div class="child-grid-item-content">
              <i *ngIf="child.isDir" class="fiv-cla fiv-icon-folder" (click)="toggle(child)" (dblclick)="openChild(child)"></i>
              <i *ngIf="child.isFile && !child.isImage" class="fiv-cla fiv-icon-{{child.extension}}" (click)="toggle(child)" (dblclick)="selectChild(child)"></i>
              <strong *ngIf="child.isFile && child.isImage" [style.background-image]="'url(' + child.url + ')'" pTooltip="{{ child.imageSize }}"  (click)="toggle(child)" (dblclick)="selectChild(child)"></strong>
              <div class="child-name" pTooltip="{{child.filename}}">{{child.filename}}</div>
              <div class="child-description">
                <ng-container *ngIf="child.isFile">{{ child.humanSize }}</ng-container>
                <ng-container *ngIf="child.isDir">{{ child.childrenCount }} {{ 'ITEMS' | translate }}</ng-container>
              </div>
            </div>
            <div class="child-grid-item-bottom">
              <p-button icon="pi pi-pencil" pTooltip="{{ 'RENAME' | translate }}" (click)="rename(child)"
                styleClass="p-button-success p-button-sm"></p-button>
              <p-button icon="pi pi-times" pTooltip="{{ 'DELETE' | translate }}" (click)="delete(child)"
                styleClass="p-button-danger p-button-sm"></p-button>
              <p-button icon="pi pi-download" pTooltip="{{ 'DOWNLOAD' | translate }}" (click)="download(child)"
                styleClass="p-button-primary p-button-sm" *ngIf="child.isFile"></p-button>
              <p-button icon="fas fa-file-alt" pTooltip="{{ 'EDITCONTENT' | translate }}" (click)="editFile(child)"
                styleClass="p-button-warning p-button-sm" *ngIf="child.isFile && child.isText"></p-button>
              <p-button icon="pi pi-folder-open p-button-sm" pTooltip="{{ 'OPEN' | translate }}" (click)="openChild(child)"
                styleClass="p-button-primary p-button-sm" *ngIf="child.isDir"></p-button>
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </div>

</div>

<p-dialog header="Rename" [(visible)]="displayRenameDialog" [draggable]="true" [resizable]="true">
  <span class="p-float-label p-mt-5">
    <input id="newName" type="text" pInputText [(ngModel)]="newName" required="true" [ngClass]="{'ng-invalid ng-dirty': newName === ''}">
    <label for="newName">New Name</label>
  </span>
  <p-footer>
    <p-button icon="pi pi-check" (click)="confirmRename()" label="Ok" styleClass="p-button-text"></p-button>
  </p-footer>
</p-dialog>

<p-dialog header="New Directory" [(visible)]="displayNewDirDialog" [draggable]="true" [resizable]="true">
  <span class="p-float-label p-mt-5">
    <input id="newDir" type="text" pInputText [(ngModel)]="newDir" required="true" [ngClass]="{'ng-invalid ng-dirty': newDir === ''}">
    <label for="newDir">New Directory Name</label>
  </span>
  <p-footer>
    <p-button icon="pi pi-check" (click)="mkdir()" label="Ok" styleClass="p-button-text"></p-button>
  </p-footer>
</p-dialog>

<p-dialog header="New File" [(visible)]="displayNewFileDialog" [draggable]="true" [resizable]="true">
  <span class="p-float-label p-mt-5">
    <input id="newFile" type="text" pInputText [(ngModel)]="newFile" required="true" [ngClass]="{'ng-invalid ng-dirty': newFile === ''}">
    <label for="newFile">New File Name</label>
  </span>
  <span class="p-float-label p-mt-5">
    <textarea id="fileContent" rows="10" cols="60" pInputTextarea [(ngModel)]="fileContent" required="true" [ngClass]="{'ng-invalid ng-dirty': fileContent === ''}"></textarea>
    <label for="fileContent">File Content</label>
  </span>
  <p-footer>
    <p-button icon="pi pi-check" (click)="dump()" label="Ok" styleClass="p-button-text"></p-button>
  </p-footer>
</p-dialog>

<p-dialog header="Edit File" [(visible)]="displayEditFileDialog" [draggable]="true" [resizable]="true">
  <span class="p-float-label p-mt-5">
    <input id="editFileName" type="text" pInputText [(ngModel)]="editFileName" required="true" [ngClass]="{'ng-invalid ng-dirty': editFileName === ''}" readonly>
    <label for="editFileName">File Name</label>
  </span>
  <span class="p-float-label p-mt-5">
    <textarea id="editFileContent" rows="10" cols="60" pInputTextarea [(ngModel)]="editFileContent" required="true" [ngClass]="{'ng-invalid ng-dirty': editFileContent === ''}"></textarea>
    <label for="editFileContent">File Content</label>
  </span>
  <p-footer>
    <p-button icon="pi pi-check" (click)="save()" label="Ok" styleClass="p-button-text"></p-button>
  </p-footer>
</p-dialog>
