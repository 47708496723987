import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { CitiesDataService, CitiesColumnsService } from '../../../services';
import { City, CitiesResult } from '../../../interfaces';

@Component({
  selector: 'app-city-form',
  templateUrl: './city-form.component.html',
  styleUrls: ['./city-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class CityFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public city: City = {};
  public cityForm: FormGroup = new FormGroup({});
  public cities: City[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;


  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private citiesDataService: CitiesDataService,
    public citiesColumnsService: CitiesColumnsService,
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'CityFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('City - Edit');
        this.getCity(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.city !== 'undefined') {
      this.city = this.dynamicDialogConfig.data.city;
    }
    if (typeof this.city.id === 'undefined' || this.city.id === null) {
      this.city.id = null;
      this.city.published = false;
    }
    this.getCities();
    this.prepareFrom();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'CityFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'CityFormComponent');
  }

  getCity(id: number): void {
    this.isLoading = true;
    this.citiesDataService.getCity(id).subscribe((response: City|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.city = response;
        this.prepareFrom();
      }
    });
  }

  getCities(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.cities !== 'undefined' && this.dynamicDialogConfig.data.cities.length > 0) {
      this.cities = this.dynamicDialogConfig.data.cities;
      return;
    }
    this.citiesDataService.getCities(this.parameters).subscribe((response: CitiesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.cities = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.cityForm = new FormGroup({
      id: new FormControl(this.city.id, []),
      country: new FormControl((typeof this.city.country === 'number') ? this.city.country : this.city.country?.id, [Validators.required, ]),
      name: new FormControl(this.city.name, [Validators.required, ]),
      nameAr: new FormControl(this.city.nameAr, []),
      nameFr: new FormControl(this.city.nameFr, []),
      published: new FormControl(this.city.published, []),
    });
  }

  list() {
    this.router.navigate(['/cities']);
  }

  showErrors() {
      this.showErrors();
      const invalidFields: string[] = [];
      Object.keys(this.cityForm.controls).forEach((field: any) => {
        const control = this.cityForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.cityForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.city = this.cityForm.value;
    this.disableSubmit = true;
    console.log('city', this.city);
    if (typeof this.city.id !== 'undefined' && this.city.id !== null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.citiesDataService.updateCity(this.city).subscribe((data: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('CITYUPDATED')});
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('CITYNOTUPDATED') + ' ' + error.message});
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.citiesDataService.addCity(this.city).subscribe((data: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('CITYCREATED')});
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('CITYNOTCREATED') + ' ' + error.message});
        console.warn(error);
    });
  }

}

@Component({
  selector: 'app-city-form',
  templateUrl: './city-form.component.html',
  styleUrls: ['./city-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogCityFormComponent extends CityFormComponent {
}

