<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="pushMessage.id === null">{{ 'NEWPUSHMESSAGE' | translate }}</span>
            <span *ngIf="pushMessage.id !== null">{{ 'EDITPUSHMESSAGE' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="pushMessageForm" id="pushMessageForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="pushMessageForm" >
            <input type="hidden" name="id" id="pushMessageId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="pushMessagePushDeviceId" [autoDisplayFirst]="false" formControlName="pushDevice" [options]="pushMessagesColumnsService.pushDevices" optionValue="id" optionLabel="appName" [required]="true" [filter]="true"></p-dropdown>
                      <label for="pushMessagePushDeviceId" class="req">{{ 'PUSHDEVICE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="pushMessageForm.controls['pushDevice'].invalid && (pushMessageForm.controls['pushDevice'].dirty || pushMessageForm.controls['pushDevice'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="pushMessageForm.controls['pushDevice'].errors !== null">
                  <div *ngIf="pushMessageForm.controls['pushDevice'].errors.required">
                   {{ 'PUSHDEVICEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="pushMessagePushNotificationId" [autoDisplayFirst]="false" formControlName="pushNotification" [options]="pushMessagesColumnsService.pushNotifications" optionValue="id" optionLabel="title" [required]="true" [filter]="true"></p-dropdown>
                      <label for="pushMessagePushNotificationId" class="req">{{ 'PUSHNOTIFICATION' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="pushMessageForm.controls['pushNotification'].invalid && (pushMessageForm.controls['pushNotification'].dirty || pushMessageForm.controls['pushNotification'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="pushMessageForm.controls['pushNotification'].errors !== null">
                  <div *ngIf="pushMessageForm.controls['pushNotification'].errors.required">
                   {{ 'PUSHNOTIFICATIONREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-signal"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="pushMessageDelivery" formControlName="delivery" [showTime]="true" hourFormat="24" [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [required]="false" yearRange="1900:2030" [stepHour]="1" [stepMinute]="15" [stepSecond]="0"></p-calendar>
                      <label for="pushMessageDelivery" class="">{{ 'DELIVERY' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-shortcode"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushMessageLanguageCode" formControlName="languageCode" type="text" pInputText [required]="false"> 
                      <label for="pushMessageLanguageCode" class="">{{ 'LANGUAGECODE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <label for="pushMessageSendingStatus" class="req">{{ 'SENDINGSTATUS' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of pushMessagesColumnsService.sendingStatusesOptions; let i = index">
                      <p-radioButton inputId="pushMessageSendingStatus_{{ i }}" formControlName="sendingStatus" name="sendingStatus" [value]="option.value"></p-radioButton>
                      <label for="pushMessageSendingStatus_{{ i }}">{{ option.label | translate }}</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="pushMessageForm.controls['sendingStatus'].invalid && (pushMessageForm.controls['sendingStatus'].dirty || pushMessageForm.controls['sendingStatus'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="pushMessageForm.controls['sendingStatus'].errors !== null">
                  <div *ngIf="pushMessageForm.controls['sendingStatus'].errors.required">
                   {{ 'SENDINGSTATUSREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-clipboard"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="pushMessageResponse" formControlName="response" type="text" pInputText [required]="false"> 
                      <label for="pushMessageResponse" class="">{{ 'RESPONSE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKPUSHMESSAGES' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="pushMessage.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!pushMessageForm.valid || disableSubmit"> <!--  [disabled]="pushMessageForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!pushMessageForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

