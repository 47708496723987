import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { Pack, PacksResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class PacksDataService {

  private uri: string = `${environment.apiUrl}packs`;
  private cacheKey: string = 'packs.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getPack(id: number): Observable<Pack|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getPacks(parameters: {[param: string]: string | string[]}): Observable<PacksResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addPack(data: Pack): Observable<Pack|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addPacks(data: Pack[]): Observable<Pack[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updatePack(data: Pack): Observable<Pack|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updatePacks(data: Pack[]): Observable<Pack[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchPack(data: Pack): Observable<Pack|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchPacks(data: Pack[]): Observable<Pack[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deletePack(data: Pack): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deletePacks(data: Pack[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((pack) => {
      ids.push(pack.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
