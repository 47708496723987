import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CacheService } from '../../services/cache/cache.service';
import { CommonService } from '../../services/common/common.service';
import { Column } from '../interfaces/column.interface';
import moment from 'moment';
import { UsersDataService } from './users-data.service';
import { User, UsersResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class CategoriesColumnsService {

  public users: User[] = [];
  public statusesOptions = [{
    value: 'Draft',
    label: 'STATUSESDRAFT',
    css: 'primary'
  }, {
    value: 'Online',
    label: 'STATUSESONLINE',
    css: 'success'
  }, {
    value: 'Deactivated',
    label: 'STATUSESDEACTIVATED',
    css: 'warning'
  }, {
    value: 'Offline',
    label: 'STATUSESOFFLINE',
    css: 'danger'
  }, {
    value: 'Deleted',
    label: 'STATUSESDELETED',
    css: 'default'
  }, {
    value: 'Archived',
    label: 'STATUSESARCHIVED',
    css: 'info'
  }];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private cache: CacheService,
    private common: CommonService,
    private usersDataService: UsersDataService,

  ) {
    this.getUsers()
  }

  getUsers(offset: number = 0): void {
    const cacheKey: string = 'users.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.users = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.usersDataService.getUsers(parameters).subscribe((response: UsersResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.users = response.results;
        } else {
          this.users = this.users.concat(response.results);
        }
        if (response.inlineCount > this.users.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getUsers(offset);
        }
        response.results = this.users;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getColumns(): Column[] {
    return [
      { field: 'id', sortField: 'id', title: this.translate.instant('ID'), show: (this.navigation.getParamValue('categories.id.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'name', sortField: 'name', title: this.translate.instant('NAME'), show: (this.navigation.getParamValue('categories.name.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'nameAr', sortField: 'nameAr', title: this.translate.instant('NAMEAR'), show: (this.navigation.getParamValue('categories.nameAr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'nameFr', sortField: 'nameFr', title: this.translate.instant('NAMEFR'), show: (this.navigation.getParamValue('categories.nameFr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'slug', sortField: 'slug', title: this.translate.instant('SLUG'), show: (this.navigation.getParamValue('categories.slug.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'slugAr', sortField: 'slugAr', title: this.translate.instant('SLUGAR'), show: (this.navigation.getParamValue('categories.slugAr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'slugFr', sortField: 'slugFr', title: this.translate.instant('SLUGFR'), show: (this.navigation.getParamValue('categories.slugFr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'picture', sortField: 'picture', title: this.translate.instant('PICTURE'), show: (this.navigation.getParamValue('categories.picture.showFiled', false) && true), displayInList: true, type: 'imgValue'},
      { field: 'color', sortField: 'color', title: this.translate.instant('COLOR'), show: (this.navigation.getParamValue('categories.color.showFiled', false) && true), displayInList: true, type: 'colorValue'},
      { field: 'description', sortField: 'description', title: this.translate.instant('DESCRIPTION'), show: (this.navigation.getParamValue('categories.description.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'descriptionAr', sortField: 'descriptionAr', title: this.translate.instant('DESCRIPTIONAR'), show: (this.navigation.getParamValue('categories.descriptionAr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'descriptionFr', sortField: 'descriptionFr', title: this.translate.instant('DESCRIPTIONFR'), show: (this.navigation.getParamValue('categories.descriptionFr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'ordering', sortField: 'ordering', title: this.translate.instant('ORDERING'), show: (this.navigation.getParamValue('categories.ordering.showFiled', false) && true), displayInList: true, type: 'numericValue'},
      { field: 'status', sortField: 'status', title: this.translate.instant('STATUS'), show: (this.navigation.getParamValue('categories.status.showFiled', false) && (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_PUBLISHER') > -1)), displayInList: (this.common.currentUser.roles.join('').indexOf('ROLE_ADMIN_PUBLISHER') > -1), type: 'enumValue', filterData: this.statusesOptions},
      { field: 'createdAt', sortField: 'createdAt', title: this.translate.instant('CREATEDAT'), show: (this.navigation.getParamValue('categories.createdAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'creatorUser', title: this.translate.instant('CREATORUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('categories.creatorUser.showFiled', false) && true), displayInList: true, filterField: 'creatorUser.id', displayField: 'username', sortField: 'creatorUser.username', url: 'users' },
      { field: 'modifiedAt', sortField: 'modifiedAt', title: this.translate.instant('MODIFIEDAT'), show: (this.navigation.getParamValue('categories.modifiedAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'modifierUser', title: this.translate.instant('MODIFIERUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('categories.modifierUser.showFiled', false) && true), displayInList: true, filterField: 'modifierUser.id', displayField: 'username', sortField: 'modifierUser.username', url: 'users' },
      { field: 'actions', title: this.translate.instant('ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }

  format(fieldName: string, value: any) {
    if (fieldName.toLowerCase().indexOf('time') > -1) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    } else if (fieldName.toLowerCase().indexOf('date') > -1) {
      return moment(value).format('YYYY-MM-DD');
    }
    return value;
  }

}
