import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { RecipeIngredient, RecipeIngredientsResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class RecipeIngredientsDataService {

  private uri: string = `${environment.apiUrl}recipe-ingredients`;
  private cacheKey: string = 'recipeIngredients.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getRecipeIngredient(id: number): Observable<RecipeIngredient|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getRecipeIngredients(parameters: {[param: string]: string | string[]}): Observable<RecipeIngredientsResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addRecipeIngredient(data: RecipeIngredient): Observable<RecipeIngredient|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addRecipeIngredients(data: RecipeIngredient[]): Observable<RecipeIngredient[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updateRecipeIngredient(data: RecipeIngredient): Observable<RecipeIngredient|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updateRecipeIngredients(data: RecipeIngredient[]): Observable<RecipeIngredient[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchRecipeIngredient(data: RecipeIngredient): Observable<RecipeIngredient|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchRecipeIngredients(data: RecipeIngredient[]): Observable<RecipeIngredient[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deleteRecipeIngredient(data: RecipeIngredient): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deleteRecipeIngredients(data: RecipeIngredient[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((recipeIngredient) => {
      ids.push(recipeIngredient.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
