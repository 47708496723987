import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../../services/navigation/navigation.service';
import { CommonService } from '../../../../services/common/common.service';
import { UsersDataService, UsersColumnsService } from '../../../services';
import { User, UsersResult } from '../../../interfaces';
import { Column } from '../../../interfaces/column.interface';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class UserDetailsComponent implements OnInit, OnDestroy {

  public user: User = {};
  public users: User[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public columns: Column[] = [];

  constructor(
    private translate: TranslateService,
    private common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private usersDataService: UsersDataService,
    private usersColumnsService: UsersColumnsService,

  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('User - Details');
        this.getUser(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.user !== 'undefined') {
      this.user = this.dynamicDialogConfig.data.user;
      this.user.password = '.';
    }
    this.columns = this.usersColumnsService.getColumns();
  }

  ngOnDestroy(): void {
  }

  getUser(id: number): void {
    this.isLoading = true;
    this.usersDataService.getUser(id).subscribe((response: User|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.user = response;
        this.user.password = '.';
      }
    });
  }

  list() {
    this.router.navigate(['/users']);
  }

  add() {
    this.router.navigate(['/users/add']);
  }

  edit() {
    this.router.navigate(['/users/edit/' + this.user.id]);
  }

}
@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogUserDetailsComponent extends UserDetailsComponent {
}

