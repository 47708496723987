<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="quizAnswer.id === null">{{ 'NEWQUIZANSWER' | translate }}</span>
            <span *ngIf="quizAnswer.id !== null">{{ 'EDITQUIZANSWER' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="quizAnswerForm" id="quizAnswerForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="quizAnswerForm" >
            <input type="hidden" name="id" id="quizAnswerId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="quizAnswerQuizId" [autoDisplayFirst]="false" formControlName="quiz" [options]="quizAnswersColumnsService.quizzes" optionValue="id" optionLabel="title" [required]="true" [filter]="true"></p-dropdown>
                      <label for="quizAnswerQuizId" class="req">{{ 'QUIZ' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="quizAnswerForm.controls['quiz'].invalid && (quizAnswerForm.controls['quiz'].dirty || quizAnswerForm.controls['quiz'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizAnswerForm.controls['quiz'].errors !== null">
                  <div *ngIf="quizAnswerForm.controls['quiz'].errors.required">
                   {{ 'QUIZREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="quizAnswerAnswer" formControlName="answer" type="text" pInputText [required]="true"> 
                      <label for="quizAnswerAnswer" class="req">{{ 'ANSWER' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="quizAnswerForm.controls['answer'].invalid && (quizAnswerForm.controls['answer'].dirty || quizAnswerForm.controls['answer'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizAnswerForm.controls['answer'].errors !== null">
                  <div *ngIf="quizAnswerForm.controls['answer'].errors.required">
                   {{ 'ANSWERREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="quizAnswerIsCorrect" formControlName="isCorrect" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="quizAnswerIsCorrect" class="p-d-inline-block p-ml-2 req">{{ 'ISCORRECT' | translate }}</label>
                </div>
                <div *ngIf="quizAnswerForm.controls['isCorrect'].invalid && (quizAnswerForm.controls['isCorrect'].dirty || quizAnswerForm.controls['isCorrect'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizAnswerForm.controls['isCorrect'].errors !== null">
                  <div *ngIf="quizAnswerForm.controls['isCorrect'].errors.required">
                   {{ 'ISCORRECTREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="quizAnswerIsWinner" formControlName="isWinner" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="quizAnswerIsWinner" class="p-d-inline-block p-ml-2 req">{{ 'ISWINNER' | translate }}</label>
                </div>
                <div *ngIf="quizAnswerForm.controls['isWinner'].invalid && (quizAnswerForm.controls['isWinner'].dirty || quizAnswerForm.controls['isWinner'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="quizAnswerForm.controls['isWinner'].errors !== null">
                  <div *ngIf="quizAnswerForm.controls['isWinner'].errors.required">
                   {{ 'ISWINNERREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-alarm-clock"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="quizAnswerResponseTime" formControlName="responseTime" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="quizAnswerResponseTime" class="">{{ 'RESPONSETIME' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKQUIZANSWERS' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="quizAnswer.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!quizAnswerForm.valid || disableSubmit"> <!--  [disabled]="quizAnswerForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!quizAnswerForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

