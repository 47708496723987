import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { autoTable } from 'jspdf-autotable';
import 'jspdf-autotable';
import { jsPDFWithPlugin } from '../../MumEat/interfaces/js-pdf-with-plugin.interface';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class ToolsService {

  public static EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  public static CSV_TYPE = 'text/csv;charset=utf-8;';

  public static EXCEL_EXTENSION = '.xlsx';
  public static PDF_EXTENSION = '.pdf';
  public static CSV_EXTENSION = '.csv';

  public static csvToArray(columns: any[], str: string, delimiter: string = ','): any[] {
    const data = ToolsService.parseCsv(str, delimiter);
    const headers: any[] = <any[]>data.shift();
    const types = columns.reduce((object: any, col: any, index: number) => {
      object[col.field] = col.type;
      return object;
    }, {});
    console.log('data', data);
    const arr = data.map((values: string[]) => {
      const el = headers.reduce((object: any, header: string, index: number) => {
        const type = types[header];
        let val: any = values[index];
        if (type === 'booleanValue') {
          if (val === 'true') {
            val = true;
          } else if (val === 'false') {
            val = false;
          }
        } else if (type == 'arrayValue') {
          if (val === null || typeof val === 'undefined') {
            val = [];
          } else {
            val = val.split(',');
          }
        } else if (type == 'linksValue') {
          if (val === null || typeof val === 'undefined') {
            val = [];
          } else {
            val = val.split(',').map((i: string) => {
              return parseInt(i, 10);
            });
          }
        } else if (type == 'linkValue') {
          if (val === '' || typeof val === 'undefined') {
            val = null;
          } else {
            val = parseInt(val, 10);
          }
        }
        object[header] = val;
        return object;
      }, {});
      return el;
    });

    // return the array
    return arr;
  }

  public static parseCsv(strData: string, strDelimiter: string = ','): [any[]] {
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    strDelimiter = (strDelimiter || ',');
    // Create a regular expression to parse the CSV values.
    let objPattern = new RegExp(
        (
          // Delimiters.
          '(\\' + strDelimiter + '|\\r?\\n|\\r|^)' +
          // Quoted fields.
          '(?:"([^"]*(?:""[^"]*)*)"|' +
          // Standard fields.
          '([^"\\' + strDelimiter + '\\r\\n]*))'
        ),
        'gi'
    );
    // Create an array to hold our data. Give the array
    // a default empty first row.
    let arrData: [any[]] = [[]];
    // Create an array to hold our individual pattern
    // matching groups.
    let arrMatches = null;
    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while (arrMatches = objPattern.exec( strData )) {
        // Get the delimiter that was found.
        let strMatchedDelimiter = arrMatches[ 1 ];
        // Check to see if the given delimiter has a length
        // (is not the start of string) and if it matches
        // field delimiter. If id does not, then we know
        // that this delimiter is a row delimiter.
        if (
            strMatchedDelimiter.length &&
            strMatchedDelimiter !== strDelimiter
          ) {
            // Since we have reached a new row of data,
            // add an empty row to our data array.
            arrData.push( [] );
        }
        let strMatchedValue: any = '';
        // Now that we have our delimiter out of the way,
        // let's check to see which kind of value we
        // captured (quoted or unquoted).
        if (arrMatches[ 2 ]) {
            // We found a quoted value. When we capture
            // this value, unescape any double quotes.
            strMatchedValue = arrMatches[ 2 ].replace(
              new RegExp( '""', 'g' ),
              '"'
            );
        } else {
            // We found a non-quoted value.
            strMatchedValue = arrMatches[ 3 ];
        }
        // Now that we have our value string, let's add
        // it to the data array.
        arrData[ arrData.length - 1 ].push( strMatchedValue );
    }
    arrData.pop();
    // Return the parsed data.
    return( arrData );
  }
  public static exportCsv(data: any[], columns: any[], name: string): void {
    const rows = ToolsService.getBody(data, columns, false);
    rows.unshift(ToolsService.getHead(columns, false));
    let csvFile = '';
    for (let i = 0; i < rows.length; i++) {
        csvFile += ToolsService.processRow(rows[i]);
    }
    const blob = new Blob([csvFile], { type:  ToolsService.CSV_TYPE });
    const fileName = name + '_Export_' + moment().format('YYYYDDMMHHmmss') + ToolsService.CSV_EXTENSION;
    ToolsService.download(blob, fileName);
  }

  public static download(blob: Blob, fileName: string) {
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, fileName);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
  }

  public static processRow(row: any) {
    let finalVal = '';
    for (let i = 0; i < row.length; i++) {
      if (typeof row[i] !== 'undefined') {
        let innerValue = row[i] === null ? '' : row[i].toString();
        if (row[i] instanceof Date) {
            innerValue = row[i].toLocaleString();
        }
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0)
            result = '"' + result + '"';
        if (finalVal !== '')
            finalVal += ',';
        finalVal += result;
      }
    }
    return finalVal + '\n';
  }

  public static exportExcel(data: any[], columns: any[], name: string): void {
    const rows = ToolsService.getBody(data, columns, true);
    rows.unshift(ToolsService.getHead(columns, true));
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(rows);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob: Blob = new Blob([excelBuffer], {
        type: ToolsService.EXCEL_TYPE
      });
      const fileName = name + '_Export_' + moment().format('YYYYDDMMHHmmss') + ToolsService.EXCEL_EXTENSION;
      ToolsService.download(blob, fileName);
    });
  }

  public static exportPdf(data: any[], columns: any[], name: string) {
    const fileName = name + '_Export_' + moment().format('YYYYDDMMHHmmss') + ToolsService.PDF_EXTENSION;
    const doc = new jsPDF({orientation: 'landscape', unit: 'pt'}) as jsPDFWithPlugin;
    const options = {
      columnStyles: { },
      body: this.getPdfBody(columns, data),
      columns: this.getPdfColumns(columns)
    };
    console.log(options);
    doc.autoTable(options);
    doc.save(fileName);
  }

  public static getPdfColumns(columns: any[]) {
    const pdfColumns = [];
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].show && columns[i].field !== 'actions') {
        pdfColumns.push({
          header: columns[i].title,
          dataKey: columns[i].field
        });
      }
    }
    return pdfColumns;
  }

  public static getPdfBody(columns: any[], data: any[]) {
    const body = [];
    for (let i = 0; i < data.length; i++) {
      const row = {};
      for (let j = 0; j < columns.length; j++) {
        if (columns[j].show) {
          const col = columns[j];
          if (data[i][col.field] === null || typeof data[i][col.field] === 'undefined') {
            row[col.field] = '';
          } else {
            if (col.type === 'linkValue') {
              row[col.field] = data[i][col.field][col.displayField];
            } else if (col.type === 'linksValue') {
              let values: any[] = [];
              values = data[i][col.field].map((val: any) => val[col.displayField]);
              row[col.field] = values.join(', ');
            } else if (col.field !== 'actions') {
              row[col.field] = data[i][col.field];
            }
          }
        }
      }
      body.push(row);
    }
    return body;
  }

  public static getHead(columns: any[], withFieldLabel: boolean) {
    const head = [];
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].field !== 'actions') {
        if (withFieldLabel) {
          head.push(columns[i].title);
        } else  {
          head.push(columns[i].field);
        }
      }
    }
    return head;
  }

  public static getBody(data: any[], columns: any[], withObjectLabel: boolean) {
    const body = [];
    for (let i = 0; i < data.length; i++) {
      const row = [];
      for (let j = 0; j < columns.length; j++) {
        const col = columns[j];
        if (data[i][col.field] === null || typeof data[i][col.field] === 'undefined') {
          row.push('');
        } else {
          if (col.type === 'linkValue') {
            if (withObjectLabel) {
              row.push(data[i][col.field][col.displayField]);
            } else {
              row.push(data[i][col.field].id);
            }
          } else if (col.type === 'linksValue') {
            let values: any[] = [];
            if (withObjectLabel) {
              values = data[i][col.field].map((val: any) => val[col.displayField]);
            } else {
              values = data[i][col.field].map((val: any) => val.id);
            }
            row.push(values.join(', '));
          } else if (col.field !== 'actions') {
            row.push(data[i][col.field]);
          }
        }
      }
      body.push(row);
    }
    return body;
  }

  public static humanFileSize(bytes: number, si=false, dp=1) : string {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    return bytes.toFixed(dp) + ' ' + units[u];
  }

}
