import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from '../../../services/auth/auth.service';
import { Credentials } from '../../interfaces/credentials.interface';
import { Message } from 'primeng/api';
import { LoginResponse } from '../../interfaces/login-response.interface';
import moment from 'moment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public credentials: Credentials = {
    username: '',
    password: ''
  };
  public msgs: Message[] = [];
  public isLoading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe((params: any) => {
        if (typeof params.email !== 'undefined') {
          this.credentials.username = params.email;
        }
        if (typeof params.username !== 'undefined') {
          this.credentials.username = params.username;
        }
      }
    );
    const auth = localStorage.getItem(AuthService.ATUH_STORAGE_KEY);
    if (auth !== null) {
      const loginResponse: LoginResponse = JSON.parse(auth);
      if (loginResponse.data) {
        const t = parseInt(moment().format('x'), 10);
        console.log('created', moment(loginResponse.data.created * 1000).toDate());
        console.log('now', moment().toDate());
        console.log('expires', moment(loginResponse.data.expires * 1000).toDate());
        if (t > loginResponse.data.expires) {
          this.router.navigate(['/dashboard']);
        }
      }
    }
  }

  ngOnDestroy(): void {
  }

  login(): void {
    console.log('credentials', this.credentials);
    if (this.credentials.username === '' || this.credentials.password === '') {
      this.msgs = [];
      this.msgs.push({severity: 'warn', summary:'Empty fields', detail:'Username and password are required.'});
      return;
    }
    this.isLoading = true;
    this.authService.login(this.credentials).subscribe((response) => {
      this.isLoading = false;
      if (response) {
        localStorage.setItem(AuthService.ATUH_STORAGE_KEY, JSON.stringify(response));
        this.msgs = [];
        this.msgs.push({severity: 'success', summary:'Login success', detail:'You will be redirected to dahsboard.'});
        setTimeout(() => {
          this.router.navigate(['/dashboard']);
        }, 2000);
      } else {
        this.msgs = [];
        this.msgs.push({severity: 'error', summary:'Incorrect credentials', detail:'Incorrect username or password.'});
      }
    }, (error) => {
      this.isLoading = false;
      this.msgs = [];
      this.msgs.push({severity: 'error', summary:'Incorrect credentials', detail:'Incorrect username or password.'});
    });
  }

  forgotPassword(): void {
    this.router.navigate(['/forgot-password']);
  }

}
