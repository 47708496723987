import { Injectable } from '@angular/core';
import { LoginUserData } from '../../MumEat/interfaces/login-user-data.interface';
import { AuthService } from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class CommonService {

    public currentUser: LoginUserData = {
        username: '',
        roles: [],
        expires: 0,
        created: 0,
    };

    constructor() {
        let jsonAuth = localStorage.getItem(AuthService.ATUH_STORAGE_KEY);
        if (jsonAuth !== null) {
            const auth = JSON.parse(jsonAuth);
            this.currentUser = auth.data;
        }
    }

}
