import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { CommonService } from '../../../services/common/common.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { StepsDataService, StepsColumnsService } from '../../services';
import { DialogStepFormComponent } from './step-form/step-form.component';
import { DialogStepDetailsComponent } from './step-details/step-details.component';
import { Step, StepsResult } from '../../interfaces';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class StepsComponent implements OnInit, OnDestroy {

  private stepAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private stepEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private stepDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  public stepToDelete: Step = {};
  public steps: Step[] = [];
  public step: Step = {};
  public selectedSteps: Step[] = [];
  public submitted: boolean = false;
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '100'};
  public chunkSize: number = 200;
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  private _selectedColumns: Column[] = [];
  @ViewChild('importer', { static: false })
  public importer?: FileUpload;
  public importChooseLabel: string = 'Import';
  public importLabel: string = 'Import CSV';

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private common: CommonService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private stepsDataService: StepsDataService,
    private stepsColumnsService: StepsColumnsService,
  ) {
    this.importChooseLabel = this.translate.instant('IMPORT');
    this.importLabel = this.translate.instant('IMPORTCSV');
  }

  ngOnInit(): void {
    console.log('StepsComponent', 'ngOnInit');
    this.isInitialized = true;
    this.titleService.setTitle('Steps - Management');
    this.primengConfig.ripple = true;
    this.getSteps();
  }

  ngOnDestroy(): void {
    console.log('StepsComponent', 'ngOnDestroy');
    this.isInitialized = false;
  }

  getSteps(): void {
    if (!this.isInitialized) {
      return;
    }
    if (parseInt(this.parameters.offset + '', 10) === 0) {
      this.isLoading = true;
    }
    this.stepsDataService.getSteps(this.parameters).subscribe((response: StepsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        if (parseInt(this.parameters.offset + '', 10) === 0) {
          this.steps = response.results;
        } else {
          this.steps = this.steps.concat(response.results);
        }
        if (response.inlineCount > this.steps.length) {
          this.parameters.offset = (parseInt(this.parameters.offset + '', 10) + parseInt(this.parameters.limit + '', 10)) + '';
          this.getSteps();
        } else {
          this.parameters.offset = '0';
        }
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('STEPSNOTLOADED')});
    });
  }

  importSteps(event: any): void {
    if (typeof event.currentFiles === 'undefined') {
      return;
    }
    if (event.currentFiles.length === 0) {
      return;
    }
    const inputFile = event.currentFiles[0];
    const reader = new FileReader();
    let rows: any[] = [];
    reader.onload = (e: any) => {
      if (typeof this.importer !== 'undefined') {
        this.importer.clear();
      }
      const text = <string>e.target.result;
      rows = ToolsService.csvToArray(this.columns, text, ',');
      if (rows.length > 0) {
        let proceed = 0;
        const j = rows.length;
        for (let i = 0; i < j; i += this.chunkSize) {
          this.isLoading = true;
          const chunk = rows.slice(i, i + this.chunkSize);
          if (typeof chunk[0].id === 'undefined') {
            this.stepsDataService.addSteps(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('STEPSCREATED')});
                if (proceed === j) {
                  this.getSteps();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('STEPSNOTCREATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('STEPSNOTCREATED') + ' ' + msg});
            });
          } else {
            this.stepsDataService.updateSteps(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('STEPSUPDATED')});
                if (proceed === j) {
                  this.getSteps();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('STEPSNOTUPDATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('STEPSNOTCREATED') + ' ' + msg});
            });
          }
        }
      }
    };
    reader.readAsText(inputFile);
  }

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.stepsColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('steps.' + this.columns[i].field + '.showFiled', show);
    }
  }

  deleteSelectedSteps() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Steps?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.stepsDataService.deleteSteps(this.selectedSteps).subscribe((response) => {
          if (response === null) {
            this.messageService.clear();
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Steps Deleted', life: 3000});
            this.getSteps();
          } else {
            this.messageService.clear();
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Steps not deleted'});
          }
        }, (error: any) => {
          this.messageService.clear();
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Steps not deleted'});
        });
        this.selectedSteps = [];
      }
    });
  }

  exportPdf() {
    ToolsService.exportPdf(this.steps, this.columns, 'Steps');
  }

  exportExcel() {
    ToolsService.exportExcel(this.steps, this.columns, 'Steps');
  }

  exportCsv() {
    ToolsService.exportCsv(this.steps, this.columns, 'Steps');
  }

  refresh() {
    this.stepsDataService.clearCache();
    this.getSteps();
  }

  stepAdd(): void {
    if (!this.navigation.getParamValue('steps.addInDialog', true)) {
      this.router.navigate(['/steps/add']);
      return;
    }
    this.stepAddDialog = this.dialogService.open(DialogStepFormComponent, {
      header: '', // this.translate.instant('NEWSTEP'),
      width: '80%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'add',
        step: {id: null},
        recipes: this.stepsColumnsService.recipes,
        users: this.stepsColumnsService.users,
      },
    });

    this.stepAddDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'info', summary: 'Success', detail: 'Step added'});
        this.getSteps();
      }
    });
  }

  stepEdit(step: Step): void {
    if (!this.navigation.getParamValue('steps.editInDialog', true)) {
      this.router.navigate(['/steps/edit/' + step.id]);
      return;
    }
    this.stepEditDialog = this.dialogService.open(DialogStepFormComponent, {
      header: '', // this.translate.instant('EDITSTEP') + ' ' + step.description,
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'edit',
        step: step,
        recipes: this.stepsColumnsService.recipes,
        users: this.stepsColumnsService.users,
      },
    });

    this.stepEditDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Step updated'});
        this.getSteps();
      }
    });
  }

  stepDetails(step: Step): void {
    if (!this.navigation.getParamValue('steps.detailsInDialog', true)) {
      this.router.navigate(['/steps/details/' + step.id]);
      return;
    }
    this.stepDetailsDialog = this.dialogService.open(DialogStepDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        step: step,
      }
    });
  }

  stepDelete(step: Step): void {
    this.stepToDelete = step;
    this.messageService.clear();
    this.messageService.add({
      key: 'stepDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want delete ' + step.description + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.stepsDataService.deleteStep(this.stepToDelete).subscribe((res: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Step deleted'});
      this.getSteps();
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Step not deleted'});
    });
  }

  onDeleteReject(): void {
    this.stepToDelete = {};
    this.messageService.clear('stepDelete');
  }

}
