<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span>{{ 'CONVERTVIDEO' | translate }}</span>
          </ng-template>

          <div class="videogular-container">

            <ng-container *ngIf="recompile.player">

                <vg-player (onPlayerReady)="onPlayerReady($event)">

                  <vg-overlay-play></vg-overlay-play>

                  <vg-buffering></vg-buffering>

                  <vg-scrub-bar>
                      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                  </vg-scrub-bar>

                  <vg-controls>
                      <vg-play-pause></vg-play-pause>
                      <vg-playback-button></vg-playback-button>
                      <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
                      <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>
                      <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
                      <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
                      <vg-track-selector></vg-track-selector>
                      <vg-mute></vg-mute>
                      <vg-volume></vg-volume>
                      <vg-fullscreen></vg-fullscreen>
                  </vg-controls>

                  <video #myMedia [vgMedia]="$any(myMedia)" id="player-video">
                      <source [src]="config.sources[0].src" [type]="config.sources[0].type">
                  </video>

                </vg-player>

            </ng-container>

          </div>

          <div class="row my-2">
            <div class="col-md-3 text-center">
              <div class="block select-list">
                <p-dropdown [options]="ratios" [(ngModel)]="ratio" optionLabel="name"></p-dropdown>
              </div>
            </div>
            <div class="col-md-2">
              <button (click)="encode()" class="btn btn-primary pull-left">
                <i class="icon-video-clapper mr-1"></i>
                {{ 'ENCOCEVIDEO' | translate }}
              </button>
            </div>
            <div class="col-md-2 text-center">
              <button type="button" (click)="setDuration()" class="btn btn-primary">
                <i class="icon-timer mr-1"></i>
                {{ 'UPDATEDURATION' | translate }}
              </button>
            </div>
            <div class="col-md-3 text-center">
              <button type="button" (click)="setThumbnail()" class="btn btn-primary">
                <i class="icon-camera mr-1"></i>
                {{ 'GENERATETHUMB' | translate }}
              </button>
            </div>

            <div class="col-md-2">
              <button type="button" (click)="showSegments = !showSegments" class="btn btn-primary pull-right">
                <i class="icon-panel mr-1"></i>
                {{ 'REMOVESEGMENTS' | translate }}
              </button>
            </div>
          </div>

          <div class="row mb-2" [hidden]="!showSegments">
            <div class="col-md-8 text-center">
                <div class="row mb-2" *ngFor="let range of ranges; let i = index">
                  <div class="col-md-3">
                    <button class="btn btn-primary" (click)="removeItem(i)">-</button>
                  </div>
                  <div class="col-md-9 pt-2">
                    <p-slider [(ngModel)]="ranges[i]" (onChange)="handleSegmentChange($event, i)" [range]="true" [min]="0" [max]="max" [step]="1"></p-slider>
                  </div>
                </div>
            </div>
            <div class="col-md-2 text-center">
              <button class="btn btn-primary" (click)="addItem()">+</button>
            </div>
            <div class="col-md-2 text-center">
              <button class="btn btn-primary" (click)="removeSegments()"><i class="icon-check"></i></button>
            </div>
          </div>

          <div class="row mb-2">
            <p-progressBar *ngIf="rate !== -1" [value]="rate"></p-progressBar>
          </div>

        </p-fieldset>

        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>

      </div>
    </div>
    <!--/p-col-12-->
  </div>
  <!--/p-fluid p-grid-->
</div>
<!--/container-fluid container-fullw-->
<!-- end: FORM -->
