import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { ShoppingListIngredient, ShoppingListIngredientsResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ShoppingListIngredientsDataService {

  private uri: string = `${environment.apiUrl}shopping-list-ingredients`;
  private cacheKey: string = 'shoppingListIngredients.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getShoppingListIngredient(id: number): Observable<ShoppingListIngredient|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getShoppingListIngredients(parameters: {[param: string]: string | string[]}): Observable<ShoppingListIngredientsResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addShoppingListIngredient(data: ShoppingListIngredient): Observable<ShoppingListIngredient|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addShoppingListIngredients(data: ShoppingListIngredient[]): Observable<ShoppingListIngredient[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updateShoppingListIngredient(data: ShoppingListIngredient): Observable<ShoppingListIngredient|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updateShoppingListIngredients(data: ShoppingListIngredient[]): Observable<ShoppingListIngredient[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchShoppingListIngredient(data: ShoppingListIngredient): Observable<ShoppingListIngredient|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchShoppingListIngredients(data: ShoppingListIngredient[]): Observable<ShoppingListIngredient[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deleteShoppingListIngredient(data: ShoppingListIngredient): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deleteShoppingListIngredients(data: ShoppingListIngredient[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((shoppingListIngredient) => {
      ids.push(shoppingListIngredient.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
