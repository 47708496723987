import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../../services/navigation/navigation.service';
import { CommonService } from '../../../../services/common/common.service';
import { LogsDataService, LogsColumnsService } from '../../../services';
import { Log, LogsResult } from '../../../interfaces';
import { Column } from '../../../interfaces/column.interface';

@Component({
  selector: 'app-log-details',
  templateUrl: './log-details.component.html',
  styleUrls: ['./log-details.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class LogDetailsComponent implements OnInit, OnDestroy {

  public log: Log = {};
  public logs: Log[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public columns: Column[] = [];

  constructor(
    private translate: TranslateService,
    private common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private logsDataService: LogsDataService,
    private logsColumnsService: LogsColumnsService,

  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Log - Details');
        this.getLog(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.log !== 'undefined') {
      this.log = this.dynamicDialogConfig.data.log;
    }
    this.columns = this.logsColumnsService.getColumns();
  }

  ngOnDestroy(): void {
  }

  getLog(id: number): void {
    this.isLoading = true;
    this.logsDataService.getLog(id).subscribe((response: Log|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.log = response;
      }
    });
  }

  list() {
    this.router.navigate(['/logs']);
  }

  add() {
    this.router.navigate(['/logs/add']);
  }

  edit() {
    this.router.navigate(['/logs/edit/' + this.log.id]);
  }

}
@Component({
  selector: 'app-log-details',
  templateUrl: './log-details.component.html',
  styleUrls: ['./log-details.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogLogDetailsComponent extends LogDetailsComponent {
}

