import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { PostTypesDataService, PostTypesColumnsService } from '../../../services';
import { PostType, PostTypesResult } from '../../../interfaces';

@Component({
  selector: 'app-post-type-form',
  templateUrl: './post-type-form.component.html',
  styleUrls: ['./post-type-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class PostTypeFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public postType: PostType = {};
  public postTypeForm: FormGroup = new FormGroup({});
  public postTypes: PostType[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;


  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private postTypesDataService: PostTypesDataService,
    public postTypesColumnsService: PostTypesColumnsService,
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'PostTypeFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Post Type - Edit');
        this.getPostType(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.postType !== 'undefined') {
      this.postType = this.dynamicDialogConfig.data.postType;
    }
    if (typeof this.postType.id === 'undefined' || this.postType.id === null) {
      this.postType.id = null;
      this.postType.isPublished = false;
    }
    this.getPostTypes();
    this.prepareFrom();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'PostTypeFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'PostTypeFormComponent');
  }

  getPostType(id: number): void {
    this.isLoading = true;
    this.postTypesDataService.getPostType(id).subscribe((response: PostType|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.postType = response;
        this.prepareFrom();
      }
    });
  }

  getPostTypes(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.postTypes !== 'undefined' && this.dynamicDialogConfig.data.postTypes.length > 0) {
      this.postTypes = this.dynamicDialogConfig.data.postTypes;
      return;
    }
    this.postTypesDataService.getPostTypes(this.parameters).subscribe((response: PostTypesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.postTypes = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.postTypeForm = new FormGroup({
      id: new FormControl(this.postType.id, []),
      name: new FormControl(this.postType.name, [Validators.required, ]),
      nameAr: new FormControl(this.postType.nameAr, []),
      nameFr: new FormControl(this.postType.nameFr, []),
      slug: new FormControl(this.postType.slug, [Validators.required, ]),
      slugAr: new FormControl(this.postType.slugAr, []),
      slugFr: new FormControl(this.postType.slugFr, []),
      isPublished: new FormControl(this.postType.isPublished, [Validators.required, ]),
    });
  }

  list() {
    this.router.navigate(['/post-types']);
  }

  showErrors() {
      this.showErrors();
      const invalidFields: string[] = [];
      Object.keys(this.postTypeForm.controls).forEach((field: any) => {
        const control = this.postTypeForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.postTypeForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.postType = this.postTypeForm.value;
    this.disableSubmit = true;
    console.log('postType', this.postType);
    if (typeof this.postType.id !== 'undefined' && this.postType.id !== null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.postTypesDataService.updatePostType(this.postType).subscribe((data: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('POSTTYPEUPDATED')});
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('POSTTYPENOTUPDATED') + ' ' + error.message});
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.postTypesDataService.addPostType(this.postType).subscribe((data: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('POSTTYPECREATED')});
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('POSTTYPENOTCREATED') + ' ' + error.message});
        console.warn(error);
    });
  }

}

@Component({
  selector: 'app-post-type-form',
  templateUrl: './post-type-form.component.html',
  styleUrls: ['./post-type-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogPostTypeFormComponent extends PostTypeFormComponent {
}

