import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../../services/navigation/navigation.service';
import { CommonService } from '../../../../services/common/common.service';
import { RecipesDataService, RecipesColumnsService } from '../../../services';
import { Recipe, RecipesResult } from '../../../interfaces';
import { Column } from '../../../interfaces/column.interface';

@Component({
  selector: 'app-recipe-details',
  templateUrl: './recipe-details.component.html',
  styleUrls: ['./recipe-details.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class RecipeDetailsComponent implements OnInit, OnDestroy {

  public recipe: Recipe = {};
  public recipes: Recipe[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public columns: Column[] = [];

  constructor(
    private translate: TranslateService,
    private common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private recipesDataService: RecipesDataService,
    private recipesColumnsService: RecipesColumnsService,

  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Recipe - Details');
        this.getRecipe(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.recipe !== 'undefined') {
      this.recipe = this.dynamicDialogConfig.data.recipe;
    }
    this.columns = this.recipesColumnsService.getColumns();
  }

  ngOnDestroy(): void {
  }

  getRecipe(id: number): void {
    this.isLoading = true;
    this.recipesDataService.getRecipe(id).subscribe((response: Recipe|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.recipe = response;
      }
    });
  }

  list() {
    this.router.navigate(['/recipes']);
  }

  add() {
    this.router.navigate(['/recipes/add']);
  }

  edit() {
    this.router.navigate(['/recipes/edit/' + this.recipe.id]);
  }

}
@Component({
  selector: 'app-recipe-details',
  templateUrl: './recipe-details.component.html',
  styleUrls: ['./recipe-details.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogRecipeDetailsComponent extends RecipeDetailsComponent {
}

