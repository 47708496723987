import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CacheService } from '../../services/cache/cache.service';
import { CommonService } from '../../services/common/common.service';
import { Column } from '../interfaces/column.interface';
import moment from 'moment';
import { ShoppingListsDataService } from './shopping-lists-data.service';
import { ShoppingList, ShoppingListsResult } from '../interfaces';
import { RecipesDataService } from './recipes-data.service';
import { Recipe, RecipesResult } from '../interfaces';
import { UsersDataService } from './users-data.service';
import { User, UsersResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ShoppingListRecipesColumnsService {

  public shoppingLists: ShoppingList[] = [];
  public recipes: Recipe[] = [];
  public users: User[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private cache: CacheService,
    private common: CommonService,
    private shoppingListsDataService: ShoppingListsDataService,
    private recipesDataService: RecipesDataService,
    private usersDataService: UsersDataService,

  ) {
    this.getShoppingLists()
    this.getRecipes()
    this.getUsers()
  }

  getShoppingLists(offset: number = 0): void {
    const cacheKey: string = 'shoppingLists.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.shoppingLists = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.shoppingListsDataService.getShoppingLists(parameters).subscribe((response: ShoppingListsResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.shoppingLists = response.results;
        } else {
          this.shoppingLists = this.shoppingLists.concat(response.results);
        }
        if (response.inlineCount > this.shoppingLists.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getShoppingLists(offset);
        }
        response.results = this.shoppingLists;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getRecipes(offset: number = 0): void {
    const cacheKey: string = 'recipes.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.recipes = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.recipesDataService.getRecipes(parameters).subscribe((response: RecipesResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.recipes = response.results;
        } else {
          this.recipes = this.recipes.concat(response.results);
        }
        if (response.inlineCount > this.recipes.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getRecipes(offset);
        }
        response.results = this.recipes;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getUsers(offset: number = 0): void {
    const cacheKey: string = 'users.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.users = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.usersDataService.getUsers(parameters).subscribe((response: UsersResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.users = response.results;
        } else {
          this.users = this.users.concat(response.results);
        }
        if (response.inlineCount > this.users.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getUsers(offset);
        }
        response.results = this.users;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getColumns(): Column[] {
    return [
      { field: 'id', sortField: 'id', title: this.translate.instant('ID'), show: (this.navigation.getParamValue('shoppingListRecipes.id.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'shoppingList', title: this.translate.instant('SHOPPINGLIST'), type: 'linkValue', filterData: this.shoppingLists, show: (this.navigation.getParamValue('shoppingListRecipes.shoppingListId.showFiled', true) && true), displayInList: true, filterField: 'shoppingList.id', displayField: 'name', sortField: 'shoppingList.name', url: 'shopping-lists' },
      { field: 'recipe', title: this.translate.instant('RECIPE'), type: 'linkValue', filterData: this.recipes, show: (this.navigation.getParamValue('shoppingListRecipes.recipeId.showFiled', true) && true), displayInList: true, filterField: 'recipe.id', displayField: 'name', sortField: 'recipe.name', url: 'recipes' },
      { field: 'servingSize', sortField: 'servingSize', title: this.translate.instant('SERVINGSIZE'), show: (this.navigation.getParamValue('shoppingListRecipes.servingSize.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'ordering', sortField: 'ordering', title: this.translate.instant('ORDERING'), show: (this.navigation.getParamValue('shoppingListRecipes.ordering.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'createdAt', sortField: 'createdAt', title: this.translate.instant('CREATEDAT'), show: (this.navigation.getParamValue('shoppingListRecipes.createdAt.showFiled', true) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'creatorUser', title: this.translate.instant('CREATORUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('shoppingListRecipes.creatorUserId.showFiled', true) && true), displayInList: true, filterField: 'creatorUser.id', displayField: 'username', sortField: 'creatorUser.username', url: 'users' },
      { field: 'modifiedAt', sortField: 'modifiedAt', title: this.translate.instant('MODIFIEDAT'), show: (this.navigation.getParamValue('shoppingListRecipes.modifiedAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'modifierUser', title: this.translate.instant('MODIFIERUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('shoppingListRecipes.modifierUser.showFiled', false) && true), displayInList: true, filterField: 'modifierUser.id', displayField: 'username', sortField: 'modifierUser.username', url: 'users' },
      { field: 'actions', title: this.translate.instant('ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }

  format(fieldName: string, value: any) {
    if (fieldName.toLowerCase().indexOf('time') > -1) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    } else if (fieldName.toLowerCase().indexOf('date') > -1) {
      return moment(value).format('YYYY-MM-DD');
    }
    return value;
  }

}
