import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { Video, VideosResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class VideosDataService {

  private uri: string = `${environment.apiUrl}videos`;
  private cacheKey: string = 'videos.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getVideo(id: number): Observable<Video|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getVideos(parameters: {[param: string]: string | string[]}): Observable<VideosResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addVideo(data: Video): Observable<Video|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addVideos(data: Video[]): Observable<Video[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updateVideo(data: Video): Observable<Video|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updateVideos(data: Video[]): Observable<Video[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchVideo(data: Video): Observable<Video|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchVideos(data: Video[]): Observable<Video[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deleteVideo(data: Video): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deleteVideos(data: Video[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((video) => {
      ids.push(video.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
