import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { SharingsDataService, SharingsColumnsService } from '../../../services';
import { Sharing, SharingsResult } from '../../../interfaces';

@Component({
  selector: 'app-sharing-form',
  templateUrl: './sharing-form.component.html',
  styleUrls: ['./sharing-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class SharingFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public sharing: Sharing = {};
  public sharingForm: FormGroup = new FormGroup({});
  public sharings: Sharing[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;


  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private sharingsDataService: SharingsDataService,
    public sharingsColumnsService: SharingsColumnsService,
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'SharingFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Sharing - Edit');
        this.getSharing(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.sharing !== 'undefined') {
      this.sharing = this.dynamicDialogConfig.data.sharing;
    }
    if (typeof this.sharing.id === 'undefined' || this.sharing.id === null) {
      this.sharing.id = null;
    }
    this.getSharings();
    this.prepareFrom();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'SharingFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'SharingFormComponent');
  }

  getSharing(id: number): void {
    this.isLoading = true;
    this.sharingsDataService.getSharing(id).subscribe((response: Sharing|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.sharing = response;
        this.prepareFrom();
      }
    });
  }

  getSharings(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.sharings !== 'undefined' && this.dynamicDialogConfig.data.sharings.length > 0) {
      this.sharings = this.dynamicDialogConfig.data.sharings;
      return;
    }
    this.sharingsDataService.getSharings(this.parameters).subscribe((response: SharingsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.sharings = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.sharingForm = new FormGroup({
      id: new FormControl(this.sharing.id, []),
      name: new FormControl(this.sharing.name, [Validators.required, ]),
      nameAr: new FormControl(this.sharing.nameAr, []),
      nameFr: new FormControl(this.sharing.nameFr, []),
      mode: new FormControl(this.sharing.mode, [Validators.required, ]),
      percentage: new FormControl(this.sharing.percentage, [Validators.required, ]),
      currency: new FormControl(this.sharing.currency, [Validators.required, ]),
      amount: new FormControl(this.sharing.amount, [Validators.required, ]),
    });
  }

  list() {
    this.router.navigate(['/sharings']);
  }

  showErrors() {
      this.showErrors();
      const invalidFields: string[] = [];
      Object.keys(this.sharingForm.controls).forEach((field: any) => {
        const control = this.sharingForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.sharingForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.sharing = this.sharingForm.value;
    this.disableSubmit = true;
    console.log('sharing', this.sharing);
    if (typeof this.sharing.id !== 'undefined' && this.sharing.id !== null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.sharingsDataService.updateSharing(this.sharing).subscribe((data: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('SHARINGUPDATED')});
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SHARINGNOTUPDATED') + ' ' + error.message});
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.sharingsDataService.addSharing(this.sharing).subscribe((data: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('SHARINGCREATED')});
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SHARINGNOTCREATED') + ' ' + error.message});
        console.warn(error);
    });
  }

}

@Component({
  selector: 'app-sharing-form',
  templateUrl: './sharing-form.component.html',
  styleUrls: ['./sharing-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogSharingFormComponent extends SharingFormComponent {
}

