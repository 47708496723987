<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="video.id === null">{{ 'NEWVIDEO' | translate }}</span>
            <span *ngIf="video.id !== null">{{ 'EDITVIDEO' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="videoForm" id="videoForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="videoForm" >
            <input type="hidden" name="id" id="videoId" formControlName="id" />
            <div class="p-fluid p-grid">
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'VIDEOA' | translate }}</h2>
              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-video-camera"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="videoShowId" [autoDisplayFirst]="false" formControlName="show" [options]="videosColumnsService.shows" optionValue="id" optionLabel="name" [required]="false" [filter]="true"></p-dropdown>
                      <label for="videoShowId" class="">{{ 'SHOW' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') === -1" >
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-file-video"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="videoVideoTypeId" [autoDisplayFirst]="false" formControlName="videoType" [options]="videosColumnsService.videoTypes" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="videoVideoTypeId" class="req">{{ 'VIDEOTYPE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="videoForm.controls['videoType'].invalid && (videoForm.controls['videoType'].dirty || videoForm.controls['videoType'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoForm.controls['videoType'].errors !== null">
                  <div *ngIf="videoForm.controls['videoType'].errors.required">
                   {{ 'VIDEOTYPEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') === -1" >
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-money"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="videoPriceId" [autoDisplayFirst]="false" formControlName="price" [options]="videosColumnsService.prices" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="videoPriceId" class="req">{{ 'PRICE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="videoForm.controls['price'].invalid && (videoForm.controls['price'].dirty || videoForm.controls['price'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoForm.controls['price'].errors !== null">
                  <div *ngIf="videoForm.controls['price'].errors.required">
                   {{ 'PRICEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_EXPERT') === -1" >
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-pie-chart"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="videoSharingId" [autoDisplayFirst]="false" formControlName="sharing" [options]="videosColumnsService.sharings" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="videoSharingId" class="req">{{ 'SHARING' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="videoForm.controls['sharing'].invalid && (videoForm.controls['sharing'].dirty || videoForm.controls['sharing'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoForm.controls['sharing'].errors !== null">
                  <div *ngIf="videoForm.controls['sharing'].errors.required">
                   {{ 'SHARINGREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-tag"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoName" formControlName="name" type="text" pInputText [required]="true"> 
                      <label for="videoName" class="req">{{ 'NAME' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="videoForm.controls['name'].invalid && (videoForm.controls['name'].dirty || videoForm.controls['name'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoForm.controls['name'].errors !== null">
                  <div *ngIf="videoForm.controls['name'].errors.required">
                   {{ 'NAMEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoNameAr" formControlName="nameAr" type="text" pInputText [required]="false"> 
                      <label for="videoNameAr" class="">{{ 'NAMEAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoNameFr" formControlName="nameFr" type="text" pInputText [required]="false"> 
                      <label for="videoNameFr" class="">{{ 'NAMEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-timer"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="videoDuration" formControlName="duration" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="videoDuration" class="">{{ 'DURATION' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-image"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoPicture" formControlName="picture" type="text" pInputText [required]="false"> 
                      <label for="videoPicture" class="">{{ 'PICTURE' | translate }}</label>
                    </span>
                    <button type="button" pButton pRipple icon="pi pi-folder" styleClass="p-button-info" (click)="openFilerDialog('picture')"></button> 
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-text"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoWatermarkText" formControlName="watermarkText" type="text" pInputText [required]="false"> 
                      <label for="videoWatermarkText" class="">{{ 'WATERMARKTEXT' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-folder"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoFile" formControlName="file" type="text" pInputText [required]="false"> 
                      <label for="videoFile" class="">{{ 'FILE' | translate }}</label>
                    </span>
                    <button type="button" pButton pRipple icon="pi pi-folder" styleClass="p-button-info" (click)="openFilerDialog('file')"></button> 
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-paperclip"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="videoFileSize" formControlName="fileSize" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="videoFileSize" class="">{{ 'FILESIZE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-info-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoDescription" formControlName="description" type="text" pInputText [required]="false"> 
                      <label for="videoDescription" class="">{{ 'DESCRIPTION' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-align-justify"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoDescriptionAr" formControlName="descriptionAr" type="text" pInputText [required]="false"> 
                      <label for="videoDescriptionAr" class="">{{ 'DESCRIPTIONAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-align-justify"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoDescriptionFr" formControlName="descriptionFr" type="text" pInputText [required]="false"> 
                      <label for="videoDescriptionFr" class="">{{ 'DESCRIPTIONFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="videoEnableStreaming" formControlName="enableStreaming" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="videoEnableStreaming" class="p-d-inline-block p-ml-2 req">{{ 'ENABLESTREAMING' | translate }}</label>
                </div>
                <div *ngIf="videoForm.controls['enableStreaming'].invalid && (videoForm.controls['enableStreaming'].dirty || videoForm.controls['enableStreaming'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoForm.controls['enableStreaming'].errors !== null">
                  <div *ngIf="videoForm.controls['enableStreaming'].errors.required">
                   {{ 'ENABLESTREAMINGREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-grav"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="videoSeasonNumber" formControlName="seasonNumber" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="videoSeasonNumber" class="">{{ 'SEASONNUMBER' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-sort-numeric-asc "></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="videoEpisodeNumber" formControlName="episodeNumber" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="videoEpisodeNumber" class="">{{ 'EPISODENUMBER' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'VIDEOB' | translate }}</h2>
              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="videoIsTop" formControlName="isTop" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="videoIsTop" class="p-d-inline-block p-ml-2 req">{{ 'ISTOP' | translate }}</label>
                </div>
                <div *ngIf="videoForm.controls['isTop'].invalid && (videoForm.controls['isTop'].dirty || videoForm.controls['isTop'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoForm.controls['isTop'].errors !== null">
                  <div *ngIf="videoForm.controls['isTop'].errors.required">
                   {{ 'ISTOPREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="videoIsNew" formControlName="isNew" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="videoIsNew" class="p-d-inline-block p-ml-2 req">{{ 'ISNEW' | translate }}</label>
                </div>
                <div *ngIf="videoForm.controls['isNew'].invalid && (videoForm.controls['isNew'].dirty || videoForm.controls['isNew'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoForm.controls['isNew'].errors !== null">
                  <div *ngIf="videoForm.controls['isNew'].errors.required">
                   {{ 'ISNEWREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12" [hidden]="common.currentUser.roles.join('').indexOf('ROLE_ADMIN_PUBLISHER') === -1" >
                  <label for="videoStatus" class="req">{{ 'STATUS' | translate }}</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-checkbox" *ngFor="let option of videosColumnsService.statusesOptions; let i = index">
                      <p-radioButton inputId="videoStatus_{{ i }}" formControlName="status" name="status" [value]="option.value"></p-radioButton>
                      <label for="videoStatus_{{ i }}">{{ option.label | translate }}</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="videoForm.controls['status'].invalid && (videoForm.controls['status'].dirty || videoForm.controls['status'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoForm.controls['status'].errors !== null">
                  <div *ngIf="videoForm.controls['status'].errors.required">
                   {{ 'STATUSREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="videoAutoPublishing" formControlName="autoPublishing" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="videoAutoPublishing" class="p-d-inline-block p-ml-2 req">{{ 'AUTOPUBLISHING' | translate }}</label>
                </div>
                <div *ngIf="videoForm.controls['autoPublishing'].invalid && (videoForm.controls['autoPublishing'].dirty || videoForm.controls['autoPublishing'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoForm.controls['autoPublishing'].errors !== null">
                  <div *ngIf="videoForm.controls['autoPublishing'].errors.required">
                   {{ 'AUTOPUBLISHINGREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-control-skip-forward"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="videoStartPublishing" formControlName="startPublishing" [showTime]="true" hourFormat="24" [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [required]="false" yearRange="1900:2030" [stepHour]="1" [stepMinute]="15" [stepSecond]="0"></p-calendar>
                      <label for="videoStartPublishing" class="">{{ 'STARTPUBLISHING' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-control-skip-backward"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="videoEndPublishing" formControlName="endPublishing" [showTime]="true" hourFormat="24" [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [required]="false" yearRange="1900:2030" [stepHour]="1" [stepMinute]="15" [stepSecond]="0"></p-calendar>
                      <label for="videoEndPublishing" class="">{{ 'ENDPUBLISHING' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'VIDEOC' | translate }}</h2>
              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-search"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="videoTotalPreviewed" formControlName="totalPreviewed" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="videoTotalPreviewed" class="">{{ 'TOTALPREVIEWED' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-download"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="videoTotalDownloads" formControlName="totalDownloads" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="videoTotalDownloads" class="">{{ 'TOTALDOWNLOADS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-eye"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="videoTotalHits" formControlName="totalHits" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="videoTotalHits" class="">{{ 'TOTALHITS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-comments"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="videoTotalComments" formControlName="totalComments" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="videoTotalComments" class="">{{ 'TOTALCOMMENTS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-star-half-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="videoTotalRatings" formControlName="totalRatings" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="videoTotalRatings" class="">{{ 'TOTALRATINGS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-pie-chart"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="videoAverageRatings" formControlName="averageRatings" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="videoAverageRatings" class="">{{ 'AVERAGERATINGS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-thumbs-down"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="videoTotalLikes" formControlName="totalLikes" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="videoTotalLikes" class="">{{ 'TOTALLIKES' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-thumbs-up"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="videoTotalDislikes" formControlName="totalDislikes" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="videoTotalDislikes" class="">{{ 'TOTALDISLIKES' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-bookmark"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="videoTotalBookmarks" formControlName="totalBookmarks" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="videoTotalBookmarks" class="">{{ 'TOTALBOOKMARKS' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'VIDEOD' | translate }}</h2>
              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoSlug" formControlName="slug" type="text" pInputText [required]="false"> 
                      <label for="videoSlug" class="">{{ 'SLUG' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoSlugAr" formControlName="slugAr" type="text" pInputText [required]="false"> 
                      <label for="videoSlugAr" class="">{{ 'SLUGAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-anchor"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoSlugFr" formControlName="slugFr" type="text" pInputText [required]="false"> 
                      <label for="videoSlugFr" class="">{{ 'SLUGFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="videoRemoteSource" formControlName="remoteSource" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="videoRemoteSource" class="p-d-inline-block p-ml-2 req">{{ 'REMOTESOURCE' | translate }}</label>
                </div>
                <div *ngIf="videoForm.controls['remoteSource'].invalid && (videoForm.controls['remoteSource'].dirty || videoForm.controls['remoteSource'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoForm.controls['remoteSource'].errors !== null">
                  <div *ngIf="videoForm.controls['remoteSource'].errors.required">
                   {{ 'REMOTESOURCEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <span class="p-float-label">
                    <textarea id="videoUrl" formControlName="url" [rows]="5" [cols]="30" pInputTextarea [autoResize]="true" [required]="false"></textarea>
                    <label for="videoUrl" class="">{{ 'URL' | translate }}</label>
                  </span>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <span class="p-float-label">
                    <textarea id="videoAlternativeUrl" formControlName="alternativeUrl" [rows]="5" [cols]="30" pInputTextarea [autoResize]="true" [required]="false"></textarea>
                    <label for="videoAlternativeUrl" class="">{{ 'ALTERNATIVEURL' | translate }}</label>
                  </span>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-copyright"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoCopyright" formControlName="copyright" type="text" pInputText [required]="false"> 
                      <label for="videoCopyright" class="">{{ 'COPYRIGHT' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'VIDEOZ' | translate }}</h2>
              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-folder"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="videoCategoryId" [autoDisplayFirst]="false" formControlName="category" [options]="videosColumnsService.categories" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="videoCategoryId" class="req">{{ 'CATEGORY' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="videoForm.controls['category'].invalid && (videoForm.controls['category'].dirty || videoForm.controls['category'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoForm.controls['category'].errors !== null">
                  <div *ngIf="videoForm.controls['category'].errors.required">
                   {{ 'CATEGORYREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-folder"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="videoSubCategoryId" [autoDisplayFirst]="false" formControlName="subCategory" [options]="videosColumnsService.subCategories" optionValue="id" optionLabel="name" [required]="false" [filter]="true"></p-dropdown>
                      <label for="videoSubCategoryId" class="">{{ 'SUBCATEGORY' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKVIDEOS' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="video.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!videoForm.valid || disableSubmit"> <!--  [disabled]="videoForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!videoForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

