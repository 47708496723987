import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { PacksDataService, PacksColumnsService } from '../../../services';
import { Pack, PacksResult } from '../../../interfaces';
import { DialogFilerComponent } from '../../filer/filer.component';

@Component({
  selector: 'app-pack-form',
  templateUrl: './pack-form.component.html',
  styleUrls: ['./pack-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class PackFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public pack: Pack = {};
  public packForm: FormGroup = new FormGroup({});
  public packs: Pack[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;

  private filerDialog: DynamicDialogRef = new DynamicDialogRef();

  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private packsDataService: PacksDataService,
    public packsColumnsService: PacksColumnsService,
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'PackFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Pack - Edit');
        this.getPack(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.pack !== 'undefined') {
      this.pack = this.dynamicDialogConfig.data.pack;
    }
    if (typeof this.pack.id === 'undefined' || this.pack.id === null) {
      this.pack.id = null;
      this.pack.isPublished = false;
    }
    this.getPacks();
    this.prepareFrom();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'PackFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'PackFormComponent');
  }

  getPack(id: number): void {
    this.isLoading = true;
    this.packsDataService.getPack(id).subscribe((response: Pack|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.pack = response;
        this.prepareFrom();
      }
    });
  }

  getPacks(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.packs !== 'undefined' && this.dynamicDialogConfig.data.packs.length > 0) {
      this.packs = this.dynamicDialogConfig.data.packs;
      return;
    }
    this.packsDataService.getPacks(this.parameters).subscribe((response: PacksResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.packs = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.packForm = new FormGroup({
      id: new FormControl(this.pack.id, []),
      packageType: new FormControl((typeof this.pack.packageType === 'number') ? this.pack.packageType : this.pack.packageType?.id, [Validators.required, ]),
      name: new FormControl(this.pack.name, [Validators.required, ]),
      nameAr: new FormControl(this.pack.nameAr, []),
      nameFr: new FormControl(this.pack.nameFr, []),
      picture: new FormControl(this.pack.picture, []),
      description: new FormControl(this.pack.description, []),
      descriptionAr: new FormControl(this.pack.descriptionAr, []),
      descriptionFr: new FormControl(this.pack.descriptionFr, []),
      price: new FormControl((typeof this.pack.price === 'number') ? this.pack.price : this.pack.price?.id, [Validators.required, ]),
      operatorKey: new FormControl(this.pack.operatorKey, [Validators.required, ]),
      mode: new FormControl(this.pack.mode, [Validators.required, ]),
      isPublished: new FormControl(this.pack.isPublished, [Validators.required, ]),
      duration: new FormControl(this.pack.duration, [Validators.required, ]),
    });
  }

  list() {
    this.router.navigate(['/packs']);
  }

  showErrors() {
      this.showErrors();
      const invalidFields: string[] = [];
      Object.keys(this.packForm.controls).forEach((field: any) => {
        const control = this.packForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.packForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.pack = this.packForm.value;
    this.disableSubmit = true;
    console.log('pack', this.pack);
    if (typeof this.pack.id !== 'undefined' && this.pack.id !== null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.packsDataService.updatePack(this.pack).subscribe((data: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('PACKUPDATED')});
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('PACKNOTUPDATED') + ' ' + error.message});
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.packsDataService.addPack(this.pack).subscribe((data: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('PACKCREATED')});
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('PACKNOTCREATED') + ' ' + error.message});
        console.warn(error);
    });
  }

  openFilerDialog(field: string): void {
    let url = '';
    const control = this.packForm.get(field);
    if (control !== null) {
      url = control.value;
    }
    this.filerDialog = this.dialogService.open(DialogFilerComponent, {
      header: '', // this.translate.instant('FILEMANAGER'),
      width: '90%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1500,
      data: {
        path: 'packs',
        field: field,
        url: url,
      },
    });
    this.filerDialog.onClose.subscribe((response: any) => {
      if (response && response.selected) {
        const control = this.packForm.get(field);
        if (control !== null) {
          control.setValue(response.selected.webPath);
        }
      }
    });
  }

}

@Component({
  selector: 'app-pack-form',
  templateUrl: './pack-form.component.html',
  styleUrls: ['./pack-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogPackFormComponent extends PackFormComponent {
}

