import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../../services/navigation/navigation.service';
import { CommonService } from '../../../../services/common/common.service';
import { ReviewsDataService, ReviewsColumnsService } from '../../../services';
import { Review, ReviewsResult } from '../../../interfaces';
import { Column } from '../../../interfaces/column.interface';

@Component({
  selector: 'app-review-details',
  templateUrl: './review-details.component.html',
  styleUrls: ['./review-details.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class ReviewDetailsComponent implements OnInit, OnDestroy {

  public review: Review = {};
  public reviews: Review[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public columns: Column[] = [];

  constructor(
    private translate: TranslateService,
    private common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private reviewsDataService: ReviewsDataService,
    private reviewsColumnsService: ReviewsColumnsService,

  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Review - Details');
        this.getReview(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.review !== 'undefined') {
      this.review = this.dynamicDialogConfig.data.review;
    }
    this.columns = this.reviewsColumnsService.getColumns();
  }

  ngOnDestroy(): void {
  }

  getReview(id: number): void {
    this.isLoading = true;
    this.reviewsDataService.getReview(id).subscribe((response: Review|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.review = response;
      }
    });
  }

  list() {
    this.router.navigate(['/reviews']);
  }

  add() {
    this.router.navigate(['/reviews/add']);
  }

  edit() {
    this.router.navigate(['/reviews/edit/' + this.review.id]);
  }

}
@Component({
  selector: 'app-review-details',
  templateUrl: './review-details.component.html',
  styleUrls: ['./review-details.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogReviewDetailsComponent extends ReviewDetailsComponent {
}

