import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { Like, LikesResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class LikesDataService {

  private uri: string = `${environment.apiUrl}likes`;
  private cacheKey: string = 'likes.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getLike(id: number): Observable<Like|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getLikes(parameters: {[param: string]: string | string[]}): Observable<LikesResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addLike(data: Like): Observable<Like|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addLikes(data: Like[]): Observable<Like[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updateLike(data: Like): Observable<Like|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updateLikes(data: Like[]): Observable<Like[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchLike(data: Like): Observable<Like|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchLikes(data: Like[]): Observable<Like[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deleteLike(data: Like): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deleteLikes(data: Like[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((like) => {
      ids.push(like.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
