import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../../services/navigation/navigation.service';
import { CommonService } from '../../../../services/common/common.service';
import { ShoppingListIngredientsDataService, ShoppingListIngredientsColumnsService } from '../../../services';
import { ShoppingListIngredient, ShoppingListIngredientsResult } from '../../../interfaces';
import { Column } from '../../../interfaces/column.interface';

@Component({
  selector: 'app-shopping-list-ingredient-details',
  templateUrl: './shopping-list-ingredient-details.component.html',
  styleUrls: ['./shopping-list-ingredient-details.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class ShoppingListIngredientDetailsComponent implements OnInit, OnDestroy {

  public shoppingListIngredient: ShoppingListIngredient = {};
  public shoppingListIngredients: ShoppingListIngredient[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public columns: Column[] = [];

  constructor(
    private translate: TranslateService,
    private common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private shoppingListIngredientsDataService: ShoppingListIngredientsDataService,
    private shoppingListIngredientsColumnsService: ShoppingListIngredientsColumnsService,

  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Shopping List Ingredient - Details');
        this.getShoppingListIngredient(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.shoppingListIngredient !== 'undefined') {
      this.shoppingListIngredient = this.dynamicDialogConfig.data.shoppingListIngredient;
    }
    this.columns = this.shoppingListIngredientsColumnsService.getColumns();
  }

  ngOnDestroy(): void {
  }

  getShoppingListIngredient(id: number): void {
    this.isLoading = true;
    this.shoppingListIngredientsDataService.getShoppingListIngredient(id).subscribe((response: ShoppingListIngredient|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.shoppingListIngredient = response;
      }
    });
  }

  list() {
    this.router.navigate(['/shopping-list-ingredients']);
  }

  add() {
    this.router.navigate(['/shopping-list-ingredients/add']);
  }

  edit() {
    this.router.navigate(['/shopping-list-ingredients/edit/' + this.shoppingListIngredient.id]);
  }

}
@Component({
  selector: 'app-shopping-list-ingredient-details',
  templateUrl: './shopping-list-ingredient-details.component.html',
  styleUrls: ['./shopping-list-ingredient-details.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogShoppingListIngredientDetailsComponent extends ShoppingListIngredientDetailsComponent {
}

