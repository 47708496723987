import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { FavoritesDataService, FavoritesColumnsService } from '../../../services';
import { Favorite, FavoritesResult } from '../../../interfaces';

@Component({
  selector: 'app-favorite-form',
  templateUrl: './favorite-form.component.html',
  styleUrls: ['./favorite-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class FavoriteFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public favorite: Favorite = {};
  public favoriteForm: FormGroup = new FormGroup({});
  public favorites: Favorite[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;


  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private favoritesDataService: FavoritesDataService,
    public favoritesColumnsService: FavoritesColumnsService,
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', 'FavoriteFormComponent');
    this.route.params.subscribe((params: any) => {
      if (typeof params['id'] !== 'undefined') {
        this.titleService.setTitle('Favorite - Edit');
        this.getFavorite(parseInt(params['id'], 10));
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.favorite !== 'undefined') {
      this.favorite = this.dynamicDialogConfig.data.favorite;
    }
    if (typeof this.favorite.id === 'undefined' || this.favorite.id === null) {
      this.favorite.id = null;
    }
    this.getFavorites();
    this.prepareFrom();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy', 'FavoriteFormComponent');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit', 'FavoriteFormComponent');
  }

  getFavorite(id: number): void {
    this.isLoading = true;
    this.favoritesDataService.getFavorite(id).subscribe((response: Favorite|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.favorite = response;
        this.prepareFrom();
      }
    });
  }

  getFavorites(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.favorites !== 'undefined' && this.dynamicDialogConfig.data.favorites.length > 0) {
      this.favorites = this.dynamicDialogConfig.data.favorites;
      return;
    }
    this.favoritesDataService.getFavorites(this.parameters).subscribe((response: FavoritesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.favorites = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.favoriteForm = new FormGroup({
      id: new FormControl(this.favorite.id, []),
      entity: new FormControl(this.favorite.entity, [Validators.required, ]),
      foreignKey: new FormControl(this.favorite.foreignKey, [Validators.required, ]),
      value: new FormControl(this.favorite.value, [Validators.required, ]),
    });
  }

  list() {
    this.router.navigate(['/favorites']);
  }

  showErrors() {
      this.showErrors();
      const invalidFields: string[] = [];
      Object.keys(this.favoriteForm.controls).forEach((field: any) => {
        const control = this.favoriteForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.favoriteForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.favorite = this.favoriteForm.value;
    this.disableSubmit = true;
    console.log('favorite', this.favorite);
    if (typeof this.favorite.id !== 'undefined' && this.favorite.id !== null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.favoritesDataService.updateFavorite(this.favorite).subscribe((data: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('FAVORITEUPDATED')});
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('FAVORITENOTUPDATED') + ' ' + error.message});
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.favoritesDataService.addFavorite(this.favorite).subscribe((data: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: this.translate.instant('FAVORITECREATED')});
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('FAVORITENOTCREATED') + ' ' + error.message});
        console.warn(error);
    });
  }

}

@Component({
  selector: 'app-favorite-form',
  templateUrl: './favorite-form.component.html',
  styleUrls: ['./favorite-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogFavoriteFormComponent extends FavoriteFormComponent {
}

