import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { CommonService } from '../../../services/common/common.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { ShoppingListIngredientsDataService, ShoppingListIngredientsColumnsService } from '../../services';
import { DialogShoppingListIngredientFormComponent } from './shopping-list-ingredient-form/shopping-list-ingredient-form.component';
import { DialogShoppingListIngredientDetailsComponent } from './shopping-list-ingredient-details/shopping-list-ingredient-details.component';
import { ShoppingListIngredient, ShoppingListIngredientsResult } from '../../interfaces';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-shopping-list-ingredients',
  templateUrl: './shopping-list-ingredients.component.html',
  styleUrls: ['./shopping-list-ingredients.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class ShoppingListIngredientsComponent implements OnInit, OnDestroy {

  private shoppingListIngredientAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private shoppingListIngredientEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private shoppingListIngredientDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  public shoppingListIngredientToDelete: ShoppingListIngredient = {};
  public shoppingListIngredients: ShoppingListIngredient[] = [];
  public shoppingListIngredient: ShoppingListIngredient = {};
  public selectedShoppingListIngredients: ShoppingListIngredient[] = [];
  public submitted: boolean = false;
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '100'};
  public chunkSize: number = 200;
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  private _selectedColumns: Column[] = [];
  @ViewChild('importer', { static: false })
  public importer?: FileUpload;
  public importChooseLabel: string = 'Import';
  public importLabel: string = 'Import CSV';

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private common: CommonService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private shoppingListIngredientsDataService: ShoppingListIngredientsDataService,
    private shoppingListIngredientsColumnsService: ShoppingListIngredientsColumnsService,
  ) {
    this.importChooseLabel = this.translate.instant('IMPORT');
    this.importLabel = this.translate.instant('IMPORTCSV');
  }

  ngOnInit(): void {
    console.log('ShoppingListIngredientsComponent', 'ngOnInit');
    this.isInitialized = true;
    this.titleService.setTitle('Shopping List Ingredients - Management');
    this.primengConfig.ripple = true;
    this.getShoppingListIngredients();
  }

  ngOnDestroy(): void {
    console.log('ShoppingListIngredientsComponent', 'ngOnDestroy');
    this.isInitialized = false;
  }

  getShoppingListIngredients(): void {
    if (!this.isInitialized) {
      return;
    }
    if (parseInt(this.parameters.offset + '', 10) === 0) {
      this.isLoading = true;
    }
    this.shoppingListIngredientsDataService.getShoppingListIngredients(this.parameters).subscribe((response: ShoppingListIngredientsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        if (parseInt(this.parameters.offset + '', 10) === 0) {
          this.shoppingListIngredients = response.results;
        } else {
          this.shoppingListIngredients = this.shoppingListIngredients.concat(response.results);
        }
        if (response.inlineCount > this.shoppingListIngredients.length) {
          this.parameters.offset = (parseInt(this.parameters.offset + '', 10) + parseInt(this.parameters.limit + '', 10)) + '';
          this.getShoppingListIngredients();
        } else {
          this.parameters.offset = '0';
        }
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SHOPPINGLISTINGREDIENTSNOTLOADED')});
    });
  }

  importShoppingListIngredients(event: any): void {
    if (typeof event.currentFiles === 'undefined') {
      return;
    }
    if (event.currentFiles.length === 0) {
      return;
    }
    const inputFile = event.currentFiles[0];
    const reader = new FileReader();
    let rows: any[] = [];
    reader.onload = (e: any) => {
      if (typeof this.importer !== 'undefined') {
        this.importer.clear();
      }
      const text = <string>e.target.result;
      rows = ToolsService.csvToArray(this.columns, text, ',');
      if (rows.length > 0) {
        let proceed = 0;
        const j = rows.length;
        for (let i = 0; i < j; i += this.chunkSize) {
          this.isLoading = true;
          const chunk = rows.slice(i, i + this.chunkSize);
          if (typeof chunk[0].id === 'undefined') {
            this.shoppingListIngredientsDataService.addShoppingListIngredients(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('SHOPPINGLISTINGREDIENTSCREATED')});
                if (proceed === j) {
                  this.getShoppingListIngredients();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SHOPPINGLISTINGREDIENTSNOTCREATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SHOPPINGLISTINGREDIENTSNOTCREATED') + ' ' + msg});
            });
          } else {
            this.shoppingListIngredientsDataService.updateShoppingListIngredients(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('SHOPPINGLISTINGREDIENTSUPDATED')});
                if (proceed === j) {
                  this.getShoppingListIngredients();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SHOPPINGLISTINGREDIENTSNOTUPDATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('SHOPPINGLISTINGREDIENTSNOTCREATED') + ' ' + msg});
            });
          }
        }
      }
    };
    reader.readAsText(inputFile);
  }

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.shoppingListIngredientsColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('shoppingListIngredients.' + this.columns[i].field + '.showFiled', show);
    }
  }

  deleteSelectedShoppingListIngredients() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Shopping List Ingredients?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.shoppingListIngredientsDataService.deleteShoppingListIngredients(this.selectedShoppingListIngredients).subscribe((response) => {
          if (response === null) {
            this.messageService.clear();
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Shopping List Ingredients Deleted', life: 3000});
            this.getShoppingListIngredients();
          } else {
            this.messageService.clear();
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Shopping List Ingredients not deleted'});
          }
        }, (error: any) => {
          this.messageService.clear();
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Shopping List Ingredients not deleted'});
        });
        this.selectedShoppingListIngredients = [];
      }
    });
  }

  exportPdf() {
    ToolsService.exportPdf(this.shoppingListIngredients, this.columns, 'ShoppingListIngredients');
  }

  exportExcel() {
    ToolsService.exportExcel(this.shoppingListIngredients, this.columns, 'ShoppingListIngredients');
  }

  exportCsv() {
    ToolsService.exportCsv(this.shoppingListIngredients, this.columns, 'ShoppingListIngredients');
  }

  refresh() {
    this.shoppingListIngredientsDataService.clearCache();
    this.getShoppingListIngredients();
  }

  shoppingListIngredientAdd(): void {
    if (!this.navigation.getParamValue('shoppingListIngredients.addInDialog', true)) {
      this.router.navigate(['/shopping-list-ingredients/add']);
      return;
    }
    this.shoppingListIngredientAddDialog = this.dialogService.open(DialogShoppingListIngredientFormComponent, {
      header: '', // this.translate.instant('NEWSHOPPINGLISTINGREDIENT'),
      width: '80%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'add',
        shoppingListIngredient: {id: null},
        shoppingLists: this.shoppingListIngredientsColumnsService.shoppingLists,
        ingredients: this.shoppingListIngredientsColumnsService.ingredients,
        users: this.shoppingListIngredientsColumnsService.users,
      },
    });

    this.shoppingListIngredientAddDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'info', summary: 'Success', detail: 'ShoppingListIngredient added'});
        this.getShoppingListIngredients();
      }
    });
  }

  shoppingListIngredientEdit(shoppingListIngredient: ShoppingListIngredient): void {
    if (!this.navigation.getParamValue('shoppingListIngredients.editInDialog', true)) {
      this.router.navigate(['/shopping-list-ingredients/edit/' + shoppingListIngredient.id]);
      return;
    }
    this.shoppingListIngredientEditDialog = this.dialogService.open(DialogShoppingListIngredientFormComponent, {
      header: '', // this.translate.instant('EDITSHOPPINGLISTINGREDIENT') + ' ' + shoppingListIngredient.id,
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'edit',
        shoppingListIngredient: shoppingListIngredient,
        shoppingLists: this.shoppingListIngredientsColumnsService.shoppingLists,
        ingredients: this.shoppingListIngredientsColumnsService.ingredients,
        users: this.shoppingListIngredientsColumnsService.users,
      },
    });

    this.shoppingListIngredientEditDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'ShoppingListIngredient updated'});
        this.getShoppingListIngredients();
      }
    });
  }

  shoppingListIngredientDetails(shoppingListIngredient: ShoppingListIngredient): void {
    if (!this.navigation.getParamValue('shoppingListIngredients.detailsInDialog', true)) {
      this.router.navigate(['/shopping-list-ingredients/details/' + shoppingListIngredient.id]);
      return;
    }
    this.shoppingListIngredientDetailsDialog = this.dialogService.open(DialogShoppingListIngredientDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        shoppingListIngredient: shoppingListIngredient,
      }
    });
  }

  shoppingListIngredientDelete(shoppingListIngredient: ShoppingListIngredient): void {
    this.shoppingListIngredientToDelete = shoppingListIngredient;
    this.messageService.clear();
    this.messageService.add({
      key: 'shoppingListIngredientDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want delete ' + shoppingListIngredient.id + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.shoppingListIngredientsDataService.deleteShoppingListIngredient(this.shoppingListIngredientToDelete).subscribe((res: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Shopping List Ingredient deleted'});
      this.getShoppingListIngredients();
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Shopping List Ingredient not deleted'});
    });
  }

  onDeleteReject(): void {
    this.shoppingListIngredientToDelete = {};
    this.messageService.clear('shoppingListIngredientDelete');
  }

}
