<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="step.id === null">{{ 'NEWSTEP' | translate }}</span>
            <span *ngIf="step.id !== null">{{ 'EDITSTEP' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="stepForm" id="stepForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="stepForm" >
            <input type="hidden" name="id" id="stepId" formControlName="id" />
            <div class="p-fluid p-grid">
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'STEPA' | translate }}</h2>
              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-info-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="stepDescription" formControlName="description" type="text" pInputText [required]="true"> 
                      <label for="stepDescription" class="req">{{ 'DESCRIPTION' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="stepForm.controls['description'].invalid && (stepForm.controls['description'].dirty || stepForm.controls['description'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="stepForm.controls['description'].errors !== null">
                  <div *ngIf="stepForm.controls['description'].errors.required">
                   {{ 'DESCRIPTIONREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-align-justify"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="stepDescriptionAr" formControlName="descriptionAr" type="text" pInputText [required]="false"> 
                      <label for="stepDescriptionAr" class="">{{ 'DESCRIPTIONAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-align-justify"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="stepDescriptionFr" formControlName="descriptionFr" type="text" pInputText [required]="false"> 
                      <label for="stepDescriptionFr" class="">{{ 'DESCRIPTIONFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
                <div class="p-col-6">
                
                  <div class="p-field p-col-12">
                    <div class="p-inputgroup">
                      <span class="p-inputgroup-addon">
                        <i class="icon-image"></i>
                      </span>
                      <span class="p-float-label">
                        <input id="stepPicture" formControlName="picture" type="text" pInputText [required]="false">
                        <label for="stepPicture" class="">{{ 'PICTURE' | translate }}</label>
                      </span>
                      <button type="button" pButton pRipple icon="pi pi-folder" styleClass="p-button-info"
                        (click)="openFilerDialog('picture')"></button>
                    </div>
                  </div>
                
                </div>
                <!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <h2>{{ 'STEPZ' | translate }}</h2>
              </div><!--/p-col-12-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="stepRecipeId" [autoDisplayFirst]="false" formControlName="recipe" [options]="stepsColumnsService.recipes" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="stepRecipeId" class="req">{{ 'RECIPE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="stepForm.controls['recipe'].invalid && (stepForm.controls['recipe'].dirty || stepForm.controls['recipe'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="stepForm.controls['recipe'].errors !== null">
                  <div *ngIf="stepForm.controls['recipe'].errors.required">
                   {{ 'RECIPEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-alarm-clock"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="stepFromTime" formControlName="fromTime" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="stepFromTime" class="">{{ 'FROMTIME' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-alarm-clock"></i>
                    </span>
                    <span class="p-float-label">
                      <p-inputNumber inputId="stepToTime" formControlName="toTime" [step]="1" [showButtons]="true" [required]="false"></p-inputNumber>
                      <label for="stepToTime" class="">{{ 'TOTIME' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKSTEPS' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="step.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!stepForm.valid || disableSubmit"> <!--  [disabled]="stepForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!stepForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

