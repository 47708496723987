import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { QuizAnswer, QuizAnswersResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class QuizAnswersDataService {

  private uri: string = `${environment.apiUrl}quiz-answers`;
  private cacheKey: string = 'quizAnswers.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getQuizAnswer(id: number): Observable<QuizAnswer|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getQuizAnswers(parameters: {[param: string]: string | string[]}): Observable<QuizAnswersResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addQuizAnswer(data: QuizAnswer): Observable<QuizAnswer|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addQuizAnswers(data: QuizAnswer[]): Observable<QuizAnswer[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updateQuizAnswer(data: QuizAnswer): Observable<QuizAnswer|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updateQuizAnswers(data: QuizAnswer[]): Observable<QuizAnswer[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchQuizAnswer(data: QuizAnswer): Observable<QuizAnswer|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchQuizAnswers(data: QuizAnswer[]): Observable<QuizAnswer[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deleteQuizAnswer(data: QuizAnswer): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deleteQuizAnswers(data: QuizAnswer[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((quizAnswer) => {
      ids.push(quizAnswer.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }

}
