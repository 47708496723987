import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MumEatNavItems } from '../../MumEat/mum-eat.nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {

  public sidebarMinimized = false;
  public navItems = MumEatNavItems;
  // public navItems = expertSanteNavItems.map(items => { this.translateNav(items); return items; });

  public locale: string | null = 'en';

  constructor(
    private router: Router,
    private translate: TranslateService
  ) {
  }

  toggleMinimize(e: any) {
    this.sidebarMinimized = e;
  }

  ngOnInit() {
    this.locale = localStorage.getItem('locale');

    this.translate.onLangChange.subscribe(() => {
      const translatedNavs = this.navItems.map(items => { this.translateNav(items); return items; });
      this.navItems = [];
      translatedNavs.forEach(val => this.navItems.push(Object.assign({}, val)));
      console.log('translatedNavs', translatedNavs);

    });
  }

  translateNav(item: any): void {
    const trans = this.translate.instant(`${item.name}`);
    item.name = trans;


    if (item.children && item.children.length > 0) {
      item.children.map((child: any) => this.translateNav(child));
    }
  }

  setLocale(locale: string) {

    this.locale = locale;

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(locale);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(locale);

    localStorage.setItem('locale', locale);
  }

}
