import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { CommonService } from '../../../services/common/common.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { LanguagesDataService, LanguagesColumnsService } from '../../services';
import { DialogLanguageFormComponent } from './language-form/language-form.component';
import { DialogLanguageDetailsComponent } from './language-details/language-details.component';
import { Language, LanguagesResult } from '../../interfaces';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class LanguagesComponent implements OnInit, OnDestroy {

  private languageAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private languageEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private languageDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  public languageToDelete: Language = {};
  public languages: Language[] = [];
  public language: Language = {};
  public selectedLanguages: Language[] = [];
  public submitted: boolean = false;
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '100'};
  public chunkSize: number = 200;
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'fr';
  private _selectedColumns: Column[] = [];
  @ViewChild('importer', { static: false })
  public importer?: FileUpload;
  public importChooseLabel: string = 'Import';
  public importLabel: string = 'Import CSV';

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private common: CommonService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private languagesDataService: LanguagesDataService,
    private languagesColumnsService: LanguagesColumnsService,
  ) {
    this.importChooseLabel = this.translate.instant('IMPORT');
    this.importLabel = this.translate.instant('IMPORTCSV');
  }

  ngOnInit(): void {
    console.log('LanguagesComponent', 'ngOnInit');
    this.isInitialized = true;
    this.titleService.setTitle('Languages - Management');
    this.primengConfig.ripple = true;
    this.getLanguages();
  }

  ngOnDestroy(): void {
    console.log('LanguagesComponent', 'ngOnDestroy');
    this.isInitialized = false;
  }

  getLanguages(): void {
    if (!this.isInitialized) {
      return;
    }
    if (parseInt(this.parameters.offset + '', 10) === 0) {
      this.isLoading = true;
    }
    this.languagesDataService.getLanguages(this.parameters).subscribe((response: LanguagesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        if (parseInt(this.parameters.offset + '', 10) === 0) {
          this.languages = response.results;
        } else {
          this.languages = this.languages.concat(response.results);
        }
        if (response.inlineCount > this.languages.length) {
          this.parameters.offset = (parseInt(this.parameters.offset + '', 10) + parseInt(this.parameters.limit + '', 10)) + '';
          this.getLanguages();
        } else {
          this.parameters.offset = '0';
        }
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('LANGUAGESNOTLOADED')});
    });
  }

  importLanguages(event: any): void {
    if (typeof event.currentFiles === 'undefined') {
      return;
    }
    if (event.currentFiles.length === 0) {
      return;
    }
    const inputFile = event.currentFiles[0];
    const reader = new FileReader();
    let rows: any[] = [];
    reader.onload = (e: any) => {
      if (typeof this.importer !== 'undefined') {
        this.importer.clear();
      }
      const text = <string>e.target.result;
      rows = ToolsService.csvToArray(this.columns, text, ',');
      if (rows.length > 0) {
        let proceed = 0;
        const j = rows.length;
        for (let i = 0; i < j; i += this.chunkSize) {
          this.isLoading = true;
          const chunk = rows.slice(i, i + this.chunkSize);
          if (typeof chunk[0].id === 'undefined') {
            this.languagesDataService.addLanguages(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('LANGUAGESCREATED')});
                if (proceed === j) {
                  this.getLanguages();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('LANGUAGESNOTCREATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('LANGUAGESNOTCREATED') + ' ' + msg});
            });
          } else {
            this.languagesDataService.updateLanguages(chunk).subscribe((response: any) => {
              this.isLoading = false;
              if (typeof response.length !== 'undefined') {
                proceed += response.length;
                this.messageService.add({severity: 'success', summary: this.translate.instant('NOTIFICATION'), detail: proceed + ' ' + this.translate.instant('LANGUAGESUPDATED')});
                if (proceed === j) {
                  this.getLanguages();
                }
              } else {
                this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('LANGUAGESNOTUPDATED')});
              }
            }, (error: any) => {
              this.isLoading = false;
              console.warn('import error', error);
              let msg = '';
              if (typeof error.error.length !== 'undefined') {
                msg = error.error;
              } else if (typeof error.error.errors.errors.children !== 'undefined') {
                const children = error.error.errors.errors.children;
                for (const field in children) {
                  if (typeof children[field].errors !== 'undefined') {
                    msg += field + ' ' + children[field].errors.join(', ');
                  }
                }
              }
              this.messageService.add({severity: 'warn', summary: this.translate.instant('ERROR'), detail: this.translate.instant('LANGUAGESNOTCREATED') + ' ' + msg});
            });
          }
        }
      }
    };
    reader.readAsText(inputFile);
  }

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.languagesColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('languages.' + this.columns[i].field + '.showFiled', show);
    }
  }

  deleteSelectedLanguages() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Languages?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.languagesDataService.deleteLanguages(this.selectedLanguages).subscribe((response) => {
          if (response === null) {
            this.messageService.clear();
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Languages Deleted', life: 3000});
            this.getLanguages();
          } else {
            this.messageService.clear();
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Languages not deleted'});
          }
        }, (error: any) => {
          this.messageService.clear();
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Languages not deleted'});
        });
        this.selectedLanguages = [];
      }
    });
  }

  exportPdf() {
    ToolsService.exportPdf(this.languages, this.columns, 'Languages');
  }

  exportExcel() {
    ToolsService.exportExcel(this.languages, this.columns, 'Languages');
  }

  exportCsv() {
    ToolsService.exportCsv(this.languages, this.columns, 'Languages');
  }

  refresh() {
    this.languagesDataService.clearCache();
    this.getLanguages();
  }

  languageAdd(): void {
    if (!this.navigation.getParamValue('languages.addInDialog', true)) {
      this.router.navigate(['/languages/add']);
      return;
    }
    this.languageAddDialog = this.dialogService.open(DialogLanguageFormComponent, {
      header: '', // this.translate.instant('NEWLANGUAGE'),
      width: '80%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'add',
        language: {id: null},
        users: this.languagesColumnsService.users,
      },
    });

    this.languageAddDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'info', summary: 'Success', detail: 'Language added'});
        this.getLanguages();
      }
    });
  }

  languageEdit(language: Language): void {
    if (!this.navigation.getParamValue('languages.editInDialog', true)) {
      this.router.navigate(['/languages/edit/' + language.id]);
      return;
    }
    this.languageEditDialog = this.dialogService.open(DialogLanguageFormComponent, {
      header: '', // this.translate.instant('EDITLANGUAGE') + ' ' + language.name,
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'edit',
        language: language,
        users: this.languagesColumnsService.users,
      },
    });

    this.languageEditDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Language updated'});
        this.getLanguages();
      }
    });
  }

  languageDetails(language: Language): void {
    if (!this.navigation.getParamValue('languages.detailsInDialog', true)) {
      this.router.navigate(['/languages/details/' + language.id]);
      return;
    }
    this.languageDetailsDialog = this.dialogService.open(DialogLanguageDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        language: language,
      }
    });
  }

  languageDelete(language: Language): void {
    this.languageToDelete = language;
    this.messageService.clear();
    this.messageService.add({
      key: 'languageDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want delete ' + language.name + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.languagesDataService.deleteLanguage(this.languageToDelete).subscribe((res: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Language deleted'});
      this.getLanguages();
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Language not deleted'});
    });
  }

  onDeleteReject(): void {
    this.languageToDelete = {};
    this.messageService.clear('languageDelete');
  }

}
