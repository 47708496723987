import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CacheService } from '../../services/cache/cache.service';
import { CommonService } from '../../services/common/common.service';
import { Column } from '../interfaces/column.interface';
import moment from 'moment';
import { QuizzesDataService } from './quizzes-data.service';
import { Quiz, QuizzesResult } from '../interfaces';
import { UsersDataService } from './users-data.service';
import { User, UsersResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class QuizAnswersColumnsService {

  public quizzes: Quiz[] = [];
  public users: User[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private cache: CacheService,
    private common: CommonService,
    private quizzesDataService: QuizzesDataService,
    private usersDataService: UsersDataService,

  ) {
    this.getQuizzes()
    this.getUsers()
  }

  getQuizzes(offset: number = 0): void {
    const cacheKey: string = 'quizzes.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.quizzes = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.quizzesDataService.getQuizzes(parameters).subscribe((response: QuizzesResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.quizzes = response.results;
        } else {
          this.quizzes = this.quizzes.concat(response.results);
        }
        if (response.inlineCount > this.quizzes.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getQuizzes(offset);
        }
        response.results = this.quizzes;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getUsers(offset: number = 0): void {
    const cacheKey: string = 'users.cache'
    const cache: any = this.cache.get(cacheKey);
    if (cache !== null && offset === 0) {
      this.users = cache.results
      return;
    }
    const parameters = Object.assign(this.defaultParameters, {offset: offset + ''});
    this.usersDataService.getUsers(parameters).subscribe((response: UsersResult|any) => {
      if (response !== null) {
        if (offset === 0) {
          this.users = response.results;
        } else {
          this.users = this.users.concat(response.results);
        }
        if (response.inlineCount > this.users.length) {
          offset = offset + parseInt(this.defaultParameters.limit + '', 10);
          this.getUsers(offset);
        }
        response.results = this.users;
        this.cache.set(cacheKey, response);
      }
    });
  }

  getColumns(): Column[] {
    return [
      { field: 'id', sortField: 'id', title: this.translate.instant('ID'), show: (this.navigation.getParamValue('quizAnswers.id.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'quiz', title: this.translate.instant('QUIZ'), type: 'linkValue', filterData: this.quizzes, show: (this.navigation.getParamValue('quizAnswers.quizId.showFiled', true) && true), displayInList: true, filterField: 'quiz.id', displayField: 'title', sortField: 'quiz.title', url: 'quizzes' },
      { field: 'answer', sortField: 'answer', title: this.translate.instant('ANSWER'), show: (this.navigation.getParamValue('quizAnswers.answer.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'isCorrect', sortField: 'isCorrect', title: this.translate.instant('ISCORRECT'), show: (this.navigation.getParamValue('quizAnswers.isCorrect.showFiled', true) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'isWinner', sortField: 'isWinner', title: this.translate.instant('ISWINNER'), show: (this.navigation.getParamValue('quizAnswers.isWinner.showFiled', true) && true), displayInList: true, type: 'booleanValue', filterData: this.navigation.booleanOptions },
      { field: 'responseTime', sortField: 'responseTime', title: this.translate.instant('RESPONSETIME'), show: (this.navigation.getParamValue('quizAnswers.responseTime.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'createdAt', sortField: 'createdAt', title: this.translate.instant('CREATEDAT'), show: (this.navigation.getParamValue('quizAnswers.createdAt.showFiled', true) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'creatorUser', title: this.translate.instant('CREATORUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('quizAnswers.creatorUser.showFiled', false) && true), displayInList: true, filterField: 'creatorUser.id', displayField: 'username', sortField: 'creatorUser.username', url: 'users' },
      { field: 'modifiedAt', sortField: 'modifiedAt', title: this.translate.instant('MODIFIEDAT'), show: (this.navigation.getParamValue('quizAnswers.modifiedAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'modifierUser', title: this.translate.instant('MODIFIERUSER'), type: 'linkValue', filterData: this.users, show: (this.navigation.getParamValue('quizAnswers.modifierUser.showFiled', false) && true), displayInList: true, filterField: 'modifierUser.id', displayField: 'username', sortField: 'modifierUser.username', url: 'users' },
      { field: 'actions', title: this.translate.instant('ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }

  format(fieldName: string, value: any) {
    if (fieldName.toLowerCase().indexOf('time') > -1) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    } else if (fieldName.toLowerCase().indexOf('date') > -1) {
      return moment(value).format('YYYY-MM-DD');
    }
    return value;
  }

}
