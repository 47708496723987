<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="city.id === null">{{ 'NEWCITY' | translate }}</span>
            <span *ngIf="city.id !== null">{{ 'EDITCITY' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="cityForm" id="cityForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="cityForm" >
            <input type="hidden" name="id" id="cityId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-flag"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="cityCountryId" [autoDisplayFirst]="false" formControlName="country" [options]="citiesColumnsService.countries" optionValue="id" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="cityCountryId" class="req">{{ 'COUNTRY' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="cityForm.controls['country'].invalid && (cityForm.controls['country'].dirty || cityForm.controls['country'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="cityForm.controls['country'].errors !== null">
                  <div *ngIf="cityForm.controls['country'].errors.required">
                   {{ 'COUNTRYREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-tag"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="cityName" formControlName="name" type="text" pInputText [required]="true"> 
                      <label for="cityName" class="req">{{ 'NAME' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="cityForm.controls['name'].invalid && (cityForm.controls['name'].dirty || cityForm.controls['name'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="cityForm.controls['name'].errors !== null">
                  <div *ngIf="cityForm.controls['name'].errors.required">
                   {{ 'NAMEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="cityNameAr" formControlName="nameAr" type="text" pInputText [required]="false"> 
                      <label for="cityNameAr" class="">{{ 'NAMEAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="cityNameFr" formControlName="nameFr" type="text" pInputText [required]="false"> 
                      <label for="cityNameFr" class="">{{ 'NAMEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                    <p-toggleButton inputId="cityPublished" formControlName="published" onIcon="pi pi-check" offIcon="pi pi-times"  [style]="{'width': '2em'}"></p-toggleButton>
                    <label for="cityPublished" class="p-d-inline-block p-ml-2 ">{{ 'PUBLISHED' | translate }}</label>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->

            <div class="p-fluid p-grid">
              <div class="p-col-4">
                <button (click)="list()" class="btn btn-primary pull-left">
                  <i class="icon-layout-list-thumb"></i>
                  <span class="p-ml-1">{{ 'BACKCITIES' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4 text-center">
                <button *ngIf="city.id !== null" type="button" (click)="submitForm(false)" class="btn btn-primary" [disabled]="!cityForm.valid || disableSubmit"> <!--  [disabled]="cityForm.$invalid" -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && !redirect"></i>
                  <i class="fas fa-check-circle" *ngIf="!(disableSubmit && !redirect)"></i>
                  <span class="p-ml-1"> {{ 'APPLY' | translate }}</span>
                </button>
              </div>
              <div class="p-col-4">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!cityForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

