import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { FilerFile } from '../../MumEat/interfaces/filer-file.interface';

@Injectable({
  providedIn: 'root'
})
export class FilerService {

  private uri = `${environment.apiUrl}filer`;

  constructor(private http: HttpClient) {

  }

  children(data: {path: string}): Observable<FilerFile[]|any> {
    return this.http.post(this.uri + `/children`, data);
  }

  mkdir(data: {path: string}): Observable<any> {
    return this.http.post(this.uri + `/mkdir`, data);
  }

  rename(data: {from: string, to: string}): Observable<any> {
    return this.http.post(this.uri + `/rename`, data);
  }

  copy(data: {from: string, to: string}): Observable<any> {
    return this.http.post(this.uri + `/copy`, data);
  }

  dump(data: {file: string, content: string}): Observable<any> {
    return this.http.post(this.uri + `/dump`, data);
  }

  remove(data: string[]): Observable<any> {
    return this.http.post(this.uri + `/remove`, data);
  }

  archive(data: string[]): Observable<any> {
    return this.http.post(this.uri + `/archive`, data);
  }

  content(data: {file: string}): Observable<any> {
    return this.http.post(this.uri + `/content`, data);
  }

  upload(path: string, file: any): any {
    const formData = new FormData();
    formData.append('file', file.data);
    formData.append('path', path);
    file.inProgress = true;
    this.postFile(formData).pipe(
      map((event: any) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          console.log(event.body);
          return event.body;
        }
      });
  }

  postFile(formData: FormData) {
    return this.http.post<any>(this.uri + `/upload`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

}
